export const faq = () => {
	return faqJson;
}

const faqJson = `[
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2801",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3161
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3161,
			"y": 40,
			"width": 473.333251953125,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2804",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3162.7216796875,
				"y": 44.70170593261719,
				"width": 113.266845703125,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2807",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3161.9375,
				"y": 104.09091186523438,
				"width": 438.3623046875,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2810",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3161.818115234375,
				"y": 297,
				"width": 446.34375,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "fbe06706aa8b1211c459b8605991cc2dbb07c9ea",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2802",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  513.333251953125
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3654.333251953125
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 513.333251953125,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3654.333251953125,
			"y": 40,
			"width": 473.333251953125,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2805",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3834.455810546875,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2808",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3673.463623046875,
				"y": 104.09091186523438,
				"width": 436.265869140625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2811",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3668.432373046875,
				"y": 297,
				"width": 445.752685546875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "df7a4ca08db360885c9a998d4a7bd338e5a422f2",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2803",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  1006.66650390625
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4147.66650390625
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 1006.66650390625,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 4147.66650390625,
			"y": 40,
			"width": 473.33349609375,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2806",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 4506.18994140625,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2809",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 4182.89306640625,
				"y": 104.09091186523438,
				"width": 436.43212890625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2812",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 4174.45556640625,
				"y": 297,
				"width": 445.7529296875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "a8b46f08a0353605aa40b2aa95b6a1282389d2b8",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_TEXT_CONTENT",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "FIXED",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-faq, makers-faq--three-columns-with-intro",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "454:2641",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Three columns with intro",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  13979
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 1440,
		  "height": 679,
		  "absoluteRenderBounds": {
			"x": 13979,
			"y": 40,
			"width": 1440,
			"height": 679
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "454:2668",
			  "parent": {
				"id": "454:2641"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  320
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  14299
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 320,
			  "y": 0,
			  "width": 800,
			  "height": 279,
			  "absoluteRenderBounds": {
				"x": 14299,
				"y": 40,
				"width": 800,
				"height": 279
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "454:2669",
				  "parent": {
					"id": "454:2668"
				  },
				  "name": "Text Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  14299
					],
					[
					  0,
					  1,
					  88
					]
				  ],
				  "x": 0,
				  "y": 48,
				  "width": 800,
				  "height": 183,
				  "absoluteRenderBounds": {
					"x": 14299,
					"y": 88,
					"width": 800,
					"height": 183
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "454:2670",
					  "parent": {
						"id": "454:2669"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14299
						],
						[
						  0,
						  1,
						  88
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 800,
					  "height": 183,
					  "absoluteRenderBounds": {
						"x": 14299,
						"y": 88,
						"width": 800,
						"height": 183
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I454:2670;394:2805",
						  "listSpacing": 0,
						  "parent": {
							"id": "454:2670"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.40392157435417175,
								"g": 0.5137255191802979,
								"b": 0.5960784554481506
							  }
							}
						  ],
						  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14299
							],
							[
							  0,
							  1,
							  88
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 800,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 14562.921875,
							"y": 93.30681610107422,
							"width": 270.91015625,
							"height": 17.95738983154297
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "We are here for you",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "UPPER",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I454:2670;394:2808",
						  "listSpacing": 0,
						  "parent": {
							"id": "454:2670"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14299
							],
							[
							  0,
							  1,
							  141
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 800,
						  "height": 58,
						  "absoluteRenderBounds": {
							"x": 14369.2685546875,
							"y": 150.09658813476562,
							"width": 660.822265625,
							"height": 46.72160339355469
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Frequently Asked Questions",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I454:2670;394:2811",
						  "listSpacing": 0,
						  "parent": {
							"id": "454:2670"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  163.33335876464844
							],
							[
							  0,
							  1,
							  135
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14462.3330078125
							],
							[
							  0,
							  1,
							  223
							]
						  ],
						  "x": 163.33335876464844,
						  "y": 135,
						  "width": 473.3332824707031,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 14476.4326171875,
							"y": 229,
							"width": 445.751953125,
							"height": 39.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2802"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 48,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "454:2642",
			  "parent": {
				"id": "454:2641"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  20
				],
				[
				  0,
				  1,
				  279
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  13999
				],
				[
				  0,
				  1,
				  319
				]
			  ],
			  "x": 20,
			  "y": 279,
			  "width": 1400,
			  "height": 400,
			  "absoluteRenderBounds": {
				"x": 13999,
				"y": 319,
				"width": 1400,
				"height": 400
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "columns",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "454:2643",
				  "parent": {
					"id": "454:2642"
				  },
				  "name": "Features Grid",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  150
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  14149
					],
					[
					  0,
					  1,
					  319
					]
				  ],
				  "x": 150,
				  "y": 0,
				  "width": 1100,
				  "height": 336,
				  "absoluteRenderBounds": {
					"x": 14149,
					"y": 319,
					"width": 1100,
					"height": 336
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "454:2647",
					  "parent": {
						"id": "454:2643"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14149
						],
						[
						  0,
						  1,
						  319
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 340,
					  "height": 336,
					  "absoluteRenderBounds": {
						"x": 14149,
						"y": 319,
						"width": 340,
						"height": 336
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2648",
						  "parent": {
							"id": "454:2647"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14149
							],
							[
							  0,
							  1,
							  319
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 191,
						  "absoluteRenderBounds": {
							"x": 14149,
							"y": 319,
							"width": 340,
							"height": 191
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2649",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2648"
							  },
							  "name": "How do you go from Figma to Site?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14149
								],
								[
								  0,
								  1,
								  319
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 48,
							  "absoluteRenderBounds": {
								"x": 14150.0908203125,
								"y": 321.7016906738281,
								"width": 336.1025390625,
								"height": 42.5625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "How do you go from Figma to Site?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2650",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2648"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14149
								],
								[
								  0,
								  1,
								  375
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 14149.5625,
								"y": 381.5,
								"width": 324.09765625,
								"height": 125.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2651",
						  "parent": {
							"id": "454:2647"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  223
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14149
							],
							[
							  0,
							  1,
							  542
							]
						  ],
						  "x": 0,
						  "y": 223,
						  "width": 340,
						  "height": 113,
						  "absoluteRenderBounds": {
							"x": 14149,
							"y": 542,
							"width": 340,
							"height": 113
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2652",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2651"
							  },
							  "name": "Why is Portugal so sunny?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14149
								],
								[
								  0,
								  1,
								  542
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14149.46875,
								"y": 544.7017211914062,
								"width": 303.9697265625,
								"height": 23.4801025390625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Why is Portugal so sunny?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2653",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2651"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14149
								],
								[
								  0,
								  1,
								  574
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 340,
							  "height": 81,
							  "absoluteRenderBounds": {
								"x": 14149.5625,
								"y": 580.5,
								"width": 292.18359375,
								"height": 71.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "454:2654",
					  "parent": {
						"id": "454:2643"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  380
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14529
						],
						[
						  0,
						  1,
						  319
						]
					  ],
					  "x": 380,
					  "y": 0,
					  "width": 340,
					  "height": 336,
					  "absoluteRenderBounds": {
						"x": 14529,
						"y": 319,
						"width": 340,
						"height": 336
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2655",
						  "parent": {
							"id": "454:2654"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14529
							],
							[
							  0,
							  1,
							  319
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 113,
						  "absoluteRenderBounds": {
							"x": 14529,
							"y": 319,
							"width": 340,
							"height": 113
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2656",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2655"
							  },
							  "name": "Where’s all the love?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  319
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14529.46875,
								"y": 322.30682373046875,
								"width": 234.8759765625,
								"height": 17.957366943359375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Where’s all the love?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2657",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2655"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  351
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 340,
							  "height": 81,
							  "absoluteRenderBounds": {
								"x": 14529.5625,
								"y": 357.5,
								"width": 292.18359375,
								"height": 71.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2658",
						  "parent": {
							"id": "454:2654"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  145
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14529
							],
							[
							  0,
							  1,
							  464
							]
						  ],
						  "x": 0,
						  "y": 145,
						  "width": 340,
						  "height": 191,
						  "absoluteRenderBounds": {
							"x": 14529,
							"y": 464,
							"width": 340,
							"height": 191
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2659",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2658"
							  },
							  "name": "How do you know Santa is coming?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  464
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 48,
							  "absoluteRenderBounds": {
								"x": 14530.0224609375,
								"y": 466.7016906738281,
								"width": 301.2529296875,
								"height": 47.480133056640625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "How do you know Santa is coming?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2660",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2658"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  520
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 14529.5625,
								"y": 526.5,
								"width": 324.09765625,
								"height": 125.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "454:2661",
					  "parent": {
						"id": "454:2643"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  760
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14909
						],
						[
						  0,
						  1,
						  319
						]
					  ],
					  "x": 760,
					  "y": 0,
					  "width": 340,
					  "height": 336,
					  "absoluteRenderBounds": {
						"x": 14909,
						"y": 319,
						"width": 340,
						"height": 336
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2662",
						  "parent": {
							"id": "454:2661"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14909
							],
							[
							  0,
							  1,
							  319
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 167,
						  "absoluteRenderBounds": {
							"x": 14909,
							"y": 319,
							"width": 340,
							"height": 167
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2663",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2662"
							  },
							  "name": "Where’s all the love?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  319
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14909.46875,
								"y": 322.30682373046875,
								"width": 234.8759765625,
								"height": 17.957366943359375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Where’s all the love?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2664",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2662"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  351
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 14909.5625,
								"y": 357.5,
								"width": 324.09765625,
								"height": 125.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2665",
						  "parent": {
							"id": "454:2661"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  199
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14909
							],
							[
							  0,
							  1,
							  518
							]
						  ],
						  "x": 0,
						  "y": 199,
						  "width": 340,
						  "height": 137,
						  "absoluteRenderBounds": {
							"x": 14909,
							"y": 518,
							"width": 340,
							"height": 137
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2666",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2665"
							  },
							  "name": "How do you know Santa is coming?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  518
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 48,
							  "absoluteRenderBounds": {
								"x": 14910.0224609375,
								"y": 520.7017211914062,
								"width": 301.2529296875,
								"height": 47.4801025390625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "How do you know Santa is coming?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2667",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2665"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  574
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 81,
							  "absoluteRenderBounds": {
								"x": 14909.5625,
								"y": 580.5,
								"width": 292.18359375,
								"height": 71.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. ",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "SPACE_BETWEEN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "a77986ca21743c268d01dddad398bed964efadea",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Three columns with intro"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-faq, makers-faq--three-columns",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "454:2613",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Three columns",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  719
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  13979
			],
			[
			  0,
			  1,
			  739
			]
		  ],
		  "x": 20,
		  "y": 719,
		  "width": 1440,
		  "height": 464,
		  "absoluteRenderBounds": {
			"x": 13979,
			"y": 739,
			"width": 1440,
			"height": 464
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "453:2541",
			  "parent": {
				"id": "454:2613"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  20
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  13999
				],
				[
				  0,
				  1,
				  739
				]
			  ],
			  "x": 20,
			  "y": 0,
			  "width": 1400,
			  "height": 464,
			  "absoluteRenderBounds": {
				"x": 13999,
				"y": 739,
				"width": 1400,
				"height": 464
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "columns",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "453:2542",
				  "parent": {
					"id": "453:2541"
				  },
				  "name": "Features Grid",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  150
					],
					[
					  0,
					  1,
					  64
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  14149
					],
					[
					  0,
					  1,
					  803
					]
				  ],
				  "x": 150,
				  "y": 64,
				  "width": 1100,
				  "height": 336,
				  "absoluteRenderBounds": {
					"x": 14149,
					"y": 803,
					"width": 1100,
					"height": 336
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "453:2543",
					  "parent": {
						"id": "453:2542"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14149
						],
						[
						  0,
						  1,
						  803
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 340,
					  "height": 158,
					  "absoluteRenderBounds": {
						"x": 14149,
						"y": 803,
						"width": 340,
						"height": 158
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "453:2544",
						  "listSpacing": 0,
						  "parent": {
							"id": "453:2543"
						  },
						  "name": "Frequently Asked Questions",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14149
							],
							[
							  0,
							  1,
							  803
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 78,
						  "absoluteRenderBounds": {
							"x": 14150.61328125,
							"y": 810.727294921875,
							"width": 271.9326171875,
							"height": 64.1817626953125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Frequently Asked Questions",
						  "hasMissingFont": false,
						  "fontSize": 32,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2599",
						  "listSpacing": 0,
						  "parent": {
							"id": "453:2543"
						  },
						  "name": "Can’t find the answer you’re looking for? Contact our customer support team.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  110
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14149
							],
							[
							  0,
							  1,
							  913
							]
						  ],
						  "x": 0,
						  "y": 110,
						  "width": 340,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 14149.9541015625,
							"y": 918.8181762695312,
							"width": 301.4111328125,
							"height": 39.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Can’t find the answer you’re looking for? Contact our customer support team.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "453:2545",
					  "parent": {
						"id": "453:2542"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  380
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14529
						],
						[
						  0,
						  1,
						  803
						]
					  ],
					  "x": 380,
					  "y": 0,
					  "width": 340,
					  "height": 336,
					  "absoluteRenderBounds": {
						"x": 14529,
						"y": 803,
						"width": 340,
						"height": 336
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2600",
						  "parent": {
							"id": "453:2545"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14529
							],
							[
							  0,
							  1,
							  803
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 191,
						  "absoluteRenderBounds": {
							"x": 14529,
							"y": 803,
							"width": 340,
							"height": 191
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2601",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2600"
							  },
							  "name": "How do you go from Figma to Site?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  803
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 48,
							  "absoluteRenderBounds": {
								"x": 14530.0908203125,
								"y": 805.7017211914062,
								"width": 336.1025390625,
								"height": 42.5625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "How do you go from Figma to Site?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2602",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2600"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  859
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 14529.5625,
								"y": 865.5,
								"width": 324.09765625,
								"height": 125.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2603",
						  "parent": {
							"id": "453:2545"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  223
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14529
							],
							[
							  0,
							  1,
							  1026
							]
						  ],
						  "x": 0,
						  "y": 223,
						  "width": 340,
						  "height": 113,
						  "absoluteRenderBounds": {
							"x": 14529,
							"y": 1026,
							"width": 340,
							"height": 113
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2604",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2603"
							  },
							  "name": "Why is Portugal so sunny?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  1026
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14529.46875,
								"y": 1028.70166015625,
								"width": 303.9697265625,
								"height": 23.4801025390625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Why is Portugal so sunny?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2605",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2603"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14529
								],
								[
								  0,
								  1,
								  1058
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 340,
							  "height": 81,
							  "absoluteRenderBounds": {
								"x": 14529.5625,
								"y": 1064.5,
								"width": 292.18359375,
								"height": 71.1817626953125
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "454:2606",
					  "parent": {
						"id": "453:2542"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  760
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14909
						],
						[
						  0,
						  1,
						  803
						]
					  ],
					  "x": 760,
					  "y": 0,
					  "width": 340,
					  "height": 336,
					  "absoluteRenderBounds": {
						"x": 14909,
						"y": 803,
						"width": 340,
						"height": 336
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2607",
						  "parent": {
							"id": "454:2606"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14909
							],
							[
							  0,
							  1,
							  803
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 113,
						  "absoluteRenderBounds": {
							"x": 14909,
							"y": 803,
							"width": 340,
							"height": 113
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2608",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2607"
							  },
							  "name": "Where’s all the love?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  803
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14909.46875,
								"y": 806.3068237304688,
								"width": 234.8759765625,
								"height": 17.9573974609375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Where’s all the love?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2609",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2607"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  835
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 340,
							  "height": 81,
							  "absoluteRenderBounds": {
								"x": 14909.5625,
								"y": 841.5,
								"width": 292.18359375,
								"height": 71.18182373046875
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2610",
						  "parent": {
							"id": "454:2606"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  145
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14909
							],
							[
							  0,
							  1,
							  948
							]
						  ],
						  "x": 0,
						  "y": 145,
						  "width": 340,
						  "height": 191,
						  "absoluteRenderBounds": {
							"x": 14909,
							"y": 948,
							"width": 340,
							"height": 191
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2611",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2610"
							  },
							  "name": "How do you know Santa is coming?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  948
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 48,
							  "absoluteRenderBounds": {
								"x": 14910.0224609375,
								"y": 950.7017211914062,
								"width": 301.2529296875,
								"height": 47.4801025390625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "How do you know Santa is coming?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2612",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2610"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14909
								],
								[
								  0,
								  1,
								  1004
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 14909.5625,
								"y": 1010.5,
								"width": 324.09765625,
								"height": 125.181884765625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "SPACE_BETWEEN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 64,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "f98d7a843bc1ebd09d597171c43c48e27c471d09",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Three columns"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-faq, makers-faq--two-columns",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "454:2615",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Two columns",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  1203
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  13979
			],
			[
			  0,
			  1,
			  1223
			]
		  ],
		  "x": 20,
		  "y": 1203,
		  "width": 1440,
		  "height": 514,
		  "absoluteRenderBounds": {
			"x": 13979,
			"y": 1223,
			"width": 1440,
			"height": 514
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "454:2616",
			  "parent": {
				"id": "454:2615"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  20
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  13999
				],
				[
				  0,
				  1,
				  1223
				]
			  ],
			  "x": 20,
			  "y": 0,
			  "width": 1400,
			  "height": 514,
			  "absoluteRenderBounds": {
				"x": 13999,
				"y": 1223,
				"width": 1400,
				"height": 514
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "columns",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "454:2617",
				  "parent": {
					"id": "454:2616"
				  },
				  "name": "Features Grid",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  150
					],
					[
					  0,
					  1,
					  64
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  14149
					],
					[
					  0,
					  1,
					  1287
					]
				  ],
				  "x": 150,
				  "y": 64,
				  "width": 1100,
				  "height": 386,
				  "absoluteRenderBounds": {
					"x": 14149,
					"y": 1287,
					"width": 1100,
					"height": 386
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "454:2618",
					  "parent": {
						"id": "454:2617"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14149
						],
						[
						  0,
						  1,
						  1287
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 340,
					  "height": 78,
					  "absoluteRenderBounds": {
						"x": 14149,
						"y": 1287,
						"width": 340,
						"height": 78
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2619",
						  "listSpacing": 0,
						  "parent": {
							"id": "454:2618"
						  },
						  "name": "Frequently Asked Questions",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14149
							],
							[
							  0,
							  1,
							  1287
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 78,
						  "absoluteRenderBounds": {
							"x": 14150.61328125,
							"y": 1294.727294921875,
							"width": 271.9326171875,
							"height": 64.1817626953125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Frequently Asked Questions",
						  "hasMissingFont": false,
						  "fontSize": 32,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "454:2621",
					  "parent": {
						"id": "454:2617"
					  },
					  "name": "Column",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  356
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  14505
						],
						[
						  0,
						  1,
						  1287
						]
					  ],
					  "x": 356,
					  "y": 0,
					  "width": 744,
					  "height": 386,
					  "absoluteRenderBounds": {
						"x": 14505,
						"y": 1287,
						"width": 744,
						"height": 386
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2622",
						  "parent": {
							"id": "454:2621"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14505
							],
							[
							  0,
							  1,
							  1287
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 744,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 14505,
							"y": 1287,
							"width": 744,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2623",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2622"
							  },
							  "name": "How do you go from Figma to Site?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1287
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 744,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14506.7216796875,
								"y": 1289.70166015625,
								"width": 399.419921875,
								"height": 23.4801025390625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "How do you go from Figma to Site?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2624",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2622"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1319
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 744,
							  "height": 54,
							  "absoluteRenderBounds": {
								"x": 14506.07421875,
								"y": 1325.5,
								"width": 734.7744140625,
								"height": 44.1817626953125
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2635",
						  "parent": {
							"id": "454:2621"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  118
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14505
							],
							[
							  0,
							  1,
							  1405
							]
						  ],
						  "x": 0,
						  "y": 118,
						  "width": 744,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 14505,
							"y": 1405,
							"width": 744,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2636",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2635"
							  },
							  "name": "Where’s all the love?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1405
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 744,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14505.46875,
								"y": 1408.3067626953125,
								"width": 234.8759765625,
								"height": 17.9573974609375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Where’s all the love?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2637",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2635"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1437
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 744,
							  "height": 54,
							  "absoluteRenderBounds": {
								"x": 14506.07421875,
								"y": 1443.5,
								"width": 734.7744140625,
								"height": 44.1817626953125
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur. Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2625",
						  "parent": {
							"id": "454:2621"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  236
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14505
							],
							[
							  0,
							  1,
							  1523
							]
						  ],
						  "x": 0,
						  "y": 236,
						  "width": 744,
						  "height": 59,
						  "absoluteRenderBounds": {
							"x": 14505,
							"y": 1523,
							"width": 744,
							"height": 59
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2626",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2625"
							  },
							  "name": "Why is Portugal so sunny?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1523
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 744,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14505.46875,
								"y": 1525.70166015625,
								"width": 303.9697265625,
								"height": 23.4801025390625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Why is Portugal so sunny?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2627",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2625"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1555
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 744,
							  "height": 27,
							  "absoluteRenderBounds": {
								"x": 14506.5849609375,
								"y": 1561.5,
								"width": 675.8681640625,
								"height": 17.1817626953125
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "454:2638",
						  "parent": {
							"id": "454:2621"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  327
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  14505
							],
							[
							  0,
							  1,
							  1614
							]
						  ],
						  "x": 0,
						  "y": 327,
						  "width": 744,
						  "height": 59,
						  "absoluteRenderBounds": {
							"x": 14505,
							"y": 1614,
							"width": 744,
							"height": 59
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2639",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2638"
							  },
							  "name": "How do you know Santa is coming?",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1614
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 744,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 14506.7216796875,
								"y": 1616.70166015625,
								"width": 405.255859375,
								"height": 23.4801025390625
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "How do you know Santa is coming?",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 24
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "454:2640",
							  "listSpacing": 0,
							  "parent": {
								"id": "454:2638"
							  },
							  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  32
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  14505
								],
								[
								  0,
								  1,
								  1646
								]
							  ],
							  "x": 0,
							  "y": 32,
							  "width": 744,
							  "height": 27,
							  "absoluteRenderBounds": {
								"x": 14506.5849609375,
								"y": 1652.5,
								"width": 675.8681640625,
								"height": 17.1817626953125
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 64,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "4c46e7bc193f8f1b2ca79366d3ffe06e22dc059c",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Two columns"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_FAQ",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	}
  ]`;
