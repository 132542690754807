<script>
    export default {
        render(createComponent) {
            return createComponent(
                `makers-${this.params.component.name.toLowerCase()}-component`,
                {props: {data: this.params, backPage: this.params.backPage}}
            );
        },
        props: ['params']
    }
</script>
