<template>
    <div class="makers-container">
        <div class="makers-welcome-card">
            <h1 class="type type--xxlarge type--bold">
                Welcome<template v-if="isAuthenticated"> {{user.name.replace(/ .*/,'')}}</template>! 👋
            </h1>
            <h3 class="type type--small">It's great to have you here. Add a new page or select a component to start building your site.</h3>
            <div class="oval-decoration"></div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';
    export default {
        computed: {
            ...mapGetters(['isAuthenticated', 'user']),
        },
    }
</script>