<template>
    <div class="makers-project-components">
        This will have all components for this project
    </div>
</template>

<script>
    export default {
        props: [''],
        data() {
            return {

            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
        }
    }
</script>