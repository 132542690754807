export const navigationBar = () => {
	return navigationBarJson;
}
const navigationBarJson = `[
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1516",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  640
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 640,
			"y": 40,
			"width": 115,
			"height": 46
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1520",
			  "listSpacing": 0,
			  "parent": {
				"id": "387:1516"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  668
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 669.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "9cf1282e4505fe911381e7709e212b11fc49b925",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "1",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1517",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  155
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  775
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 155,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 759,
			"y": 28,
			"width": 147,
			"height": 78
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1521",
			  "listSpacing": 0,
			  "parent": {
				"id": "387:1517"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  803
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 804.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "0688943cc3bc89a25aa64316266e92c7066d7daf",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1518",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  290
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  910
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 290,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 910,
			"y": 40,
			"width": 115,
			"height": 46
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1522",
			  "listSpacing": 0,
			  "parent": {
				"id": "387:1518"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  938
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 939.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "4a4841b2df89b2a8d26b8db80f7edf008e48d2c3",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "1",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1519",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  425
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1045
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 425,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 1029,
			"y": 28,
			"width": 147,
			"height": 78
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1523",
			  "listSpacing": 0,
			  "parent": {
				"id": "387:1519"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1073
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 1074.6553955078125,
				"y": 56.909088134765625,
				"width": 55.8233642578125,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b49290d7cedbbb0400e37e3e5d97b078f52afd4d",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BUTTON",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1527",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1240
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 68,
		  "height": 19,
		  "absoluteRenderBounds": {
			"x": 1240,
			"y": 40,
			"width": 68,
			"height": 19
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1529",
			  "listSpacing": 0,
			  "parent": {
				"id": "387:1527"
			  },
			  "name": "Nav Item",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1240
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 68,
			  "height": 19,
			  "absoluteRenderBounds": {
				"x": 1241.2784423828125,
				"y": 43.3636360168457,
				"width": 65.336669921875,
				"height": 11.829547882080078
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "SCALE",
				"vertical": "SCALE"
			  },
			  "characters": "Nav Item",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Medium"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "1c1564dd5f51d96b674bbf9ee5c21b280bbe951f",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "1",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1528",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  108
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1328
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 108,
		  "y": 20,
		  "width": 68,
		  "height": 19,
		  "absoluteRenderBounds": {
			"x": 1325.2784423828125,
			"y": 40,
			"width": 73.336669921875,
			"height": 23.19318389892578
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1530",
			  "listSpacing": 0,
			  "parent": {
				"id": "387:1528"
			  },
			  "name": "Nav Item",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [
				{
				  "type": "DROP_SHADOW",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0,
					"a": 0.15000000596046448
				  },
				  "offset": {
					"x": 0,
					"y": 4
				  },
				  "radius": 4,
				  "spread": 0,
				  "visible": true,
				  "blendMode": "NORMAL",
				  "showShadowBehindNode": false
				}
			  ],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1328
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 68,
			  "height": 19,
			  "absoluteRenderBounds": {
				"x": 1325.2784423828125,
				"y": 43.3636360168457,
				"width": 73.336669921875,
				"height": 19.829547882080078
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "SCALE",
				"vertical": "SCALE"
			  },
			  "characters": "Nav Item",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Medium"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 10,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "d831c2282e85e162205e6d3203a49c5143509aec",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_NAVIGATION_ITEM",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-387-1533.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1533",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Type=Logo",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1476
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 85,
		  "height": 30,
		  "absoluteRenderBounds": {
			"x": 1476,
			"y": 40,
			"width": 85,
			"height": 30
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1535",
			  "parent": {
				"id": "387:1533"
			  },
			  "name": "making.it logo",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1476
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 85,
			  "height": 30,
			  "absoluteRenderBounds": {
				"x": 1476,
				"y": 40,
				"width": 85,
				"height": 30
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1537",
				  "parent": {
					"id": "387:1535"
				  },
				  "name": "icon",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1476
					],
					[
					  0,
					  1,
					  45
					]
				  ],
				  "x": 0,
				  "y": 5,
				  "width": 34,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 1476,
					"y": 45,
					"width": 34,
					"height": 20
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1539",
					  "parent": {
						"id": "387:1537"
					  },
					  "name": "Rectangle 5",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  0
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  11.313720703125
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1476
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  56.313720703125
						]
					  ],
					  "x": 0,
					  "y": 11.313720703125,
					  "width": 16.000017166137695,
					  "height": 4.0000200271606445,
					  "absoluteRenderBounds": {
						"x": 1476,
						"y": 45,
						"width": 14.1422119140625,
						"height": 14.142162322998047
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.8941176533699036,
							"b": 0.3686274588108063
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1541",
					  "parent": {
						"id": "387:1537"
					  },
					  "name": "Rectangle 6",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  7.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  14.14215087890625
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1483.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  59.14215087890625
						]
					  ],
					  "x": 7.14208984375,
					  "y": 14.14215087890625,
					  "width": 20.000017166137695,
					  "height": 4.0000200271606445,
					  "absoluteRenderBounds": {
						"x": 1483.14208984375,
						"y": 45.000003814697266,
						"width": 16.9705810546875,
						"height": 16.97058868408203
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.3686274588108063,
							"g": 0.6705882549285889,
							"b": 0.8901960849761963
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1543",
					  "parent": {
						"id": "387:1537"
					  },
					  "name": "Rectangle 7",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  14.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  16.970550537109375
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1490.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  61.970550537109375
						]
					  ],
					  "x": 14.11279296875,
					  "y": 16.970550537109375,
					  "width": 24.000017166137695,
					  "height": 4.0000200271606445,
					  "absoluteRenderBounds": {
						"x": 1490.11279296875,
						"y": 45,
						"width": 19.799072265625,
						"height": 19.798988342285156
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.38823530077934265,
							"b": 0.572549045085907
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1545",
				  "listSpacing": 0,
				  "parent": {
					"id": "387:1535"
				  },
				  "name": "Logo",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.03947368264198303,
						"g": 0.16421058773994446,
						"b": 0.2605263292789459
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  42
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1518
					],
					[
					  0,
					  1,
					  40
					]
				  ],
				  "x": 42,
				  "y": 0,
				  "width": 43,
				  "height": 30,
				  "absoluteRenderBounds": {
					"x": 1519.457763671875,
					"y": 48.909088134765625,
					"width": 40.6741943359375,
					"height": 16.977279663085938
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Logo",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 30
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Medium"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 8,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 10,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "676e8e12e9330034246ee2a3420b4b504b3af0af",
		  "reactions": [],
		  "variantProperties": {
			"Type": "Logo"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-387-1534.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1534",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Type=Icon",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  70
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1476
			],
			[
			  0,
			  1,
			  90
			]
		  ],
		  "x": 20,
		  "y": 70,
		  "width": 34,
		  "height": 34,
		  "absoluteRenderBounds": {
			"x": 1476,
			"y": 90,
			"width": 34,
			"height": 34
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1536",
			  "parent": {
				"id": "387:1534"
			  },
			  "name": "making.it logo",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1476
				],
				[
				  0,
				  1,
				  90
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 34,
			  "height": 34,
			  "absoluteRenderBounds": {
				"x": 1476,
				"y": 90,
				"width": 34,
				"height": 34
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1538",
				  "parent": {
					"id": "387:1536"
				  },
				  "name": "icon",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  7
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1476
					],
					[
					  0,
					  1,
					  97
					]
				  ],
				  "x": 0,
				  "y": 7,
				  "width": 34,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 1476,
					"y": 97,
					"width": 34,
					"height": 20
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1540",
					  "parent": {
						"id": "387:1538"
					  },
					  "name": "Rectangle 5",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  0
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  11.313720703125
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1476
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  108.313720703125
						]
					  ],
					  "x": 0,
					  "y": 11.313720703125,
					  "width": 16.000017166137695,
					  "height": 4.0000200271606445,
					  "absoluteRenderBounds": {
						"x": 1476,
						"y": 97,
						"width": 14.1422119140625,
						"height": 14.142158508300781
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.8941176533699036,
							"b": 0.3686274588108063
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1542",
					  "parent": {
						"id": "387:1538"
					  },
					  "name": "Rectangle 6",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  7.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  14.14215087890625
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1483.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  111.14215087890625
						]
					  ],
					  "x": 7.14208984375,
					  "y": 14.14215087890625,
					  "width": 20.000017166137695,
					  "height": 4.0000200271606445,
					  "absoluteRenderBounds": {
						"x": 1483.14208984375,
						"y": 97,
						"width": 16.9705810546875,
						"height": 16.97058868408203
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.3686274588108063,
							"g": 0.6705882549285889,
							"b": 0.8901960849761963
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1544",
					  "parent": {
						"id": "387:1538"
					  },
					  "name": "Rectangle 7",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  14.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  16.970550537109375
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1490.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  113.97055053710938
						]
					  ],
					  "x": 14.11279296875,
					  "y": 16.970550537109375,
					  "width": 24.000017166137695,
					  "height": 4.0000200271606445,
					  "absoluteRenderBounds": {
						"x": 1490.11279296875,
						"y": 97,
						"width": 19.799072265625,
						"height": 19.798988342285156
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.38823530077934265,
							"b": 0.572549045085907
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 4,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 20,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "12007d3bd0ec58d11f2138e467853f78ce702bd4",
		  "reactions": [],
		  "variantProperties": {
			"Type": "Icon"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BRAND_LOGO",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-navigation-bar--right",
		  "makersComponent": "navbar",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1547",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Type=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1641
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 1440,
		  "height": 94,
		  "absoluteRenderBounds": {
			"x": 1641,
			"y": 40,
			"width": 1440,
			"height": 94
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "nav",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1550",
			  "parent": {
				"id": "387:1547"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  170
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1811
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 170,
			  "y": 0,
			  "width": 1100,
			  "height": 94,
			  "absoluteRenderBounds": {
				"x": 1811,
				"y": 40,
				"width": 1100,
				"height": 94
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "start",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1553",
				  "parent": {
					"id": "387:1550"
				  },
				  "name": "Left",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1811
					],
					[
					  0,
					  1,
					  72
					]
				  ],
				  "x": 0,
				  "y": 32,
				  "width": 85,
				  "height": 30,
				  "absoluteRenderBounds": {
					"x": 1811,
					"y": 72,
					"width": 85,
					"height": 30
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-387-1556.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1556",
					  "parent": {
						"id": "387:1553"
					  },
					  "name": "MAKERS_BRAND_LOGO",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  1811
						],
						[
						  0,
						  1,
						  72
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 85,
					  "height": 30,
					  "absoluteRenderBounds": {
						"x": 1811,
						"y": 72,
						"width": 85,
						"height": 30
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I387:1556;387:1535",
						  "parent": {
							"id": "387:1556"
						  },
						  "name": "making.it logo",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  1811
							],
							[
							  0,
							  1,
							  72
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 85,
						  "height": 30,
						  "absoluteRenderBounds": {
							"x": 1811,
							"y": 72,
							"width": 85,
							"height": 30
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1556;387:1537",
							  "parent": {
								"id": "I387:1556;387:1535"
							  },
							  "name": "icon",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  5
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  1811
								],
								[
								  0,
								  1,
								  77
								]
							  ],
							  "x": 0,
							  "y": 5,
							  "width": 34,
							  "height": 20,
							  "absoluteRenderBounds": {
								"x": 1811,
								"y": 77,
								"width": 34,
								"height": 20
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1556;387:1539",
								  "parent": {
									"id": "I387:1556;387:1537"
								  },
								  "name": "Rectangle 5",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  0
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  11.313720703125
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1811
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  88.313720703125
									]
								  ],
								  "x": 0,
								  "y": 11.313720703125,
								  "width": 16.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1811,
									"y": 77,
									"width": 14.1422119140625,
									"height": 14.142158508300781
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.8941176533699036,
										"b": 0.3686274588108063
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1556;387:1541",
								  "parent": {
									"id": "I387:1556;387:1537"
								  },
								  "name": "Rectangle 6",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  7.14208984375
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  14.14215087890625
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1818.14208984375
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  91.14215087890625
									]
								  ],
								  "x": 7.14208984375,
								  "y": 14.14215087890625,
								  "width": 20.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1818.14208984375,
									"y": 77,
									"width": 16.9705810546875,
									"height": 16.97058868408203
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.3686274588108063,
										"g": 0.6705882549285889,
										"b": 0.8901960849761963
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1556;387:1543",
								  "parent": {
									"id": "I387:1556;387:1537"
								  },
								  "name": "Rectangle 7",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  14.11279296875
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  16.970550537109375
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1825.11279296875
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  93.97055053710938
									]
								  ],
								  "x": 14.11279296875,
								  "y": 16.970550537109375,
								  "width": 24.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1825.11279296875,
									"y": 77,
									"width": 19.799072265625,
									"height": 19.798988342285156
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.38823530077934265,
										"b": 0.572549045085907
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": true,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "NONE",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1556;387:1545",
							  "listSpacing": 0,
							  "parent": {
								"id": "I387:1556;387:1535"
							  },
							  "name": "Logo",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  42
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  1853
								],
								[
								  0,
								  1,
								  72
								]
							  ],
							  "x": 42,
							  "y": 0,
							  "width": 43,
							  "height": 30,
							  "absoluteRenderBounds": {
								"x": 1854.457763671875,
								"y": 80.90908813476562,
								"width": 40.6741943359375,
								"height": 16.977279663085938
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Logo",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 30
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 10,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "387:1533"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Type": "Logo"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 32,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "end",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1578",
				  "parent": {
					"id": "387:1550"
				  },
				  "name": "Right",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  590
					],
					[
					  0,
					  1,
					  24
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2401
					],
					[
					  0,
					  1,
					  64
					]
				  ],
				  "x": 590,
				  "y": 24,
				  "width": 510,
				  "height": 46,
				  "absoluteRenderBounds": {
					"x": 2401,
					"y": 64,
					"width": 510,
					"height": 46
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "columns",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1582",
					  "parent": {
						"id": "387:1578"
					  },
					  "name": "links",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  13.5
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2401
						],
						[
						  0,
						  1,
						  77.5
						]
					  ],
					  "x": 0,
					  "y": 13.5,
					  "width": 371,
					  "height": 19,
					  "absoluteRenderBounds": {
						"x": 2401,
						"y": 77.5,
						"width": 371,
						"height": 19
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1584",
						  "parent": {
							"id": "387:1582"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  27
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2428
							],
							[
							  0,
							  1,
							  77.5
							]
						  ],
						  "x": 27,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2428,
							"y": 77.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1584;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1584"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2428
								],
								[
								  0,
								  1,
								  77.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2429.2783203125,
								"y": 80.86363220214844,
								"width": 65.336669921875,
								"height": 11.829551696777344
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1590",
						  "parent": {
							"id": "387:1582"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  119
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2520
							],
							[
							  0,
							  1,
							  77.5
							]
						  ],
						  "x": 119,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2520,
							"y": 77.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1590;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1590"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2520
								],
								[
								  0,
								  1,
								  77.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2521.2783203125,
								"y": 80.86363220214844,
								"width": 65.336669921875,
								"height": 11.829551696777344
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1596",
						  "parent": {
							"id": "387:1582"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  211
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2612
							],
							[
							  0,
							  1,
							  77.5
							]
						  ],
						  "x": 211,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2612,
							"y": 77.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1596;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1596"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2612
								],
								[
								  0,
								  1,
								  77.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2613.2783203125,
								"y": 80.86363220214844,
								"width": 65.336669921875,
								"height": 11.829551696777344
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1602",
						  "parent": {
							"id": "387:1582"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  303
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2704
							],
							[
							  0,
							  1,
							  77.5
							]
						  ],
						  "x": 303,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2704,
							"y": 77.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1602;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1602"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2704
								],
								[
								  0,
								  1,
								  77.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2705.2783203125,
								"y": 80.86363220214844,
								"width": 65.336669921875,
								"height": 11.829551696777344
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 27,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MAX",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1607",
					  "parent": {
						"id": "387:1578"
					  },
					  "name": "MAKERS_BUTTON",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  395
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2796
						],
						[
						  0,
						  1,
						  64
						]
					  ],
					  "x": 395,
					  "y": 0,
					  "width": 115,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 2796,
						"y": 64,
						"width": 115,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I387:1607;387:1520",
						  "listSpacing": 0,
						  "parent": {
							"id": "387:1607"
						  },
						  "name": "Button",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  28
							],
							[
							  0,
							  1,
							  3
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2824
							],
							[
							  0,
							  1,
							  67
							]
						  ],
						  "x": 28,
						  "y": 3,
						  "width": 59,
						  "height": 40,
						  "absoluteRenderBounds": {
							"x": 2825.655517578125,
							"y": 80.90908813476562,
							"width": 55.8232421875,
							"height": 13.282669067382812
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Button",
						  "hasMissingFont": false,
						  "fontSize": 18,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PIXELS",
							"value": 40
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0,
							"g": 0.4117647111415863,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 10,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 10,
					  "topRightRadius": 10,
					  "bottomLeftRadius": 10,
					  "bottomRightRadius": 10,
					  "paddingLeft": 28,
					  "paddingRight": 28,
					  "paddingTop": 3,
					  "paddingBottom": 3,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "387:1516"
					  },
					  "scaleFactor": 1,
					  "reactions": [
						{
						  "action": {
							"type": "NODE",
							"destinationId": "1",
							"navigation": "CHANGE_TO",
							"transition": {
							  "type": "SMART_ANIMATE",
							  "easing": {
								"type": "EASE_IN_AND_OUT"
							  },
							  "duration": 0.30000001192092896
							},
							"preserveScrollPosition": false
						  },
						  "trigger": {
							"type": "ON_HOVER"
						  }
						}
					  ],
					  "variantProperties": {
						"State": "Default",
						"Type": "Primary"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MAX",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 24,
			  "paddingBottom": 24,
			  "primaryAxisAlignItems": "SPACE_BETWEEN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 127,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "FIXED",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "5b9167ffd11d586afa7b69fa79e244ad3c6e5609",
		  "reactions": [],
		  "variantProperties": {
			"Type": "Right"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-navigation-bar--left",
		  "makersComponent": "navbar",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1548",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Type=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  134
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1641
			],
			[
			  0,
			  1,
			  154
			]
		  ],
		  "x": 20,
		  "y": 134,
		  "width": 1440,
		  "height": 94,
		  "absoluteRenderBounds": {
			"x": 1641,
			"y": 154,
			"width": 1440,
			"height": 94
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "nav",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1551",
			  "parent": {
				"id": "387:1548"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  170
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1811
				],
				[
				  0,
				  1,
				  154
				]
			  ],
			  "x": 170,
			  "y": 0,
			  "width": 1100,
			  "height": 94,
			  "absoluteRenderBounds": {
				"x": 1811,
				"y": 154,
				"width": 1100,
				"height": 94
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "start",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1554",
				  "parent": {
					"id": "387:1551"
				  },
				  "name": "Left",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1811
					],
					[
					  0,
					  1,
					  186
					]
				  ],
				  "x": 0,
				  "y": 32,
				  "width": 473,
				  "height": 30,
				  "absoluteRenderBounds": {
					"x": 1811,
					"y": 186,
					"width": 473,
					"height": 30
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-387-1563.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1563",
					  "parent": {
						"id": "387:1554"
					  },
					  "name": "MAKERS_BRAND_LOGO",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  1811
						],
						[
						  0,
						  1,
						  186
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 85,
					  "height": 30,
					  "absoluteRenderBounds": {
						"x": 1811,
						"y": 186,
						"width": 85,
						"height": 30
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I387:1563;387:1535",
						  "parent": {
							"id": "387:1563"
						  },
						  "name": "making.it logo",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  1811
							],
							[
							  0,
							  1,
							  186
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 85,
						  "height": 30,
						  "absoluteRenderBounds": {
							"x": 1811,
							"y": 186,
							"width": 85,
							"height": 30
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1563;387:1537",
							  "parent": {
								"id": "I387:1563;387:1535"
							  },
							  "name": "icon",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  5
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  1811
								],
								[
								  0,
								  1,
								  191
								]
							  ],
							  "x": 0,
							  "y": 5,
							  "width": 34,
							  "height": 20,
							  "absoluteRenderBounds": {
								"x": 1811,
								"y": 191,
								"width": 34,
								"height": 20
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1563;387:1539",
								  "parent": {
									"id": "I387:1563;387:1537"
								  },
								  "name": "Rectangle 5",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  0
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  11.313720703125
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1811
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  202.313720703125
									]
								  ],
								  "x": 0,
								  "y": 11.313720703125,
								  "width": 16.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1811,
									"y": 191,
									"width": 14.1422119140625,
									"height": 14.142166137695312
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.8941176533699036,
										"b": 0.3686274588108063
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1563;387:1541",
								  "parent": {
									"id": "I387:1563;387:1537"
								  },
								  "name": "Rectangle 6",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  7.14208984375
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  14.14215087890625
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1818.14208984375
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  205.14215087890625
									]
								  ],
								  "x": 7.14208984375,
								  "y": 14.14215087890625,
								  "width": 20.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1818.14208984375,
									"y": 191,
									"width": 16.9705810546875,
									"height": 16.970596313476562
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.3686274588108063,
										"g": 0.6705882549285889,
										"b": 0.8901960849761963
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1563;387:1543",
								  "parent": {
									"id": "I387:1563;387:1537"
								  },
								  "name": "Rectangle 7",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  14.11279296875
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  16.970550537109375
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1825.11279296875
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  207.97055053710938
									]
								  ],
								  "x": 14.11279296875,
								  "y": 16.970550537109375,
								  "width": 24.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1825.11279296875,
									"y": 191,
									"width": 19.799072265625,
									"height": 19.798995971679688
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.38823530077934265,
										"b": 0.572549045085907
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": true,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "NONE",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1563;387:1545",
							  "listSpacing": 0,
							  "parent": {
								"id": "I387:1563;387:1535"
							  },
							  "name": "Logo",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  42
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  1853
								],
								[
								  0,
								  1,
								  186
								]
							  ],
							  "x": 42,
							  "y": 0,
							  "width": 43,
							  "height": 30,
							  "absoluteRenderBounds": {
								"x": 1854.457763671875,
								"y": 194.90908813476562,
								"width": 40.6741943359375,
								"height": 16.977279663085938
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Logo",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 30
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 10,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "387:1533"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Type": "Logo"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "columns",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1577",
					  "parent": {
						"id": "387:1554"
					  },
					  "name": "links",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  109
						],
						[
						  0,
						  1,
						  5.5
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  1920
						],
						[
						  0,
						  1,
						  191.5
						]
					  ],
					  "x": 109,
					  "y": 5.5,
					  "width": 364,
					  "height": 19,
					  "absoluteRenderBounds": {
						"x": 1920,
						"y": 191.5,
						"width": 364,
						"height": 19
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1580",
						  "parent": {
							"id": "387:1577"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  1920
							],
							[
							  0,
							  1,
							  191.5
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 1920,
							"y": 191.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1580;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1580"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  1920
								],
								[
								  0,
								  1,
								  191.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 1921.2784423828125,
								"y": 194.86363220214844,
								"width": 65.336669921875,
								"height": 11.829544067382812
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1588",
						  "parent": {
							"id": "387:1577"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  92
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2012
							],
							[
							  0,
							  1,
							  191.5
							]
						  ],
						  "x": 92,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2012,
							"y": 191.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1588;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1588"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2012
								],
								[
								  0,
								  1,
								  191.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2013.2784423828125,
								"y": 194.86363220214844,
								"width": 65.3365478515625,
								"height": 11.829544067382812
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1594",
						  "parent": {
							"id": "387:1577"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  184
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2104
							],
							[
							  0,
							  1,
							  191.5
							]
						  ],
						  "x": 184,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2104,
							"y": 191.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1594;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1594"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2104
								],
								[
								  0,
								  1,
								  191.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2105.2783203125,
								"y": 194.86363220214844,
								"width": 65.336669921875,
								"height": 11.829544067382812
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1600",
						  "parent": {
							"id": "387:1577"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  276
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2196
							],
							[
							  0,
							  1,
							  191.5
							]
						  ],
						  "x": 276,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2196,
							"y": 191.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1600;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1600"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2196
								],
								[
								  0,
								  1,
								  191.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2197.2783203125,
								"y": 194.86363220214844,
								"width": 65.336669921875,
								"height": 11.829544067382812
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 20,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "end",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1606",
				  "parent": {
					"id": "387:1551"
				  },
				  "name": "Right",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  985
					],
					[
					  0,
					  1,
					  24
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2796
					],
					[
					  0,
					  1,
					  178
					]
				  ],
				  "x": 985,
				  "y": 24,
				  "width": 115,
				  "height": 46,
				  "absoluteRenderBounds": {
					"x": 2796,
					"y": 178,
					"width": 115,
					"height": 46
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1609",
					  "parent": {
						"id": "387:1606"
					  },
					  "name": "MAKERS_BUTTON",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2796
						],
						[
						  0,
						  1,
						  178
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 115,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 2796,
						"y": 178,
						"width": 115,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I387:1609;387:1520",
						  "listSpacing": 0,
						  "parent": {
							"id": "387:1609"
						  },
						  "name": "Button",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  28
							],
							[
							  0,
							  1,
							  3
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2824
							],
							[
							  0,
							  1,
							  181
							]
						  ],
						  "x": 28,
						  "y": 3,
						  "width": 59,
						  "height": 40,
						  "absoluteRenderBounds": {
							"x": 2825.655517578125,
							"y": 194.90908813476562,
							"width": 55.8232421875,
							"height": 13.282669067382812
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Button",
						  "hasMissingFont": false,
						  "fontSize": 18,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PIXELS",
							"value": 40
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0,
							"g": 0.4117647111415863,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 10,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 10,
					  "topRightRadius": 10,
					  "bottomLeftRadius": 10,
					  "bottomRightRadius": 10,
					  "paddingLeft": 28,
					  "paddingRight": 28,
					  "paddingTop": 3,
					  "paddingBottom": 3,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "387:1516"
					  },
					  "scaleFactor": 1,
					  "reactions": [
						{
						  "action": {
							"type": "NODE",
							"destinationId": "1",
							"navigation": "CHANGE_TO",
							"transition": {
							  "type": "SMART_ANIMATE",
							  "easing": {
								"type": "EASE_IN_AND_OUT"
							  },
							  "duration": 0.30000001192092896
							},
							"preserveScrollPosition": false
						  },
						  "trigger": {
							"type": "ON_HOVER"
						  }
						}
					  ],
					  "variantProperties": {
						"State": "Default",
						"Type": "Primary"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MAX",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 24,
			  "paddingBottom": 24,
			  "primaryAxisAlignItems": "SPACE_BETWEEN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 127,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "FIXED",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "c8abe76ff9a4c637f08e7eb947e338079b8962c8",
		  "reactions": [],
		  "variantProperties": {
			"Type": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-navigation-bar--center",
		  "makersComponent": "navbar",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "387:1549",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Type=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  248
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1641
			],
			[
			  0,
			  1,
			  268
			]
		  ],
		  "x": 20,
		  "y": 248,
		  "width": 1440,
		  "height": 94,
		  "absoluteRenderBounds": {
			"x": 1641,
			"y": 268,
			"width": 1440,
			"height": 94
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "nav",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "387:1552",
			  "parent": {
				"id": "387:1549"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  170
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1811
				],
				[
				  0,
				  1,
				  268
				]
			  ],
			  "x": 170,
			  "y": 0,
			  "width": 1100,
			  "height": 94,
			  "absoluteRenderBounds": {
				"x": 1811,
				"y": 268,
				"width": 1100,
				"height": 94
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "start",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1555",
				  "parent": {
					"id": "387:1552"
				  },
				  "name": "Left",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1811
					],
					[
					  0,
					  1,
					  300
					]
				  ],
				  "x": 0,
				  "y": 32,
				  "width": 200,
				  "height": 30,
				  "absoluteRenderBounds": {
					"x": 1811,
					"y": 300,
					"width": 200,
					"height": 30
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-387-1570.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1570",
					  "parent": {
						"id": "387:1555"
					  },
					  "name": "MAKERS_BRAND_LOGO",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  1811
						],
						[
						  0,
						  1,
						  300
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 85,
					  "height": 30,
					  "absoluteRenderBounds": {
						"x": 1811,
						"y": 300,
						"width": 85,
						"height": 30
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I387:1570;387:1535",
						  "parent": {
							"id": "387:1570"
						  },
						  "name": "making.it logo",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  1811
							],
							[
							  0,
							  1,
							  300
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 85,
						  "height": 30,
						  "absoluteRenderBounds": {
							"x": 1811,
							"y": 300,
							"width": 85,
							"height": 30
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1570;387:1537",
							  "parent": {
								"id": "I387:1570;387:1535"
							  },
							  "name": "icon",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  5
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  1811
								],
								[
								  0,
								  1,
								  305
								]
							  ],
							  "x": 0,
							  "y": 5,
							  "width": 34,
							  "height": 20,
							  "absoluteRenderBounds": {
								"x": 1811,
								"y": 305,
								"width": 34,
								"height": 20
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1570;387:1539",
								  "parent": {
									"id": "I387:1570;387:1537"
								  },
								  "name": "Rectangle 5",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  0
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  11.313720703125
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1811
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  316.313720703125
									]
								  ],
								  "x": 0,
								  "y": 11.313720703125,
								  "width": 16.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1811,
									"y": 305,
									"width": 14.1422119140625,
									"height": 14.14215087890625
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.8941176533699036,
										"b": 0.3686274588108063
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1570;387:1541",
								  "parent": {
									"id": "I387:1570;387:1537"
								  },
								  "name": "Rectangle 6",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  7.14208984375
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  14.14215087890625
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1818.14208984375
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  319.14215087890625
									]
								  ],
								  "x": 7.14208984375,
								  "y": 14.14215087890625,
								  "width": 20.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1818.14208984375,
									"y": 305,
									"width": 16.9705810546875,
									"height": 16.9705810546875
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.3686274588108063,
										"g": 0.6705882549285889,
										"b": 0.8901960849761963
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I387:1570;387:1543",
								  "parent": {
									"id": "I387:1570;387:1537"
								  },
								  "name": "Rectangle 7",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  14.11279296875
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  16.970550537109375
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  1825.11279296875
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  321.9705505371094
									]
								  ],
								  "x": 14.11279296875,
								  "y": 16.970550537109375,
								  "width": 24.000017166137695,
								  "height": 4.0000200271606445,
								  "absoluteRenderBounds": {
									"x": 1825.11279296875,
									"y": 305,
									"width": 19.799072265625,
									"height": 19.798980712890625
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.38823530077934265,
										"b": 0.572549045085907
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": true,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "NONE",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1570;387:1545",
							  "listSpacing": 0,
							  "parent": {
								"id": "I387:1570;387:1535"
							  },
							  "name": "Logo",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  42
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  1853
								],
								[
								  0,
								  1,
								  300
								]
							  ],
							  "x": 42,
							  "y": 0,
							  "width": 43,
							  "height": 30,
							  "absoluteRenderBounds": {
								"x": 1854.457763671875,
								"y": 308.9090881347656,
								"width": 40.6741943359375,
								"height": 16.977264404296875
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Logo",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 30
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 8,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 10,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "387:1533"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Type": "Logo"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 32,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "start",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1579",
				  "parent": {
					"id": "387:1552"
				  },
				  "name": "Center",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  358
					],
					[
					  0,
					  1,
					  37.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2169
					],
					[
					  0,
					  1,
					  305.5
					]
				  ],
				  "x": 358,
				  "y": 37.5,
				  "width": 384,
				  "height": 19,
				  "absoluteRenderBounds": {
					"x": 2169,
					"y": 305.5,
					"width": 384,
					"height": 19
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "columns",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1583",
					  "parent": {
						"id": "387:1579"
					  },
					  "name": "links",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2169
						],
						[
						  0,
						  1,
						  305.5
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 384,
					  "height": 19,
					  "absoluteRenderBounds": {
						"x": 2169,
						"y": 305.5,
						"width": 384,
						"height": 19
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1586",
						  "parent": {
							"id": "387:1583"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  20
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2189
							],
							[
							  0,
							  1,
							  305.5
							]
						  ],
						  "x": 20,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2189,
							"y": 305.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1586;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1586"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2189
								],
								[
								  0,
								  1,
								  305.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2190.2783203125,
								"y": 308.8636474609375,
								"width": 65.336669921875,
								"height": 11.82952880859375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1592",
						  "parent": {
							"id": "387:1583"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  112
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2281
							],
							[
							  0,
							  1,
							  305.5
							]
						  ],
						  "x": 112,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2281,
							"y": 305.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1592;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1592"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2281
								],
								[
								  0,
								  1,
								  305.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2282.2783203125,
								"y": 308.8636474609375,
								"width": 65.336669921875,
								"height": 11.82952880859375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1598",
						  "parent": {
							"id": "387:1583"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  204
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2373
							],
							[
							  0,
							  1,
							  305.5
							]
						  ],
						  "x": 204,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2373,
							"y": 305.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1598;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1598"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2373
								],
								[
								  0,
								  1,
								  305.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2374.2783203125,
								"y": 308.8636474609375,
								"width": 65.336669921875,
								"height": 11.82952880859375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "387:1604",
						  "parent": {
							"id": "387:1583"
						  },
						  "name": "MAKERS_NAVIGATION_ITEM",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  296
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2465
							],
							[
							  0,
							  1,
							  305.5
							]
						  ],
						  "x": 296,
						  "y": 0,
						  "width": 68,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 2465,
							"y": 305.5,
							"width": 68,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "div",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I387:1604;387:1529",
							  "listSpacing": 0,
							  "parent": {
								"id": "387:1604"
							  },
							  "name": "Nav Item",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  2465
								],
								[
								  0,
								  1,
								  305.5
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 68,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 2466.2783203125,
								"y": 308.8636474609375,
								"width": 65.336669921875,
								"height": 11.82952880859375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Nav Item",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Medium"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "387:1527"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 20,
					  "paddingRight": 20,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MAX",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 32,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "end",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "387:1611",
				  "parent": {
					"id": "387:1552"
				  },
				  "name": "Right",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  900
					],
					[
					  0,
					  1,
					  24
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2711
					],
					[
					  0,
					  1,
					  292
					]
				  ],
				  "x": 900,
				  "y": 24,
				  "width": 200,
				  "height": 46,
				  "absoluteRenderBounds": {
					"x": 2711,
					"y": 292,
					"width": 200,
					"height": 46
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "387:1612",
					  "parent": {
						"id": "387:1611"
					  },
					  "name": "MAKERS_BUTTON",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  85
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2796
						],
						[
						  0,
						  1,
						  292
						]
					  ],
					  "x": 85,
					  "y": 0,
					  "width": 115,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 2796,
						"y": 292,
						"width": 115,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I387:1612;387:1520",
						  "listSpacing": 0,
						  "parent": {
							"id": "387:1612"
						  },
						  "name": "Button",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  28
							],
							[
							  0,
							  1,
							  3
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  2824
							],
							[
							  0,
							  1,
							  295
							]
						  ],
						  "x": 28,
						  "y": 3,
						  "width": 59,
						  "height": 40,
						  "absoluteRenderBounds": {
							"x": 2825.655517578125,
							"y": 308.9090881347656,
							"width": 55.8232421875,
							"height": 13.282684326171875
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Button",
						  "hasMissingFont": false,
						  "fontSize": 18,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PIXELS",
							"value": 40
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0,
							"g": 0.4117647111415863,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 10,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 10,
					  "topRightRadius": 10,
					  "bottomLeftRadius": 10,
					  "bottomRightRadius": 10,
					  "paddingLeft": 28,
					  "paddingRight": 28,
					  "paddingTop": 3,
					  "paddingBottom": 3,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "387:1516"
					  },
					  "scaleFactor": 1,
					  "reactions": [
						{
						  "action": {
							"type": "NODE",
							"destinationId": "1",
							"navigation": "CHANGE_TO",
							"transition": {
							  "type": "SMART_ANIMATE",
							  "easing": {
								"type": "EASE_IN_AND_OUT"
							  },
							  "duration": 0.30000001192092896
							},
							"preserveScrollPosition": false
						  },
						  "trigger": {
							"type": "ON_HOVER"
						  }
						}
					  ],
					  "variantProperties": {
						"State": "Default",
						"Type": "Primary"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MAX",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 24,
			  "paddingBottom": 24,
			  "primaryAxisAlignItems": "SPACE_BETWEEN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 127,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "FIXED",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "f27ea2843ba87d84180ded2fbf8fd29780e3c7bc",
		  "reactions": [],
		  "variantProperties": {
			"Type": "Center"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_NAVIGATION_BAR",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	}
  ]`
;
