<template>
    <div class="makers-footer">
        <template v-if="$parent.currentStep === 1">
            <div class="start"></div>
            <div class="end">
                <button class="button button--primary" :disabled="homePage === ''" @click="$parent.changeCurrentStep('next')">Next</button>
            </div>
        </template>
        <template v-else-if="$parent.currentStep === 2">
            <div class="start">
                <div class="type type--small" v-show="$parent.isDomainNameValid">
                    Your domain: <span class="type--semibold">{{$parent.form.domain}}</span>
                </div>
            </div>
            <div class="end">
                <button class="button button--secondary" @click="$parent.changeCurrentStep('back')">Back</button>
                <button class="button button--primary" :disabled="!$parent.isDomainNameValid" @click="$parent.handleNewDomain()">
                    Next
                    <div v-if="$parent.isLoading" class="icon icon--spinner icon--white" style="margin-left: 8px"></div>
                </button>
            </div>
        </template>
        <template v-else-if="$parent.currentStep === 3 || $parent.currentStep === 4">
            <div class="start"></div>
            <div class="end">
                <button class="button button--secondary" @click="$parent.changeCurrentStep('back')">Back</button>
                <button class="button button--primary" @click="$parent.changeCurrentStep('next')">Next</button>
            </div>
        </template>
        <template v-else-if="$parent.currentStep === 5">
            <div class="start"></div>
            <div class="end">
                <button class="button button--secondary" @click="$parent.changeCurrentStep('back')">Back</button>
            </div>
        </template>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    export default {
        computed: {
            ...mapState(['homePage', 'site']),
        },
    }
</script>