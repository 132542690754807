<template>
    <div class="makers-home-page">
        <makers-header :controller="controller"></makers-header>
        <main>
			<template v-if="pages.length <= 0">
				<makers-welcome-card></makers-welcome-card>
				<div class="new-page-wrapper">
					<div class="type type--small">Start by creating a new Page</div>
					<button class="button button--primary" @click="addNewPage(true)">+ New Page</button>
				</div>
			</template>
			<makers-components-list v-else @addNewPage="addNewPage"></makers-components-list>
        </main>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
	import { dispatch } from '../figma/ui-message-handler';
    export default {
		props: ['controller'],
        computed: {
            ...mapState(['pages']),
        },
		methods: {
			addNewPage(isFirstPage = false) {
                if (isFirstPage) {
                    dispatch("createStylesIfNotExists");
                    dispatch("addPage", this.pages);
                    return;
                }
                dispatch("addPage", this.pages);
            },
		}
    }
</script>
