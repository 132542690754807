export const components = [
	{
		name: 'layout',
		components: [
			{ id: 'columns', name: 'Columns', description: '', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-columns.svg' },
		]
	},
	{
		name: 'page sections (with figma variants)',
		components: [
			{ id: 'nav_bar', name: 'Navigation Bar', description: '3 variants', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-navbar.svg' },
			{ id: 'main', name: 'Main Section', description: '5 variants', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-main.svg' },
			{ id: 'features', name: 'Features', description: '5 variants', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-features.svg' },
			{ id: 'call_to_action', name: 'Call To Action', description: '4 variants', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-call-to-action.svg' },
			{ id: 'stats', name: 'Stats', description: '3 variants', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-stats.svg' },
			{ id: 'faq', name: 'FAQ', description: '3 variants', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-faq.svg' },
			{ id: 'footer', name: 'Footer', description: '4 variants', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-footer.svg' },
		]
	},
	// {
	// 	name: 'elements',
	// 	components: [
	// 		{ id: 'button', name: 'Button', description: '', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-button.svg' },
	// 		{ id: 'media_object', name: 'Media Object', description: '', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-media-object.svg' },
	// 	]
	// },

	// { id: 'text_content', name: 'Text Content', description: '', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-text-content.svg' },
    // { id: 'scrollable_cards', name: 'Scrollable Cards', description: '', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/makers-component-scrollable.svg' },
    // { id: 'video_section', name: 'Video Section', description: '', image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/makers-component-video.svg' },
];
