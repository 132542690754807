<template>
	<div class="set-navbar-position">
		<div class="start">
			<div class="switch">
				<input id="toggleNavbarPosition" class="switch__toggle" type="checkbox" :checked="isNavbarFixed()" @change="toggleNavbarPosition()"/>
				<label class="switch__label type type--xsmall" for="toggleNavbarPosition">Fix Navigation Bar to the Top</label>
			</div>
		</div>
		<div class="end">
		</div>
	</div>
</template>

<script>
	import { dispatch } from '../figma/ui-message-handler';
	const IS_FIXED = 'is-fixed';
	export default {
		props: ['currentSelection'],
		data() {
			return {
				makersClasses: [],
			}
		},
		mounted() {
			this.setMakersClasses();
		},
		methods: {
			setMakersClasses() {
				this.makersClasses = (this.currentSelection.makersClasses == "" || this.currentSelection.makersClasses == null)
									? []
									: this.currentSelection.makersClasses.replaceAll(" ", "").split(',');
			},
			isNavbarFixed() {
				return this.makersClasses.some(className => className === IS_FIXED);
			},
			toggleNavbarPosition() {
				this.makersClasses = this.makersClasses.includes(IS_FIXED) ? this.makersClasses.filter( c => c !== IS_FIXED) : [...this.makersClasses, IS_FIXED];
				const payload = { id: this.currentSelection.id, key: 'makersClasses', value: this.makersClasses.join() };
                dispatch('updateNode', payload);
			},
		}
	}
</script>
