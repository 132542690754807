export const footer = () => {
	return footerJson;
}
const footerJson = `[
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2455.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3024",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=facebook",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8396
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8396,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3025",
			  "parent": {
				"id": "395:3024"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8397
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8397,
				"y": 1680,
				"width": 23,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3026",
				  "parent": {
					"id": "395:3025"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  6
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8403
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 6,
				  "y": 2,
				  "width": 11,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8402,
					"y": 1681,
					"width": 13,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.326082468032837,
						  "y": 8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 0.9376819133758545,
						  "y": -0.9376819133758545
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.9376819133758545,
						  "y": 0.9376819133758545
						},
						"tangentEnd": {
						  "x": 1.7763568394002505e-15,
						  "y": -1.326082468032837
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0,
						  "y": -0.26521649956703186
						},
						"tangentEnd": {
						  "x": -0.18753637373447418,
						  "y": 0.18753637373447418
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.18753637373447418,
						  "y": -0.18753637373447418
						},
						"tangentEnd": {
						  "x": -0.26521649956703186,
						  "y": -8.881784197001252e-16
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 17,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 11,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.464466094970703,
						"y": 1.4644660949707031,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.292893409729004,
						"y": 4.292893409729004,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 11 0 L 8 0 C 6.673917531967163 8.881784197001252e-16 5.402148008346558 0.5267841815948486 4.464466094970703 1.4644660949707031 C 3.5267841815948486 2.4021480083465576 3.0000000000000018 3.673917531967163 3 5 L 3 8 L 0 8 L 0 12 L 3 12 L 3 20 L 7 20 L 7 12 L 10 12 L 11 8 L 7 8 L 7 5 C 7 4.734783500432968 7.10535703599453 4.480429783463478 7.292893409729004 4.292893409729004 C 7.480429783463478 4.10535703599453 7.734783500432968 3.999999999999999 8 4 L 11 4 L 11 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "9b76a30f66ed5ae9ab2ca80cfc11dcde4c25fac8",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "facebook"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2458.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3027",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitter",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  64
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8440
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 64,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8440,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3028",
			  "parent": {
				"id": "395:3027"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8440
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8440,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3029",
				  "parent": {
					"id": "395:3028"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  2.9899444580078125
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8441
					],
					[
					  0,
					  1,
					  1682.989990234375
					]
				  ],
				  "x": 1,
				  "y": 2.9899444580078125,
				  "width": 22,
				  "height": 17.943681716918945,
				  "absoluteRenderBounds": {
					"x": 8440,
					"y": 1681.989990234375,
					"width": 23.9716796875,
					"height": 19.9437255859375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.9576150178909302,
						  "y": 0.6754773855209351
						},
						"tangentEnd": {
						  "x": 1.1221078634262085,
						  "y": -0.33789074420928955
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.6022599339485168,
						  "y": -0.6924893856048584
						},
						"tangentEnd": {
						  "x": 0.890299916267395,
						  "y": 0.22276580333709717
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.890299916267395,
						  "y": -0.22276580333709717
						},
						"tangentEnd": {
						  "x": 0.8574152588844299,
						  "y": -0.32725775241851807
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8574152588844299,
						  "y": 0.32725775241851807
						},
						"tangentEnd": {
						  "x": 0.5154598355293274,
						  "y": -0.7593153119087219
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5154598355293274,
						  "y": 0.7593153119087219
						},
						"tangentEnd": {
						  "x": -0.012302441522479057,
						  "y": -0.9176639914512634
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -1.757366418838501,
						  "y": 0.045568279922008514
						},
						"tangentEnd": {
						  "x": 1.570265293121338,
						  "y": 0.7903668880462646
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -1.570265293121338,
						  "y": -0.7903668880462646
						},
						"tangentEnd": {
						  "x": 1.010317087173462,
						  "y": 1.4386355876922607
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 4.440892098500626e-16,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": -9,
						  "y": -4
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.0594723224639893,
						  "y": 1.3979662656784058
						},
						"tangentEnd": {
						  "x": 2.487156867980957,
						  "y": 0.09892935305833817
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 9,
						  "y": 5
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 11.5
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.0009208884439431131,
						  "y": -0.27854636311531067
						},
						"tangentEnd": {
						  "x": 0.052297499030828476,
						  "y": 0.2735944092273712
						}
					  },
					  {
						"start": 12,
						"end": 0,
						"tangentStart": {
						  "x": 1.0206031799316406,
						  "y": -1.0065069198608398
						},
						"tangentEnd": {
						  "x": -0.3391726016998291,
						  "y": 1.3927128314971924
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 22,
						"y": 0.010055706836283207,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.860000610351562,
						"y": 1.5400558710098267,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 16.567033767700195,
						"y": 0.1339818686246872,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.882081031799316,
						"y": 0.29450908303260803,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.772982597351074,
						"y": 1.9637724161148071,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4.540055751800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 5.540055274963379,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.931008815765381,
						"y": 4.405501842498779,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 1.010055661201477,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 14.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 4.5100555419921875,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.920000076293945,
						"y": 3.680055856704712,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 22 0.010055706836283207 C 21.04238498210907 0.6855330923572183 19.98210847377777 1.202165126800537 18.860000610351562 1.5400558710098267 C 18.257740676403046 0.8475664854049683 17.45733368396759 0.35674767196178436 16.567033767700195 0.1339818686246872 C 15.6767338514328 -0.08878393471240997 14.739496290683746 -0.032748669385910034 13.882081031799316 0.29450908303260803 C 13.024665772914886 0.6217668354511261 12.288442432880402 1.2044571042060852 11.772982597351074 1.9637724161148071 C 11.257522761821747 2.723087728023529 10.987697558477521 3.6223917603492737 11 4.540055751800537 L 11 5.540055274963379 C 9.242633581161499 5.585623554885387 7.501274108886719 5.195868730545044 5.931008815765381 4.405501842498779 C 4.360743522644043 3.6151349544525146 3.010317087173462 2.448691248893738 2 1.010055661201477 C 2.0000000000000004 1.0100556612014762 -2 10.010055541992188 7 14.010055541992188 C 4.940527677536011 15.408021807670593 2.487156867980957 16.108984895050526 0 16.010055541992188 C 9 21.010055541992188 20 16.010055541992188 20 4.5100555419921875 C 19.999079111556057 4.231509178876877 19.972297575324774 3.953650265932083 19.920000076293945 3.680055856704712 C 20.940603256225586 2.673548936843872 21.66082739830017 1.4027685383334756 22 0.010055706836283207 L 22 0.010055706836283207 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "aa272c1fecdebcd9b50203192c22a20b7624c8e4",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitter"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2461.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3030",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitch",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  108
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8484
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 108,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8484,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3031",
			  "parent": {
				"id": "395:3030"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8484
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8484,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3032",
				  "parent": {
					"id": "395:3031"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  3
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8487
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 3,
				  "y": 2,
				  "width": 18,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8486,
					"y": 1681,
					"width": 20,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ],
						  [
							8,
							8
						  ],
						  [
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 18,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 9,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 18 0 L 0 0 L 0 16 L 5 16 L 5 20 L 9 16 L 14 16 L 18 12 L 18 0 Z M 8 9 L 8 5 L 8 9 Z M 13 9 L 13 5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b14c6c6c70817b147a3b4ba97ae90f827095fd9d",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitch"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2464.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3033",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=github",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  152
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8528
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 152,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8528,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3034",
			  "parent": {
				"id": "395:3033"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8529
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 22,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8529,
				"y": 1680,
				"width": 22,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3035",
				  "parent": {
					"id": "395:3034"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  0.97320556640625
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8530
					],
					[
					  0,
					  1,
					  1680.97314453125
					]
				  ],
				  "x": 1,
				  "y": 0.97320556640625,
				  "width": 19.5,
				  "height": 21.026792526245117,
				  "absoluteRenderBounds": {
					"x": 8529,
					"y": 1680,
					"width": 21.5,
					"height": 22.9998779296875
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ],
						  [
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -5,
						  "y": 1.5
						},
						"tangentEnd": {
						  "x": 2,
						  "y": 0.5
						}
					  },
					  {
						"start": 1,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.03751164302229881,
						  "y": -0.476836234331131
						},
						"tangentEnd": {
						  "x": 0.1620737761259079,
						  "y": 0.45001333951950073
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.1620737761259079,
						  "y": -0.45001333951950073
						},
						"tangentEnd": {
						  "x": 0.332929790019989,
						  "y": 0.3434205651283264
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 3.140000104904175,
						  "y": -0.3499999940395355
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 5.460000038146973
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.00025664002168923616,
						  "y": -1.3961730003356934
						},
						"tangentEnd": {
						  "x": 0.9626920819282532,
						  "y": 1.0111987590789795
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.4558558762073517,
						  "y": -1.2214934825897217
						},
						"tangentEnd": {
						  "x": 0.5136240124702454,
						  "y": 1.1983493566513062
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 2.7300000190734863,
						  "y": -1.8300000429153442
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.2919909954071045,
						  "y": -0.6211782693862915
						},
						"tangentEnd": {
						  "x": 2.2919909954071045,
						  "y": -0.6211782693862915
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -2.7300000190734863,
						  "y": -1.8300000429153442
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.5136240124702454,
						  "y": 1.1983493566513062
						},
						"tangentEnd": {
						  "x": -0.4558558762073517,
						  "y": -1.2214934825897217
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.9698728919029236,
						  "y": 1.0186996459960938
						},
						"tangentEnd": {
						  "x": -0.007477347739040852,
						  "y": -1.4065370559692383
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -1.3322676295501878e-15,
						  "y": 5.420000076293945
						},
						"tangentEnd": {
						  "x": -3.140000104904175,
						  "y": -0.38999998569488525
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.3290020823478699,
						  "y": 0.3399706780910492
						},
						"tangentEnd": {
						  "x": 0.161954864859581,
						  "y": -0.4445143938064575
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": -0.161954864859581,
						  "y": 0.4445143938064575
						},
						"tangentEnd": {
						  "x": -0.0331922248005867,
						  "y": -0.47193294763565063
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7,
						"y": 18.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15.026793479919434,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.811015129089355,
						"y": 15.750584602355957,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.0600004196167,
						"y": 14.546793937683105,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.5,
						"y": 7.546793460845947,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.90999984741211,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.0900001525878906,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.5,
						"y": 7.576793193817139,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.939999580383301,
						"y": 14.576793670654297,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.195308685302734,
						"y": 15.76671314239502,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7 18.026792526245117 C 2 19.526792526245117 2 15.526793479919434 0 15.026793479919434 L 7 18.026792526245117 Z M 14 21.026792526245117 L 14 17.15679168701172 C 14.037511643022299 16.679955452680588 13.973088905215263 16.200597941875458 13.811015129089355 15.750584602355957 C 13.648941352963448 15.300571262836456 13.392930209636688 14.890214502811432 13.0600004196167 14.546793937683105 C 16.200000524520874 14.19679394364357 19.5 13.00679349899292 19.5 7.546793460845947 C 19.49974335997831 6.150620460510254 18.962692081928253 4.8079917430877686 18 3.796792984008789 C 18.45585587620735 2.5752995014190674 18.423623859882355 1.225142465904355 17.90999984741211 0.026793109253048897 C 17.90999984741211 0.02679310925304801 16.730000019073486 -0.32320690155029297 14 1.5067931413650513 C 11.708009004592896 0.8856148719787598 9.291990995407104 0.8856148719787598 7 1.5067931413650513 C 4.269999980926514 -0.32320690155029297 3.0900001525878906 0.026793109253048897 3.0900001525878906 0.026793109253048897 C 2.5763761401176453 1.225142465904355 2.5441441237926483 2.5752995014190674 3 3.796792984008789 C 2.0301271080970764 4.815492630004883 1.4925226522609591 6.1702561378479 1.5 7.576793193817139 C 1.4999999999999987 12.996793270111084 4.799999475479126 14.186793684959412 7.939999580383301 14.576793670654297 C 7.610997498035431 14.916764348745346 7.357263550162315 15.322198748588562 7.195308685302734 15.76671314239502 C 7.033353820443153 16.211227536201477 6.966807775199413 16.684858739376068 7 17.15679168701172 L 7 21.026792526245117"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "373063ab2542ac4426cd0c65e2483c6f816822ec",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "github"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2467.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3036",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=gitlab",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  196
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8572
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 196,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8572,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3037",
			  "parent": {
				"id": "395:3036"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8572
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8572,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3038",
				  "parent": {
					"id": "395:3037"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1.0078125
					],
					[
					  0,
					  1,
					  1.8874893188476562
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8573.0078125
					],
					[
					  0,
					  1,
					  1681.887451171875
					]
				  ],
				  "x": 1.0078125,
				  "y": 1.8874893188476562,
				  "width": 22.025835037231445,
				  "height": 20.242509841918945,
				  "absoluteRenderBounds": {
					"x": 8572.0078125,
					"y": 1680.887451171875,
					"width": 23.9921875,
					"height": 22.2425537109375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17,
							18,
							19,
							20,
							21
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.14277499914169312,
						  "y": -0.10500118136405945
						},
						"tangentEnd": {
						  "x": 0.0538845919072628,
						  "y": 0.16883838176727295
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.0538845919072628,
						  "y": -0.16883838176727295
						},
						"tangentEnd": {
						  "x": -0.055552154779434204,
						  "y": 0.16829708218574524
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": 2.220446049250313e-16
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": -4.440892098500626e-16
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 17,
						"end": 18,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 18,
						"end": 19,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 19,
						"end": 20,
						"tangentStart": {
						  "x": 0.050522733479738235,
						  "y": 0.17348626255989075
						},
						"tangentEnd": {
						  "x": 0.06305060535669327,
						  "y": -0.16933591663837433
						}
					  },
					  {
						"start": 20,
						"end": 0,
						"tangentStart": {
						  "x": -0.06305060535669327,
						  "y": 0.16933591663837433
						},
						"tangentEnd": {
						  "x": 0.1516815572977066,
						  "y": -0.09819743782281876
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.642332077026367,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.992331504821777,
						"y": 20.242509841918945,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.3423318862915039,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.039766304194927216,
						"y": 12.08120059967041,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.04233180731534958,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.2623318433761597,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.702331781387329,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.8123319149017334,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.10233211517334,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.392332077026367,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.5023322105407715,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.942331790924072,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15.042330741882324,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.482332229614258,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.59233283996582,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.88233184814453,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.172332763671875,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.282333374023438,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.722332000732422,
						"y": 7.802509307861328,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.992332458496094,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.973037719726562,
						"y": 12.090485572814941,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.642332077026367 12.502510070800781 L 10.992331504821777 20.242509841918945 L 0.3423318862915039 12.502510070800781 C 0.1995568871498108 12.397508889436722 0.09365089610219002 12.250038981437683 0.039766304194927216 12.08120059967041 C -0.014118287712335587 11.912362217903137 -0.013220347464084625 11.73080661892891 0.04233180731534958 11.562509536743164 L 1.2623318433761597 7.782509803771973 L 3.702331781387329 0.27250993251800537 C 3.7259979881346226 0.21131689473986626 3.7636714093387127 0.1565193012356758 3.8123319149017334 0.1125098466873169 C 3.891571380198002 0.04013139754533768 3.9950123503804207 2.22044604925035e-16 4.10233211517334 3.680674433215635e-30 C 4.209651879966259 2.22044604925035e-16 4.313092611730099 0.04013139754533768 4.392332077026367 0.1125098466873169 C 4.443714894354343 0.16217468678951263 4.481575883924961 0.22412888705730438 4.5023322105407715 0.29250991344451904 L 6.942331790924072 7.782509803771973 L 15.042330741882324 7.782509803771973 L 17.482332229614258 0.27250993251800537 C 17.50599843636155 0.21131689473986626 17.5436723344028 0.1565193012356758 17.59233283996582 0.1125098466873169 C 17.67157230526209 0.04013139754533768 17.775012083351612 -4.440892098500589e-16 17.88233184814453 3.680674433215635e-30 C 17.98965161293745 2.22044604925035e-16 18.093093298375607 0.04013139754533768 18.172332763671875 0.1125098466873169 C 18.22371558099985 0.16217468678951263 18.261577047407627 0.22412888705730438 18.282333374023438 0.29250991344451904 L 20.722332000732422 7.802509307861328 L 21.992332458496094 11.562509536743164 C 22.042855191975832 11.735995799303055 22.036088325083256 11.921149656176567 21.973037719726562 12.090485572814941 C 21.90998711436987 12.259821489453316 21.794013634324074 12.404312632977962 21.642332077026367 12.502510070800781 L 21.642332077026367 12.502510070800781 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "2c68f2ca7a6e5ee9405e77e4aa41b45d62abc618",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "gitlab"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2470.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3039",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=instagram",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  240
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8616
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 240,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8616,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3040",
			  "parent": {
				"id": "395:3039"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8616
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8616,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3041",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8618
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8617,
					"y": 1681,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -2.7614238262176514
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 2.7614238262176514
						},
						"tangentEnd": {
						  "x": 2.7614238262176514,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 2.7614238262176514
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": -2.7614238262176514
						},
						"tangentEnd": {
						  "x": -2.7614238262176514,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 5 0 L 15 0 C 17.76142382621765 0 20 2.2385761737823486 20 5 L 20 15 C 20 17.76142382621765 17.76142382621765 20 15 20 L 5 20 C 2.2385761737823486 20 0 17.76142382621765 0 15 L 0 5 C 0 2.2385761737823486 2.2385761737823486 0 5 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3042",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  8.04296875
					],
					[
					  0,
					  1,
					  7.956729888916016
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8624.04296875
					],
					[
					  0,
					  1,
					  1687.956787109375
					]
				  ],
				  "x": 8.04296875,
				  "y": 7.956729888916016,
				  "width": 8.000008583068848,
				  "height": 8.000008583068848,
				  "absoluteRenderBounds": {
					"x": 8623.04296875,
					"y": 1686.956787109375,
					"width": 10,
					"height": 10
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0.1234116330742836,
						  "y": 0.8322460651397705
						},
						"tangentEnd": {
						  "x": 0.38749924302101135,
						  "y": -0.7467986941337585
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.38749924302101135,
						  "y": 0.7467986941337585
						},
						"tangentEnd": {
						  "x": 0.7515201568603516,
						  "y": -0.37826108932495117
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.7515201568603516,
						  "y": 0.37826108932495117
						},
						"tangentEnd": {
						  "x": 0.8306610584259033,
						  "y": 0.13366460800170898
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8306610584259033,
						  "y": -0.13366460800170898
						},
						"tangentEnd": {
						  "x": 0.5949218273162842,
						  "y": 0.5949218273162842
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5949218273162842,
						  "y": -0.5949218273162842
						},
						"tangentEnd": {
						  "x": 0.13366460800170898,
						  "y": 0.8306610584259033
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.13366460800170898,
						  "y": -0.8306610584259033
						},
						"tangentEnd": {
						  "x": -0.37826108932495117,
						  "y": 0.7515201568603516
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.37826108932495117,
						  "y": -0.7515201568603516
						},
						"tangentEnd": {
						  "x": -0.7467986941337585,
						  "y": 0.38749924302101135
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.7467986941337585,
						  "y": -0.38749924302101135
						},
						"tangentEnd": {
						  "x": -0.8322460651397705,
						  "y": -0.1234116330742836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.8489227890968323,
						  "y": 0.12588457763195038
						},
						"tangentEnd": {
						  "x": -0.6068429946899414,
						  "y": -0.6068429946899414
						}
					  },
					  {
						"start": 9,
						"end": 0,
						"tangentStart": {
						  "x": 0.6068429946899414,
						  "y": 0.6068429946899414
						},
						"tangentEnd": {
						  "x": -0.12588457763195038,
						  "y": -0.8489227890968323
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.956738471984863,
						"y": 3.4132702350616455,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.550497055053711,
						"y": 5.84228515625,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.798364639282227,
						"y": 7.572943687438965,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.3645246028900146,
						"y": 7.949202537536621,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1715772151947021,
						"y": 6.828431129455566,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.05080585181713104,
						"y": 4.635483741760254,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.4270646870136261,
						"y": 2.2016441822052,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1577231884002686,
						"y": 0.44951170682907104,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.586738586425781,
						"y": 0.0432702973484993,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.828432083129883,
						"y": 1.171576738357544,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.956738471984863 3.4132702350616455 C 8.080150105059147 4.245516300201416 7.937996298074722 5.0954864621162415 7.550497055053711 5.84228515625 C 7.1629978120327 6.5890838503837585 6.549884796142578 7.194682598114014 5.798364639282227 7.572943687438965 C 5.046844482421875 7.951204776763916 4.195185661315918 8.08286714553833 3.3645246028900146 7.949202537536621 C 2.5338635444641113 7.815537929534912 1.7664990425109863 7.423352956771851 1.1715772151947021 6.828431129455566 C 0.576655387878418 6.233509302139282 0.18447045981884003 5.466144800186157 0.05080585181713104 4.635483741760254 C -0.08285875618457794 3.8048226833343506 0.04880359768867493 2.9531643390655518 0.4270646870136261 2.2016441822052 C 0.8053257763385773 1.4501240253448486 1.41092449426651 0.8370109498500824 2.1577231884002686 0.44951170682907104 C 2.904521882534027 0.06201246380805969 3.7544925212860107 -0.0801413357257843 4.586738586425781 0.0432702973484993 C 5.4356613755226135 0.16915487498044968 6.221589088439941 0.5647337436676025 6.828432083129883 1.171576738357544 C 7.435275077819824 1.7784197330474854 7.830853894352913 2.5643474459648132 7.956738471984863 3.4132702350616455 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3043",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  17.5
					],
					[
					  0,
					  1,
					  6.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8633.5
					],
					[
					  0,
					  1,
					  1686.5
					]
				  ],
				  "x": 17.5,
				  "y": 6.5,
				  "width": 0.010000228881835938,
				  "height": 0,
				  "absoluteRenderBounds": {
					"x": 8632.5,
					"y": 1685.5,
					"width": 2.009765625,
					"height": 2
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.010000228881835938,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0.010000228881835938 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "1e26c2fda3ea56df0e72d4d3814245edfb57c368",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "instagram"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2475.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3044",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=linkedin",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  284
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8660
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 284,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8660,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3045",
			  "parent": {
				"id": "395:3044"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8660
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8660,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3046",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  10
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8670
					],
					[
					  0,
					  1,
					  1688
					]
				  ],
				  "x": 10,
				  "y": 8,
				  "width": 12,
				  "height": 13,
				  "absoluteRenderBounds": {
					"x": 8669,
					"y": 1687,
					"width": 14,
					"height": 15
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 1.5912989377975464,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": -1.1252182722091675
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": 1.1252182722091675
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.5912989377975464
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": -0.5304329991340637
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": 0.37507274746894836
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": -0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0.5304329991340637,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.5304329991340637,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": -0.37507274746894836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": 0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.5304329991340637
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": -1.5912989377975464
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": 1.1252182722091675
						}
					  },
					  {
						"start": 13,
						"end": 0,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": -1.1252182722091675
						},
						"tangentEnd": {
						  "x": -1.5912989377975464,
						  "y": 8.881784197001252e-16
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 6,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.24264144897461,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.414213180541992,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.585786819458008,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.757359504699707,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 6 0 C 7.591298937797546 0 9.117423176765442 0.6321412324905396 10.24264144897461 1.757359504699707 C 11.367859721183777 2.8825777769088745 12 4.408701062202454 12 6 L 12 13 L 8 13 L 8 6 C 8 5.469567000865936 7.7892859280109406 4.960859566926956 7.414213180541992 4.585786819458008 C 7.039140433073044 4.2107140719890594 6.530432999134064 4 6 4 C 5.469567000865936 4 4.960859566926956 4.2107140719890594 4.585786819458008 4.585786819458008 C 4.2107140719890594 4.960859566926956 4 5.469567000865936 4 6 L 4 13 L 0 13 L 0 6 C 0 4.408701062202454 0.6321412324905396 2.8825777769088745 1.757359504699707 1.757359504699707 C 2.8825777769088745 0.6321412324905396 4.408701062202454 8.881784197001252e-16 6 0 L 6 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3047",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8662
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 2,
				  "y": 9,
				  "width": 4,
				  "height": 12,
				  "absoluteRenderBounds": {
					"x": 8661,
					"y": 1688,
					"width": 6,
					"height": 14
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 4 0 L 4 12 L 0 12 L 0 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3048",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8662
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 4,
				  "height": 4,
				  "absoluteRenderBounds": {
					"x": 8661,
					"y": 1681,
					"width": 6,
					"height": 6
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 1.1045695543289185
						},
						"tangentEnd": {
						  "x": 1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 1.1045695543289185
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": -1.1045695543289185
						},
						"tangentEnd": {
						  "x": -1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.1045695543289185
						}
					  }
					],
					"vertices": [
					  {
						"x": 4,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 4 2 C 4 3.1045695543289185 3.1045695543289185 4 2 4 C 0.8954304456710815 4 0 3.1045695543289185 0 2 C 0 0.8954304456710815 0.8954304456710815 0 2 0 C 3.1045695543289185 0 4 0.8954304456710815 4 2 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "eb87f6e2b8e9918c146899bbbc954f3281aff585",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "linkedin"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2480.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3049",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=codepen",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  328
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8704
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 328,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8704,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3050",
			  "parent": {
				"id": "395:3049"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8704
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8704,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3051",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1681,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 10 0 L 20 6.5 L 20 13.5 L 10 20 L 0 13.5 L 0 6.5 L 10 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3052",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  15.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8716
					],
					[
					  0,
					  1,
					  1695.5
					]
				  ],
				  "x": 12,
				  "y": 15.5,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 8715,
					"y": 1694.5,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 6.5 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3053",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1688.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1687.4998779296875,
					"width": 22,
					"height": 9.0001220703125
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 20,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 20 0 L 10 7 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3054",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1688.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1687.5,
					"width": 22,
					"height": 9.0001220703125
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 7 L 10 0 L 20 7"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3055",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8716
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 8715,
					"y": 1681,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0 6.5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "620ca35555fe695ea0b6e46dc61daac4225f375d",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "codepen"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2487.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3056",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=figma",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  372
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8748
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 372,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8748,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3057",
			  "parent": {
				"id": "395:3056"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8748
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8748,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3058",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 5,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1681,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 4.440892098500626e-16
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3059",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8760
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8759,
					"y": 1681,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": -6.8489693916262695e-9
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 6.8489693916262695e-9
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664215564727783,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606078147888184,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839392185211182,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3060",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8760
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 12,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8759,
					"y": 1688,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -9.685905411060958e-9,
						  "y": -0.45962655544281006
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 9.685901858347279e-9,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 9.685905411060958e-9,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -9.685907187417797e-9,
						  "y": 0.45962655544281006
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.77230656136968e-16,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3061",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  16
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1696
					]
				  ],
				  "x": 5,
				  "y": 16,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1695,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0.9282577037811279
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.025125503540039,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.9748735427856445,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3062",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 5,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1688,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 1.7763568394002505e-15
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "8115e8dc41092d9186d9e986f6c5e2f8400d8350",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "figma"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2494.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3063",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=youtube",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  416
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8792
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 416,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8792,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3064",
			  "parent": {
				"id": "395:3063"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8792
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8792,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3065",
				  "parent": {
					"id": "395:3064"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0.9990234375
					],
					[
					  0,
					  1,
					  4
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8792.9990234375
					],
					[
					  0,
					  1,
					  1684
					]
				  ],
				  "x": 0.9990234375,
				  "y": 4,
				  "width": 22.001144409179688,
				  "height": 15.459999084472656,
				  "absoluteRenderBounds": {
					"x": 8792,
					"y": 1683,
					"width": 24,
					"height": 17.4599609375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.11878856271505356,
						  "y": -0.47459015250205994
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.4707529842853546,
						  "y": 0.13318465650081635
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -1.7200000286102295,
						  "y": -0.41999998688697815
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 1.7200000286102295,
						  "y": -0.46000000834465027
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.4707529842853546,
						  "y": 0.13318465650081635
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.11878856271505356,
						  "y": -0.47459015250205994
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.3147854506969452,
						  "y": 1.7455555200576782
						},
						"tangentEnd": {
						  "x": -0.008764072321355343,
						  "y": -1.7736902236938477
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.011220705695450306,
						  "y": 1.7870450019836426
						},
						"tangentEnd": {
						  "x": -0.31723231077194214,
						  "y": -1.7586982250213623
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.13095924258232117,
						  "y": 0.45984727144241333
						},
						"tangentEnd": {
						  "x": -0.33983731269836426,
						  "y": -0.33633384108543396
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.33983731269836426,
						  "y": 0.33633384108543396
						},
						"tangentEnd": {
						  "x": -0.4611796438694,
						  "y": -0.12618708610534668
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 1.7200000286102295,
						  "y": 0.46000000834465027
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.7200000286102295,
						  "y": 0.46000000834465027
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0.4707529842853546,
						  "y": -0.13318465650081635
						},
						"tangentEnd": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": -0.11878856271505356,
						  "y": 0.47459015250205994
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.3123571574687958,
						  "y": -1.732411503791809
						},
						"tangentEnd": {
						  "x": 0.00632609985768795,
						  "y": 1.7603342533111572
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": 0.011220705695450306,
						  "y": -1.7870450019836426
						},
						"tangentEnd": {
						  "x": 0.31723231077194214,
						  "y": 1.7586982250213623
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.540573120117188,
						"y": 2.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 1.1594061851501465,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 0.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 0.46000003814697266,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.16191828250885,
						"y": 1.1994061470031738,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 2.4600000381469727,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.0005716445157304406,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 13.079999923706055,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1787155866622925,
						"y": 14.294479370117188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 15.459999084472656,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 14.26059341430664,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.540573120117188,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 22.000572204589844,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.540573120117188 2.4200000762939453 C 21.421784557402134 1.9454099237918854 21.179856419563293 1.5105718076229095 20.83922576904297 1.1594061851501465 C 20.498595118522644 0.8082405626773834 20.071325570344925 0.5531847327947617 19.60057258605957 0.4200000762939453 C 17.88057255744934 8.940696716308594e-8 11.000571250915527 0 11.000571250915527 0 C 11.000571250915527 0 4.120571851730347 2.9802322387695312e-8 2.400571823120117 0.46000003814697266 C 1.9298188388347626 0.593184694647789 1.5025489330291748 0.8482405245304108 1.16191828250885 1.1994061470031738 C 0.8212876319885254 1.550571769475937 0.5793602392077446 1.9854098856449127 0.46057167649269104 2.4600000381469727 C 0.14578622579574585 4.205555558204651 -0.008192427805624902 5.976309776306152 0.0005716445157304406 7.75 C -0.010649061179719865 9.537045001983643 0.1433393657207489 11.321301698684692 0.46057167649269104 13.079999923706055 C 0.5915309190750122 13.539847195148468 0.8388782739639282 13.958145529031754 1.1787155866622925 14.294479370117188 C 1.5185528993606567 14.630813211202621 1.9393921792507172 14.873812913894653 2.400571823120117 15 C 4.120571851730347 15.46000000834465 11.000571250915527 15.459999084472656 11.000571250915527 15.459999084472656 C 11.000571250915527 15.459999084472656 17.88057255744934 15.46000000834465 19.60057258605957 15 C 20.071325570344925 14.866815343499184 20.498595118522644 14.611759036779404 20.83922576904297 14.26059341430664 C 21.179856419563293 13.909427791833878 21.421784557402134 13.47459015250206 21.540573120117188 13 C 21.852930277585983 11.267588496208191 22.00689830444753 9.510334253311157 22.000572204589844 7.75 C 22.011792910285294 5.962954998016357 21.85780543088913 4.178698301315308 21.540573120117188 2.4200000762939453 L 21.540573120117188 2.4200000762939453 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3066",
				  "parent": {
					"id": "395:3064"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  9.75
					],
					[
					  0,
					  1,
					  8.479999542236328
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8801.75
					],
					[
					  0,
					  1,
					  1688.47998046875
					]
				  ],
				  "x": 9.75,
				  "y": 8.479999542236328,
				  "width": 5.75,
				  "height": 6.540000915527344,
				  "absoluteRenderBounds": {
					"x": 8800.75,
					"y": 1687.47998046875,
					"width": 7.75,
					"height": 8.5400390625
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.540000915527344,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.75,
						"y": 3.270000457763672,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 6.540000915527344 L 5.75 3.270000457763672 L 0 0 L 0 6.540000915527344 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "67ccb2c03ff9a2032a953c5c78d4753c1e6479fb",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "youtube"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2455.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3024",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=facebook",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8396
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8396,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3025",
			  "parent": {
				"id": "395:3024"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8397
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8397,
				"y": 1680,
				"width": 23,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3026",
				  "parent": {
					"id": "395:3025"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  6
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8403
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 6,
				  "y": 2,
				  "width": 11,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8402,
					"y": 1681,
					"width": 13,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.326082468032837,
						  "y": 8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 0.9376819133758545,
						  "y": -0.9376819133758545
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.9376819133758545,
						  "y": 0.9376819133758545
						},
						"tangentEnd": {
						  "x": 1.7763568394002505e-15,
						  "y": -1.326082468032837
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0,
						  "y": -0.26521649956703186
						},
						"tangentEnd": {
						  "x": -0.18753637373447418,
						  "y": 0.18753637373447418
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.18753637373447418,
						  "y": -0.18753637373447418
						},
						"tangentEnd": {
						  "x": -0.26521649956703186,
						  "y": -8.881784197001252e-16
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 17,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 11,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.464466094970703,
						"y": 1.4644660949707031,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.292893409729004,
						"y": 4.292893409729004,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 11 0 L 8 0 C 6.673917531967163 8.881784197001252e-16 5.402148008346558 0.5267841815948486 4.464466094970703 1.4644660949707031 C 3.5267841815948486 2.4021480083465576 3.0000000000000018 3.673917531967163 3 5 L 3 8 L 0 8 L 0 12 L 3 12 L 3 20 L 7 20 L 7 12 L 10 12 L 11 8 L 7 8 L 7 5 C 7 4.734783500432968 7.10535703599453 4.480429783463478 7.292893409729004 4.292893409729004 C 7.480429783463478 4.10535703599453 7.734783500432968 3.999999999999999 8 4 L 11 4 L 11 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "9b76a30f66ed5ae9ab2ca80cfc11dcde4c25fac8",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "facebook"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2458.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3027",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitter",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  64
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8440
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 64,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8440,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3028",
			  "parent": {
				"id": "395:3027"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8440
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8440,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3029",
				  "parent": {
					"id": "395:3028"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  2.9899444580078125
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8441
					],
					[
					  0,
					  1,
					  1682.989990234375
					]
				  ],
				  "x": 1,
				  "y": 2.9899444580078125,
				  "width": 22,
				  "height": 17.943681716918945,
				  "absoluteRenderBounds": {
					"x": 8440,
					"y": 1681.989990234375,
					"width": 23.9716796875,
					"height": 19.9437255859375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.9576150178909302,
						  "y": 0.6754773855209351
						},
						"tangentEnd": {
						  "x": 1.1221078634262085,
						  "y": -0.33789074420928955
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.6022599339485168,
						  "y": -0.6924893856048584
						},
						"tangentEnd": {
						  "x": 0.890299916267395,
						  "y": 0.22276580333709717
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.890299916267395,
						  "y": -0.22276580333709717
						},
						"tangentEnd": {
						  "x": 0.8574152588844299,
						  "y": -0.32725775241851807
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8574152588844299,
						  "y": 0.32725775241851807
						},
						"tangentEnd": {
						  "x": 0.5154598355293274,
						  "y": -0.7593153119087219
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5154598355293274,
						  "y": 0.7593153119087219
						},
						"tangentEnd": {
						  "x": -0.012302441522479057,
						  "y": -0.9176639914512634
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -1.757366418838501,
						  "y": 0.045568279922008514
						},
						"tangentEnd": {
						  "x": 1.570265293121338,
						  "y": 0.7903668880462646
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -1.570265293121338,
						  "y": -0.7903668880462646
						},
						"tangentEnd": {
						  "x": 1.010317087173462,
						  "y": 1.4386355876922607
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 4.440892098500626e-16,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": -9,
						  "y": -4
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.0594723224639893,
						  "y": 1.3979662656784058
						},
						"tangentEnd": {
						  "x": 2.487156867980957,
						  "y": 0.09892935305833817
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 9,
						  "y": 5
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 11.5
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.0009208884439431131,
						  "y": -0.27854636311531067
						},
						"tangentEnd": {
						  "x": 0.052297499030828476,
						  "y": 0.2735944092273712
						}
					  },
					  {
						"start": 12,
						"end": 0,
						"tangentStart": {
						  "x": 1.0206031799316406,
						  "y": -1.0065069198608398
						},
						"tangentEnd": {
						  "x": -0.3391726016998291,
						  "y": 1.3927128314971924
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 22,
						"y": 0.010055706836283207,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.860000610351562,
						"y": 1.5400558710098267,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 16.567033767700195,
						"y": 0.1339818686246872,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.882081031799316,
						"y": 0.29450908303260803,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.772982597351074,
						"y": 1.9637724161148071,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4.540055751800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 5.540055274963379,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.931008815765381,
						"y": 4.405501842498779,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 1.010055661201477,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 14.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 4.5100555419921875,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.920000076293945,
						"y": 3.680055856704712,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 22 0.010055706836283207 C 21.04238498210907 0.6855330923572183 19.98210847377777 1.202165126800537 18.860000610351562 1.5400558710098267 C 18.257740676403046 0.8475664854049683 17.45733368396759 0.35674767196178436 16.567033767700195 0.1339818686246872 C 15.6767338514328 -0.08878393471240997 14.739496290683746 -0.032748669385910034 13.882081031799316 0.29450908303260803 C 13.024665772914886 0.6217668354511261 12.288442432880402 1.2044571042060852 11.772982597351074 1.9637724161148071 C 11.257522761821747 2.723087728023529 10.987697558477521 3.6223917603492737 11 4.540055751800537 L 11 5.540055274963379 C 9.242633581161499 5.585623554885387 7.501274108886719 5.195868730545044 5.931008815765381 4.405501842498779 C 4.360743522644043 3.6151349544525146 3.010317087173462 2.448691248893738 2 1.010055661201477 C 2.0000000000000004 1.0100556612014762 -2 10.010055541992188 7 14.010055541992188 C 4.940527677536011 15.408021807670593 2.487156867980957 16.108984895050526 0 16.010055541992188 C 9 21.010055541992188 20 16.010055541992188 20 4.5100555419921875 C 19.999079111556057 4.231509178876877 19.972297575324774 3.953650265932083 19.920000076293945 3.680055856704712 C 20.940603256225586 2.673548936843872 21.66082739830017 1.4027685383334756 22 0.010055706836283207 L 22 0.010055706836283207 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "aa272c1fecdebcd9b50203192c22a20b7624c8e4",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitter"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2461.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3030",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitch",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  108
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8484
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 108,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8484,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3031",
			  "parent": {
				"id": "395:3030"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8484
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8484,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3032",
				  "parent": {
					"id": "395:3031"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  3
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8487
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 3,
				  "y": 2,
				  "width": 18,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8486,
					"y": 1681,
					"width": 20,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ],
						  [
							8,
							8
						  ],
						  [
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 18,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 9,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 18 0 L 0 0 L 0 16 L 5 16 L 5 20 L 9 16 L 14 16 L 18 12 L 18 0 Z M 8 9 L 8 5 L 8 9 Z M 13 9 L 13 5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b14c6c6c70817b147a3b4ba97ae90f827095fd9d",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitch"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2464.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3033",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=github",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  152
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8528
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 152,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8528,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3034",
			  "parent": {
				"id": "395:3033"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8529
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 22,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8529,
				"y": 1680,
				"width": 22,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3035",
				  "parent": {
					"id": "395:3034"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  0.97320556640625
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8530
					],
					[
					  0,
					  1,
					  1680.97314453125
					]
				  ],
				  "x": 1,
				  "y": 0.97320556640625,
				  "width": 19.5,
				  "height": 21.026792526245117,
				  "absoluteRenderBounds": {
					"x": 8529,
					"y": 1680,
					"width": 21.5,
					"height": 22.9998779296875
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ],
						  [
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -5,
						  "y": 1.5
						},
						"tangentEnd": {
						  "x": 2,
						  "y": 0.5
						}
					  },
					  {
						"start": 1,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.03751164302229881,
						  "y": -0.476836234331131
						},
						"tangentEnd": {
						  "x": 0.1620737761259079,
						  "y": 0.45001333951950073
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.1620737761259079,
						  "y": -0.45001333951950073
						},
						"tangentEnd": {
						  "x": 0.332929790019989,
						  "y": 0.3434205651283264
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 3.140000104904175,
						  "y": -0.3499999940395355
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 5.460000038146973
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.00025664002168923616,
						  "y": -1.3961730003356934
						},
						"tangentEnd": {
						  "x": 0.9626920819282532,
						  "y": 1.0111987590789795
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.4558558762073517,
						  "y": -1.2214934825897217
						},
						"tangentEnd": {
						  "x": 0.5136240124702454,
						  "y": 1.1983493566513062
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 2.7300000190734863,
						  "y": -1.8300000429153442
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.2919909954071045,
						  "y": -0.6211782693862915
						},
						"tangentEnd": {
						  "x": 2.2919909954071045,
						  "y": -0.6211782693862915
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -2.7300000190734863,
						  "y": -1.8300000429153442
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.5136240124702454,
						  "y": 1.1983493566513062
						},
						"tangentEnd": {
						  "x": -0.4558558762073517,
						  "y": -1.2214934825897217
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.9698728919029236,
						  "y": 1.0186996459960938
						},
						"tangentEnd": {
						  "x": -0.007477347739040852,
						  "y": -1.4065370559692383
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -1.3322676295501878e-15,
						  "y": 5.420000076293945
						},
						"tangentEnd": {
						  "x": -3.140000104904175,
						  "y": -0.38999998569488525
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.3290020823478699,
						  "y": 0.3399706780910492
						},
						"tangentEnd": {
						  "x": 0.161954864859581,
						  "y": -0.4445143938064575
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": -0.161954864859581,
						  "y": 0.4445143938064575
						},
						"tangentEnd": {
						  "x": -0.0331922248005867,
						  "y": -0.47193294763565063
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7,
						"y": 18.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15.026793479919434,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.811015129089355,
						"y": 15.750584602355957,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.0600004196167,
						"y": 14.546793937683105,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.5,
						"y": 7.546793460845947,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.90999984741211,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.0900001525878906,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.5,
						"y": 7.576793193817139,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.939999580383301,
						"y": 14.576793670654297,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.195308685302734,
						"y": 15.76671314239502,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7 18.026792526245117 C 2 19.526792526245117 2 15.526793479919434 0 15.026793479919434 L 7 18.026792526245117 Z M 14 21.026792526245117 L 14 17.15679168701172 C 14.037511643022299 16.679955452680588 13.973088905215263 16.200597941875458 13.811015129089355 15.750584602355957 C 13.648941352963448 15.300571262836456 13.392930209636688 14.890214502811432 13.0600004196167 14.546793937683105 C 16.200000524520874 14.19679394364357 19.5 13.00679349899292 19.5 7.546793460845947 C 19.49974335997831 6.150620460510254 18.962692081928253 4.8079917430877686 18 3.796792984008789 C 18.45585587620735 2.5752995014190674 18.423623859882355 1.225142465904355 17.90999984741211 0.026793109253048897 C 17.90999984741211 0.02679310925304801 16.730000019073486 -0.32320690155029297 14 1.5067931413650513 C 11.708009004592896 0.8856148719787598 9.291990995407104 0.8856148719787598 7 1.5067931413650513 C 4.269999980926514 -0.32320690155029297 3.0900001525878906 0.026793109253048897 3.0900001525878906 0.026793109253048897 C 2.5763761401176453 1.225142465904355 2.5441441237926483 2.5752995014190674 3 3.796792984008789 C 2.0301271080970764 4.815492630004883 1.4925226522609591 6.1702561378479 1.5 7.576793193817139 C 1.4999999999999987 12.996793270111084 4.799999475479126 14.186793684959412 7.939999580383301 14.576793670654297 C 7.610997498035431 14.916764348745346 7.357263550162315 15.322198748588562 7.195308685302734 15.76671314239502 C 7.033353820443153 16.211227536201477 6.966807775199413 16.684858739376068 7 17.15679168701172 L 7 21.026792526245117"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "373063ab2542ac4426cd0c65e2483c6f816822ec",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "github"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2467.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3036",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=gitlab",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  196
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8572
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 196,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8572,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3037",
			  "parent": {
				"id": "395:3036"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8572
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8572,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3038",
				  "parent": {
					"id": "395:3037"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1.0078125
					],
					[
					  0,
					  1,
					  1.8874893188476562
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8573.0078125
					],
					[
					  0,
					  1,
					  1681.887451171875
					]
				  ],
				  "x": 1.0078125,
				  "y": 1.8874893188476562,
				  "width": 22.025835037231445,
				  "height": 20.242509841918945,
				  "absoluteRenderBounds": {
					"x": 8572.0078125,
					"y": 1680.887451171875,
					"width": 23.9921875,
					"height": 22.2425537109375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17,
							18,
							19,
							20,
							21
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.14277499914169312,
						  "y": -0.10500118136405945
						},
						"tangentEnd": {
						  "x": 0.0538845919072628,
						  "y": 0.16883838176727295
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.0538845919072628,
						  "y": -0.16883838176727295
						},
						"tangentEnd": {
						  "x": -0.055552154779434204,
						  "y": 0.16829708218574524
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": 2.220446049250313e-16
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": -4.440892098500626e-16
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 17,
						"end": 18,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 18,
						"end": 19,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 19,
						"end": 20,
						"tangentStart": {
						  "x": 0.050522733479738235,
						  "y": 0.17348626255989075
						},
						"tangentEnd": {
						  "x": 0.06305060535669327,
						  "y": -0.16933591663837433
						}
					  },
					  {
						"start": 20,
						"end": 0,
						"tangentStart": {
						  "x": -0.06305060535669327,
						  "y": 0.16933591663837433
						},
						"tangentEnd": {
						  "x": 0.1516815572977066,
						  "y": -0.09819743782281876
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.642332077026367,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.992331504821777,
						"y": 20.242509841918945,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.3423318862915039,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.039766304194927216,
						"y": 12.08120059967041,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.04233180731534958,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.2623318433761597,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.702331781387329,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.8123319149017334,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.10233211517334,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.392332077026367,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.5023322105407715,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.942331790924072,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15.042330741882324,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.482332229614258,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.59233283996582,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.88233184814453,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.172332763671875,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.282333374023438,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.722332000732422,
						"y": 7.802509307861328,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.992332458496094,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.973037719726562,
						"y": 12.090485572814941,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.642332077026367 12.502510070800781 L 10.992331504821777 20.242509841918945 L 0.3423318862915039 12.502510070800781 C 0.1995568871498108 12.397508889436722 0.09365089610219002 12.250038981437683 0.039766304194927216 12.08120059967041 C -0.014118287712335587 11.912362217903137 -0.013220347464084625 11.73080661892891 0.04233180731534958 11.562509536743164 L 1.2623318433761597 7.782509803771973 L 3.702331781387329 0.27250993251800537 C 3.7259979881346226 0.21131689473986626 3.7636714093387127 0.1565193012356758 3.8123319149017334 0.1125098466873169 C 3.891571380198002 0.04013139754533768 3.9950123503804207 2.22044604925035e-16 4.10233211517334 3.680674433215635e-30 C 4.209651879966259 2.22044604925035e-16 4.313092611730099 0.04013139754533768 4.392332077026367 0.1125098466873169 C 4.443714894354343 0.16217468678951263 4.481575883924961 0.22412888705730438 4.5023322105407715 0.29250991344451904 L 6.942331790924072 7.782509803771973 L 15.042330741882324 7.782509803771973 L 17.482332229614258 0.27250993251800537 C 17.50599843636155 0.21131689473986626 17.5436723344028 0.1565193012356758 17.59233283996582 0.1125098466873169 C 17.67157230526209 0.04013139754533768 17.775012083351612 -4.440892098500589e-16 17.88233184814453 3.680674433215635e-30 C 17.98965161293745 2.22044604925035e-16 18.093093298375607 0.04013139754533768 18.172332763671875 0.1125098466873169 C 18.22371558099985 0.16217468678951263 18.261577047407627 0.22412888705730438 18.282333374023438 0.29250991344451904 L 20.722332000732422 7.802509307861328 L 21.992332458496094 11.562509536743164 C 22.042855191975832 11.735995799303055 22.036088325083256 11.921149656176567 21.973037719726562 12.090485572814941 C 21.90998711436987 12.259821489453316 21.794013634324074 12.404312632977962 21.642332077026367 12.502510070800781 L 21.642332077026367 12.502510070800781 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "2c68f2ca7a6e5ee9405e77e4aa41b45d62abc618",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "gitlab"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2470.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3039",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=instagram",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  240
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8616
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 240,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8616,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3040",
			  "parent": {
				"id": "395:3039"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8616
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8616,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3041",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8618
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8617,
					"y": 1681,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -2.7614238262176514
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 2.7614238262176514
						},
						"tangentEnd": {
						  "x": 2.7614238262176514,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 2.7614238262176514
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": -2.7614238262176514
						},
						"tangentEnd": {
						  "x": -2.7614238262176514,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 5 0 L 15 0 C 17.76142382621765 0 20 2.2385761737823486 20 5 L 20 15 C 20 17.76142382621765 17.76142382621765 20 15 20 L 5 20 C 2.2385761737823486 20 0 17.76142382621765 0 15 L 0 5 C 0 2.2385761737823486 2.2385761737823486 0 5 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3042",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  8.04296875
					],
					[
					  0,
					  1,
					  7.956729888916016
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8624.04296875
					],
					[
					  0,
					  1,
					  1687.956787109375
					]
				  ],
				  "x": 8.04296875,
				  "y": 7.956729888916016,
				  "width": 8.000008583068848,
				  "height": 8.000008583068848,
				  "absoluteRenderBounds": {
					"x": 8623.04296875,
					"y": 1686.956787109375,
					"width": 10,
					"height": 10
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0.1234116330742836,
						  "y": 0.8322460651397705
						},
						"tangentEnd": {
						  "x": 0.38749924302101135,
						  "y": -0.7467986941337585
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.38749924302101135,
						  "y": 0.7467986941337585
						},
						"tangentEnd": {
						  "x": 0.7515201568603516,
						  "y": -0.37826108932495117
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.7515201568603516,
						  "y": 0.37826108932495117
						},
						"tangentEnd": {
						  "x": 0.8306610584259033,
						  "y": 0.13366460800170898
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8306610584259033,
						  "y": -0.13366460800170898
						},
						"tangentEnd": {
						  "x": 0.5949218273162842,
						  "y": 0.5949218273162842
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5949218273162842,
						  "y": -0.5949218273162842
						},
						"tangentEnd": {
						  "x": 0.13366460800170898,
						  "y": 0.8306610584259033
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.13366460800170898,
						  "y": -0.8306610584259033
						},
						"tangentEnd": {
						  "x": -0.37826108932495117,
						  "y": 0.7515201568603516
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.37826108932495117,
						  "y": -0.7515201568603516
						},
						"tangentEnd": {
						  "x": -0.7467986941337585,
						  "y": 0.38749924302101135
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.7467986941337585,
						  "y": -0.38749924302101135
						},
						"tangentEnd": {
						  "x": -0.8322460651397705,
						  "y": -0.1234116330742836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.8489227890968323,
						  "y": 0.12588457763195038
						},
						"tangentEnd": {
						  "x": -0.6068429946899414,
						  "y": -0.6068429946899414
						}
					  },
					  {
						"start": 9,
						"end": 0,
						"tangentStart": {
						  "x": 0.6068429946899414,
						  "y": 0.6068429946899414
						},
						"tangentEnd": {
						  "x": -0.12588457763195038,
						  "y": -0.8489227890968323
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.956738471984863,
						"y": 3.4132702350616455,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.550497055053711,
						"y": 5.84228515625,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.798364639282227,
						"y": 7.572943687438965,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.3645246028900146,
						"y": 7.949202537536621,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1715772151947021,
						"y": 6.828431129455566,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.05080585181713104,
						"y": 4.635483741760254,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.4270646870136261,
						"y": 2.2016441822052,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1577231884002686,
						"y": 0.44951170682907104,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.586738586425781,
						"y": 0.0432702973484993,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.828432083129883,
						"y": 1.171576738357544,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.956738471984863 3.4132702350616455 C 8.080150105059147 4.245516300201416 7.937996298074722 5.0954864621162415 7.550497055053711 5.84228515625 C 7.1629978120327 6.5890838503837585 6.549884796142578 7.194682598114014 5.798364639282227 7.572943687438965 C 5.046844482421875 7.951204776763916 4.195185661315918 8.08286714553833 3.3645246028900146 7.949202537536621 C 2.5338635444641113 7.815537929534912 1.7664990425109863 7.423352956771851 1.1715772151947021 6.828431129455566 C 0.576655387878418 6.233509302139282 0.18447045981884003 5.466144800186157 0.05080585181713104 4.635483741760254 C -0.08285875618457794 3.8048226833343506 0.04880359768867493 2.9531643390655518 0.4270646870136261 2.2016441822052 C 0.8053257763385773 1.4501240253448486 1.41092449426651 0.8370109498500824 2.1577231884002686 0.44951170682907104 C 2.904521882534027 0.06201246380805969 3.7544925212860107 -0.0801413357257843 4.586738586425781 0.0432702973484993 C 5.4356613755226135 0.16915487498044968 6.221589088439941 0.5647337436676025 6.828432083129883 1.171576738357544 C 7.435275077819824 1.7784197330474854 7.830853894352913 2.5643474459648132 7.956738471984863 3.4132702350616455 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3043",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  17.5
					],
					[
					  0,
					  1,
					  6.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8633.5
					],
					[
					  0,
					  1,
					  1686.5
					]
				  ],
				  "x": 17.5,
				  "y": 6.5,
				  "width": 0.010000228881835938,
				  "height": 0,
				  "absoluteRenderBounds": {
					"x": 8632.5,
					"y": 1685.5,
					"width": 2.009765625,
					"height": 2
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.010000228881835938,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0.010000228881835938 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "1e26c2fda3ea56df0e72d4d3814245edfb57c368",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "instagram"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2475.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3044",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=linkedin",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  284
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8660
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 284,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8660,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3045",
			  "parent": {
				"id": "395:3044"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8660
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8660,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3046",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  10
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8670
					],
					[
					  0,
					  1,
					  1688
					]
				  ],
				  "x": 10,
				  "y": 8,
				  "width": 12,
				  "height": 13,
				  "absoluteRenderBounds": {
					"x": 8669,
					"y": 1687,
					"width": 14,
					"height": 15
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 1.5912989377975464,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": -1.1252182722091675
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": 1.1252182722091675
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.5912989377975464
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": -0.5304329991340637
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": 0.37507274746894836
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": -0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0.5304329991340637,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.5304329991340637,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": -0.37507274746894836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": 0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.5304329991340637
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": -1.5912989377975464
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": 1.1252182722091675
						}
					  },
					  {
						"start": 13,
						"end": 0,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": -1.1252182722091675
						},
						"tangentEnd": {
						  "x": -1.5912989377975464,
						  "y": 8.881784197001252e-16
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 6,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.24264144897461,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.414213180541992,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.585786819458008,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.757359504699707,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 6 0 C 7.591298937797546 0 9.117423176765442 0.6321412324905396 10.24264144897461 1.757359504699707 C 11.367859721183777 2.8825777769088745 12 4.408701062202454 12 6 L 12 13 L 8 13 L 8 6 C 8 5.469567000865936 7.7892859280109406 4.960859566926956 7.414213180541992 4.585786819458008 C 7.039140433073044 4.2107140719890594 6.530432999134064 4 6 4 C 5.469567000865936 4 4.960859566926956 4.2107140719890594 4.585786819458008 4.585786819458008 C 4.2107140719890594 4.960859566926956 4 5.469567000865936 4 6 L 4 13 L 0 13 L 0 6 C 0 4.408701062202454 0.6321412324905396 2.8825777769088745 1.757359504699707 1.757359504699707 C 2.8825777769088745 0.6321412324905396 4.408701062202454 8.881784197001252e-16 6 0 L 6 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3047",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8662
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 2,
				  "y": 9,
				  "width": 4,
				  "height": 12,
				  "absoluteRenderBounds": {
					"x": 8661,
					"y": 1688,
					"width": 6,
					"height": 14
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 4 0 L 4 12 L 0 12 L 0 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3048",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8662
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 4,
				  "height": 4,
				  "absoluteRenderBounds": {
					"x": 8661,
					"y": 1681,
					"width": 6,
					"height": 6
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 1.1045695543289185
						},
						"tangentEnd": {
						  "x": 1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 1.1045695543289185
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": -1.1045695543289185
						},
						"tangentEnd": {
						  "x": -1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.1045695543289185
						}
					  }
					],
					"vertices": [
					  {
						"x": 4,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 4 2 C 4 3.1045695543289185 3.1045695543289185 4 2 4 C 0.8954304456710815 4 0 3.1045695543289185 0 2 C 0 0.8954304456710815 0.8954304456710815 0 2 0 C 3.1045695543289185 0 4 0.8954304456710815 4 2 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "eb87f6e2b8e9918c146899bbbc954f3281aff585",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "linkedin"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2480.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3049",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=codepen",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  328
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8704
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 328,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8704,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3050",
			  "parent": {
				"id": "395:3049"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8704
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8704,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3051",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1681,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 10 0 L 20 6.5 L 20 13.5 L 10 20 L 0 13.5 L 0 6.5 L 10 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3052",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  15.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8716
					],
					[
					  0,
					  1,
					  1695.5
					]
				  ],
				  "x": 12,
				  "y": 15.5,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 8715,
					"y": 1694.5,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 6.5 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3053",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1688.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1687.4998779296875,
					"width": 22,
					"height": 9.0001220703125
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 20,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 20 0 L 10 7 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3054",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1688.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1687.5,
					"width": 22,
					"height": 9.0001220703125
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 7 L 10 0 L 20 7"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3055",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8716
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 8715,
					"y": 1681,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0 6.5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "620ca35555fe695ea0b6e46dc61daac4225f375d",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "codepen"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2487.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3056",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=figma",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  372
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8748
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 372,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8748,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3057",
			  "parent": {
				"id": "395:3056"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8748
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8748,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3058",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 5,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1681,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 4.440892098500626e-16
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3059",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8760
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8759,
					"y": 1681,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": -6.8489693916262695e-9
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 6.8489693916262695e-9
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664215564727783,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606078147888184,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839392185211182,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3060",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8760
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 12,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8759,
					"y": 1688,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -9.685905411060958e-9,
						  "y": -0.45962655544281006
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 9.685901858347279e-9,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 9.685905411060958e-9,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -9.685907187417797e-9,
						  "y": 0.45962655544281006
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.77230656136968e-16,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3061",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  16
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1696
					]
				  ],
				  "x": 5,
				  "y": 16,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1695,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0.9282577037811279
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.025125503540039,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.9748735427856445,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3062",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 5,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1688,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 1.7763568394002505e-15
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "8115e8dc41092d9186d9e986f6c5e2f8400d8350",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "figma"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2494.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3063",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=youtube",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  416
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8792
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 416,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8792,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3064",
			  "parent": {
				"id": "395:3063"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8792
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8792,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3065",
				  "parent": {
					"id": "395:3064"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0.9990234375
					],
					[
					  0,
					  1,
					  4
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8792.9990234375
					],
					[
					  0,
					  1,
					  1684
					]
				  ],
				  "x": 0.9990234375,
				  "y": 4,
				  "width": 22.001144409179688,
				  "height": 15.459999084472656,
				  "absoluteRenderBounds": {
					"x": 8792,
					"y": 1683,
					"width": 24,
					"height": 17.4599609375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.11878856271505356,
						  "y": -0.47459015250205994
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.4707529842853546,
						  "y": 0.13318465650081635
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -1.7200000286102295,
						  "y": -0.41999998688697815
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 1.7200000286102295,
						  "y": -0.46000000834465027
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.4707529842853546,
						  "y": 0.13318465650081635
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.11878856271505356,
						  "y": -0.47459015250205994
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.3147854506969452,
						  "y": 1.7455555200576782
						},
						"tangentEnd": {
						  "x": -0.008764072321355343,
						  "y": -1.7736902236938477
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.011220705695450306,
						  "y": 1.7870450019836426
						},
						"tangentEnd": {
						  "x": -0.31723231077194214,
						  "y": -1.7586982250213623
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.13095924258232117,
						  "y": 0.45984727144241333
						},
						"tangentEnd": {
						  "x": -0.33983731269836426,
						  "y": -0.33633384108543396
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.33983731269836426,
						  "y": 0.33633384108543396
						},
						"tangentEnd": {
						  "x": -0.4611796438694,
						  "y": -0.12618708610534668
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 1.7200000286102295,
						  "y": 0.46000000834465027
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.7200000286102295,
						  "y": 0.46000000834465027
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0.4707529842853546,
						  "y": -0.13318465650081635
						},
						"tangentEnd": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": -0.11878856271505356,
						  "y": 0.47459015250205994
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.3123571574687958,
						  "y": -1.732411503791809
						},
						"tangentEnd": {
						  "x": 0.00632609985768795,
						  "y": 1.7603342533111572
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": 0.011220705695450306,
						  "y": -1.7870450019836426
						},
						"tangentEnd": {
						  "x": 0.31723231077194214,
						  "y": 1.7586982250213623
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.540573120117188,
						"y": 2.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 1.1594061851501465,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 0.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 0.46000003814697266,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.16191828250885,
						"y": 1.1994061470031738,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 2.4600000381469727,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.0005716445157304406,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 13.079999923706055,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1787155866622925,
						"y": 14.294479370117188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 15.459999084472656,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 14.26059341430664,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.540573120117188,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 22.000572204589844,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.540573120117188 2.4200000762939453 C 21.421784557402134 1.9454099237918854 21.179856419563293 1.5105718076229095 20.83922576904297 1.1594061851501465 C 20.498595118522644 0.8082405626773834 20.071325570344925 0.5531847327947617 19.60057258605957 0.4200000762939453 C 17.88057255744934 8.940696716308594e-8 11.000571250915527 0 11.000571250915527 0 C 11.000571250915527 0 4.120571851730347 2.9802322387695312e-8 2.400571823120117 0.46000003814697266 C 1.9298188388347626 0.593184694647789 1.5025489330291748 0.8482405245304108 1.16191828250885 1.1994061470031738 C 0.8212876319885254 1.550571769475937 0.5793602392077446 1.9854098856449127 0.46057167649269104 2.4600000381469727 C 0.14578622579574585 4.205555558204651 -0.008192427805624902 5.976309776306152 0.0005716445157304406 7.75 C -0.010649061179719865 9.537045001983643 0.1433393657207489 11.321301698684692 0.46057167649269104 13.079999923706055 C 0.5915309190750122 13.539847195148468 0.8388782739639282 13.958145529031754 1.1787155866622925 14.294479370117188 C 1.5185528993606567 14.630813211202621 1.9393921792507172 14.873812913894653 2.400571823120117 15 C 4.120571851730347 15.46000000834465 11.000571250915527 15.459999084472656 11.000571250915527 15.459999084472656 C 11.000571250915527 15.459999084472656 17.88057255744934 15.46000000834465 19.60057258605957 15 C 20.071325570344925 14.866815343499184 20.498595118522644 14.611759036779404 20.83922576904297 14.26059341430664 C 21.179856419563293 13.909427791833878 21.421784557402134 13.47459015250206 21.540573120117188 13 C 21.852930277585983 11.267588496208191 22.00689830444753 9.510334253311157 22.000572204589844 7.75 C 22.011792910285294 5.962954998016357 21.85780543088913 4.178698301315308 21.540573120117188 2.4200000762939453 L 21.540573120117188 2.4200000762939453 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3066",
				  "parent": {
					"id": "395:3064"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  9.75
					],
					[
					  0,
					  1,
					  8.479999542236328
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8801.75
					],
					[
					  0,
					  1,
					  1688.47998046875
					]
				  ],
				  "x": 9.75,
				  "y": 8.479999542236328,
				  "width": 5.75,
				  "height": 6.540000915527344,
				  "absoluteRenderBounds": {
					"x": 8800.75,
					"y": 1687.47998046875,
					"width": 7.75,
					"height": 8.5400390625
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.540000915527344,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.75,
						"y": 3.270000457763672,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 6.540000915527344 L 5.75 3.270000457763672 L 0 0 L 0 6.540000915527344 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "67ccb2c03ff9a2032a953c5c78d4753c1e6479fb",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "youtube"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2455.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3024",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=facebook",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8396
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8396,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3025",
			  "parent": {
				"id": "395:3024"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8397
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8397,
				"y": 1680,
				"width": 23,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3026",
				  "parent": {
					"id": "395:3025"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  6
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8403
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 6,
				  "y": 2,
				  "width": 11,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8402,
					"y": 1681,
					"width": 13,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.326082468032837,
						  "y": 8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 0.9376819133758545,
						  "y": -0.9376819133758545
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.9376819133758545,
						  "y": 0.9376819133758545
						},
						"tangentEnd": {
						  "x": 1.7763568394002505e-15,
						  "y": -1.326082468032837
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0,
						  "y": -0.26521649956703186
						},
						"tangentEnd": {
						  "x": -0.18753637373447418,
						  "y": 0.18753637373447418
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.18753637373447418,
						  "y": -0.18753637373447418
						},
						"tangentEnd": {
						  "x": -0.26521649956703186,
						  "y": -8.881784197001252e-16
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 17,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 11,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.464466094970703,
						"y": 1.4644660949707031,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.292893409729004,
						"y": 4.292893409729004,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 11 0 L 8 0 C 6.673917531967163 8.881784197001252e-16 5.402148008346558 0.5267841815948486 4.464466094970703 1.4644660949707031 C 3.5267841815948486 2.4021480083465576 3.0000000000000018 3.673917531967163 3 5 L 3 8 L 0 8 L 0 12 L 3 12 L 3 20 L 7 20 L 7 12 L 10 12 L 11 8 L 7 8 L 7 5 C 7 4.734783500432968 7.10535703599453 4.480429783463478 7.292893409729004 4.292893409729004 C 7.480429783463478 4.10535703599453 7.734783500432968 3.999999999999999 8 4 L 11 4 L 11 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "9b76a30f66ed5ae9ab2ca80cfc11dcde4c25fac8",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "facebook"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2458.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3027",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitter",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  64
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8440
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 64,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8440,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3028",
			  "parent": {
				"id": "395:3027"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8440
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8440,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3029",
				  "parent": {
					"id": "395:3028"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  2.9899444580078125
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8441
					],
					[
					  0,
					  1,
					  1682.989990234375
					]
				  ],
				  "x": 1,
				  "y": 2.9899444580078125,
				  "width": 22,
				  "height": 17.943681716918945,
				  "absoluteRenderBounds": {
					"x": 8440,
					"y": 1681.989990234375,
					"width": 23.9716796875,
					"height": 19.9437255859375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.9576150178909302,
						  "y": 0.6754773855209351
						},
						"tangentEnd": {
						  "x": 1.1221078634262085,
						  "y": -0.33789074420928955
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.6022599339485168,
						  "y": -0.6924893856048584
						},
						"tangentEnd": {
						  "x": 0.890299916267395,
						  "y": 0.22276580333709717
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.890299916267395,
						  "y": -0.22276580333709717
						},
						"tangentEnd": {
						  "x": 0.8574152588844299,
						  "y": -0.32725775241851807
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8574152588844299,
						  "y": 0.32725775241851807
						},
						"tangentEnd": {
						  "x": 0.5154598355293274,
						  "y": -0.7593153119087219
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5154598355293274,
						  "y": 0.7593153119087219
						},
						"tangentEnd": {
						  "x": -0.012302441522479057,
						  "y": -0.9176639914512634
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -1.757366418838501,
						  "y": 0.045568279922008514
						},
						"tangentEnd": {
						  "x": 1.570265293121338,
						  "y": 0.7903668880462646
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -1.570265293121338,
						  "y": -0.7903668880462646
						},
						"tangentEnd": {
						  "x": 1.010317087173462,
						  "y": 1.4386355876922607
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 4.440892098500626e-16,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": -9,
						  "y": -4
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.0594723224639893,
						  "y": 1.3979662656784058
						},
						"tangentEnd": {
						  "x": 2.487156867980957,
						  "y": 0.09892935305833817
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 9,
						  "y": 5
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 11.5
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.0009208884439431131,
						  "y": -0.27854636311531067
						},
						"tangentEnd": {
						  "x": 0.052297499030828476,
						  "y": 0.2735944092273712
						}
					  },
					  {
						"start": 12,
						"end": 0,
						"tangentStart": {
						  "x": 1.0206031799316406,
						  "y": -1.0065069198608398
						},
						"tangentEnd": {
						  "x": -0.3391726016998291,
						  "y": 1.3927128314971924
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 22,
						"y": 0.010055706836283207,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.860000610351562,
						"y": 1.5400558710098267,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 16.567033767700195,
						"y": 0.1339818686246872,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.882081031799316,
						"y": 0.29450908303260803,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.772982597351074,
						"y": 1.9637724161148071,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4.540055751800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 5.540055274963379,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.931008815765381,
						"y": 4.405501842498779,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 1.010055661201477,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 14.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 4.5100555419921875,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.920000076293945,
						"y": 3.680055856704712,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 22 0.010055706836283207 C 21.04238498210907 0.6855330923572183 19.98210847377777 1.202165126800537 18.860000610351562 1.5400558710098267 C 18.257740676403046 0.8475664854049683 17.45733368396759 0.35674767196178436 16.567033767700195 0.1339818686246872 C 15.6767338514328 -0.08878393471240997 14.739496290683746 -0.032748669385910034 13.882081031799316 0.29450908303260803 C 13.024665772914886 0.6217668354511261 12.288442432880402 1.2044571042060852 11.772982597351074 1.9637724161148071 C 11.257522761821747 2.723087728023529 10.987697558477521 3.6223917603492737 11 4.540055751800537 L 11 5.540055274963379 C 9.242633581161499 5.585623554885387 7.501274108886719 5.195868730545044 5.931008815765381 4.405501842498779 C 4.360743522644043 3.6151349544525146 3.010317087173462 2.448691248893738 2 1.010055661201477 C 2.0000000000000004 1.0100556612014762 -2 10.010055541992188 7 14.010055541992188 C 4.940527677536011 15.408021807670593 2.487156867980957 16.108984895050526 0 16.010055541992188 C 9 21.010055541992188 20 16.010055541992188 20 4.5100555419921875 C 19.999079111556057 4.231509178876877 19.972297575324774 3.953650265932083 19.920000076293945 3.680055856704712 C 20.940603256225586 2.673548936843872 21.66082739830017 1.4027685383334756 22 0.010055706836283207 L 22 0.010055706836283207 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "aa272c1fecdebcd9b50203192c22a20b7624c8e4",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitter"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2461.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3030",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitch",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  108
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8484
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 108,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8484,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3031",
			  "parent": {
				"id": "395:3030"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8484
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8484,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3032",
				  "parent": {
					"id": "395:3031"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  3
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8487
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 3,
				  "y": 2,
				  "width": 18,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8486,
					"y": 1681,
					"width": 20,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ],
						  [
							8,
							8
						  ],
						  [
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 18,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 9,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 18 0 L 0 0 L 0 16 L 5 16 L 5 20 L 9 16 L 14 16 L 18 12 L 18 0 Z M 8 9 L 8 5 L 8 9 Z M 13 9 L 13 5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b14c6c6c70817b147a3b4ba97ae90f827095fd9d",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitch"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2464.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3033",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=github",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  152
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8528
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 152,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8528,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3034",
			  "parent": {
				"id": "395:3033"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8529
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 22,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8529,
				"y": 1680,
				"width": 22,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3035",
				  "parent": {
					"id": "395:3034"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  0.97320556640625
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8530
					],
					[
					  0,
					  1,
					  1680.97314453125
					]
				  ],
				  "x": 1,
				  "y": 0.97320556640625,
				  "width": 19.5,
				  "height": 21.026792526245117,
				  "absoluteRenderBounds": {
					"x": 8529,
					"y": 1680,
					"width": 21.5,
					"height": 22.9998779296875
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ],
						  [
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -5,
						  "y": 1.5
						},
						"tangentEnd": {
						  "x": 2,
						  "y": 0.5
						}
					  },
					  {
						"start": 1,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.03751164302229881,
						  "y": -0.476836234331131
						},
						"tangentEnd": {
						  "x": 0.1620737761259079,
						  "y": 0.45001333951950073
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.1620737761259079,
						  "y": -0.45001333951950073
						},
						"tangentEnd": {
						  "x": 0.332929790019989,
						  "y": 0.3434205651283264
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 3.140000104904175,
						  "y": -0.3499999940395355
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 5.460000038146973
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.00025664002168923616,
						  "y": -1.3961730003356934
						},
						"tangentEnd": {
						  "x": 0.9626920819282532,
						  "y": 1.0111987590789795
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.4558558762073517,
						  "y": -1.2214934825897217
						},
						"tangentEnd": {
						  "x": 0.5136240124702454,
						  "y": 1.1983493566513062
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 2.7300000190734863,
						  "y": -1.8300000429153442
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.2919909954071045,
						  "y": -0.6211782693862915
						},
						"tangentEnd": {
						  "x": 2.2919909954071045,
						  "y": -0.6211782693862915
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -2.7300000190734863,
						  "y": -1.8300000429153442
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.5136240124702454,
						  "y": 1.1983493566513062
						},
						"tangentEnd": {
						  "x": -0.4558558762073517,
						  "y": -1.2214934825897217
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.9698728919029236,
						  "y": 1.0186996459960938
						},
						"tangentEnd": {
						  "x": -0.007477347739040852,
						  "y": -1.4065370559692383
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -1.3322676295501878e-15,
						  "y": 5.420000076293945
						},
						"tangentEnd": {
						  "x": -3.140000104904175,
						  "y": -0.38999998569488525
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.3290020823478699,
						  "y": 0.3399706780910492
						},
						"tangentEnd": {
						  "x": 0.161954864859581,
						  "y": -0.4445143938064575
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": -0.161954864859581,
						  "y": 0.4445143938064575
						},
						"tangentEnd": {
						  "x": -0.0331922248005867,
						  "y": -0.47193294763565063
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7,
						"y": 18.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15.026793479919434,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.811015129089355,
						"y": 15.750584602355957,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.0600004196167,
						"y": 14.546793937683105,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.5,
						"y": 7.546793460845947,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.90999984741211,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.0900001525878906,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.5,
						"y": 7.576793193817139,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.939999580383301,
						"y": 14.576793670654297,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.195308685302734,
						"y": 15.76671314239502,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7 18.026792526245117 C 2 19.526792526245117 2 15.526793479919434 0 15.026793479919434 L 7 18.026792526245117 Z M 14 21.026792526245117 L 14 17.15679168701172 C 14.037511643022299 16.679955452680588 13.973088905215263 16.200597941875458 13.811015129089355 15.750584602355957 C 13.648941352963448 15.300571262836456 13.392930209636688 14.890214502811432 13.0600004196167 14.546793937683105 C 16.200000524520874 14.19679394364357 19.5 13.00679349899292 19.5 7.546793460845947 C 19.49974335997831 6.150620460510254 18.962692081928253 4.8079917430877686 18 3.796792984008789 C 18.45585587620735 2.5752995014190674 18.423623859882355 1.225142465904355 17.90999984741211 0.026793109253048897 C 17.90999984741211 0.02679310925304801 16.730000019073486 -0.32320690155029297 14 1.5067931413650513 C 11.708009004592896 0.8856148719787598 9.291990995407104 0.8856148719787598 7 1.5067931413650513 C 4.269999980926514 -0.32320690155029297 3.0900001525878906 0.026793109253048897 3.0900001525878906 0.026793109253048897 C 2.5763761401176453 1.225142465904355 2.5441441237926483 2.5752995014190674 3 3.796792984008789 C 2.0301271080970764 4.815492630004883 1.4925226522609591 6.1702561378479 1.5 7.576793193817139 C 1.4999999999999987 12.996793270111084 4.799999475479126 14.186793684959412 7.939999580383301 14.576793670654297 C 7.610997498035431 14.916764348745346 7.357263550162315 15.322198748588562 7.195308685302734 15.76671314239502 C 7.033353820443153 16.211227536201477 6.966807775199413 16.684858739376068 7 17.15679168701172 L 7 21.026792526245117"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "373063ab2542ac4426cd0c65e2483c6f816822ec",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "github"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2467.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3036",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=gitlab",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  196
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8572
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 196,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8572,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3037",
			  "parent": {
				"id": "395:3036"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8572
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8572,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3038",
				  "parent": {
					"id": "395:3037"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1.0078125
					],
					[
					  0,
					  1,
					  1.8874893188476562
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8573.0078125
					],
					[
					  0,
					  1,
					  1681.887451171875
					]
				  ],
				  "x": 1.0078125,
				  "y": 1.8874893188476562,
				  "width": 22.025835037231445,
				  "height": 20.242509841918945,
				  "absoluteRenderBounds": {
					"x": 8572.0078125,
					"y": 1680.887451171875,
					"width": 23.9921875,
					"height": 22.2425537109375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17,
							18,
							19,
							20,
							21
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.14277499914169312,
						  "y": -0.10500118136405945
						},
						"tangentEnd": {
						  "x": 0.0538845919072628,
						  "y": 0.16883838176727295
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.0538845919072628,
						  "y": -0.16883838176727295
						},
						"tangentEnd": {
						  "x": -0.055552154779434204,
						  "y": 0.16829708218574524
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": 2.220446049250313e-16
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": -4.440892098500626e-16
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 17,
						"end": 18,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 18,
						"end": 19,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 19,
						"end": 20,
						"tangentStart": {
						  "x": 0.050522733479738235,
						  "y": 0.17348626255989075
						},
						"tangentEnd": {
						  "x": 0.06305060535669327,
						  "y": -0.16933591663837433
						}
					  },
					  {
						"start": 20,
						"end": 0,
						"tangentStart": {
						  "x": -0.06305060535669327,
						  "y": 0.16933591663837433
						},
						"tangentEnd": {
						  "x": 0.1516815572977066,
						  "y": -0.09819743782281876
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.642332077026367,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.992331504821777,
						"y": 20.242509841918945,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.3423318862915039,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.039766304194927216,
						"y": 12.08120059967041,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.04233180731534958,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.2623318433761597,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.702331781387329,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.8123319149017334,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.10233211517334,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.392332077026367,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.5023322105407715,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.942331790924072,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15.042330741882324,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.482332229614258,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.59233283996582,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.88233184814453,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.172332763671875,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.282333374023438,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.722332000732422,
						"y": 7.802509307861328,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.992332458496094,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.973037719726562,
						"y": 12.090485572814941,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.642332077026367 12.502510070800781 L 10.992331504821777 20.242509841918945 L 0.3423318862915039 12.502510070800781 C 0.1995568871498108 12.397508889436722 0.09365089610219002 12.250038981437683 0.039766304194927216 12.08120059967041 C -0.014118287712335587 11.912362217903137 -0.013220347464084625 11.73080661892891 0.04233180731534958 11.562509536743164 L 1.2623318433761597 7.782509803771973 L 3.702331781387329 0.27250993251800537 C 3.7259979881346226 0.21131689473986626 3.7636714093387127 0.1565193012356758 3.8123319149017334 0.1125098466873169 C 3.891571380198002 0.04013139754533768 3.9950123503804207 2.22044604925035e-16 4.10233211517334 3.680674433215635e-30 C 4.209651879966259 2.22044604925035e-16 4.313092611730099 0.04013139754533768 4.392332077026367 0.1125098466873169 C 4.443714894354343 0.16217468678951263 4.481575883924961 0.22412888705730438 4.5023322105407715 0.29250991344451904 L 6.942331790924072 7.782509803771973 L 15.042330741882324 7.782509803771973 L 17.482332229614258 0.27250993251800537 C 17.50599843636155 0.21131689473986626 17.5436723344028 0.1565193012356758 17.59233283996582 0.1125098466873169 C 17.67157230526209 0.04013139754533768 17.775012083351612 -4.440892098500589e-16 17.88233184814453 3.680674433215635e-30 C 17.98965161293745 2.22044604925035e-16 18.093093298375607 0.04013139754533768 18.172332763671875 0.1125098466873169 C 18.22371558099985 0.16217468678951263 18.261577047407627 0.22412888705730438 18.282333374023438 0.29250991344451904 L 20.722332000732422 7.802509307861328 L 21.992332458496094 11.562509536743164 C 22.042855191975832 11.735995799303055 22.036088325083256 11.921149656176567 21.973037719726562 12.090485572814941 C 21.90998711436987 12.259821489453316 21.794013634324074 12.404312632977962 21.642332077026367 12.502510070800781 L 21.642332077026367 12.502510070800781 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "2c68f2ca7a6e5ee9405e77e4aa41b45d62abc618",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "gitlab"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2470.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3039",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=instagram",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  240
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8616
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 240,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8616,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3040",
			  "parent": {
				"id": "395:3039"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8616
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8616,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3041",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8618
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8617,
					"y": 1681,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -2.7614238262176514
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 2.7614238262176514
						},
						"tangentEnd": {
						  "x": 2.7614238262176514,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 2.7614238262176514
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": -2.7614238262176514
						},
						"tangentEnd": {
						  "x": -2.7614238262176514,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 5 0 L 15 0 C 17.76142382621765 0 20 2.2385761737823486 20 5 L 20 15 C 20 17.76142382621765 17.76142382621765 20 15 20 L 5 20 C 2.2385761737823486 20 0 17.76142382621765 0 15 L 0 5 C 0 2.2385761737823486 2.2385761737823486 0 5 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3042",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  8.04296875
					],
					[
					  0,
					  1,
					  7.956729888916016
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8624.04296875
					],
					[
					  0,
					  1,
					  1687.956787109375
					]
				  ],
				  "x": 8.04296875,
				  "y": 7.956729888916016,
				  "width": 8.000008583068848,
				  "height": 8.000008583068848,
				  "absoluteRenderBounds": {
					"x": 8623.04296875,
					"y": 1686.956787109375,
					"width": 10,
					"height": 10
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0.1234116330742836,
						  "y": 0.8322460651397705
						},
						"tangentEnd": {
						  "x": 0.38749924302101135,
						  "y": -0.7467986941337585
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.38749924302101135,
						  "y": 0.7467986941337585
						},
						"tangentEnd": {
						  "x": 0.7515201568603516,
						  "y": -0.37826108932495117
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.7515201568603516,
						  "y": 0.37826108932495117
						},
						"tangentEnd": {
						  "x": 0.8306610584259033,
						  "y": 0.13366460800170898
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8306610584259033,
						  "y": -0.13366460800170898
						},
						"tangentEnd": {
						  "x": 0.5949218273162842,
						  "y": 0.5949218273162842
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5949218273162842,
						  "y": -0.5949218273162842
						},
						"tangentEnd": {
						  "x": 0.13366460800170898,
						  "y": 0.8306610584259033
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.13366460800170898,
						  "y": -0.8306610584259033
						},
						"tangentEnd": {
						  "x": -0.37826108932495117,
						  "y": 0.7515201568603516
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.37826108932495117,
						  "y": -0.7515201568603516
						},
						"tangentEnd": {
						  "x": -0.7467986941337585,
						  "y": 0.38749924302101135
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.7467986941337585,
						  "y": -0.38749924302101135
						},
						"tangentEnd": {
						  "x": -0.8322460651397705,
						  "y": -0.1234116330742836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.8489227890968323,
						  "y": 0.12588457763195038
						},
						"tangentEnd": {
						  "x": -0.6068429946899414,
						  "y": -0.6068429946899414
						}
					  },
					  {
						"start": 9,
						"end": 0,
						"tangentStart": {
						  "x": 0.6068429946899414,
						  "y": 0.6068429946899414
						},
						"tangentEnd": {
						  "x": -0.12588457763195038,
						  "y": -0.8489227890968323
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.956738471984863,
						"y": 3.4132702350616455,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.550497055053711,
						"y": 5.84228515625,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.798364639282227,
						"y": 7.572943687438965,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.3645246028900146,
						"y": 7.949202537536621,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1715772151947021,
						"y": 6.828431129455566,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.05080585181713104,
						"y": 4.635483741760254,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.4270646870136261,
						"y": 2.2016441822052,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1577231884002686,
						"y": 0.44951170682907104,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.586738586425781,
						"y": 0.0432702973484993,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.828432083129883,
						"y": 1.171576738357544,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.956738471984863 3.4132702350616455 C 8.080150105059147 4.245516300201416 7.937996298074722 5.0954864621162415 7.550497055053711 5.84228515625 C 7.1629978120327 6.5890838503837585 6.549884796142578 7.194682598114014 5.798364639282227 7.572943687438965 C 5.046844482421875 7.951204776763916 4.195185661315918 8.08286714553833 3.3645246028900146 7.949202537536621 C 2.5338635444641113 7.815537929534912 1.7664990425109863 7.423352956771851 1.1715772151947021 6.828431129455566 C 0.576655387878418 6.233509302139282 0.18447045981884003 5.466144800186157 0.05080585181713104 4.635483741760254 C -0.08285875618457794 3.8048226833343506 0.04880359768867493 2.9531643390655518 0.4270646870136261 2.2016441822052 C 0.8053257763385773 1.4501240253448486 1.41092449426651 0.8370109498500824 2.1577231884002686 0.44951170682907104 C 2.904521882534027 0.06201246380805969 3.7544925212860107 -0.0801413357257843 4.586738586425781 0.0432702973484993 C 5.4356613755226135 0.16915487498044968 6.221589088439941 0.5647337436676025 6.828432083129883 1.171576738357544 C 7.435275077819824 1.7784197330474854 7.830853894352913 2.5643474459648132 7.956738471984863 3.4132702350616455 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3043",
				  "parent": {
					"id": "395:3040"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  17.5
					],
					[
					  0,
					  1,
					  6.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8633.5
					],
					[
					  0,
					  1,
					  1686.5
					]
				  ],
				  "x": 17.5,
				  "y": 6.5,
				  "width": 0.010000228881835938,
				  "height": 0,
				  "absoluteRenderBounds": {
					"x": 8632.5,
					"y": 1685.5,
					"width": 2.009765625,
					"height": 2
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.010000228881835938,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0.010000228881835938 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "1e26c2fda3ea56df0e72d4d3814245edfb57c368",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "instagram"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2475.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3044",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=linkedin",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  284
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8660
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 284,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8660,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3045",
			  "parent": {
				"id": "395:3044"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8660
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8660,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3046",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  10
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8670
					],
					[
					  0,
					  1,
					  1688
					]
				  ],
				  "x": 10,
				  "y": 8,
				  "width": 12,
				  "height": 13,
				  "absoluteRenderBounds": {
					"x": 8669,
					"y": 1687,
					"width": 14,
					"height": 15
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 1.5912989377975464,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": -1.1252182722091675
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": 1.1252182722091675
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.5912989377975464
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": -0.5304329991340637
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": 0.37507274746894836
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": -0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0.5304329991340637,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.5304329991340637,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": -0.37507274746894836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": 0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.5304329991340637
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": -1.5912989377975464
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": 1.1252182722091675
						}
					  },
					  {
						"start": 13,
						"end": 0,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": -1.1252182722091675
						},
						"tangentEnd": {
						  "x": -1.5912989377975464,
						  "y": 8.881784197001252e-16
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 6,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.24264144897461,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.414213180541992,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.585786819458008,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.757359504699707,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 6 0 C 7.591298937797546 0 9.117423176765442 0.6321412324905396 10.24264144897461 1.757359504699707 C 11.367859721183777 2.8825777769088745 12 4.408701062202454 12 6 L 12 13 L 8 13 L 8 6 C 8 5.469567000865936 7.7892859280109406 4.960859566926956 7.414213180541992 4.585786819458008 C 7.039140433073044 4.2107140719890594 6.530432999134064 4 6 4 C 5.469567000865936 4 4.960859566926956 4.2107140719890594 4.585786819458008 4.585786819458008 C 4.2107140719890594 4.960859566926956 4 5.469567000865936 4 6 L 4 13 L 0 13 L 0 6 C 0 4.408701062202454 0.6321412324905396 2.8825777769088745 1.757359504699707 1.757359504699707 C 2.8825777769088745 0.6321412324905396 4.408701062202454 8.881784197001252e-16 6 0 L 6 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3047",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8662
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 2,
				  "y": 9,
				  "width": 4,
				  "height": 12,
				  "absoluteRenderBounds": {
					"x": 8661,
					"y": 1688,
					"width": 6,
					"height": 14
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 4 0 L 4 12 L 0 12 L 0 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3048",
				  "parent": {
					"id": "395:3045"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8662
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 4,
				  "height": 4,
				  "absoluteRenderBounds": {
					"x": 8661,
					"y": 1681,
					"width": 6,
					"height": 6
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 1.1045695543289185
						},
						"tangentEnd": {
						  "x": 1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 1.1045695543289185
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": -1.1045695543289185
						},
						"tangentEnd": {
						  "x": -1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.1045695543289185
						}
					  }
					],
					"vertices": [
					  {
						"x": 4,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 4 2 C 4 3.1045695543289185 3.1045695543289185 4 2 4 C 0.8954304456710815 4 0 3.1045695543289185 0 2 C 0 0.8954304456710815 0.8954304456710815 0 2 0 C 3.1045695543289185 0 4 0.8954304456710815 4 2 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "eb87f6e2b8e9918c146899bbbc954f3281aff585",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "linkedin"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2480.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3049",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=codepen",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  328
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8704
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 328,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8704,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3050",
			  "parent": {
				"id": "395:3049"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8704
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8704,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3051",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1681,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 10 0 L 20 6.5 L 20 13.5 L 10 20 L 0 13.5 L 0 6.5 L 10 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3052",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  15.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8716
					],
					[
					  0,
					  1,
					  1695.5
					]
				  ],
				  "x": 12,
				  "y": 15.5,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 8715,
					"y": 1694.5,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 6.5 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3053",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1688.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1687.4998779296875,
					"width": 22,
					"height": 9.0001220703125
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 20,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 20 0 L 10 7 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3054",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8706
					],
					[
					  0,
					  1,
					  1688.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8705,
					"y": 1687.5,
					"width": 22,
					"height": 9.0001220703125
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 7 L 10 0 L 20 7"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3055",
				  "parent": {
					"id": "395:3050"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8716
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 8715,
					"y": 1681,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0 6.5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "620ca35555fe695ea0b6e46dc61daac4225f375d",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "codepen"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2487.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3056",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=figma",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  372
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8748
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 372,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8748,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3057",
			  "parent": {
				"id": "395:3056"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8748
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8748,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3058",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 5,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1681,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 4.440892098500626e-16
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3059",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8760
					],
					[
					  0,
					  1,
					  1682
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8759,
					"y": 1681,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": -6.8489693916262695e-9
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 6.8489693916262695e-9
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664215564727783,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606078147888184,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839392185211182,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3060",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8760
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 12,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8759,
					"y": 1688,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -9.685905411060958e-9,
						  "y": -0.45962655544281006
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 9.685901858347279e-9,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 9.685905411060958e-9,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -9.685907187417797e-9,
						  "y": 0.45962655544281006
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.77230656136968e-16,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3061",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  16
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1696
					]
				  ],
				  "x": 5,
				  "y": 16,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1695,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0.9282577037811279
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.025125503540039,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.9748735427856445,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3062",
				  "parent": {
					"id": "395:3057"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8753
					],
					[
					  0,
					  1,
					  1689
					]
				  ],
				  "x": 5,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 8752,
					"y": 1688,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 1.7763568394002505e-15
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "8115e8dc41092d9186d9e986f6c5e2f8400d8350",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "figma"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2494.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3063",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=youtube",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  416
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8792
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 416,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 8792,
			"y": 1680,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3064",
			  "parent": {
				"id": "395:3063"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8792
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 8792,
				"y": 1680,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3065",
				  "parent": {
					"id": "395:3064"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0.9990234375
					],
					[
					  0,
					  1,
					  4
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8792.9990234375
					],
					[
					  0,
					  1,
					  1684
					]
				  ],
				  "x": 0.9990234375,
				  "y": 4,
				  "width": 22.001144409179688,
				  "height": 15.459999084472656,
				  "absoluteRenderBounds": {
					"x": 8792,
					"y": 1683,
					"width": 24,
					"height": 17.4599609375
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.11878856271505356,
						  "y": -0.47459015250205994
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.4707529842853546,
						  "y": 0.13318465650081635
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -1.7200000286102295,
						  "y": -0.41999998688697815
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 1.7200000286102295,
						  "y": -0.46000000834465027
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.4707529842853546,
						  "y": 0.13318465650081635
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.11878856271505356,
						  "y": -0.47459015250205994
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.3147854506969452,
						  "y": 1.7455555200576782
						},
						"tangentEnd": {
						  "x": -0.008764072321355343,
						  "y": -1.7736902236938477
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.011220705695450306,
						  "y": 1.7870450019836426
						},
						"tangentEnd": {
						  "x": -0.31723231077194214,
						  "y": -1.7586982250213623
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.13095924258232117,
						  "y": 0.45984727144241333
						},
						"tangentEnd": {
						  "x": -0.33983731269836426,
						  "y": -0.33633384108543396
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.33983731269836426,
						  "y": 0.33633384108543396
						},
						"tangentEnd": {
						  "x": -0.4611796438694,
						  "y": -0.12618708610534668
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 1.7200000286102295,
						  "y": 0.46000000834465027
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.7200000286102295,
						  "y": 0.46000000834465027
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0.4707529842853546,
						  "y": -0.13318465650081635
						},
						"tangentEnd": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": -0.11878856271505356,
						  "y": 0.47459015250205994
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.3123571574687958,
						  "y": -1.732411503791809
						},
						"tangentEnd": {
						  "x": 0.00632609985768795,
						  "y": 1.7603342533111572
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": 0.011220705695450306,
						  "y": -1.7870450019836426
						},
						"tangentEnd": {
						  "x": 0.31723231077194214,
						  "y": 1.7586982250213623
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.540573120117188,
						"y": 2.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 1.1594061851501465,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 0.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 0.46000003814697266,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.16191828250885,
						"y": 1.1994061470031738,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 2.4600000381469727,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.0005716445157304406,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 13.079999923706055,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1787155866622925,
						"y": 14.294479370117188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 15.459999084472656,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 14.26059341430664,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.540573120117188,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 22.000572204589844,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.540573120117188 2.4200000762939453 C 21.421784557402134 1.9454099237918854 21.179856419563293 1.5105718076229095 20.83922576904297 1.1594061851501465 C 20.498595118522644 0.8082405626773834 20.071325570344925 0.5531847327947617 19.60057258605957 0.4200000762939453 C 17.88057255744934 8.940696716308594e-8 11.000571250915527 0 11.000571250915527 0 C 11.000571250915527 0 4.120571851730347 2.9802322387695312e-8 2.400571823120117 0.46000003814697266 C 1.9298188388347626 0.593184694647789 1.5025489330291748 0.8482405245304108 1.16191828250885 1.1994061470031738 C 0.8212876319885254 1.550571769475937 0.5793602392077446 1.9854098856449127 0.46057167649269104 2.4600000381469727 C 0.14578622579574585 4.205555558204651 -0.008192427805624902 5.976309776306152 0.0005716445157304406 7.75 C -0.010649061179719865 9.537045001983643 0.1433393657207489 11.321301698684692 0.46057167649269104 13.079999923706055 C 0.5915309190750122 13.539847195148468 0.8388782739639282 13.958145529031754 1.1787155866622925 14.294479370117188 C 1.5185528993606567 14.630813211202621 1.9393921792507172 14.873812913894653 2.400571823120117 15 C 4.120571851730347 15.46000000834465 11.000571250915527 15.459999084472656 11.000571250915527 15.459999084472656 C 11.000571250915527 15.459999084472656 17.88057255744934 15.46000000834465 19.60057258605957 15 C 20.071325570344925 14.866815343499184 20.498595118522644 14.611759036779404 20.83922576904297 14.26059341430664 C 21.179856419563293 13.909427791833878 21.421784557402134 13.47459015250206 21.540573120117188 13 C 21.852930277585983 11.267588496208191 22.00689830444753 9.510334253311157 22.000572204589844 7.75 C 22.011792910285294 5.962954998016357 21.85780543088913 4.178698301315308 21.540573120117188 2.4200000762939453 L 21.540573120117188 2.4200000762939453 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3066",
				  "parent": {
					"id": "395:3064"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  9.75
					],
					[
					  0,
					  1,
					  8.479999542236328
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8801.75
					],
					[
					  0,
					  1,
					  1688.47998046875
					]
				  ],
				  "x": 9.75,
				  "y": 8.479999542236328,
				  "width": 5.75,
				  "height": 6.540000915527344,
				  "absoluteRenderBounds": {
					"x": 8800.75,
					"y": 1687.47998046875,
					"width": 7.75,
					"height": 8.5400390625
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.540000915527344,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.75,
						"y": 3.270000457763672,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 6.540000915527344 L 5.75 3.270000457763672 L 0 0 L 0 6.540000915527344 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "67ccb2c03ff9a2032a953c5c78d4753c1e6479fb",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "youtube"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2697.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2697",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Type=Logo",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1476
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 85,
		  "height": 30,
		  "absoluteRenderBounds": {
			"x": 1476,
			"y": 40,
			"width": 85,
			"height": 30
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2699",
			  "parent": {
				"id": "394:2697"
			  },
			  "name": "making.it logo",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1476
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 85,
			  "height": 30,
			  "absoluteRenderBounds": {
				"x": 1476,
				"y": 40,
				"width": 85,
				"height": 30
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "394:2701",
				  "parent": {
					"id": "394:2699"
				  },
				  "name": "icon",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1476
					],
					[
					  0,
					  1,
					  45
					]
				  ],
				  "x": 0,
				  "y": 5,
				  "width": 34,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 1476,
					"y": 45,
					"width": 34,
					"height": 20
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "394:2703",
					  "parent": {
						"id": "394:2701"
					  },
					  "name": "Rectangle 5",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  0
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  11.313720703125
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1476
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  56.313720703125
						]
					  ],
					  "x": 0,
					  "y": 11.313720703125,
					  "width": 16.000022888183594,
					  "height": 4.000026702880859,
					  "absoluteRenderBounds": {
						"x": 1476,
						"y": 45,
						"width": 14.1422119140625,
						"height": 14.142166137695312
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.8941176533699036,
							"b": 0.3686274588108063
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "394:2705",
					  "parent": {
						"id": "394:2701"
					  },
					  "name": "Rectangle 6",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  7.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  14.14215087890625
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1483.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  59.14215087890625
						]
					  ],
					  "x": 7.14208984375,
					  "y": 14.14215087890625,
					  "width": 20.000022888183594,
					  "height": 4.000026702880859,
					  "absoluteRenderBounds": {
						"x": 1483.14208984375,
						"y": 45,
						"width": 16.9705810546875,
						"height": 16.970596313476562
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.3686274588108063,
							"g": 0.6705882549285889,
							"b": 0.8901960849761963
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "394:2707",
					  "parent": {
						"id": "394:2701"
					  },
					  "name": "Rectangle 7",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  14.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  16.970550537109375
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1490.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  61.970550537109375
						]
					  ],
					  "x": 14.11279296875,
					  "y": 16.970550537109375,
					  "width": 24.000022888183594,
					  "height": 4.000026702880859,
					  "absoluteRenderBounds": {
						"x": 1490.11279296875,
						"y": 45,
						"width": 19.799072265625,
						"height": 19.798995971679688
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.38823530077934265,
							"b": 0.572549045085907
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "394:2709",
				  "listSpacing": 0,
				  "parent": {
					"id": "394:2699"
				  },
				  "name": "Logo",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.03947368264198303,
						"g": 0.16421058773994446,
						"b": 0.2605263292789459
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  42
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1518
					],
					[
					  0,
					  1,
					  40
					]
				  ],
				  "x": 42,
				  "y": 0,
				  "width": 43,
				  "height": 30,
				  "absoluteRenderBounds": {
					"x": 1519.457763671875,
					"y": 48.909088134765625,
					"width": 40.6741943359375,
					"height": 16.977279663085938
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Logo",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 30
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Medium"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 8,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 10,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "bce74241b95b0de65a9ecfb6084474dab419bd25",
		  "reactions": [],
		  "variantProperties": {
			"Type": "Logo"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2698.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2698",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Type=Icon",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  70
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1476
			],
			[
			  0,
			  1,
			  90
			]
		  ],
		  "x": 20,
		  "y": 70,
		  "width": 34,
		  "height": 34,
		  "absoluteRenderBounds": {
			"x": 1476,
			"y": 90,
			"width": 34,
			"height": 34
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2700",
			  "parent": {
				"id": "394:2698"
			  },
			  "name": "making.it logo",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1476
				],
				[
				  0,
				  1,
				  90
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 34,
			  "height": 34,
			  "absoluteRenderBounds": {
				"x": 1476,
				"y": 90,
				"width": 34,
				"height": 34
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "394:2702",
				  "parent": {
					"id": "394:2700"
				  },
				  "name": "icon",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  7
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1476
					],
					[
					  0,
					  1,
					  97
					]
				  ],
				  "x": 0,
				  "y": 7,
				  "width": 34,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 1476,
					"y": 97,
					"width": 34,
					"height": 20
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "394:2704",
					  "parent": {
						"id": "394:2702"
					  },
					  "name": "Rectangle 5",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  0
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  11.313720703125
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1476
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  108.313720703125
						]
					  ],
					  "x": 0,
					  "y": 11.313720703125,
					  "width": 16.000022888183594,
					  "height": 4.000026702880859,
					  "absoluteRenderBounds": {
						"x": 1476,
						"y": 97,
						"width": 14.1422119140625,
						"height": 14.142166137695312
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.8941176533699036,
							"b": 0.3686274588108063
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "394:2706",
					  "parent": {
						"id": "394:2702"
					  },
					  "name": "Rectangle 6",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  7.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  14.14215087890625
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1483.14208984375
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  111.14215087890625
						]
					  ],
					  "x": 7.14208984375,
					  "y": 14.14215087890625,
					  "width": 20.000022888183594,
					  "height": 4.000026702880859,
					  "absoluteRenderBounds": {
						"x": 1483.14208984375,
						"y": 97,
						"width": 16.9705810546875,
						"height": 16.970596313476562
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.3686274588108063,
							"g": 0.6705882549285889,
							"b": 0.8901960849761963
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "394:2708",
					  "parent": {
						"id": "394:2702"
					  },
					  "name": "Rectangle 7",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  14.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  16.970550537109375
						]
					  ],
					  "absoluteTransform": [
						[
						  0.7071068286895752,
						  0.7071068286895752,
						  1490.11279296875
						],
						[
						  -0.7071068286895752,
						  0.7071068286895752,
						  113.97055053710938
						]
					  ],
					  "x": 14.11279296875,
					  "y": 16.970550537109375,
					  "width": 24.000022888183594,
					  "height": 4.000026702880859,
					  "absoluteRenderBounds": {
						"x": 1490.11279296875,
						"y": 97,
						"width": 19.799072265625,
						"height": 19.798995971679688
					  },
					  "rotation": 45,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 0.38823530077934265,
							"b": 0.572549045085907
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 4,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 20,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "798ff383c78d739690c79ea2904926d590b1528e",
		  "reactions": [],
		  "variantProperties": {
			"Type": "Icon"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BRAND_LOGO",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "footer",
		  "makersClasses": "makers-footer, makers-footer--four-columns",
		  "makersComponent": "footer",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3068",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Four Columns",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8896
			],
			[
			  0,
			  1,
			  1680
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 1440,
		  "height": 229,
		  "absoluteRenderBounds": {
			"x": 8896,
			"y": 1680,
			"width": 1440,
			"height": 229
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "columns",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3069",
			  "parent": {
				"id": "395:3068"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  220
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9116
				],
				[
				  0,
				  1,
				  1680
				]
			  ],
			  "x": 220,
			  "y": 0,
			  "width": 1000,
			  "height": 229,
			  "absoluteRenderBounds": {
				"x": 9116,
				"y": 1680,
				"width": 1000,
				"height": 229
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3070",
				  "parent": {
					"id": "395:3069"
				  },
				  "name": "Column",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9116
					],
					[
					  0,
					  1,
					  1712
					]
				  ],
				  "x": 0,
				  "y": 32,
				  "width": 240,
				  "height": 127,
				  "absoluteRenderBounds": {
					"x": 9116,
					"y": 1712,
					"width": 240,
					"height": 127
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-395-3011.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3071",
					  "parent": {
						"id": "395:3070"
					  },
					  "name": "MAKERS_BRAND_LOGO",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9116
						],
						[
						  0,
						  1,
						  1712
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 34,
					  "height": 34,
					  "absoluteRenderBounds": {
						"x": 9116,
						"y": 1712,
						"width": 34,
						"height": 34
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I395:3071;394:2700",
						  "parent": {
							"id": "395:3071"
						  },
						  "name": "making.it logo",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9116
							],
							[
							  0,
							  1,
							  1712
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 34,
						  "height": 34,
						  "absoluteRenderBounds": {
							"x": 9116,
							"y": 1712,
							"width": 34,
							"height": 34
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3071;394:2702",
							  "parent": {
								"id": "I395:3071;394:2700"
							  },
							  "name": "icon",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  7
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9116
								],
								[
								  0,
								  1,
								  1719
								]
							  ],
							  "x": 0,
							  "y": 7,
							  "width": 34,
							  "height": 20,
							  "absoluteRenderBounds": {
								"x": 9116,
								"y": 1719,
								"width": 34,
								"height": 20
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3071;394:2704",
								  "parent": {
									"id": "I395:3071;394:2702"
								  },
								  "name": "Rectangle 5",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  0
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  11.313720703125
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  9116
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  1730.313720703125
									]
								  ],
								  "x": 0,
								  "y": 11.313720703125,
								  "width": 16.000022888183594,
								  "height": 4.000026702880859,
								  "absoluteRenderBounds": {
									"x": 9116,
									"y": 1719,
									"width": 14.142578125,
									"height": 14.1422119140625
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.8941176533699036,
										"b": 0.3686274588108063
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3071;394:2706",
								  "parent": {
									"id": "I395:3071;394:2702"
								  },
								  "name": "Rectangle 6",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  7.14208984375
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  14.14215087890625
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  9123.142578125
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  1733.14208984375
									]
								  ],
								  "x": 7.14208984375,
								  "y": 14.14215087890625,
								  "width": 20.000022888183594,
								  "height": 4.000026702880859,
								  "absoluteRenderBounds": {
									"x": 9123.142578125,
									"y": 1719,
									"width": 16.970703125,
									"height": 16.9705810546875
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.3686274588108063,
										"g": 0.6705882549285889,
										"b": 0.8901960849761963
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3071;394:2708",
								  "parent": {
									"id": "I395:3071;394:2702"
								  },
								  "name": "Rectangle 7",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  14.11279296875
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  16.970550537109375
									]
								  ],
								  "absoluteTransform": [
									[
									  0.7071068286895752,
									  0.7071068286895752,
									  9130.11328125
									],
									[
									  -0.7071068286895752,
									  0.7071068286895752,
									  1735.9705810546875
									]
								  ],
								  "x": 14.11279296875,
								  "y": 16.970550537109375,
								  "width": 24.000022888183594,
								  "height": 4.000026702880859,
								  "absoluteRenderBounds": {
									"x": 9130.11328125,
									"y": 1719,
									"width": 19.798828125,
									"height": 19.799072265625
								  },
								  "rotation": 45,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 0.38823530077934265,
										"b": 0.572549045085907
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": true,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "NONE",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 4,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 20,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2698"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Type": "Icon"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3072",
					  "listSpacing": 0,
					  "parent": {
						"id": "395:3070"
					  },
					  "name": "Making the world a better place.",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.40392157435417175,
							"g": 0.5137255191802979,
							"b": 0.5960784554481506
						  }
						}
					  ],
					  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  50
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9116
						],
						[
						  0,
						  1,
						  1762
						]
					  ],
					  "x": 0,
					  "y": 50,
					  "width": 240,
					  "height": 21,
					  "absoluteRenderBounds": {
						"x": 9117.1181640625,
						"y": 1767.4105224609375,
						"width": 213.7275390625,
						"height": 13.6121826171875
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Making the world a better place.",
					  "hasMissingFont": false,
					  "fontSize": 14,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PERCENT",
						"value": 150
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Medium"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "LEFT",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3073",
					  "parent": {
						"id": "395:3070"
					  },
					  "name": "Social Media",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  87
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9116
						],
						[
						  0,
						  1,
						  1799
						]
					  ],
					  "x": 0,
					  "y": 87,
					  "width": 240,
					  "height": 40,
					  "absoluteRenderBounds": {
						"x": 9116,
						"y": 1799,
						"width": 240,
						"height": 40
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2505.svg",
						  "makersExportAsSvg": "true",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3074",
						  "parent": {
							"id": "395:3073"
						  },
						  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  8
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9116
							],
							[
							  0,
							  1,
							  1807
							]
						  ],
						  "x": 0,
						  "y": 8,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 9116,
							"y": 1807,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3074;395:3057",
							  "parent": {
								"id": "395:3074"
							  },
							  "name": "vectors",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9116
								],
								[
								  0,
								  1,
								  1807
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 24,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 9116,
								"y": 1807,
								"width": 24,
								"height": 24
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3074;395:3058",
								  "parent": {
									"id": "I395:3074;395:3057"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  5
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9121
									],
									[
									  0,
									  1,
									  1809
									]
								  ],
								  "x": 5,
								  "y": 2,
								  "width": 7,
								  "height": 7,
								  "absoluteRenderBounds": {
									"x": 9120,
									"y": 1808,
									"width": 9,
									"height": 9
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [
									  {
										"windingRule": "NONZERO",
										"loops": [
										  [
											0,
											1,
											2,
											3,
											4,
											5,
											6
										  ]
										]
									  }
									],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": 0,
										  "y": -0.9282577037811279
										},
										"tangentEnd": {
										  "x": -0.6563773155212402,
										  "y": 0.6563773155212402
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": 0.6563773155212402,
										  "y": -0.6563773155212402
										},
										"tangentEnd": {
										  "x": -0.9282577037811279,
										  "y": 4.440892098500626e-16
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": -0.9282577037811279,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0.6563773155212402,
										  "y": 0.6563773155212402
										}
									  },
									  {
										"start": 6,
										"end": 0,
										"tangentStart": {
										  "x": -0.6563773155212402,
										  "y": -0.6563773155212402
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0.9282577037811279
										}
									  }
									],
									"vertices": [
									  {
										"x": 0,
										"y": 3.5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 1.0251262187957764,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 5.9748735427856445,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONZERO",
									  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3074;395:3059",
								  "parent": {
									"id": "I395:3074;395:3057"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  12
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9128
									],
									[
									  0,
									  1,
									  1809
									]
								  ],
								  "x": 12,
								  "y": 2,
								  "width": 7,
								  "height": 7,
								  "absoluteRenderBounds": {
									"x": 9127,
									"y": 1808,
									"width": 9,
									"height": 9
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [
									  {
										"windingRule": "NONZERO",
										"loops": [
										  [
											0,
											1,
											2,
											3,
											4,
											5,
											6,
											7,
											8,
											9,
											10
										  ]
										]
									  }
									],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": 0.45962655544281006,
										  "y": -6.8489693916262695e-9
										},
										"tangentEnd": {
										  "x": -0.4246395528316498,
										  "y": -0.1758914589881897
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": 0.4246395528316498,
										  "y": 0.1758914589881897
										},
										"tangentEnd": {
										  "x": -0.32500505447387695,
										  "y": -0.32500505447387695
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": 0.32500505447387695,
										  "y": 0.32500505447387695
										},
										"tangentEnd": {
										  "x": -0.1758914738893509,
										  "y": -0.4246395528316498
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": 0.1758914738893509,
										  "y": 0.4246395528316498
										},
										"tangentEnd": {
										  "x": 0,
										  "y": -0.45962655544281006
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": 0,
										  "y": 0.45962655544281006
										},
										"tangentEnd": {
										  "x": 0.1758914738893509,
										  "y": -0.4246395528316498
										}
									  },
									  {
										"start": 6,
										"end": 7,
										"tangentStart": {
										  "x": -0.1758914738893509,
										  "y": 0.4246395528316498
										},
										"tangentEnd": {
										  "x": 0.32500505447387695,
										  "y": -0.32500505447387695
										}
									  },
									  {
										"start": 7,
										"end": 8,
										"tangentStart": {
										  "x": -0.32500505447387695,
										  "y": 0.32500505447387695
										},
										"tangentEnd": {
										  "x": 0.4246395528316498,
										  "y": -0.1758914589881897
										}
									  },
									  {
										"start": 8,
										"end": 9,
										"tangentStart": {
										  "x": -0.4246395528316498,
										  "y": 0.1758914589881897
										},
										"tangentEnd": {
										  "x": 0.45962655544281006,
										  "y": 6.8489693916262695e-9
										}
									  },
									  {
										"start": 9,
										"end": 10,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 10,
										"end": 0,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  }
									],
									"vertices": [
									  {
										"x": 0,
										"y": 3.886141898679611e-16,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 3.886141898679611e-16,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 4.839391708374023,
										"y": 0.2664215564727783,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5.974874496459961,
										"y": 1.0251262187957764,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 6.733577728271484,
										"y": 2.1606078147888184,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 3.5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 6.733577728271484,
										"y": 4.839392185211182,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5.974874496459961,
										"y": 5.9748735427856445,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 4.839391708374023,
										"y": 6.733578681945801,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONZERO",
									  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3074;395:3060",
								  "parent": {
									"id": "I395:3074;395:3057"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  12
									],
									[
									  0,
									  1,
									  9
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9128
									],
									[
									  0,
									  1,
									  1816
									]
								  ],
								  "x": 12,
								  "y": 9,
								  "width": 7,
								  "height": 7,
								  "absoluteRenderBounds": {
									"x": 9127,
									"y": 1815,
									"width": 9,
									"height": 9
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [
									  {
										"windingRule": "NONZERO",
										"loops": [
										  [
											0,
											1,
											2,
											3,
											4,
											5,
											6,
											7,
											8,
											9,
											10,
											11,
											12,
											13,
											14,
											15,
											16
										  ]
										]
									  }
									],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": -9.685905411060958e-9,
										  "y": -0.45962655544281006
										},
										"tangentEnd": {
										  "x": -0.1758914589881897,
										  "y": 0.42463958263397217
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": 0.1758914589881897,
										  "y": -0.42463958263397217
										},
										"tangentEnd": {
										  "x": -0.32500505447387695,
										  "y": 0.32500505447387695
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": 0.32500505447387695,
										  "y": -0.32500505447387695
										},
										"tangentEnd": {
										  "x": -0.4246395528316498,
										  "y": 0.1758914738893509
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": 0.4246395528316498,
										  "y": -0.1758914738893509
										},
										"tangentEnd": {
										  "x": -0.45962655544281006,
										  "y": 0
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": 0.45962655544281006,
										  "y": 0
										},
										"tangentEnd": {
										  "x": -0.4246395528316498,
										  "y": -0.1758914738893509
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": 0.4246395528316498,
										  "y": 0.1758914738893509
										},
										"tangentEnd": {
										  "x": -0.32500505447387695,
										  "y": -0.32500505447387695
										}
									  },
									  {
										"start": 6,
										"end": 7,
										"tangentStart": {
										  "x": 0.32500505447387695,
										  "y": 0.32500505447387695
										},
										"tangentEnd": {
										  "x": -0.1758914589881897,
										  "y": -0.42463958263397217
										}
									  },
									  {
										"start": 7,
										"end": 8,
										"tangentStart": {
										  "x": 0.1758914589881897,
										  "y": 0.42463958263397217
										},
										"tangentEnd": {
										  "x": 9.685901858347279e-9,
										  "y": -0.45962655544281006
										}
									  },
									  {
										"start": 8,
										"end": 9,
										"tangentStart": {
										  "x": 9.685905411060958e-9,
										  "y": 0.45962655544281006
										},
										"tangentEnd": {
										  "x": 0.1758914589881897,
										  "y": -0.42463958263397217
										}
									  },
									  {
										"start": 9,
										"end": 10,
										"tangentStart": {
										  "x": -0.1758914589881897,
										  "y": 0.42463958263397217
										},
										"tangentEnd": {
										  "x": 0.32500505447387695,
										  "y": -0.32500505447387695
										}
									  },
									  {
										"start": 10,
										"end": 11,
										"tangentStart": {
										  "x": -0.32500505447387695,
										  "y": 0.32500505447387695
										},
										"tangentEnd": {
										  "x": 0.4246395528316498,
										  "y": -0.1758914738893509
										}
									  },
									  {
										"start": 11,
										"end": 12,
										"tangentStart": {
										  "x": -0.4246395528316498,
										  "y": 0.1758914738893509
										},
										"tangentEnd": {
										  "x": 0.45962655544281006,
										  "y": 0
										}
									  },
									  {
										"start": 12,
										"end": 13,
										"tangentStart": {
										  "x": -0.45962655544281006,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0.4246395528316498,
										  "y": 0.1758914738893509
										}
									  },
									  {
										"start": 13,
										"end": 14,
										"tangentStart": {
										  "x": -0.4246395528316498,
										  "y": -0.1758914738893509
										},
										"tangentEnd": {
										  "x": 0.32500505447387695,
										  "y": 0.32500505447387695
										}
									  },
									  {
										"start": 14,
										"end": 15,
										"tangentStart": {
										  "x": -0.32500505447387695,
										  "y": -0.32500505447387695
										},
										"tangentEnd": {
										  "x": 0.1758914589881897,
										  "y": 0.42463958263397217
										}
									  },
									  {
										"start": 15,
										"end": 0,
										"tangentStart": {
										  "x": -0.1758914589881897,
										  "y": -0.42463958263397217
										},
										"tangentEnd": {
										  "x": -9.685907187417797e-9,
										  "y": 0.45962655544281006
										}
									  },
									  {
										"start": 0,
										"end": 0,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  }
									],
									"vertices": [
									  {
										"x": 7.77230656136968e-16,
										"y": 3.5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0.2664213180541992,
										"y": 2.1606082916259766,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 1.0251264572143555,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 2.1606082916259766,
										"y": 0.2664213180541992,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 4.839391708374023,
										"y": 0.2664213180541992,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5.974874496459961,
										"y": 1.0251264572143555,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 6.733577728271484,
										"y": 2.1606082916259766,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 3.5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 6.733577728271484,
										"y": 4.839391708374023,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5.974874496459961,
										"y": 5.9748735427856445,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 4.839391708374023,
										"y": 6.733578681945801,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 2.1606082916259766,
										"y": 6.733578681945801,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 5.9748735427856445,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0.2664213180541992,
										"y": 4.839391708374023,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONZERO",
									  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3074;395:3061",
								  "parent": {
									"id": "I395:3074;395:3057"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  5
									],
									[
									  0,
									  1,
									  16
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9121
									],
									[
									  0,
									  1,
									  1823
									]
								  ],
								  "x": 5,
								  "y": 16,
								  "width": 7,
								  "height": 7,
								  "absoluteRenderBounds": {
									"x": 9120,
									"y": 1822,
									"width": 9,
									"height": 9
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [
									  {
										"windingRule": "NONZERO",
										"loops": [
										  [
											0,
											1,
											2,
											3,
											4,
											5,
											6,
											7
										  ]
										]
									  }
									],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": 0,
										  "y": -0.9282577037811279
										},
										"tangentEnd": {
										  "x": -0.6563773155212402,
										  "y": 0.6563773155212402
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": 0.6563773155212402,
										  "y": -0.6563773155212402
										},
										"tangentEnd": {
										  "x": -0.9282577037811279,
										  "y": 0
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": 0,
										  "y": 0.9282577037811279
										},
										"tangentEnd": {
										  "x": 0.6563773155212402,
										  "y": -0.6563773155212402
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": -0.6563773155212402,
										  "y": 0.6563773155212402
										},
										"tangentEnd": {
										  "x": 0.9282577037811279,
										  "y": 0
										}
									  },
									  {
										"start": 6,
										"end": 7,
										"tangentStart": {
										  "x": -0.9282577037811279,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0.6563773155212402,
										  "y": 0.6563773155212402
										}
									  },
									  {
										"start": 7,
										"end": 0,
										"tangentStart": {
										  "x": -0.6563773155212402,
										  "y": -0.6563773155212402
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0.9282577037811279
										}
									  }
									],
									"vertices": [
									  {
										"x": 0,
										"y": 3.5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 1.025125503540039,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 3.5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5.9748735427856445,
										"y": 5.974874496459961,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 5.974874496459961,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONZERO",
									  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3074;395:3062",
								  "parent": {
									"id": "I395:3074;395:3057"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  5
									],
									[
									  0,
									  1,
									  9
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9121
									],
									[
									  0,
									  1,
									  1816
									]
								  ],
								  "x": 5,
								  "y": 9,
								  "width": 7,
								  "height": 7,
								  "absoluteRenderBounds": {
									"x": 9120,
									"y": 1815,
									"width": 9,
									"height": 9
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [
									  {
										"windingRule": "NONZERO",
										"loops": [
										  [
											0,
											1,
											2,
											3,
											4,
											5,
											6
										  ]
										]
									  }
									],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": 0,
										  "y": -0.9282577037811279
										},
										"tangentEnd": {
										  "x": -0.6563773155212402,
										  "y": 0.6563773155212402
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": 0.6563773155212402,
										  "y": -0.6563773155212402
										},
										"tangentEnd": {
										  "x": -0.9282577037811279,
										  "y": 1.7763568394002505e-15
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": -0.9282577037811279,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0.6563773155212402,
										  "y": 0.6563773155212402
										}
									  },
									  {
										"start": 6,
										"end": 0,
										"tangentStart": {
										  "x": -0.6563773155212402,
										  "y": -0.6563773155212402
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0.9282577037811279
										}
									  }
									],
									"vertices": [
									  {
										"x": 0,
										"y": 3.5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 1.0251264572143555,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.5,
										"y": 7,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.0251264572143555,
										"y": 5.9748735427856445,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONZERO",
									  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": true,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "NONE",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "395:3056"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Logo": "figma"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "8"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2506.svg",
						  "makersExportAsSvg": "true",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3075",
						  "parent": {
							"id": "395:3073"
						  },
						  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  48
							],
							[
							  0,
							  1,
							  8
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9164
							],
							[
							  0,
							  1,
							  1807
							]
						  ],
						  "x": 48,
						  "y": 8,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 9164,
							"y": 1807,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3075;395:3028",
							  "parent": {
								"id": "395:3075"
							  },
							  "name": "vectors",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9164
								],
								[
								  0,
								  1,
								  1807
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 24,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 9164,
								"y": 1807,
								"width": 24,
								"height": 24
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3075;395:3029",
								  "parent": {
									"id": "I395:3075;395:3028"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  1
									],
									[
									  0,
									  1,
									  2.9899444580078125
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9165
									],
									[
									  0,
									  1,
									  1809.989990234375
									]
								  ],
								  "x": 1,
								  "y": 2.9899444580078125,
								  "width": 22,
								  "height": 17.943681716918945,
								  "absoluteRenderBounds": {
									"x": 9164,
									"y": 1808.989990234375,
									"width": 23.9716796875,
									"height": 19.9437255859375
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [
									  {
										"windingRule": "NONZERO",
										"loops": [
										  [
											0,
											1,
											2,
											3,
											4,
											5,
											6,
											7,
											8,
											9,
											10,
											11,
											12,
											13
										  ]
										]
									  }
									],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": -0.9576150178909302,
										  "y": 0.6754773855209351
										},
										"tangentEnd": {
										  "x": 1.1221078634262085,
										  "y": -0.33789074420928955
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": -0.6022599339485168,
										  "y": -0.6924893856048584
										},
										"tangentEnd": {
										  "x": 0.890299916267395,
										  "y": 0.22276580333709717
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": -0.890299916267395,
										  "y": -0.22276580333709717
										},
										"tangentEnd": {
										  "x": 0.8574152588844299,
										  "y": -0.32725775241851807
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": -0.8574152588844299,
										  "y": 0.32725775241851807
										},
										"tangentEnd": {
										  "x": 0.5154598355293274,
										  "y": -0.7593153119087219
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": -0.5154598355293274,
										  "y": 0.7593153119087219
										},
										"tangentEnd": {
										  "x": -0.012302441522479057,
										  "y": -0.9176639914512634
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 6,
										"end": 7,
										"tangentStart": {
										  "x": -1.757366418838501,
										  "y": 0.045568279922008514
										},
										"tangentEnd": {
										  "x": 1.570265293121338,
										  "y": 0.7903668880462646
										}
									  },
									  {
										"start": 7,
										"end": 8,
										"tangentStart": {
										  "x": -1.570265293121338,
										  "y": -0.7903668880462646
										},
										"tangentEnd": {
										  "x": 1.010317087173462,
										  "y": 1.4386355876922607
										}
									  },
									  {
										"start": 8,
										"end": 9,
										"tangentStart": {
										  "x": 4.440892098500626e-16,
										  "y": -8.881784197001252e-16
										},
										"tangentEnd": {
										  "x": -9,
										  "y": -4
										}
									  },
									  {
										"start": 9,
										"end": 10,
										"tangentStart": {
										  "x": -2.0594723224639893,
										  "y": 1.3979662656784058
										},
										"tangentEnd": {
										  "x": 2.487156867980957,
										  "y": 0.09892935305833817
										}
									  },
									  {
										"start": 10,
										"end": 11,
										"tangentStart": {
										  "x": 9,
										  "y": 5
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 11.5
										}
									  },
									  {
										"start": 11,
										"end": 12,
										"tangentStart": {
										  "x": -0.0009208884439431131,
										  "y": -0.27854636311531067
										},
										"tangentEnd": {
										  "x": 0.052297499030828476,
										  "y": 0.2735944092273712
										}
									  },
									  {
										"start": 12,
										"end": 0,
										"tangentStart": {
										  "x": 1.0206031799316406,
										  "y": -1.0065069198608398
										},
										"tangentEnd": {
										  "x": -0.3391726016998291,
										  "y": 1.3927128314971924
										}
									  },
									  {
										"start": 0,
										"end": 0,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  }
									],
									"vertices": [
									  {
										"x": 22,
										"y": 0.010055706836283207,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 18.860000610351562,
										"y": 1.5400558710098267,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 16.567033767700195,
										"y": 0.1339818686246872,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 13.882081031799316,
										"y": 0.29450908303260803,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 11.772982597351074,
										"y": 1.9637724161148071,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 11,
										"y": 4.540055751800537,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 11,
										"y": 5.540055274963379,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5.931008815765381,
										"y": 4.405501842498779,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 2,
										"y": 1.010055661201477,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7,
										"y": 14.010055541992188,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0,
										"y": 16.010055541992188,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 20,
										"y": 4.5100555419921875,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 19.920000076293945,
										"y": 3.680055856704712,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONZERO",
									  "data": "M 22 0.010055706836283207 C 21.04238498210907 0.6855330923572183 19.98210847377777 1.202165126800537 18.860000610351562 1.5400558710098267 C 18.257740676403046 0.8475664854049683 17.45733368396759 0.35674767196178436 16.567033767700195 0.1339818686246872 C 15.6767338514328 -0.08878393471240997 14.739496290683746 -0.032748669385910034 13.882081031799316 0.29450908303260803 C 13.024665772914886 0.6217668354511261 12.288442432880402 1.2044571042060852 11.772982597351074 1.9637724161148071 C 11.257522761821747 2.723087728023529 10.987697558477521 3.6223917603492737 11 4.540055751800537 L 11 5.540055274963379 C 9.242633581161499 5.585623554885387 7.501274108886719 5.195868730545044 5.931008815765381 4.405501842498779 C 4.360743522644043 3.6151349544525146 3.010317087173462 2.448691248893738 2 1.010055661201477 C 2.0000000000000004 1.0100556612014762 -2 10.010055541992188 7 14.010055541992188 C 4.940527677536011 15.408021807670593 2.487156867980957 16.108984895050526 0 16.010055541992188 C 9 21.010055541992188 20 16.010055541992188 20 4.5100555419921875 C 19.999079111556057 4.231509178876877 19.972297575324774 3.953650265932083 19.920000076293945 3.680055856704712 C 20.940603256225586 2.673548936843872 21.66082739830017 1.4027685383334756 22 0.010055706836283207 L 22 0.010055706836283207 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": true,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "NONE",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "395:3027"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Logo": "twitter"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "1"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2507.svg",
						  "makersExportAsSvg": "true",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3076",
						  "parent": {
							"id": "395:3073"
						  },
						  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  96
							],
							[
							  0,
							  1,
							  8
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9212
							],
							[
							  0,
							  1,
							  1807
							]
						  ],
						  "x": 96,
						  "y": 8,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 9212,
							"y": 1807,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3076;395:3040",
							  "parent": {
								"id": "395:3076"
							  },
							  "name": "vectors",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9212
								],
								[
								  0,
								  1,
								  1807
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 24,
							  "height": 24,
							  "absoluteRenderBounds": {
								"x": 9212,
								"y": 1807,
								"width": 24,
								"height": 24
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3076;395:3041",
								  "parent": {
									"id": "I395:3076;395:3040"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  2
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9214
									],
									[
									  0,
									  1,
									  1809
									]
								  ],
								  "x": 2,
								  "y": 2,
								  "width": 20,
								  "height": 20,
								  "absoluteRenderBounds": {
									"x": 9213,
									"y": 1808,
									"width": 22,
									"height": 22
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": 2.7614238262176514,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": -2.7614238262176514
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": 0,
										  "y": 2.7614238262176514
										},
										"tangentEnd": {
										  "x": 2.7614238262176514,
										  "y": 0
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": -2.7614238262176514,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 2.7614238262176514
										}
									  },
									  {
										"start": 6,
										"end": 7,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  },
									  {
										"start": 7,
										"end": 0,
										"tangentStart": {
										  "x": 0,
										  "y": -2.7614238262176514
										},
										"tangentEnd": {
										  "x": -2.7614238262176514,
										  "y": 0
										}
									  }
									],
									"vertices": [
									  {
										"x": 5,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 15,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 20,
										"y": 5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 20,
										"y": 15,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 15,
										"y": 20,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5,
										"y": 20,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0,
										"y": 15,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0,
										"y": 5,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONE",
									  "data": "M 5 0 L 15 0 C 17.76142382621765 0 20 2.2385761737823486 20 5 L 20 15 C 20 17.76142382621765 17.76142382621765 20 15 20 L 5 20 C 2.2385761737823486 20 0 17.76142382621765 0 15 L 0 5 C 0 2.2385761737823486 2.2385761737823486 0 5 0 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3076;395:3042",
								  "parent": {
									"id": "I395:3076;395:3040"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  8.04296875
									],
									[
									  0,
									  1,
									  7.956729888916016
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9220.04296875
									],
									[
									  0,
									  1,
									  1814.956787109375
									]
								  ],
								  "x": 8.04296875,
								  "y": 7.956729888916016,
								  "width": 8.000008583068848,
								  "height": 8.000008583068848,
								  "absoluteRenderBounds": {
									"x": 9219.04296875,
									"y": 1813.956787109375,
									"width": 10,
									"height": 10
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [
									  {
										"windingRule": "NONZERO",
										"loops": [
										  [
											0,
											1,
											2,
											3,
											4,
											5,
											6,
											7,
											8,
											9
										  ]
										]
									  }
									],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": 0.1234116330742836,
										  "y": 0.8322460651397705
										},
										"tangentEnd": {
										  "x": 0.38749924302101135,
										  "y": -0.7467986941337585
										}
									  },
									  {
										"start": 1,
										"end": 2,
										"tangentStart": {
										  "x": -0.38749924302101135,
										  "y": 0.7467986941337585
										},
										"tangentEnd": {
										  "x": 0.7515201568603516,
										  "y": -0.37826108932495117
										}
									  },
									  {
										"start": 2,
										"end": 3,
										"tangentStart": {
										  "x": -0.7515201568603516,
										  "y": 0.37826108932495117
										},
										"tangentEnd": {
										  "x": 0.8306610584259033,
										  "y": 0.13366460800170898
										}
									  },
									  {
										"start": 3,
										"end": 4,
										"tangentStart": {
										  "x": -0.8306610584259033,
										  "y": -0.13366460800170898
										},
										"tangentEnd": {
										  "x": 0.5949218273162842,
										  "y": 0.5949218273162842
										}
									  },
									  {
										"start": 4,
										"end": 5,
										"tangentStart": {
										  "x": -0.5949218273162842,
										  "y": -0.5949218273162842
										},
										"tangentEnd": {
										  "x": 0.13366460800170898,
										  "y": 0.8306610584259033
										}
									  },
									  {
										"start": 5,
										"end": 6,
										"tangentStart": {
										  "x": -0.13366460800170898,
										  "y": -0.8306610584259033
										},
										"tangentEnd": {
										  "x": -0.37826108932495117,
										  "y": 0.7515201568603516
										}
									  },
									  {
										"start": 6,
										"end": 7,
										"tangentStart": {
										  "x": 0.37826108932495117,
										  "y": -0.7515201568603516
										},
										"tangentEnd": {
										  "x": -0.7467986941337585,
										  "y": 0.38749924302101135
										}
									  },
									  {
										"start": 7,
										"end": 8,
										"tangentStart": {
										  "x": 0.7467986941337585,
										  "y": -0.38749924302101135
										},
										"tangentEnd": {
										  "x": -0.8322460651397705,
										  "y": -0.1234116330742836
										}
									  },
									  {
										"start": 8,
										"end": 9,
										"tangentStart": {
										  "x": 0.8489227890968323,
										  "y": 0.12588457763195038
										},
										"tangentEnd": {
										  "x": -0.6068429946899414,
										  "y": -0.6068429946899414
										}
									  },
									  {
										"start": 9,
										"end": 0,
										"tangentStart": {
										  "x": 0.6068429946899414,
										  "y": 0.6068429946899414
										},
										"tangentEnd": {
										  "x": -0.12588457763195038,
										  "y": -0.8489227890968323
										}
									  }
									],
									"vertices": [
									  {
										"x": 7.956738471984863,
										"y": 3.4132702350616455,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 7.550497055053711,
										"y": 5.84228515625,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 5.798364639282227,
										"y": 7.572943687438965,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 3.3645246028900146,
										"y": 7.949202537536621,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 1.1715772151947021,
										"y": 6.828431129455566,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0.05080585181713104,
										"y": 4.635483741760254,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0.4270646870136261,
										"y": 2.2016441822052,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 2.1577231884002686,
										"y": 0.44951170682907104,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 4.586738586425781,
										"y": 0.0432702973484993,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 6.828432083129883,
										"y": 1.171576738357544,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONZERO",
									  "data": "M 7.956738471984863 3.4132702350616455 C 8.080150105059147 4.245516300201416 7.937996298074722 5.0954864621162415 7.550497055053711 5.84228515625 C 7.1629978120327 6.5890838503837585 6.549884796142578 7.194682598114014 5.798364639282227 7.572943687438965 C 5.046844482421875 7.951204776763916 4.195185661315918 8.08286714553833 3.3645246028900146 7.949202537536621 C 2.5338635444641113 7.815537929534912 1.7664990425109863 7.423352956771851 1.1715772151947021 6.828431129455566 C 0.576655387878418 6.233509302139282 0.18447045981884003 5.466144800186157 0.05080585181713104 4.635483741760254 C -0.08285875618457794 3.8048226833343506 0.04880359768867493 2.9531643390655518 0.4270646870136261 2.2016441822052 C 0.8053257763385773 1.4501240253448486 1.41092449426651 0.8370109498500824 2.1577231884002686 0.44951170682907104 C 2.904521882534027 0.06201246380805969 3.7544925212860107 -0.0801413357257843 4.586738586425781 0.0432702973484993 C 5.4356613755226135 0.16915487498044968 6.221589088439941 0.5647337436676025 6.828432083129883 1.171576738357544 C 7.435275077819824 1.7784197330474854 7.830853894352913 2.5643474459648132 7.956738471984863 3.4132702350616455 Z"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I395:3076;395:3043",
								  "parent": {
									"id": "I395:3076;395:3040"
								  },
								  "name": "Vector",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.40392157435417175,
										"g": 0.5137255191802979,
										"b": 0.5960784554481506
									  }
									}
								  ],
								  "strokeStyleId": "",
								  "strokeWeight": 2,
								  "strokeAlign": "CENTER",
								  "strokeJoin": "ROUND",
								  "dashPattern": [],
								  "strokeCap": "ROUND",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  17.5
									],
									[
									  0,
									  1,
									  6.5
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9229.5
									],
									[
									  0,
									  1,
									  1813.5
									]
								  ],
								  "x": 17.5,
								  "y": 6.5,
								  "width": 0.010000228881835938,
								  "height": 0,
								  "absoluteRenderBounds": {
									"x": 9228.5,
									"y": 1812.5,
									"width": 2.009765625,
									"height": 2
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "SCALE",
									"vertical": "SCALE"
								  },
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "vectorNetwork": {
									"regions": [],
									"segments": [
									  {
										"start": 0,
										"end": 1,
										"tangentStart": {
										  "x": 0,
										  "y": 0
										},
										"tangentEnd": {
										  "x": 0,
										  "y": 0
										}
									  }
									],
									"vertices": [
									  {
										"x": 0,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  },
									  {
										"x": 0.010000228881835938,
										"y": 0,
										"strokeCap": "ROUND",
										"strokeJoin": "ROUND",
										"cornerRadius": 0,
										"handleMirroring": "NONE"
									  }
									]
								  },
								  "vectorPaths": [
									{
									  "windingRule": "NONE",
									  "data": "M 0 0 L 0.010000228881835938 0"
									}
								  ],
								  "handleMirroring": "NONE",
								  "reactions": [],
								  "type": "VECTOR",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": true,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "NONE",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "395:3039"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Logo": "instagram"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "5"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 8,
					  "paddingBottom": 8,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3077",
				  "parent": {
					"id": "395:3069"
				  },
				  "name": "Column",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  293.3333435058594
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9409.3330078125
					],
					[
					  0,
					  1,
					  1712
					]
				  ],
				  "x": 293.3333435058594,
				  "y": 32,
				  "width": 200,
				  "height": 130,
				  "absoluteRenderBounds": {
					"x": 9409.3330078125,
					"y": 1712,
					"width": 200,
					"height": 130
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3078",
					  "listSpacing": 0,
					  "parent": {
						"id": "395:3077"
					  },
					  "name": "COMPANY",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.40392157435417175,
							"g": 0.5137255191802979,
							"b": 0.5960784554481506
						  }
						}
					  ],
					  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9409.3330078125
						],
						[
						  0,
						  1,
						  1712
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 200,
					  "height": 31,
					  "absoluteRenderBounds": {
						"x": 9410.0791015625,
						"y": 1715.678955078125,
						"width": 71.1259765625,
						"height": 10.460205078125
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "COMPANY",
					  "hasMissingFont": false,
					  "fontSize": 14,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "LEFT",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "NONE",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3079",
					  "parent": {
						"id": "395:3077"
					  },
					  "name": "Navigation Links",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  41
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9409.3330078125
						],
						[
						  0,
						  1,
						  1753
						]
					  ],
					  "x": 0,
					  "y": 41,
					  "width": 200,
					  "height": 89,
					  "absoluteRenderBounds": {
						"x": 9409.3330078125,
						"y": 1753,
						"width": 200,
						"height": 89
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3080",
						  "parent": {
							"id": "395:3079"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9409.3330078125
							],
							[
							  0,
							  1,
							  1753
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 46,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9409.3330078125,
							"y": 1753,
							"width": 46,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3081",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3080"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9409.3330078125
								],
								[
								  0,
								  1,
								  1753
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 46,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9409.7421875,
								"y": 1756.3636474609375,
								"width": 44.3662109375,
								"height": 11.818115234375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "About",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3082",
						  "parent": {
							"id": "395:3079"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  35
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9409.3330078125
							],
							[
							  0,
							  1,
							  1788
							]
						  ],
						  "x": 0,
						  "y": 35,
						  "width": 53,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9409.3330078125,
							"y": 1788,
							"width": 53,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3083",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3082"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9409.3330078125
								],
								[
								  0,
								  1,
								  1788
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 53,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9410.10546875,
								"y": 1791,
								"width": 51.724609375,
								"height": 12.1817626953125
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Twitter",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3084",
						  "parent": {
							"id": "395:3079"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  70
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9409.3330078125
							],
							[
							  0,
							  1,
							  1823
							]
						  ],
						  "x": 0,
						  "y": 70,
						  "width": 34,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9409.3330078125,
							"y": 1823,
							"width": 34,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3085",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3084"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9409.3330078125
								],
								[
								  0,
								  1,
								  1823
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 34,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9410.7421875,
								"y": 1826.3636474609375,
								"width": 30.86328125,
								"height": 15.0909423828125
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Blog",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3086",
				  "parent": {
					"id": "395:3069"
				  },
				  "name": "Column",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  546.6666870117188
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9662.6669921875
					],
					[
					  0,
					  1,
					  1712
					]
				  ],
				  "x": 546.6666870117188,
				  "y": 32,
				  "width": 200,
				  "height": 130,
				  "absoluteRenderBounds": {
					"x": 9662.6669921875,
					"y": 1712,
					"width": 200,
					"height": 130
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3087",
					  "listSpacing": 0,
					  "parent": {
						"id": "395:3086"
					  },
					  "name": "PRODUCT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.40392157435417175,
							"g": 0.5137255191802979,
							"b": 0.5960784554481506
						  }
						}
					  ],
					  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9662.6669921875
						],
						[
						  0,
						  1,
						  1712
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 200,
					  "height": 31,
					  "absoluteRenderBounds": {
						"x": 9663.6708984375,
						"y": 1715.678955078125,
						"width": 67.4501953125,
						"height": 10.4752197265625
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "PRODUCT",
					  "hasMissingFont": false,
					  "fontSize": 14,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "LEFT",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "NONE",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3088",
					  "parent": {
						"id": "395:3086"
					  },
					  "name": "Navigation Links",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  41
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9662.6669921875
						],
						[
						  0,
						  1,
						  1753
						]
					  ],
					  "x": 0,
					  "y": 41,
					  "width": 200,
					  "height": 89,
					  "absoluteRenderBounds": {
						"x": 9662.6669921875,
						"y": 1753,
						"width": 200,
						"height": 89
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3089",
						  "parent": {
							"id": "395:3088"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9662.6669921875
							],
							[
							  0,
							  1,
							  1753
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 52,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9662.6669921875,
							"y": 1753,
							"width": 52,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3090",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3089"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9662.6669921875
								],
								[
								  0,
								  1,
								  1753
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 52,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9664.076171875,
								"y": 1756,
								"width": 49.11328125,
								"height": 15.45458984375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Pricing",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3091",
						  "parent": {
							"id": "395:3088"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  35
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9662.6669921875
							],
							[
							  0,
							  1,
							  1788
							]
						  ],
						  "x": 0,
						  "y": 35,
						  "width": 66,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9662.6669921875,
							"y": 1788,
							"width": 66,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3092",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3091"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9662.6669921875
								],
								[
								  0,
								  1,
								  1788
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 66,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9664.076171875,
								"y": 1791.3636474609375,
								"width": 63.3466796875,
								"height": 11.8409423828125
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Features",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3093",
						  "parent": {
							"id": "395:3088"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  70
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9662.6669921875
							],
							[
							  0,
							  1,
							  1823
							]
						  ],
						  "x": 0,
						  "y": 70,
						  "width": 79,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9662.6669921875,
							"y": 1823,
							"width": 79,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3094",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3093"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9662.6669921875
								],
								[
								  0,
								  1,
								  1823
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 79,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9663.439453125,
								"y": 1826.3636474609375,
								"width": 76.6083984375,
								"height": 14.9090576171875
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Templates",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3095",
				  "parent": {
					"id": "395:3069"
				  },
				  "name": "Column",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  800
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9916
					],
					[
					  0,
					  1,
					  1712
					]
				  ],
				  "x": 800,
				  "y": 32,
				  "width": 200,
				  "height": 165,
				  "absoluteRenderBounds": {
					"x": 9916,
					"y": 1712,
					"width": 200,
					"height": 165
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3096",
					  "listSpacing": 0,
					  "parent": {
						"id": "395:3095"
					  },
					  "name": "RESOURCES",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.40392157435417175,
							"g": 0.5137255191802979,
							"b": 0.5960784554481506
						  }
						}
					  ],
					  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9916
						],
						[
						  0,
						  1,
						  1712
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 200,
					  "height": 31,
					  "absoluteRenderBounds": {
						"x": 9917.00390625,
						"y": 1715.678955078125,
						"width": 83.2724609375,
						"height": 10.4752197265625
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "RESOURCES",
					  "hasMissingFont": false,
					  "fontSize": 14,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "LEFT",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "NONE",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3097",
					  "parent": {
						"id": "395:3095"
					  },
					  "name": "Navigation Links",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  41
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9916
						],
						[
						  0,
						  1,
						  1753
						]
					  ],
					  "x": 0,
					  "y": 41,
					  "width": 200,
					  "height": 124,
					  "absoluteRenderBounds": {
						"x": 9916,
						"y": 1753,
						"width": 200,
						"height": 124
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3098",
						  "parent": {
							"id": "395:3097"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9916
							],
							[
							  0,
							  1,
							  1753
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 95,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9916,
							"y": 1753,
							"width": 95,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3099",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3098"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9916
								],
								[
								  0,
								  1,
								  1753
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 95,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9916.7724609375,
								"y": 1756,
								"width": 92.4990234375,
								"height": 12.20458984375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Testimonials",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3100",
						  "parent": {
							"id": "395:3097"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  35
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9916
							],
							[
							  0,
							  1,
							  1788
							]
						  ],
						  "x": 0,
						  "y": 35,
						  "width": 61,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9916,
							"y": 1788,
							"width": 61,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3101",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3100"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9916
								],
								[
								  0,
								  1,
								  1788
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 61,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9916.9091796875,
								"y": 1791.20458984375,
								"width": 59.3193359375,
								"height": 15.068115234375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Support",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3102",
						  "parent": {
							"id": "395:3097"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  70
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9916
							],
							[
							  0,
							  1,
							  1823
							]
						  ],
						  "x": 0,
						  "y": 70,
						  "width": 47,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9916,
							"y": 1823,
							"width": 47,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3103",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3102"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9916
								],
								[
								  0,
								  1,
								  1823
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 47,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9916.7724609375,
								"y": 1826.3636474609375,
								"width": 44.9677734375,
								"height": 11.818115234375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Terms",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3104",
						  "parent": {
							"id": "395:3097"
						  },
						  "name": "Navigation Item",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  105
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9916
							],
							[
							  0,
							  1,
							  1858
							]
						  ],
						  "x": 0,
						  "y": 105,
						  "width": 56,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9916,
							"y": 1858,
							"width": 56,
							"height": 19
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "a",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "395:3105",
							  "listSpacing": 0,
							  "parent": {
								"id": "395:3104"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9916
								],
								[
								  0,
								  1,
								  1858
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 56,
							  "height": 19,
							  "absoluteRenderBounds": {
								"x": 9917.4091796875,
								"y": 1861,
								"width": 53.4658203125,
								"height": 15.272705078125
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "characters": "Privacy",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": false,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 32,
			  "paddingBottom": 32,
			  "primaryAxisAlignItems": "SPACE_BETWEEN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "019de9b19e1103dc760da285323eda8119cbdb38",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Four Columns"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "footer",
		  "makersClasses": "makers-footer, makers-footer--simple-centered",
		  "makersComponent": "footer",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3106",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Simple Centered",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  259
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8896
			],
			[
			  0,
			  1,
			  1919
			]
		  ],
		  "x": 20,
		  "y": 259,
		  "width": 1440,
		  "height": 204,
		  "absoluteRenderBounds": {
			"x": 8896,
			"y": 1919,
			"width": 1440,
			"height": 204
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3107",
			  "parent": {
				"id": "395:3106"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  220
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9116
				],
				[
				  0,
				  1,
				  1919
				]
			  ],
			  "x": 220,
			  "y": 0,
			  "width": 1000,
			  "height": 204,
			  "absoluteRenderBounds": {
				"x": 9116,
				"y": 1919,
				"width": 1000,
				"height": 204
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "columns",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3108",
				  "parent": {
					"id": "395:3107"
				  },
				  "name": "Navigation Links",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9116
					],
					[
					  0,
					  1,
					  1951
					]
				  ],
				  "x": 0,
				  "y": 32,
				  "width": 1000,
				  "height": 19,
				  "absoluteRenderBounds": {
					"x": 9116,
					"y": 1951,
					"width": 1000,
					"height": 19
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3109",
					  "parent": {
						"id": "395:3108"
					  },
					  "name": "Navigation Item",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  359.5
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9475.5
						],
						[
						  0,
						  1,
						  1951
						]
					  ],
					  "x": 359.5,
					  "y": 0,
					  "width": 46,
					  "height": 19,
					  "absoluteRenderBounds": {
						"x": 9475.5,
						"y": 1951,
						"width": 46,
						"height": 19
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "a",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3110",
						  "listSpacing": 0,
						  "parent": {
							"id": "395:3109"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.40392157435417175,
								"g": 0.5137255191802979,
								"b": 0.5960784554481506
							  }
							}
						  ],
						  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9475.5
							],
							[
							  0,
							  1,
							  1951
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 46,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9475.9091796875,
							"y": 1954.3636474609375,
							"width": 44.3662109375,
							"height": 11.818115234375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "characters": "About",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3111",
					  "parent": {
						"id": "395:3108"
					  },
					  "name": "Navigation Item",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  421.5
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9537.5
						],
						[
						  0,
						  1,
						  1951
						]
					  ],
					  "x": 421.5,
					  "y": 0,
					  "width": 34,
					  "height": 19,
					  "absoluteRenderBounds": {
						"x": 9537.5,
						"y": 1951,
						"width": 34,
						"height": 19
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "a",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3112",
						  "listSpacing": 0,
						  "parent": {
							"id": "395:3111"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.40392157435417175,
								"g": 0.5137255191802979,
								"b": 0.5960784554481506
							  }
							}
						  ],
						  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9537.5
							],
							[
							  0,
							  1,
							  1951
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 34,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9538.9091796875,
							"y": 1954.3636474609375,
							"width": 30.86328125,
							"height": 15.0909423828125
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "characters": "Blog",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3113",
					  "parent": {
						"id": "395:3108"
					  },
					  "name": "Navigation Item",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  471.5
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9587.5
						],
						[
						  0,
						  1,
						  1951
						]
					  ],
					  "x": 471.5,
					  "y": 0,
					  "width": 37,
					  "height": 19,
					  "absoluteRenderBounds": {
						"x": 9587.5,
						"y": 1951,
						"width": 37,
						"height": 19
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "a",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3114",
						  "listSpacing": 0,
						  "parent": {
							"id": "395:3113"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.40392157435417175,
								"g": 0.5137255191802979,
								"b": 0.5960784554481506
							  }
							}
						  ],
						  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9587.5
							],
							[
							  0,
							  1,
							  1951
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 37,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9588.1591796875,
							"y": 1954.3636474609375,
							"width": 35.0810546875,
							"height": 11.818115234375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "characters": "Jobs",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3115",
					  "parent": {
						"id": "395:3108"
					  },
					  "name": "Navigation Item",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  524.5
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9640.5
						],
						[
						  0,
						  1,
						  1951
						]
					  ],
					  "x": 524.5,
					  "y": 0,
					  "width": 116,
					  "height": 19,
					  "absoluteRenderBounds": {
						"x": 9640.5,
						"y": 1951,
						"width": 116,
						"height": 19
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "a",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "395:3116",
						  "listSpacing": 0,
						  "parent": {
							"id": "395:3115"
						  },
						  "name": "Text",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.40392157435417175,
								"g": 0.5137255191802979,
								"b": 0.5960784554481506
							  }
							}
						  ],
						  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9640.5
							],
							[
							  0,
							  1,
							  1951
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 116,
						  "height": 19,
						  "absoluteRenderBounds": {
							"x": 9641.9091796875,
							"y": 1954,
							"width": 112.5712890625,
							"height": 12.20458984375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "characters": "Documentation",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3117",
				  "parent": {
					"id": "395:3107"
				  },
				  "name": "Social Media",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  75
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9116
					],
					[
					  0,
					  1,
					  1994
					]
				  ],
				  "x": 0,
				  "y": 75,
				  "width": 1000,
				  "height": 56,
				  "absoluteRenderBounds": {
					"x": 9116,
					"y": 1994,
					"width": 1000,
					"height": 56
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2549.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3118",
					  "parent": {
						"id": "395:3117"
					  },
					  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  440
						],
						[
						  0,
						  1,
						  16
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9556
						],
						[
						  0,
						  1,
						  2010
						]
					  ],
					  "x": 440,
					  "y": 16,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 9556,
						"y": 2010,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I395:3118;395:3057",
						  "parent": {
							"id": "395:3118"
						  },
						  "name": "vectors",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9556
							],
							[
							  0,
							  1,
							  2010
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 9556,
							"y": 2010,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3118;395:3058",
							  "parent": {
								"id": "I395:3118;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  5
								],
								[
								  0,
								  1,
								  2
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9561
								],
								[
								  0,
								  1,
								  2012
								]
							  ],
							  "x": 5,
							  "y": 2,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 9560,
								"y": 2011,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": -0.9282577037811279
									},
									"tangentEnd": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": -0.9282577037811279,
									  "y": 4.440892098500626e-16
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.9282577037811279,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 6,
									"end": 0,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0.9282577037811279
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.0251262187957764,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3118;395:3059",
							  "parent": {
								"id": "I395:3118;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  12
								],
								[
								  0,
								  1,
								  2
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9568
								],
								[
								  0,
								  1,
								  2012
								]
							  ],
							  "x": 12,
							  "y": 2,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 9567,
								"y": 2011,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9,
										10
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.45962655544281006,
									  "y": -6.8489693916262695e-9
									},
									"tangentEnd": {
									  "x": -0.4246395528316498,
									  "y": -0.1758914589881897
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0.4246395528316498,
									  "y": 0.1758914589881897
									},
									"tangentEnd": {
									  "x": -0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": -0.1758914738893509,
									  "y": -0.4246395528316498
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0.1758914738893509,
									  "y": 0.4246395528316498
									},
									"tangentEnd": {
									  "x": 0,
									  "y": -0.45962655544281006
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": 0,
									  "y": 0.45962655544281006
									},
									"tangentEnd": {
									  "x": 0.1758914738893509,
									  "y": -0.4246395528316498
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": -0.1758914738893509,
									  "y": 0.4246395528316498
									},
									"tangentEnd": {
									  "x": 0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": -0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": 0.4246395528316498,
									  "y": -0.1758914589881897
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": -0.4246395528316498,
									  "y": 0.1758914589881897
									},
									"tangentEnd": {
									  "x": 0.45962655544281006,
									  "y": 6.8489693916262695e-9
									}
								  },
								  {
									"start": 9,
									"end": 10,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 10,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.886141898679611e-16,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 3.886141898679611e-16,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 0.2664215564727783,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 1.0251262187957764,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 2.1606078147888184,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 4.839392185211182,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 6.733578681945801,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3118;395:3060",
							  "parent": {
								"id": "I395:3118;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  12
								],
								[
								  0,
								  1,
								  9
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9568
								],
								[
								  0,
								  1,
								  2019
								]
							  ],
							  "x": 12,
							  "y": 9,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 9567,
								"y": 2018,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9,
										10,
										11,
										12,
										13,
										14,
										15,
										16
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": -9.685905411060958e-9,
									  "y": -0.45962655544281006
									},
									"tangentEnd": {
									  "x": -0.1758914589881897,
									  "y": 0.42463958263397217
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.1758914589881897,
									  "y": -0.42463958263397217
									},
									"tangentEnd": {
									  "x": -0.32500505447387695,
									  "y": 0.32500505447387695
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0.32500505447387695,
									  "y": -0.32500505447387695
									},
									"tangentEnd": {
									  "x": -0.4246395528316498,
									  "y": 0.1758914738893509
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0.4246395528316498,
									  "y": -0.1758914738893509
									},
									"tangentEnd": {
									  "x": -0.45962655544281006,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0.45962655544281006,
									  "y": 0
									},
									"tangentEnd": {
									  "x": -0.4246395528316498,
									  "y": -0.1758914738893509
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": 0.4246395528316498,
									  "y": 0.1758914738893509
									},
									"tangentEnd": {
									  "x": -0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": 0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": -0.1758914589881897,
									  "y": -0.42463958263397217
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": 0.1758914589881897,
									  "y": 0.42463958263397217
									},
									"tangentEnd": {
									  "x": 9.685901858347279e-9,
									  "y": -0.45962655544281006
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": 9.685905411060958e-9,
									  "y": 0.45962655544281006
									},
									"tangentEnd": {
									  "x": 0.1758914589881897,
									  "y": -0.42463958263397217
									}
								  },
								  {
									"start": 9,
									"end": 10,
									"tangentStart": {
									  "x": -0.1758914589881897,
									  "y": 0.42463958263397217
									},
									"tangentEnd": {
									  "x": 0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 10,
									"end": 11,
									"tangentStart": {
									  "x": -0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": 0.4246395528316498,
									  "y": -0.1758914738893509
									}
								  },
								  {
									"start": 11,
									"end": 12,
									"tangentStart": {
									  "x": -0.4246395528316498,
									  "y": 0.1758914738893509
									},
									"tangentEnd": {
									  "x": 0.45962655544281006,
									  "y": 0
									}
								  },
								  {
									"start": 12,
									"end": 13,
									"tangentStart": {
									  "x": -0.45962655544281006,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.4246395528316498,
									  "y": 0.1758914738893509
									}
								  },
								  {
									"start": 13,
									"end": 14,
									"tangentStart": {
									  "x": -0.4246395528316498,
									  "y": -0.1758914738893509
									},
									"tangentEnd": {
									  "x": 0.32500505447387695,
									  "y": 0.32500505447387695
									}
								  },
								  {
									"start": 14,
									"end": 15,
									"tangentStart": {
									  "x": -0.32500505447387695,
									  "y": -0.32500505447387695
									},
									"tangentEnd": {
									  "x": 0.1758914589881897,
									  "y": 0.42463958263397217
									}
								  },
								  {
									"start": 15,
									"end": 0,
									"tangentStart": {
									  "x": -0.1758914589881897,
									  "y": -0.42463958263397217
									},
									"tangentEnd": {
									  "x": -9.685907187417797e-9,
									  "y": 0.45962655544281006
									}
								  },
								  {
									"start": 0,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 7.77230656136968e-16,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.2664213180541992,
									"y": 2.1606082916259766,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.0251264572143555,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2.1606082916259766,
									"y": 0.2664213180541992,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 0.2664213180541992,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 1.0251264572143555,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 2.1606082916259766,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 4.839391708374023,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 6.733578681945801,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2.1606082916259766,
									"y": 6.733578681945801,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.2664213180541992,
									"y": 4.839391708374023,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3118;395:3061",
							  "parent": {
								"id": "I395:3118;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  5
								],
								[
								  0,
								  1,
								  16
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9561
								],
								[
								  0,
								  1,
								  2026
								]
							  ],
							  "x": 5,
							  "y": 16,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 9560,
								"y": 2025,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": -0.9282577037811279
									},
									"tangentEnd": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": -0.9282577037811279,
									  "y": 0
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0.9282577037811279
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0.9282577037811279,
									  "y": 0
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": -0.9282577037811279,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 7,
									"end": 0,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0.9282577037811279
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.025125503540039,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.9748735427856445,
									"y": 5.974874496459961,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.974874496459961,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3118;395:3062",
							  "parent": {
								"id": "I395:3118;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  5
								],
								[
								  0,
								  1,
								  9
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9561
								],
								[
								  0,
								  1,
								  2019
								]
							  ],
							  "x": 5,
							  "y": 9,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 9560,
								"y": 2018,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": -0.9282577037811279
									},
									"tangentEnd": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": -0.9282577037811279,
									  "y": 1.7763568394002505e-15
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.9282577037811279,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 6,
									"end": 0,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0.9282577037811279
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.0251264572143555,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "395:3056"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Logo": "figma"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "8"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2550.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3119",
					  "parent": {
						"id": "395:3117"
					  },
					  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  488
						],
						[
						  0,
						  1,
						  16
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9604
						],
						[
						  0,
						  1,
						  2010
						]
					  ],
					  "x": 488,
					  "y": 16,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 9604,
						"y": 2010,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I395:3119;395:3028",
						  "parent": {
							"id": "395:3119"
						  },
						  "name": "vectors",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9604
							],
							[
							  0,
							  1,
							  2010
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 9604,
							"y": 2010,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3119;395:3029",
							  "parent": {
								"id": "I395:3119;395:3028"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  1
								],
								[
								  0,
								  1,
								  2.9899444580078125
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9605
								],
								[
								  0,
								  1,
								  2012.989990234375
								]
							  ],
							  "x": 1,
							  "y": 2.9899444580078125,
							  "width": 22,
							  "height": 17.943681716918945,
							  "absoluteRenderBounds": {
								"x": 9604,
								"y": 2011.989990234375,
								"width": 23.9716796875,
								"height": 19.9437255859375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9,
										10,
										11,
										12,
										13
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": -0.9576150178909302,
									  "y": 0.6754773855209351
									},
									"tangentEnd": {
									  "x": 1.1221078634262085,
									  "y": -0.33789074420928955
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": -0.6022599339485168,
									  "y": -0.6924893856048584
									},
									"tangentEnd": {
									  "x": 0.890299916267395,
									  "y": 0.22276580333709717
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": -0.890299916267395,
									  "y": -0.22276580333709717
									},
									"tangentEnd": {
									  "x": 0.8574152588844299,
									  "y": -0.32725775241851807
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": -0.8574152588844299,
									  "y": 0.32725775241851807
									},
									"tangentEnd": {
									  "x": 0.5154598355293274,
									  "y": -0.7593153119087219
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": -0.5154598355293274,
									  "y": 0.7593153119087219
									},
									"tangentEnd": {
									  "x": -0.012302441522479057,
									  "y": -0.9176639914512634
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": -1.757366418838501,
									  "y": 0.045568279922008514
									},
									"tangentEnd": {
									  "x": 1.570265293121338,
									  "y": 0.7903668880462646
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": -1.570265293121338,
									  "y": -0.7903668880462646
									},
									"tangentEnd": {
									  "x": 1.010317087173462,
									  "y": 1.4386355876922607
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": 4.440892098500626e-16,
									  "y": -8.881784197001252e-16
									},
									"tangentEnd": {
									  "x": -9,
									  "y": -4
									}
								  },
								  {
									"start": 9,
									"end": 10,
									"tangentStart": {
									  "x": -2.0594723224639893,
									  "y": 1.3979662656784058
									},
									"tangentEnd": {
									  "x": 2.487156867980957,
									  "y": 0.09892935305833817
									}
								  },
								  {
									"start": 10,
									"end": 11,
									"tangentStart": {
									  "x": 9,
									  "y": 5
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 11.5
									}
								  },
								  {
									"start": 11,
									"end": 12,
									"tangentStart": {
									  "x": -0.0009208884439431131,
									  "y": -0.27854636311531067
									},
									"tangentEnd": {
									  "x": 0.052297499030828476,
									  "y": 0.2735944092273712
									}
								  },
								  {
									"start": 12,
									"end": 0,
									"tangentStart": {
									  "x": 1.0206031799316406,
									  "y": -1.0065069198608398
									},
									"tangentEnd": {
									  "x": -0.3391726016998291,
									  "y": 1.3927128314971924
									}
								  },
								  {
									"start": 0,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 22,
									"y": 0.010055706836283207,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 18.860000610351562,
									"y": 1.5400558710098267,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 16.567033767700195,
									"y": 0.1339818686246872,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 13.882081031799316,
									"y": 0.29450908303260803,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 11.772982597351074,
									"y": 1.9637724161148071,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 11,
									"y": 4.540055751800537,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 11,
									"y": 5.540055274963379,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.931008815765381,
									"y": 4.405501842498779,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2,
									"y": 1.010055661201477,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 14.010055541992188,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 16.010055541992188,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 20,
									"y": 4.5100555419921875,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 19.920000076293945,
									"y": 3.680055856704712,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 22 0.010055706836283207 C 21.04238498210907 0.6855330923572183 19.98210847377777 1.202165126800537 18.860000610351562 1.5400558710098267 C 18.257740676403046 0.8475664854049683 17.45733368396759 0.35674767196178436 16.567033767700195 0.1339818686246872 C 15.6767338514328 -0.08878393471240997 14.739496290683746 -0.032748669385910034 13.882081031799316 0.29450908303260803 C 13.024665772914886 0.6217668354511261 12.288442432880402 1.2044571042060852 11.772982597351074 1.9637724161148071 C 11.257522761821747 2.723087728023529 10.987697558477521 3.6223917603492737 11 4.540055751800537 L 11 5.540055274963379 C 9.242633581161499 5.585623554885387 7.501274108886719 5.195868730545044 5.931008815765381 4.405501842498779 C 4.360743522644043 3.6151349544525146 3.010317087173462 2.448691248893738 2 1.010055661201477 C 2.0000000000000004 1.0100556612014762 -2 10.010055541992188 7 14.010055541992188 C 4.940527677536011 15.408021807670593 2.487156867980957 16.108984895050526 0 16.010055541992188 C 9 21.010055541992188 20 16.010055541992188 20 4.5100555419921875 C 19.999079111556057 4.231509178876877 19.972297575324774 3.953650265932083 19.920000076293945 3.680055856704712 C 20.940603256225586 2.673548936843872 21.66082739830017 1.4027685383334756 22 0.010055706836283207 L 22 0.010055706836283207 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "395:3027"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Logo": "twitter"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2551.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3120",
					  "parent": {
						"id": "395:3117"
					  },
					  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  536
						],
						[
						  0,
						  1,
						  16
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9652
						],
						[
						  0,
						  1,
						  2010
						]
					  ],
					  "x": 536,
					  "y": 16,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 9652,
						"y": 2010,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I395:3120;395:3040",
						  "parent": {
							"id": "395:3120"
						  },
						  "name": "vectors",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9652
							],
							[
							  0,
							  1,
							  2010
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 9652,
							"y": 2010,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3120;395:3041",
							  "parent": {
								"id": "I395:3120;395:3040"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  2
								],
								[
								  0,
								  1,
								  2
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9654
								],
								[
								  0,
								  1,
								  2012
								]
							  ],
							  "x": 2,
							  "y": 2,
							  "width": 20,
							  "height": 20,
							  "absoluteRenderBounds": {
								"x": 9653,
								"y": 2011,
								"width": 22,
								"height": 22
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 2.7614238262176514,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": -2.7614238262176514
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 2.7614238262176514
									},
									"tangentEnd": {
									  "x": 2.7614238262176514,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -2.7614238262176514,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 2.7614238262176514
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 7,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": -2.7614238262176514
									},
									"tangentEnd": {
									  "x": -2.7614238262176514,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 15,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 20,
									"y": 5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 20,
									"y": 15,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 15,
									"y": 20,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5,
									"y": 20,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 15,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONE",
								  "data": "M 5 0 L 15 0 C 17.76142382621765 0 20 2.2385761737823486 20 5 L 20 15 C 20 17.76142382621765 17.76142382621765 20 15 20 L 5 20 C 2.2385761737823486 20 0 17.76142382621765 0 15 L 0 5 C 0 2.2385761737823486 2.2385761737823486 0 5 0 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3120;395:3042",
							  "parent": {
								"id": "I395:3120;395:3040"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  8.04296875
								],
								[
								  0,
								  1,
								  7.956729888916016
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9660.04296875
								],
								[
								  0,
								  1,
								  2017.956787109375
								]
							  ],
							  "x": 8.04296875,
							  "y": 7.956729888916016,
							  "width": 8.000008583068848,
							  "height": 8.000008583068848,
							  "absoluteRenderBounds": {
								"x": 9659.04296875,
								"y": 2016.956787109375,
								"width": 10,
								"height": 10
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0.1234116330742836,
									  "y": 0.8322460651397705
									},
									"tangentEnd": {
									  "x": 0.38749924302101135,
									  "y": -0.7467986941337585
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": -0.38749924302101135,
									  "y": 0.7467986941337585
									},
									"tangentEnd": {
									  "x": 0.7515201568603516,
									  "y": -0.37826108932495117
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": -0.7515201568603516,
									  "y": 0.37826108932495117
									},
									"tangentEnd": {
									  "x": 0.8306610584259033,
									  "y": 0.13366460800170898
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": -0.8306610584259033,
									  "y": -0.13366460800170898
									},
									"tangentEnd": {
									  "x": 0.5949218273162842,
									  "y": 0.5949218273162842
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": -0.5949218273162842,
									  "y": -0.5949218273162842
									},
									"tangentEnd": {
									  "x": 0.13366460800170898,
									  "y": 0.8306610584259033
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.13366460800170898,
									  "y": -0.8306610584259033
									},
									"tangentEnd": {
									  "x": -0.37826108932495117,
									  "y": 0.7515201568603516
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": 0.37826108932495117,
									  "y": -0.7515201568603516
									},
									"tangentEnd": {
									  "x": -0.7467986941337585,
									  "y": 0.38749924302101135
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": 0.7467986941337585,
									  "y": -0.38749924302101135
									},
									"tangentEnd": {
									  "x": -0.8322460651397705,
									  "y": -0.1234116330742836
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": 0.8489227890968323,
									  "y": 0.12588457763195038
									},
									"tangentEnd": {
									  "x": -0.6068429946899414,
									  "y": -0.6068429946899414
									}
								  },
								  {
									"start": 9,
									"end": 0,
									"tangentStart": {
									  "x": 0.6068429946899414,
									  "y": 0.6068429946899414
									},
									"tangentEnd": {
									  "x": -0.12588457763195038,
									  "y": -0.8489227890968323
									}
								  }
								],
								"vertices": [
								  {
									"x": 7.956738471984863,
									"y": 3.4132702350616455,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7.550497055053711,
									"y": 5.84228515625,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.798364639282227,
									"y": 7.572943687438965,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.3645246028900146,
									"y": 7.949202537536621,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.1715772151947021,
									"y": 6.828431129455566,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.05080585181713104,
									"y": 4.635483741760254,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.4270646870136261,
									"y": 2.2016441822052,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2.1577231884002686,
									"y": 0.44951170682907104,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.586738586425781,
									"y": 0.0432702973484993,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.828432083129883,
									"y": 1.171576738357544,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 7.956738471984863 3.4132702350616455 C 8.080150105059147 4.245516300201416 7.937996298074722 5.0954864621162415 7.550497055053711 5.84228515625 C 7.1629978120327 6.5890838503837585 6.549884796142578 7.194682598114014 5.798364639282227 7.572943687438965 C 5.046844482421875 7.951204776763916 4.195185661315918 8.08286714553833 3.3645246028900146 7.949202537536621 C 2.5338635444641113 7.815537929534912 1.7664990425109863 7.423352956771851 1.1715772151947021 6.828431129455566 C 0.576655387878418 6.233509302139282 0.18447045981884003 5.466144800186157 0.05080585181713104 4.635483741760254 C -0.08285875618457794 3.8048226833343506 0.04880359768867493 2.9531643390655518 0.4270646870136261 2.2016441822052 C 0.8053257763385773 1.4501240253448486 1.41092449426651 0.8370109498500824 2.1577231884002686 0.44951170682907104 C 2.904521882534027 0.06201246380805969 3.7544925212860107 -0.0801413357257843 4.586738586425781 0.0432702973484993 C 5.4356613755226135 0.16915487498044968 6.221589088439941 0.5647337436676025 6.828432083129883 1.171576738357544 C 7.435275077819824 1.7784197330474854 7.830853894352913 2.5643474459648132 7.956738471984863 3.4132702350616455 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3120;395:3043",
							  "parent": {
								"id": "I395:3120;395:3040"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  17.5
								],
								[
								  0,
								  1,
								  6.5
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9669.5
								],
								[
								  0,
								  1,
								  2016.5
								]
							  ],
							  "x": 17.5,
							  "y": 6.5,
							  "width": 0.010000228881835938,
							  "height": 0,
							  "absoluteRenderBounds": {
								"x": 9668.5,
								"y": 2015.5,
								"width": 2.009765625,
								"height": 2
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.010000228881835938,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONE",
								  "data": "M 0 0 L 0.010000228881835938 0"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "395:3039"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Logo": "instagram"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "5"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 16,
				  "paddingBottom": 16,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3121",
				  "parent": {
					"id": "395:3107"
				  },
				  "name": "Copyright",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  155
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9116
					],
					[
					  0,
					  1,
					  2074
					]
				  ],
				  "x": 0,
				  "y": 155,
				  "width": 1000,
				  "height": 17,
				  "absoluteRenderBounds": {
					"x": 9116,
					"y": 2074,
					"width": 1000,
					"height": 17.022705078125
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3122",
					  "listSpacing": 0,
					  "parent": {
						"id": "395:3121"
					  },
					  "name": "© 2022 Your Company, Inc. All rights reserved.",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.40392157435417175,
							"g": 0.5137255191802979,
							"b": 0.5960784554481506
						  }
						}
					  ],
					  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  344.5
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9460.5
						],
						[
						  0,
						  1,
						  2074
						]
					  ],
					  "x": 344.5,
					  "y": 0,
					  "width": 311,
					  "height": 17,
					  "absoluteRenderBounds": {
						"x": 9461.9951171875,
						"y": 2077.5,
						"width": 308.1220703125,
						"height": 13.522705078125
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "© 2022 Your Company, Inc. All rights reserved.",
					  "hasMissingFont": false,
					  "fontSize": 14,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Regular"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 32,
			  "paddingBottom": 32,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "89c836793a27bd763693d2d051403d39fb90df8c",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Simple Centered"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "footer",
		  "makersClasses": "makers-footer, makers-footer--simple-social-media",
		  "makersComponent": "footer",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3123",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Simple Social Media",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  473
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8896
			],
			[
			  0,
			  1,
			  2133
			]
		  ],
		  "x": 20,
		  "y": 473,
		  "width": 1440,
		  "height": 88,
		  "absoluteRenderBounds": {
			"x": 8896,
			"y": 2133,
			"width": 1440,
			"height": 88
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "columns",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3124",
			  "parent": {
				"id": "395:3123"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  220
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9116
				],
				[
				  0,
				  1,
				  2133
				]
			  ],
			  "x": 220,
			  "y": 0,
			  "width": 1000,
			  "height": 88,
			  "absoluteRenderBounds": {
				"x": 9116,
				"y": 2133,
				"width": 1000,
				"height": 88
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3125",
				  "parent": {
					"id": "395:3124"
				  },
				  "name": "Copyright",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9116
					],
					[
					  0,
					  1,
					  2165
					]
				  ],
				  "x": 0,
				  "y": 32,
				  "width": 500,
				  "height": 24,
				  "absoluteRenderBounds": {
					"x": 9116,
					"y": 2165,
					"width": 500,
					"height": 24
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3126",
					  "listSpacing": 0,
					  "parent": {
						"id": "395:3125"
					  },
					  "name": "© 2022 Your Company, Inc. All rights reserved.",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.40392157435417175,
							"g": 0.5137255191802979,
							"b": 0.5960784554481506
						  }
						}
					  ],
					  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  3.5
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9116
						],
						[
						  0,
						  1,
						  2168.5
						]
					  ],
					  "x": 0,
					  "y": 3.5,
					  "width": 311,
					  "height": 17,
					  "absoluteRenderBounds": {
						"x": 9117.4951171875,
						"y": 2172,
						"width": 308.1220703125,
						"height": 13.522705078125
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "© 2022 Your Company, Inc. All rights reserved.",
					  "hasMissingFont": false,
					  "fontSize": 14,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Regular"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3127",
				  "parent": {
					"id": "395:3124"
				  },
				  "name": "Social Media",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  500
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9616
					],
					[
					  0,
					  1,
					  2165
					]
				  ],
				  "x": 500,
				  "y": 32,
				  "width": 500,
				  "height": 24,
				  "absoluteRenderBounds": {
					"x": 9616,
					"y": 2165,
					"width": 500,
					"height": 24
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2559.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3128",
					  "parent": {
						"id": "395:3127"
					  },
					  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  380
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9996
						],
						[
						  0,
						  1,
						  2165
						]
					  ],
					  "x": 380,
					  "y": 0,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 9996,
						"y": 2165,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I395:3128;395:3057",
						  "parent": {
							"id": "395:3128"
						  },
						  "name": "vectors",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9996
							],
							[
							  0,
							  1,
							  2165
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 9996,
							"y": 2165,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3128;395:3058",
							  "parent": {
								"id": "I395:3128;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  5
								],
								[
								  0,
								  1,
								  2
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10001
								],
								[
								  0,
								  1,
								  2167
								]
							  ],
							  "x": 5,
							  "y": 2,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 10000,
								"y": 2166,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": -0.9282577037811279
									},
									"tangentEnd": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": -0.9282577037811279,
									  "y": 4.440892098500626e-16
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.9282577037811279,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 6,
									"end": 0,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0.9282577037811279
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.0251262187957764,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3128;395:3059",
							  "parent": {
								"id": "I395:3128;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  12
								],
								[
								  0,
								  1,
								  2
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10008
								],
								[
								  0,
								  1,
								  2167
								]
							  ],
							  "x": 12,
							  "y": 2,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 10007,
								"y": 2166,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9,
										10
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.45962655544281006,
									  "y": -6.8489693916262695e-9
									},
									"tangentEnd": {
									  "x": -0.4246395528316498,
									  "y": -0.1758914589881897
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0.4246395528316498,
									  "y": 0.1758914589881897
									},
									"tangentEnd": {
									  "x": -0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": -0.1758914738893509,
									  "y": -0.4246395528316498
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0.1758914738893509,
									  "y": 0.4246395528316498
									},
									"tangentEnd": {
									  "x": 0,
									  "y": -0.45962655544281006
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": 0,
									  "y": 0.45962655544281006
									},
									"tangentEnd": {
									  "x": 0.1758914738893509,
									  "y": -0.4246395528316498
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": -0.1758914738893509,
									  "y": 0.4246395528316498
									},
									"tangentEnd": {
									  "x": 0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": -0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": 0.4246395528316498,
									  "y": -0.1758914589881897
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": -0.4246395528316498,
									  "y": 0.1758914589881897
									},
									"tangentEnd": {
									  "x": 0.45962655544281006,
									  "y": 6.8489693916262695e-9
									}
								  },
								  {
									"start": 9,
									"end": 10,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 10,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.886141898679611e-16,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 3.886141898679611e-16,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 0.2664215564727783,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 1.0251262187957764,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 2.1606078147888184,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 4.839392185211182,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 6.733578681945801,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3128;395:3060",
							  "parent": {
								"id": "I395:3128;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  12
								],
								[
								  0,
								  1,
								  9
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10008
								],
								[
								  0,
								  1,
								  2174
								]
							  ],
							  "x": 12,
							  "y": 9,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 10007,
								"y": 2173,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9,
										10,
										11,
										12,
										13,
										14,
										15,
										16
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": -9.685905411060958e-9,
									  "y": -0.45962655544281006
									},
									"tangentEnd": {
									  "x": -0.1758914589881897,
									  "y": 0.42463958263397217
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.1758914589881897,
									  "y": -0.42463958263397217
									},
									"tangentEnd": {
									  "x": -0.32500505447387695,
									  "y": 0.32500505447387695
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0.32500505447387695,
									  "y": -0.32500505447387695
									},
									"tangentEnd": {
									  "x": -0.4246395528316498,
									  "y": 0.1758914738893509
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0.4246395528316498,
									  "y": -0.1758914738893509
									},
									"tangentEnd": {
									  "x": -0.45962655544281006,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0.45962655544281006,
									  "y": 0
									},
									"tangentEnd": {
									  "x": -0.4246395528316498,
									  "y": -0.1758914738893509
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": 0.4246395528316498,
									  "y": 0.1758914738893509
									},
									"tangentEnd": {
									  "x": -0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": 0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": -0.1758914589881897,
									  "y": -0.42463958263397217
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": 0.1758914589881897,
									  "y": 0.42463958263397217
									},
									"tangentEnd": {
									  "x": 9.685901858347279e-9,
									  "y": -0.45962655544281006
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": 9.685905411060958e-9,
									  "y": 0.45962655544281006
									},
									"tangentEnd": {
									  "x": 0.1758914589881897,
									  "y": -0.42463958263397217
									}
								  },
								  {
									"start": 9,
									"end": 10,
									"tangentStart": {
									  "x": -0.1758914589881897,
									  "y": 0.42463958263397217
									},
									"tangentEnd": {
									  "x": 0.32500505447387695,
									  "y": -0.32500505447387695
									}
								  },
								  {
									"start": 10,
									"end": 11,
									"tangentStart": {
									  "x": -0.32500505447387695,
									  "y": 0.32500505447387695
									},
									"tangentEnd": {
									  "x": 0.4246395528316498,
									  "y": -0.1758914738893509
									}
								  },
								  {
									"start": 11,
									"end": 12,
									"tangentStart": {
									  "x": -0.4246395528316498,
									  "y": 0.1758914738893509
									},
									"tangentEnd": {
									  "x": 0.45962655544281006,
									  "y": 0
									}
								  },
								  {
									"start": 12,
									"end": 13,
									"tangentStart": {
									  "x": -0.45962655544281006,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.4246395528316498,
									  "y": 0.1758914738893509
									}
								  },
								  {
									"start": 13,
									"end": 14,
									"tangentStart": {
									  "x": -0.4246395528316498,
									  "y": -0.1758914738893509
									},
									"tangentEnd": {
									  "x": 0.32500505447387695,
									  "y": 0.32500505447387695
									}
								  },
								  {
									"start": 14,
									"end": 15,
									"tangentStart": {
									  "x": -0.32500505447387695,
									  "y": -0.32500505447387695
									},
									"tangentEnd": {
									  "x": 0.1758914589881897,
									  "y": 0.42463958263397217
									}
								  },
								  {
									"start": 15,
									"end": 0,
									"tangentStart": {
									  "x": -0.1758914589881897,
									  "y": -0.42463958263397217
									},
									"tangentEnd": {
									  "x": -9.685907187417797e-9,
									  "y": 0.45962655544281006
									}
								  },
								  {
									"start": 0,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 7.77230656136968e-16,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.2664213180541992,
									"y": 2.1606082916259766,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.0251264572143555,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2.1606082916259766,
									"y": 0.2664213180541992,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 0.2664213180541992,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 1.0251264572143555,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 2.1606082916259766,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.733577728271484,
									"y": 4.839391708374023,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.974874496459961,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.839391708374023,
									"y": 6.733578681945801,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2.1606082916259766,
									"y": 6.733578681945801,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.2664213180541992,
									"y": 4.839391708374023,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3128;395:3061",
							  "parent": {
								"id": "I395:3128;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  5
								],
								[
								  0,
								  1,
								  16
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10001
								],
								[
								  0,
								  1,
								  2181
								]
							  ],
							  "x": 5,
							  "y": 16,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 10000,
								"y": 2180,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": -0.9282577037811279
									},
									"tangentEnd": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": -0.9282577037811279,
									  "y": 0
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0.9282577037811279
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0.9282577037811279,
									  "y": 0
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": -0.9282577037811279,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 7,
									"end": 0,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0.9282577037811279
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.025125503540039,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.9748735427856445,
									"y": 5.974874496459961,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.974874496459961,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3128;395:3062",
							  "parent": {
								"id": "I395:3128;395:3057"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  5
								],
								[
								  0,
								  1,
								  9
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10001
								],
								[
								  0,
								  1,
								  2174
								]
							  ],
							  "x": 5,
							  "y": 9,
							  "width": 7,
							  "height": 7,
							  "absoluteRenderBounds": {
								"x": 10000,
								"y": 2173,
								"width": 9,
								"height": 9
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": -0.9282577037811279
									},
									"tangentEnd": {
									  "x": -0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": -0.9282577037811279,
									  "y": 1.7763568394002505e-15
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.9282577037811279,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0.6563773155212402,
									  "y": 0.6563773155212402
									}
								  },
								  {
									"start": 6,
									"end": 0,
									"tangentStart": {
									  "x": -0.6563773155212402,
									  "y": -0.6563773155212402
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0.9282577037811279
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 3.5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 1.0251264572143555,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.5,
									"y": 7,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.0251264572143555,
									"y": 5.9748735427856445,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "395:3056"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Logo": "figma"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "8"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2560.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3129",
					  "parent": {
						"id": "395:3127"
					  },
					  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  428
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  10044
						],
						[
						  0,
						  1,
						  2165
						]
					  ],
					  "x": 428,
					  "y": 0,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 10044,
						"y": 2165,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I395:3129;395:3028",
						  "parent": {
							"id": "395:3129"
						  },
						  "name": "vectors",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10044
							],
							[
							  0,
							  1,
							  2165
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 10044,
							"y": 2165,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3129;395:3029",
							  "parent": {
								"id": "I395:3129;395:3028"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  1
								],
								[
								  0,
								  1,
								  2.9899444580078125
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10045
								],
								[
								  0,
								  1,
								  2167.989990234375
								]
							  ],
							  "x": 1,
							  "y": 2.9899444580078125,
							  "width": 22,
							  "height": 17.943681716918945,
							  "absoluteRenderBounds": {
								"x": 10044,
								"y": 2166.989990234375,
								"width": 23.9716796875,
								"height": 19.943603515625
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9,
										10,
										11,
										12,
										13
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": -0.9576150178909302,
									  "y": 0.6754773855209351
									},
									"tangentEnd": {
									  "x": 1.1221078634262085,
									  "y": -0.33789074420928955
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": -0.6022599339485168,
									  "y": -0.6924893856048584
									},
									"tangentEnd": {
									  "x": 0.890299916267395,
									  "y": 0.22276580333709717
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": -0.890299916267395,
									  "y": -0.22276580333709717
									},
									"tangentEnd": {
									  "x": 0.8574152588844299,
									  "y": -0.32725775241851807
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": -0.8574152588844299,
									  "y": 0.32725775241851807
									},
									"tangentEnd": {
									  "x": 0.5154598355293274,
									  "y": -0.7593153119087219
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": -0.5154598355293274,
									  "y": 0.7593153119087219
									},
									"tangentEnd": {
									  "x": -0.012302441522479057,
									  "y": -0.9176639914512634
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": -1.757366418838501,
									  "y": 0.045568279922008514
									},
									"tangentEnd": {
									  "x": 1.570265293121338,
									  "y": 0.7903668880462646
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": -1.570265293121338,
									  "y": -0.7903668880462646
									},
									"tangentEnd": {
									  "x": 1.010317087173462,
									  "y": 1.4386355876922607
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": 4.440892098500626e-16,
									  "y": -8.881784197001252e-16
									},
									"tangentEnd": {
									  "x": -9,
									  "y": -4
									}
								  },
								  {
									"start": 9,
									"end": 10,
									"tangentStart": {
									  "x": -2.0594723224639893,
									  "y": 1.3979662656784058
									},
									"tangentEnd": {
									  "x": 2.487156867980957,
									  "y": 0.09892935305833817
									}
								  },
								  {
									"start": 10,
									"end": 11,
									"tangentStart": {
									  "x": 9,
									  "y": 5
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 11.5
									}
								  },
								  {
									"start": 11,
									"end": 12,
									"tangentStart": {
									  "x": -0.0009208884439431131,
									  "y": -0.27854636311531067
									},
									"tangentEnd": {
									  "x": 0.052297499030828476,
									  "y": 0.2735944092273712
									}
								  },
								  {
									"start": 12,
									"end": 0,
									"tangentStart": {
									  "x": 1.0206031799316406,
									  "y": -1.0065069198608398
									},
									"tangentEnd": {
									  "x": -0.3391726016998291,
									  "y": 1.3927128314971924
									}
								  },
								  {
									"start": 0,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 22,
									"y": 0.010055706836283207,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 18.860000610351562,
									"y": 1.5400558710098267,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 16.567033767700195,
									"y": 0.1339818686246872,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 13.882081031799316,
									"y": 0.29450908303260803,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 11.772982597351074,
									"y": 1.9637724161148071,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 11,
									"y": 4.540055751800537,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 11,
									"y": 5.540055274963379,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.931008815765381,
									"y": 4.405501842498779,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2,
									"y": 1.010055661201477,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7,
									"y": 14.010055541992188,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 16.010055541992188,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 20,
									"y": 4.5100555419921875,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 19.920000076293945,
									"y": 3.680055856704712,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 22 0.010055706836283207 C 21.04238498210907 0.6855330923572183 19.98210847377777 1.202165126800537 18.860000610351562 1.5400558710098267 C 18.257740676403046 0.8475664854049683 17.45733368396759 0.35674767196178436 16.567033767700195 0.1339818686246872 C 15.6767338514328 -0.08878393471240997 14.739496290683746 -0.032748669385910034 13.882081031799316 0.29450908303260803 C 13.024665772914886 0.6217668354511261 12.288442432880402 1.2044571042060852 11.772982597351074 1.9637724161148071 C 11.257522761821747 2.723087728023529 10.987697558477521 3.6223917603492737 11 4.540055751800537 L 11 5.540055274963379 C 9.242633581161499 5.585623554885387 7.501274108886719 5.195868730545044 5.931008815765381 4.405501842498779 C 4.360743522644043 3.6151349544525146 3.010317087173462 2.448691248893738 2 1.010055661201477 C 2.0000000000000004 1.0100556612014762 -2 10.010055541992188 7 14.010055541992188 C 4.940527677536011 15.408021807670593 2.487156867980957 16.108984895050526 0 16.010055541992188 C 9 21.010055541992188 20 16.010055541992188 20 4.5100555419921875 C 19.999079111556057 4.231509178876877 19.972297575324774 3.953650265932083 19.920000076293945 3.680055856704712 C 20.940603256225586 2.673548936843872 21.66082739830017 1.4027685383334756 22 0.010055706836283207 L 22 0.010055706836283207 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "395:3027"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Logo": "twitter"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-394-2561.svg",
					  "makersExportAsSvg": "true",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3130",
					  "parent": {
						"id": "395:3127"
					  },
					  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  476
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  10092
						],
						[
						  0,
						  1,
						  2165
						]
					  ],
					  "x": 476,
					  "y": 0,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 10092,
						"y": 2165,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I395:3130;395:3040",
						  "parent": {
							"id": "395:3130"
						  },
						  "name": "vectors",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10092
							],
							[
							  0,
							  1,
							  2165
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 24,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 10092,
							"y": 2165,
							"width": 24,
							"height": 24
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3130;395:3041",
							  "parent": {
								"id": "I395:3130;395:3040"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  2
								],
								[
								  0,
								  1,
								  2
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10094
								],
								[
								  0,
								  1,
								  2167
								]
							  ],
							  "x": 2,
							  "y": 2,
							  "width": 20,
							  "height": 20,
							  "absoluteRenderBounds": {
								"x": 10093,
								"y": 2166,
								"width": 22,
								"height": 22
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": 2.7614238262176514,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": -2.7614238262176514
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": 0,
									  "y": 2.7614238262176514
									},
									"tangentEnd": {
									  "x": 2.7614238262176514,
									  "y": 0
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -2.7614238262176514,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 2.7614238262176514
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  },
								  {
									"start": 7,
									"end": 0,
									"tangentStart": {
									  "x": 0,
									  "y": -2.7614238262176514
									},
									"tangentEnd": {
									  "x": -2.7614238262176514,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 5,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 15,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 20,
									"y": 5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 20,
									"y": 15,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 15,
									"y": 20,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5,
									"y": 20,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 15,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0,
									"y": 5,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONE",
								  "data": "M 5 0 L 15 0 C 17.76142382621765 0 20 2.2385761737823486 20 5 L 20 15 C 20 17.76142382621765 17.76142382621765 20 15 20 L 5 20 C 2.2385761737823486 20 0 17.76142382621765 0 15 L 0 5 C 0 2.2385761737823486 2.2385761737823486 0 5 0 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3130;395:3042",
							  "parent": {
								"id": "I395:3130;395:3040"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  8.04296875
								],
								[
								  0,
								  1,
								  7.956729888916016
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10100.04296875
								],
								[
								  0,
								  1,
								  2172.956787109375
								]
							  ],
							  "x": 8.04296875,
							  "y": 7.956729888916016,
							  "width": 8.000008583068848,
							  "height": 8.000008583068848,
							  "absoluteRenderBounds": {
								"x": 10099.04296875,
								"y": 2171.956787109375,
								"width": 10,
								"height": 10
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [
								  {
									"windingRule": "NONZERO",
									"loops": [
									  [
										0,
										1,
										2,
										3,
										4,
										5,
										6,
										7,
										8,
										9
									  ]
									]
								  }
								],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0.1234116330742836,
									  "y": 0.8322460651397705
									},
									"tangentEnd": {
									  "x": 0.38749924302101135,
									  "y": -0.7467986941337585
									}
								  },
								  {
									"start": 1,
									"end": 2,
									"tangentStart": {
									  "x": -0.38749924302101135,
									  "y": 0.7467986941337585
									},
									"tangentEnd": {
									  "x": 0.7515201568603516,
									  "y": -0.37826108932495117
									}
								  },
								  {
									"start": 2,
									"end": 3,
									"tangentStart": {
									  "x": -0.7515201568603516,
									  "y": 0.37826108932495117
									},
									"tangentEnd": {
									  "x": 0.8306610584259033,
									  "y": 0.13366460800170898
									}
								  },
								  {
									"start": 3,
									"end": 4,
									"tangentStart": {
									  "x": -0.8306610584259033,
									  "y": -0.13366460800170898
									},
									"tangentEnd": {
									  "x": 0.5949218273162842,
									  "y": 0.5949218273162842
									}
								  },
								  {
									"start": 4,
									"end": 5,
									"tangentStart": {
									  "x": -0.5949218273162842,
									  "y": -0.5949218273162842
									},
									"tangentEnd": {
									  "x": 0.13366460800170898,
									  "y": 0.8306610584259033
									}
								  },
								  {
									"start": 5,
									"end": 6,
									"tangentStart": {
									  "x": -0.13366460800170898,
									  "y": -0.8306610584259033
									},
									"tangentEnd": {
									  "x": -0.37826108932495117,
									  "y": 0.7515201568603516
									}
								  },
								  {
									"start": 6,
									"end": 7,
									"tangentStart": {
									  "x": 0.37826108932495117,
									  "y": -0.7515201568603516
									},
									"tangentEnd": {
									  "x": -0.7467986941337585,
									  "y": 0.38749924302101135
									}
								  },
								  {
									"start": 7,
									"end": 8,
									"tangentStart": {
									  "x": 0.7467986941337585,
									  "y": -0.38749924302101135
									},
									"tangentEnd": {
									  "x": -0.8322460651397705,
									  "y": -0.1234116330742836
									}
								  },
								  {
									"start": 8,
									"end": 9,
									"tangentStart": {
									  "x": 0.8489227890968323,
									  "y": 0.12588457763195038
									},
									"tangentEnd": {
									  "x": -0.6068429946899414,
									  "y": -0.6068429946899414
									}
								  },
								  {
									"start": 9,
									"end": 0,
									"tangentStart": {
									  "x": 0.6068429946899414,
									  "y": 0.6068429946899414
									},
									"tangentEnd": {
									  "x": -0.12588457763195038,
									  "y": -0.8489227890968323
									}
								  }
								],
								"vertices": [
								  {
									"x": 7.956738471984863,
									"y": 3.4132702350616455,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 7.550497055053711,
									"y": 5.84228515625,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 5.798364639282227,
									"y": 7.572943687438965,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 3.3645246028900146,
									"y": 7.949202537536621,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 1.1715772151947021,
									"y": 6.828431129455566,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.05080585181713104,
									"y": 4.635483741760254,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.4270646870136261,
									"y": 2.2016441822052,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 2.1577231884002686,
									"y": 0.44951170682907104,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 4.586738586425781,
									"y": 0.0432702973484993,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 6.828432083129883,
									"y": 1.171576738357544,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONZERO",
								  "data": "M 7.956738471984863 3.4132702350616455 C 8.080150105059147 4.245516300201416 7.937996298074722 5.0954864621162415 7.550497055053711 5.84228515625 C 7.1629978120327 6.5890838503837585 6.549884796142578 7.194682598114014 5.798364639282227 7.572943687438965 C 5.046844482421875 7.951204776763916 4.195185661315918 8.08286714553833 3.3645246028900146 7.949202537536621 C 2.5338635444641113 7.815537929534912 1.7664990425109863 7.423352956771851 1.1715772151947021 6.828431129455566 C 0.576655387878418 6.233509302139282 0.18447045981884003 5.466144800186157 0.05080585181713104 4.635483741760254 C -0.08285875618457794 3.8048226833343506 0.04880359768867493 2.9531643390655518 0.4270646870136261 2.2016441822052 C 0.8053257763385773 1.4501240253448486 1.41092449426651 0.8370109498500824 2.1577231884002686 0.44951170682907104 C 2.904521882534027 0.06201246380805969 3.7544925212860107 -0.0801413357257843 4.586738586425781 0.0432702973484993 C 5.4356613755226135 0.16915487498044968 6.221589088439941 0.5647337436676025 6.828432083129883 1.171576738357544 C 7.435275077819824 1.7784197330474854 7.830853894352913 2.5643474459648132 7.956738471984863 3.4132702350616455 Z"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I395:3130;395:3043",
							  "parent": {
								"id": "I395:3130;395:3040"
							  },
							  "name": "Vector",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.40392157435417175,
									"g": 0.5137255191802979,
									"b": 0.5960784554481506
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "CENTER",
							  "strokeJoin": "ROUND",
							  "dashPattern": [],
							  "strokeCap": "ROUND",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  17.5
								],
								[
								  0,
								  1,
								  6.5
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10109.5
								],
								[
								  0,
								  1,
								  2171.5
								]
							  ],
							  "x": 17.5,
							  "y": 6.5,
							  "width": 0.010000228881835938,
							  "height": 0,
							  "absoluteRenderBounds": {
								"x": 10108.5,
								"y": 2170.5,
								"width": 2.009765625,
								"height": 2
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "SCALE",
								"vertical": "SCALE"
							  },
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "vectorNetwork": {
								"regions": [],
								"segments": [
								  {
									"start": 0,
									"end": 1,
									"tangentStart": {
									  "x": 0,
									  "y": 0
									},
									"tangentEnd": {
									  "x": 0,
									  "y": 0
									}
								  }
								],
								"vertices": [
								  {
									"x": 0,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  },
								  {
									"x": 0.010000228881835938,
									"y": 0,
									"strokeCap": "ROUND",
									"strokeJoin": "ROUND",
									"cornerRadius": 0,
									"handleMirroring": "NONE"
								  }
								]
							  },
							  "vectorPaths": [
								{
								  "windingRule": "NONE",
								  "data": "M 0 0 L 0.010000228881835938 0"
								}
							  ],
							  "handleMirroring": "NONE",
							  "reactions": [],
							  "type": "VECTOR",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": true,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "NONE",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 0,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": false,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "395:3039"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Logo": "instagram"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "5"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MAX",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 32,
			  "paddingBottom": 32,
			  "primaryAxisAlignItems": "SPACE_BETWEEN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "5f80c6dadc998eb25d8d283c978dbf1c77ac67c4",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Simple Social Media"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "footer",
		  "makersClasses": "makers-footer, makers-footer--legal",
		  "makersComponent": "footer",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "395:3131",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Legal",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  571
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8896
			],
			[
			  0,
			  1,
			  2231
			]
		  ],
		  "x": 20,
		  "y": 571,
		  "width": 1440,
		  "height": 81,
		  "absoluteRenderBounds": {
			"x": 8896,
			"y": 2231,
			"width": 1440,
			"height": 81
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "395:3132",
			  "parent": {
				"id": "395:3131"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  220
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9116
				],
				[
				  0,
				  1,
				  2231
				]
			  ],
			  "x": 220,
			  "y": 0,
			  "width": 1000,
			  "height": 81,
			  "absoluteRenderBounds": {
				"x": 9116,
				"y": 2231,
				"width": 1000,
				"height": 81
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "395:3133",
				  "parent": {
					"id": "395:3132"
				  },
				  "name": "Copyright",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  32
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9116
					],
					[
					  0,
					  1,
					  2263
					]
				  ],
				  "x": 0,
				  "y": 32,
				  "width": 1000,
				  "height": 17,
				  "absoluteRenderBounds": {
					"x": 9116,
					"y": 2263,
					"width": 1000,
					"height": 17.022705078125
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "395:3134",
					  "listSpacing": 0,
					  "parent": {
						"id": "395:3133"
					  },
					  "name": "© 2022 Your Company, Inc. All rights reserved.",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.40392157435417175,
							"g": 0.5137255191802979,
							"b": 0.5960784554481506
						  }
						}
					  ],
					  "fillStyleId": "S:61176b528f7e5d05edd759214db910ad9bea35e3,",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  344.5
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9460.5
						],
						[
						  0,
						  1,
						  2263
						]
					  ],
					  "x": 344.5,
					  "y": 0,
					  "width": 311,
					  "height": 17,
					  "absoluteRenderBounds": {
						"x": 9461.9951171875,
						"y": 2266.5,
						"width": 308.1220703125,
						"height": 13.522705078125
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "© 2022 Your Company, Inc. All rights reserved.",
					  "hasMissingFont": false,
					  "fontSize": 14,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Regular"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 32,
			  "paddingBottom": 32,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "88c50cb505fbe41be034f93e4d67d81fa567a164",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Legal"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_FOOTER",
	  "styles": {
		"itemSpacing": 10,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	}
  ]`;
