<template>
    <div class="makers-component-page">
        <makers-component-header header-name="Page" :back-page="(backPage == null) ? 'home' : backPage"/>
        <main>
			<div class="makers-content-wrapper">
            	<makers-update-page-screen :page="page" :has-footer="true" :back-page="(backPage == null) ? 'home' : backPage"></makers-update-page-screen>
			</div>
        </main>
    </div>
</template>
<script>
	import { mapState } from 'vuex';
    import { validFileType } from '../../helpers/utilities';
    import { dispatch, handleEvent } from "../../figma/ui-message-handler";
    export default {
        props: ['data', 'backPage'],
        data() {
            return {
                isLoading: false,
                hasErrors: false,

                selectedFile: null,
                pageUrl: ''
            }
        },
        mounted() {
            handleEvent("pageUpdated", () => {
                dispatch("notify", "✅ Page Updated Successfully");
                this.isLoading = false;
            });
        },
        computed: {
            ...mapState(['currentSelection']),
            page() {
                if (this.currentSelection == null || !this.data.isPublishWorkflow) {return this.data.component.node;}
                return (this.currentSelection.makersComponent === 'page') ? this.currentSelection : this.data.component.node;
            }
        },
        methods: {
            handleInput() {
                this.pageUrl = this.page.title.toLowerCase().replaceAll(" ", "-");
                if (this.hasErrors) this.hasErrors = false;
            },
            handleUpdatePage() {
                this.isLoading = true;
                (this.selectedFile !== null) ? this.saveImageInCloud() : this.updatePage(this.page.imageUrl);
            },

            saveImageInCloud() {
                const URL = 'site/upload/image';
                const data = new FormData();
                data.append('fileKey', this.fileKey);
                data.append('image', this.selectedFile);
                axios.post(URL, data, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}})
                    .then( (response) => this.updatePage(response.data.imageUrl) )
                    .catch( () => { this.isLoading = false; } );
            },
            updatePage(imageUrl) {
                dispatch('updatePage', {
                    url: this.pageUrl,
                    imageUrl: imageUrl,
                    title: this.page.title,
                    id: this.data.component.node.id,
                    description: this.page.description,
                });
            },
            handleImageSelection() {
                const input = this.$refs.uploadPageImage;
                this.selectedFile = input.files[0];
                const isImage = validFileType(this.selectedFile, ['image/png', 'image/jpeg', 'image/svg+xml'])
                if (isImage) {
                    const imageUrl = URL.createObjectURL(this.selectedFile);
                    this.data.component.node.makersPageImage = imageUrl; // For UI purposes
                } else {
                    dispatch("notify", "❌ Not a valid image format. Please use png, jpeg or svg 🙌")
                }
            },
            openUploadImage() {
                this.$refs.uploadPageImage.click();
            },
        }
    }
</script>
