import headers from './headers';

async function createUser(data) {
    const URL = 'auth/signup';
    return await axios.post(URL, data, {headers})
}

async function updateUser(data) {
    const URL = 'auth/update';
    return await axios.post(URL, data, {headers})
}

// Subscription
async function isSubscribed() {
    const URL = 'is-subscribed';
    return await axios.get(URL, {headers});
}
async function updateSubscriptionQuantity(data) {
    const URL = 'subscription/quantity';
    return await axios.post(URL, data, {headers});
}

// Other
async function sendSupportMessage(data) {
    const URL = 'send-message';
    return await axios.post(URL, data, {headers});
}

// Authentication
async function attemptAuthentication() {
    const URL = 'auth/me';
    return await axios.get(URL, {headers});
}

async function authenticateUser(data) {
    const URL = 'auth/login';
    return await axios.post(URL, data, {headers});
}

async function signOut() {
    const URL = 'auth/logout';
    return await axios.post(URL, {headers});
}

// Before Auth
async function isEmailAvailable(data) {
    const URL = 'auth/available';
    return await axios.post(URL, data, {headers});
}

async function sendRecoverPasswordLink(data) {
    const URL = 'auth/recoverlink';
    return await axios.post(URL, data, {headers});
}

async function setNewPassword(data) {
    const URL = 'auth/reset';
    return await axios.post(URL, data, {headers});
}

export default {
    signOut: () => signOut(),
    isSubscribed: () => isSubscribed(),
    createUser: (data) => createUser(data),
    updateUser: (data) => updateUser(data),
    setNewPassword: (data) => setNewPassword(data),
    authenticateUser: (data) => authenticateUser(data),
    isEmailAvailable: (data) => isEmailAvailable(data),
    attemptAuthentication: () => attemptAuthentication(),
    sendSupportMessage: (data) => sendSupportMessage(data),
    sendRecoverPasswordLink: (data) => sendRecoverPasswordLink(data),
    updateSubscriptionQuantity: (data) => updateSubscriptionQuantity(data),
}