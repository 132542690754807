export function getImageUrlFromBucket(figmaId, imageHash) {
    const endpoint = "https://zecoda.nyc3.digitaloceanspaces.com/makers/u";
    return `${endpoint}/${figmaId}/images/${imageHash}`;
}

export function setStatusColor(status) {
    switch (status) {
        case 'live': return {color: '#5DCBAC', transparent: '#AEE5D5', text: '#194D3E'};
        case 'building': return {color: '#18A0FB', transparent: '#BAE3FE', text: '#023D64'};
        case 'processing': return {color: '#18A0FB', transparent: '#BAE3FE', text: '#023D64'};
        case 'publishing': return {color: '#25745E', transparent: '#A8C7BF', text: '#194D3E'};
        case 'deploying': return {color: '#25745E', transparent: '#A8C7BF', text: '#194D3E'};
        default: return {color: '#FFE45E', transparent: '#FFF1AF', text: '#3D3300'}
    }
}
export function setStatusName(status) {
    switch (status) {
        case 'live': return 'Live';
        case 'building': return 'Building';
        case 'deploying': return 'Deploying';
        case 'processing': return 'Processing';
        case 'publishing': return 'Publishing';
        default: return 'Draft';
    }
}

export function isHomePage(homePage, page) {
    return (homePage.id === page.id);
}

export function validFileType(file, fileTypes) {
    return fileTypes.includes(file.type);
}

export function validateDomainName(domain) {
    return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(domain);
}

export function getMaxValueOfAttrInArrayOfObjects(array, attr) {
    return Math.max.apply(Math, array.map((o) => o[attr] ));
}
export function getObjectWithMaxValueOfAttrInArrayOfObjects(array, attr, res) {
    return array.find((o) => o[attr] == res );
}

export function getUint8ArrayFromImageUrl(imageUrl) {
    const request = new XMLHttpRequest();
    request.open("GET", imageUrl);
    request.responseType = "arraybuffer";
    request.setRequestHeader('Content-Type', 'application/xml');

    request.onload = () => {
        // Obtain a blob: URL for the image data.
        const arrayBufferView = new Uint8Array(request.response);
        dispatch("addComponent", {id: component.id, data: {arrayBuffer: arrayBufferView} });

        const blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
    };

    request.send();
}

export function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}
