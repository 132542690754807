<template>
    <div class="makers-header">
        <makers-header-decoration></makers-header-decoration>
        <header>
            <!-- <div class="input input--with-icon" :class="{hide: pages.length <= 0}">
                <div class="icon icon--search"></div>
                <input type="input" v-model="query" class="input__field" placeholder="Search components">
            </div> -->
			<!-- <makers-site-status/> -->
			<div class="makers-header-site-status" :class="{hide: pages.length <= 0 || user.user == null}"></div>
            <makers-header-buttons :controller="controller" />
            <makers-header-options-menu/>
        </header>
    </div>
</template>
<script>
    import Fuse from 'fuse.js';
    import {mapState, mapMutations} from 'vuex';
    export default {
		props: ['controller'],
        data() {
            return {
                query: null,
            }
        },
        mounted() {
            // this.setFilteredComponents(this.components);
        },
        computed: {
            ...mapState(['components', 'pages', 'user']),
			isSubscribed() {
				if (this.user.user == null) return false;
				return this.user.isSubscribed;
			}
        },
        watch: {
            query(newVal) {
                let query = newVal;
                if (query == null || query == '') {
                    this.setFilteredComponents(this.components);
                    return;
                }

                const fuse = new Fuse (this.components, {
                    threshold: 0.125,
                    keys: ['name'],
                });
                this.setFilteredComponents(fuse.search(query).map(item => item.item));
            }
        },
        methods: {
            ...mapMutations(['setFilteredComponents']),
        }
    }
</script>
