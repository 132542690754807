<template>
	<div class="makers-templates-tab">
		<template v-if="templates.length === 0">
			<div class="flex align-items-center justify-content-center">
				<div class="icon icon--spinner icon--black"></div>
			</div>
		</template>
		<template v-else>
			<div class="templates-wrapper">
				<div class="template" v-for="(template, index) in templates" :key="index">
					<div class="image-wrapper" @click.stop="selectTemplate(template)">
						<div class="image" :style="{'background-image': `url(${template.image})`}"></div>
					</div>
					<div class="details">
						<div class="start">
							<div class="name">{{template.name}}</div>
							<a class="link" :href="template.demo_link" target="_blank">Preview in browser</a>
						</div>
						<div class="price">
							${{template.price}}
						</div>
					</div>
				</div>
			</div>
			<makers-template-purchase-modal v-if="selectedTemplate != null" :template="selectedTemplate" @selectedTemplate="(value) => selectedTemplate = value" />
		</template>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	export default {
		data() {
			return {
				selectedTemplate: null,
			}
		},
		mounted() {
			if (this.templates.length === 0) {
				this.getTemplates();
			}
		},
		computed: {
			...mapState(['templates']),
		},
		methods: {
			...mapMutations(['setTemplates']),

			async getTemplates() {
				const response = await axios.get('/templates');
				const templates = response.data
				this.setTemplates(templates.reverse());
			},
			selectTemplate(template) {
				if (template.price === 0) {
					window.open(template.download_link, "_blank");
				} else {
					this.selectedTemplate = template;
				}
			}
		}
	}
</script>
