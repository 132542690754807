<template>
	<div class="makers-components-tab">
		<template v-if="currentSelection === null">
			<section>
				<div class="main-title">New Page</div>
				<div class="makers-components-wrapper" style="padding-left: 16px">
					<div
						class="makers-component"
						@click="onComponentClick(newPage)"
						:class="{'is-loading': isLoadingComponent}"
					>
						<div class="image-wrapper">
							<img :src="newPage.image" loading="lazy"/>
						</div>
						<div class="type type--xsmall type--semibold">{{newPage.name}}</div>
						<div class="type type--xxsmall">{{newPage.description}}</div>
						<div class="icon-wrapper">
							<div v-if="isLoadingComponent" class="icon icon--spinner"></div>
						</div>
					</div>
				</div>
			</section>
			<section style="margin-top: 24px">
				<div class="main-title">No page selected</div>
				<div class="notification notification--info">
					<div class="icon">
						<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10ZM12 16v-4M12 8h.01" stroke="#919191" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</div>
					<div>Select a page to add new components</div>
				</div>
			</section>
		</template>
		<template v-else>
			<template v-if="isPageSelected">
				<section class="elements-section" v-for="(section, index) in components" :key="index">
					<div class="main-title">{{section.name}}</div>
					<div class="makers-components-wrapper">
						<div
							class="makers-component"
							@click="onComponentClick(component, i)"
							:class="{'is-loading': isLoadingComponent}"
							v-for="(component, i) in section.components" :key="i"
						>
							<div class="image-wrapper">
								<img :src="component.image" loading="lazy"/>
							</div>
							<div class="type type--xsmall type--semibold">{{component.name}}</div>
							<div class="type type--xxsmall">{{component.description}}</div>
							<div class="icon-wrapper">
								<div v-if="isLoadingComponent && selectedComponent.id === component.id" class="icon icon--spinner"></div>
							</div>
						</div>
					</div>
				</section>
			</template>
			<template v-else>
				<div class="main-title">No page selected</div>
				<div class="notification notification--info">
					<div class="icon">
						<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10ZM12 16v-4M12 8h.01" stroke="#919191" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</div>
					<div>Select a page to add new components</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
	import { components } from '../../helpers/components';
	import { dispatch, handleEvent } from '../../figma/ui-message-handler';
	export default {
		props: ['currentSelection'],
		data() {
			return {
				isLoading: false,
                isLoadingComponent: false,
                selectedComponent: null,
				newPage: {
					id: 'new_page',
					name: 'New Page',
					description: 'Create an empty page',
					image: 'https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/components/makers-component-new-page.svg'
				},
			}
		},
		mounted() {
			handleEvent("componentWasAddedSuccessfully", () => this.handleComponentWasAddedSuccessfully());
		},
		computed: {
			components() {
				return components;
			},
			isPageSelected() {
				return this.currentSelection.makersComponent === 'page';
			}
		},
		methods: {
			onComponentClick(component) {
                this.selectedComponent = component;
                this.isLoadingComponent = true;
                switch (component.id) {
                    case 'new_page': this.$emit("addNewPage"); break;
                    default: dispatch("addComponent", {id: component.id}); break;
                }
            },
			handleComponentWasAddedSuccessfully() {
                this.selectedComponent = null;
                this.isLoadingComponent = false;
            },
		}
	}
</script>
