<template>
	<div class="makers-credit-card">
		<div id="payment-element"><!--Stripe.js injects the Payment Element--></div>
		<template v-if="isGlobalLoading">
			<div class="flex align-items-center justify-content-center">
				<div class="icon icon--spinner icon--black"></div>
			</div>
		</template>
		<template v-else>
			<!-- <div v-if="errorMessage" class="error-message type type--xsmall" v-text="errorMessage"></div> -->
			<button
				ref="cardButton"
				@click="handlePayButtonClick()"
				class="button button--primary type type--small"
				:data-secret="`${clientSecret}`"
				:disabled="isLoading"
			>
				<template v-if="!isLoading">
					{{ buttonName }}
				</template>
				<div v-else class="icon icon--spinner icon--white"></div>
			</button>
			<div class="disclaimer type type--xsmall">
				Your payment details will never touch our servers. You will be charged ${{price}}<template v-if="isSubscription"> every month</template>.
			</div>
		</template>
    </div>
</template>

<script>
    import { loadStripe } from '@stripe/stripe-js';
	export default {
		props: ['template', 'buttonName', 'price', 'isSubscription', 'errorMessage', 'isLoading'],
		data() {
			return {
				stripe: '',
                intent: '',
				elements: '',

				isDisabled: true,
				isGlobalLoading: true,
			}
		},
		mounted() {
			this.initialize();
        },
		computed: {
			clientSecret() {
                return this.intent.client_secret;
            }
		},
		methods: {
			async initialize() {
				// Fetches a payment intent and captures the client secret
				this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
				const response = await axios.post("/payment-intent", {
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ templateId: this.template.id }),
				});
				this.intent = response.data.intent;

				this.elements = this.stripe.elements({
                    locale: 'auto',
					clientSecret: this.clientSecret,
				});

				const paymentElement = this.elements.create("payment");
				await paymentElement.mount("#payment-element", {style: this.getStripeElementStyles()});
				this.isGlobalLoading = false;
			},

			handlePayButtonClick() {
				this.$emit('updatePaymentLoading', true);
				this.$emit('handlePayButtonClick', {
					elements: this.elements,
					stripe: this.stripe,
				});
			},


			getStripeElementStyles() {
                return {
					theme: 'stripe',
                    vaiables: {
                        color: "#32325D",
						colorText: '#32325D',
    					colorDanger: '#E25950',
                        fontWeight: 500,
                        fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
                        fontSize: "16px",
                        fontSmoothing: "antialiased",
                        ".Input::placeholder": {
                            color: "#CFD7DF"
                        }
                    },
                };
            }
		}
	}
</script>
