<template>
	<div class="modal" :class="{'is-active': isModalOpen}">
		<div class="modal-background" @click="$emit('selectedTemplate', null)"></div>
		<div class="modal-content" ref="modalContent">
			<makers-thank-you-message v-if="step === 3" service="template" message="We have emailed you your Template. Go make something insanely great!"/>
			<div v-else>
				<header>
					<div class="title">{{template.name}} is ready for you 🎉</div>
					<button class="close-button" @click="$emit('selectedTemplate', null)">
						<div class="icon icon--close"></div>
					</button>
				</header>
				<div class="main-content">
					<template v-if="step === 1">
						<div class="summary">
							<h1>Summary</h1>
							<div class="flex justify-content-between">
								<p><strong>Template Name</strong></p>
								<p>{{template.name}}</p>
							</div>
							<div class="flex justify-content-between">
								<p><strong>Price</strong></p>
								<p>${{template.price}}</p>
							</div>
							<div class="flex justify-content-end">
								<a :href="template.demo_link" target="_blank">Demo</a>
							</div>
						</div>
						<div class="p-xxsmall">
							<p class="type type--xsmall">You will receive a .fig file in your email.</p>
							<p class="type type--xsmall mt-xxxsmall">The .fig file is a Figma Project ready to be used with the Makers Plugin.</p>
							<!-- <a href="/tutorials/templates" target="_blank" class="button button--secondary mt-xxsmall" style="display: inline-flex">
								Watch Tutorial
							</a> -->
						</div>
					</template>
					<makers-template-purchase-confirmation
						v-if="step === 2"
						:template="template"
						@handlePaymentSuccess="(email) => handlePaymentSuccess(email)"
					/>
				</div>
			</div>
			<footer>
				<template v-if="step === 1">
					<button class="button button--primary type type--small" @click="step = 2">
						I want this template!
					</button>
				</template>
				<template v-else-if="step === 2">
					<div class="flex justify-content-between">
						<button class="button button--secondary" @click="step = 1" style="min-width: 60px">
							Back
						</button>
					</div>
				</template>
				<template v-else-if="step === 3">
					<div class="flex justify-content-between">
						<button class="button button--secondary" @click="$emit('selectedTemplate', null)" style="min-width: 60px">
							Close
						</button>
					</div>
				</template>
			</footer>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['template'],
		data() {
			return {
				isLoading: false,
				step: 1,
			}
		},
		mounted() {
		},
		computed: {
			isModalOpen() {
				return this.template !== null;
			}
		},
		methods: {
			handlePaymentSuccess(email) {
				// Save purchase in Database
				const id = this.template.id;
				const name = this.template.name;
				axios.post("/purchase", { body: JSON.stringify({ id, email, name }) }
				).then(() => this.handleSuccess() );
			},
			handleSuccess() {
				this.$refs.modalContent.scrollTop = 0;
				this.step = 3;
			}
		}
	}
</script>
