<template>
    <div class="modal loading-modal" :class="{'is-active': isLoading}">
		<div class="modal-background"></div>
		<div class="modal-content">
			<div class="main-content">
				<div class="man">
					<div class="body">
						<span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</span>
						<div class="base">
							<span></span>
							<div class="face"></div>
						</div>
					</div>
					<div class="longfazers">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
				Launching your site...<br/><small>It might take a while. Thank you for holding strong.</small>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        props: ['isLoading'],
    }
</script>
