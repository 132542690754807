<template>
	<div id="app">
		<template v-if="isLoading">
			<div class="global-loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
		</template>
		<template v-else>
			<home-page v-if="currentPage.name === 'home'" :controller="$refs.controller"></home-page>
			<sites-page v-else-if="currentPage.name === 'sites'" :controller="$refs.controller"></sites-page>
			<publish-page v-else-if="currentPage.name === 'publish'" :controller="$refs.controller"></publish-page>
			<update-site-page v-else-if="currentPage.name === 'update-site'" :controller="$refs.controller"></update-site-page>

			<login-page v-if="currentPage.name === 'login'"></login-page>
			<signup-page v-if="currentPage.name === 'signup'"></signup-page>
			<plans-page v-else-if="currentPage.name === 'plans'"></plans-page>
			<account-page v-else-if="currentPage.name === 'account'"></account-page>
			<support-page v-else-if="currentPage.name === 'support'"></support-page>
			<component-page v-else-if="currentPage.name === 'component'" :params="currentPage.params"></component-page>

			<admin-page v-else-if="currentPage.name === 'admin' && isAdmin"></admin-page>

			<makers-notification v-if="notification.show" :message="notification.message" :slug="notification.slug" />
		</template>

		<makers-handle-images-renderless />
		<makers-handle-components-json-renderless />
		<makers-site-controller-renderless ref="controller" />
		<makers-get-initial-data-renderless @loading="(value) => isLoading = value" @status="(value) => siteStatus = value" @domain="(value) => siteDomain = value" />

		<makers-site-status-flash-card v-if="site !== null && showSiteStatusFlashCard" :site-status="siteStatus" :site-domain="siteDomain" />
	</div>
</template>

<script>
	import { handleEvent } from './figma/ui-message-handler';
	import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
	export default {
		data() {
			return {
				nodes: [],
				isLoading: true,

				// For Flash Card Management
				siteStatus: '',
				siteDomain: '',
			}
		},
		mounted() {
			handleEvent("setCurrentSelection", (value) => this.setCurrentSelection(value));
		},
		computed: {
			...mapState(['currentPage', 'currentSelection', 'site', 'pages', 'showSiteStatusFlashCard', 'notification']),
			...mapGetters(['isAdmin']),
		},
		watch: {
			pages(newVal) {
				if (newVal.length === 0 && this.site.site != null) {
					// this.setPages([]);
					//this.deleteSite(this.site.site.id);
				}
			}
		},
		methods: {
			...mapActions(['deleteSite']),
			...mapMutations(['setPages', 'setCurrentSelection', 'setIsPreviewWorkflow']),
		}
	}
</script>
