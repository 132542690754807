<template>
	<div class="makers-tutorials-tab">
		<template v-if="tutorials.length === 0">
			<div class="flex align-items-center justify-content-center">
				<div class="icon icon--spinner icon--black"></div>
			</div>
		</template>
		<template v-else>
			<div class="tutorials-wrapper">
				<a :href="`${baseDomain}/tutorials/${tutorial.slug}`" target="_blank" class="tutorial" v-for="(tutorial, index) in tutorials" :key="index">
					<div class="image-wrapper">
						<div class="image" :style="{'background-image': `url(${tutorial.image})`}">
							<!-- <div class="play-icon">
								<svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5002 31.1666c7.824 0 14.1666-6.3426 14.1666-14.1666 0-7.82405-6.3426-14.16669-14.1666-14.16669C9.67613 2.83331 3.3335 9.17595 3.3335 17c0 7.824 6.34263 14.1666 14.1667 14.1666Z" stroke="#BAC8D6" stroke-width="1.41667" stroke-linecap="round" stroke-linejoin="round"/><path d="m14.6665 11.3333 8.5 5.6667-8.5 5.6666V11.3333Z" stroke="#BAC8D6" stroke-width="1.41667" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</div> -->
						</div>
					</div>
					<div class="details">
						<div class="title">{{tutorial.title}}</div>
						<div class="description">{{tutorial.description}}</div>
					</div>
				</a>
			</div>
		</template>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	export default {
		data() {
			return {
				baseDomain: process.env.VUE_APP_URL
			}
		},
		mounted() {
			if (this.tutorials.length === 0) {
				this.getTutorials();
			}
		},
		computed: {
			...mapState(['tutorials']),
		},
		methods: {
			...mapMutations(['setTutorials']),

			async getTutorials() {
				const response = await axios.get('/tutorials');
				this.setTutorials(response.data);
			}
		}
	}
</script>
