<template>
    <div class="makers-publish-content makers-ready-to-publish">
        <template v-if="!isSubscribed">
            <div class="intro">
                <h1>Your site is ready!</h1>
                <h2>Please fill your payment details. Once it’s done we’ll publish your site immediately.</h2>
            </div>
            <section>
                <makers-credit-card :is-to-subscribe="true"></makers-credit-card>
            </section>
        </template>
        <template v-else>
            <div class="intro">
                <h1>Ready to publish!</h1>
                <h2>Preview your site before publishing. You can always update it directly in Figma.</h2>
                <section>
                    <div class="buttons-wrapper">
                        <div class="start"></div>
                        <div class="end">
                            <button class="button button--secondary" :disabled="isLoading" @click="handlePreview()">
                                <template v-if="!isLoading">
                                    Preview
                    				<div class="icon icon--small"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8.66667v4.00003c0 .3536-.1405.6927-.3905.9428-.2501.25-.5892.3905-.9428.3905H3.33333c-.35362 0-.69276-.1405-.94281-.3905C2.14048 13.3594 2 13.0203 2 12.6667V5.33333c0-.35362.14048-.69276.39052-.94281C2.64057 4.14048 2.97971 4 3.33333 4h4M10 2h4v4M6.66675 9.33333L14.0001 2" stroke="#4A4A4A" stroke-width="1.32493" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
                                </template>
                                <div v-else class="icon icon--spinner icon--black3"></div>
                            </button>
                            <button class="button button--primary" :disabled="isLoading" @click="handlePublish('publish')">Publish site • $12 / month</button>
                        </div>
                    </div>
                </section>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    export default {
		props: ['controller'],
        computed: {
            ...mapState(['site', 'isLoading']),
            ...mapGetters(['isSubscribed']),
        },
        methods: {
            ...mapActions(['publishSite', 'updateSubscriptionQuantity']),
            ...mapMutations(['setIsLoading', 'setIsPreviewWorkflow']),

			handlePreview() {
				this.setIsLoading(true);
				this.setIsPreviewWorkflow(true);
				this.controller.getAllNodesSaveInDatabaseAndOpenBrowser();
            },
			handlePublish(workflow) {
				this.setIsLoading(true);
				this.setIsPreviewWorkflow(false);
				this.controller.getAllNodesSaveInDatabaseAndOpenBrowser();

				if ( workflow === 'publish' ) {
					const response = this.updateSubscriptionQuantity({isIncrease: true});
                	if (response) setTimeout(() => this.$parent.currentStep++, 3000);
				} else if ( workflow === 'publish-from-credit-card' ) {
					setTimeout(() => this.$parent.currentStep++, 3000);
				}
			},
        }
    }
</script>
