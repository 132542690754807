<template>
    <div class="makers-auth-page">
        <makers-component-header header-name="Create account" back-page="home"/>
        <main>
            <div class="form-wrapper">
                <h1>Hey <span>Hi</span> <span>Hello</span> <span>Howdy</span></h1>
                <h2>Welcome to our home!</h2>
                <div v-if="hasRegisterError" class="errors-wrapper">
                    <div class="error type type--small" v-for="(error, index) in hasRegisterError" :key="index">
                        {{error}}
                    </div>
                </div>
                <form @submit.prevent="handleRegister()">
                    <div class="field">
                        <div class="input">
                            <input ref="emailRegisterInput" @input="removeErrors()" v-model="registerForm.name" type="text" class="input__field  input__with_border" placeholder="Name" required>
                        </div>
                    </div>
                    <div class="field">
                        <div class="input">
                            <input v-model="registerForm.email" @input="removeErrors()" type="email" class="input__field  input__with_border" placeholder="Email" required>
                        </div>
                    </div>
                    <div class="field">
                        <div class="input">
                            <input v-model="registerForm.password" @input="removeErrors()" type="password" class="input__field input__with_border" placeholder="Password" required>
                        </div>
                    </div>
                    <button type="submit" class="button button--primary" :disabled="isLoading">
                        <template v-if="!isLoading">Create account</template>
                        <div v-else class="icon icon--spinner icon--white"></div>
                    </button>
                </form>
                <p class="switch-text type type--small type--medium has-text-centered">Already have an account? <button class="button button--tertiary" @click.stop="setCurrentPage({name: 'login'})">Log in</button></p>
            </div>
            <div class="terms type has-text-centered">By clicking "Create account", you agree to our <a href="https://makers.so" target="_blank">Terms</a> and <a href="https://makers.so" target="_blank">Privacy Policy</a>.</div>
        </main>
    </div>
</template>
<script>

    import { mapActions, mapMutations } from 'vuex';
    import { dispatch } from '../figma/ui-message-handler';
    export default {
        data() {
            return {
                isLoading: false,
                hasRegisterError: false,
                registerForm: {
                    name: null,
                    email: null,
                    password: null,
                }
            }
        },
        mounted() {
            this.focusInput('emailRegisterInput');
        },
        methods: {
            ...mapActions(['createUser']),
            ...mapMutations(['setCurrentPage']),

            handleRegister() {
                this.isLoading = true;
                const body = {
                    name: this.registerForm.name,
                    email: this.registerForm.email,
                    password: this.registerForm.password,
                };
                this.createUser(body)
                    .then(() => {
                        dispatch("getFileKey");
                        this.setCurrentPage({name: 'home'});
						dispatch("notify", "✅ Welcome to Makers! It's great to have you.");
                    })
                    .catch((error) => {
                        this.hasRegisterError = Object.values(error.response.data.errors).flat();
                        this.isLoading = false;
                    });
            },

            removeErrors() {
                if (this.hasRegisterError) {
                    this.hasRegisterError = false;
                }
            },

            focusInput(ref) {
                setTimeout(() => {
                    if (this.$refs[ref] != null) {
                        this.$refs[ref].focus();
                    }
                }, 50);
            }
        }
    }
</script>
