<template>
    <div class="makers-auth-page">
        <makers-component-header header-name="Log in" back-page="home"/>
        <main>
            <div class="form-wrapper">
                <h1>Let’s log you in</h1>
                <h2>We’ve missed you!</h2>
                <p v-if="hasLoginError" class="errors-wrapper error type type--small">Invalid credentials. Double check and try again.</p>
                <form @submit.prevent="handleLogIn()">
                    <div class="field">
                        <div class="input">
                            <input ref="emailLoginInput" v-model="loginForm.email" @input="removeErrors()" type="email" class="input__field  input__with_border" placeholder="Email" required>
                        </div>
                    </div>
                    <div class="field">
                        <div class="input">
                            <input v-model="loginForm.password" @input="removeErrors()" type="password" class="input__field  input__with_border" placeholder="Password" required>
                        </div>
                    </div>
                    <button type="submit" class="button button--primary" :disabled="isLoading">
                        <template v-if="!isLoading">Log in</template>
                        <div v-else class="icon icon--spinner icon--white"></div>
                    </button>
                </form>
                <p class="switch-text type type--small type--medium has-text-centered">Don't have an account? <button class="button button--tertiary" @click.stop="setCurrentPage({name: 'signup'})">Create one</button></p>
            </div>
        </main>
    </div>
</template>
<script>
    import { mapActions, mapMutations } from 'vuex';
    import { dispatch } from '../figma/ui-message-handler';
    export default {
        data() {
            return {
                isLoading: false,
                hasLoginError: false,
                loginForm: {
                    email: null,
                    password: null,
                },
            }
        },
        mounted() {
            this.focusInput('emailLoginInput');
        },
        methods: {
            ...mapActions(['authenticateUser', 'getIsSubscribed']),
            ...mapMutations(['setCurrentPage']),

            handleLogIn() {
                this.isLoading = true;
                const body = {
                    email: this.loginForm.email,
                    password: this.loginForm.password,
                };
                this.authenticateUser(body)
                    .then(() => {
                        dispatch("getFileKey");
                        this.getIsSubscribed();
                        this.setCurrentPage({name: 'home'});
						dispatch("notify", "✅ Welcome back! You look awesome today 😎");
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.hasLoginError = true;
                    });
            },

            removeErrors() {
                if (this.hasLoginError) {
                    this.hasLoginError = false;
                }
            },

            focusInput(ref) {
                setTimeout(() => {
                    if (this.$refs[ref] != null) {
                        this.$refs[ref].focus();
                    }
                }, 50);
            }
        }
    }
</script>
