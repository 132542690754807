<template>
    <div class="makers-credit-card">
        <div id="card-element"><!-- A Stripe Element will be inserted here. --></div>
        <div v-if="errorMessage" class="error-message type type--xsmall" v-text="errorMessage"></div>
        <button
            ref="cardButton"
            @click="sendToStripe()"
            class="button button--primary"
            :data-secret="`${clientSecret}`"
            :disabled="isLoading || isDisabled"
        >
            <template v-if="!isLoading">
                {{ (isToSubscribe) ? `Publish site • $${price} / month` : 'Update Card' }}
            </template>
            <div v-else class="icon icon--spinner icon--white"></div>
        </button>
        <div v-if="isToSubscribe" class="disclaimer type type--xsmall">Your payment details will never touch our servers. You will be charged ${{price}} every month.</div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { loadStripe } from '@stripe/stripe-js';
    import { dispatch } from '../figma/ui-message-handler';
    export default {
        props: ['isToSubscribe'],
        data() {
            return {
                card: '',
                price: 12,
                stripe: '',
                intent: '',
				isDisabled: true,
                isLoading: false,
                errorMessage: null
            }
        },
        mounted() {
            this.getStripeIntent();
            this.setupStripeCardElement();
        },
        computed: {
            ...mapState(['site']),

            clientSecret() {
                return this.intent.client_secret;
            }
        },
        methods: {
            async getStripeIntent() {
                const response = await axios.get('/intent');
                this.intent = response.data.intent;
            },
            async setupStripeCardElement() {
                this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
                const elements = this.stripe.elements({
                    fonts: [{cssSrc: "https://rsms.me/inter/inter.css"}],
                    locale: 'auto'
                });
                this.card = elements.create("card", {style: this.getStripeElementStyles()});

                this.card.mount("#card-element");
				this.card.on('change', (event) => {
					if (event.complete) {
						// Enable payment button
						this.isDisabled = false;
					} else if (event.error) {
						// Show validation to customer
						this.errorMessage = event.error.message;
						this.isDisabled = true;
					} else {
						this.errorMessage = "";
						this.isDisabled = true;
					}
				});
            },
            async sendToStripe() {
                this.isLoading = true;
                const { setupIntent, error } = await this.stripe.confirmCardSetup(
                    this.clientSecret, {
                        payment_method: {card: this.card} // billing_details: { name: cardHolderName.value }
                    }
                );

                if (error) {
                    this.errorMessage = error.message;
                    this.isLoading = false;
                } else {
                    // The card has been verified successfully, send token to server
                    const body = JSON.stringify({paymentMethod: setupIntent.payment_method});
                    if (this.isToSubscribe) {
                        const result = await axios.post('/subscribe', body, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'} });
                        if (result) this.publishSite();
                        else {this.isLoading = false; dispatch("notify", "❌ Something went wrong updating your credit card")}
                    } else {
                        const result = await axios.post('/update-card', body, {headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'} });
                        if (result) dispatch("notify", "✅  Card Updated Successfully");
                        this.isLoading = false;
                    }
                }
            },

            publishSite() {
                dispatch("notify", "✅ Yes! Let's create amazing websites together 🚀");
                this.$parent.handlePublish('publish-from-credit-card');
            },

            getStripeElementStyles() {
                return {
                    base: {
                        color: "#32325D",
                        fontWeight: 500,
                        fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
                        fontSize: "16px",
                        fontSmoothing: "antialiased",
                        "::placeholder": {
                            color: "#CFD7DF"
                        }
                    },
                    invalid: {
                        color: "#E25950"
                    }
                };
            }
        }
    }
</script>
