export const main = () => {
	return mainJson;
}
const image1 = `https://images.unsplash.com/photo-1604076913837-52ab5629fba9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80`;
const mainJson = `[
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1587",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3161
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3161,
			"y": 40,
			"width": 486.666748046875,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1590",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3162.7216796875,
				"y": 44.70170593261719,
				"width": 113.266845703125,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1593",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3161.9375,
				"y": 104.09091186523438,
				"width": 438.3623046875,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1596",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3161.818115234375,
				"y": 297,
				"width": 446.34375,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "daabc7f58e434860155df8025c923543cf294c7a",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1588",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  526.6666259765625
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3667.66650390625
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 526.6666259765625,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3667.66650390625,
			"y": 40,
			"width": 486.66650390625,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1591",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3854.455810546875,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1594",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3693.463623046875,
				"y": 104.09091186523438,
				"width": 436.265380859375,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1597",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3688.432373046875,
				"y": 297,
				"width": 445.752685546875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "6fc60f3e20c210a61683606592d9522b1e348ce3",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1589",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  1033.333251953125
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4174.3330078125
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 1033.333251953125,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 4174.3330078125,
			"y": 40,
			"width": 486.66650390625,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1592",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 4546.18994140625,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1595",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 4222.89306640625,
				"y": 104.09091186523438,
				"width": 436.43212890625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1598",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 4214.45556640625,
				"y": 297,
				"width": 445.7529296875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "22c6e3c72b28ba7eb1e1d13f1fc5291db9f0da23",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_TEXT_CONTENT",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "FIXED",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1587",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3161
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3161,
			"y": 40,
			"width": 486.666748046875,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1590",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3162.7216796875,
				"y": 44.70170593261719,
				"width": 113.266845703125,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1593",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3161.9375,
				"y": 104.09091186523438,
				"width": 438.3623046875,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1596",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3161.818115234375,
				"y": 297,
				"width": 446.34375,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "daabc7f58e434860155df8025c923543cf294c7a",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1588",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  526.6666259765625
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3667.66650390625
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 526.6666259765625,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3667.66650390625,
			"y": 40,
			"width": 486.66650390625,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1591",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3854.455810546875,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1594",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3693.463623046875,
				"y": 104.09091186523438,
				"width": 436.265380859375,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1597",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3688.432373046875,
				"y": 297,
				"width": 445.752685546875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "6fc60f3e20c210a61683606592d9522b1e348ce3",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1589",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  1033.333251953125
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4174.3330078125
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 1033.333251953125,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 4174.3330078125,
			"y": 40,
			"width": 486.66650390625,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1592",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 4546.18994140625,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1595",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 4222.89306640625,
				"y": 104.09091186523438,
				"width": 436.43212890625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1598",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 4214.45556640625,
				"y": 297,
				"width": 445.7529296875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "22c6e3c72b28ba7eb1e1d13f1fc5291db9f0da23",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_TEXT_CONTENT",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "FIXED",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1531",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  640
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 640,
			"y": 40,
			"width": 115,
			"height": 46
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1535",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1531"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  668
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 669.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "944a08e4c272c40cf1854ea2cab592e3944679fc",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "1",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1532",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  155
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  775
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 155,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 759,
			"y": 28,
			"width": 147,
			"height": 78
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1536",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1532"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  803
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 804.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b3c32acfb1b3100ad599987cf63189af75a6f7b9",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1533",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  290
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  910
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 290,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 910,
			"y": 40,
			"width": 115,
			"height": 46
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1537",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1533"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  938
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 939.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "58c1ced245df778b1ffb10b58ab39a28335bbdae",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "1",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1534",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  425
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1045
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 425,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 1029,
			"y": 28,
			"width": 147,
			"height": 78
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1538",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1534"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1073
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 1074.6553955078125,
				"y": 56.909088134765625,
				"width": 55.8233642578125,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "4d42a07aabef77008e9e315069c4a91f97735d61",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BUTTON",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1531",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  640
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 640,
			"y": 40,
			"width": 115,
			"height": 46
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1535",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1531"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  668
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 669.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "944a08e4c272c40cf1854ea2cab592e3944679fc",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "1",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1532",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  155
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  775
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 155,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 759,
			"y": 28,
			"width": 147,
			"height": 78
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1536",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1532"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  803
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 804.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b3c32acfb1b3100ad599987cf63189af75a6f7b9",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1533",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  290
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  910
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 290,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 910,
			"y": 40,
			"width": 115,
			"height": 46
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1537",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1533"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  938
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 939.6554565429688,
				"y": 56.909088134765625,
				"width": 55.82330322265625,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "58c1ced245df778b1ffb10b58ab39a28335bbdae",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "1",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "389:1534",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  425
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1045
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 425,
		  "y": 20,
		  "width": 115,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 1029,
			"y": 28,
			"width": 147,
			"height": 78
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "389:1538",
			  "listSpacing": 0,
			  "parent": {
				"id": "389:1534"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  3
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1073
				],
				[
				  0,
				  1,
				  43
				]
			  ],
			  "x": 28,
			  "y": 3,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 1074.6553955078125,
				"y": 56.909088134765625,
				"width": 55.8233642578125,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 3,
		  "paddingBottom": 3,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "4d42a07aabef77008e9e315069c4a91f97735d61",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BUTTON",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-buttons-group",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1600",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Direction=Horizontal",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4741
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 246,
		  "height": 46,
		  "absoluteRenderBounds": {
			"x": 4741,
			"y": 40,
			"width": 246,
			"height": 46
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1602",
			  "parent": {
				"id": "392:1600"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4741
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 115,
			  "height": 46,
			  "absoluteRenderBounds": {
				"x": 4741,
				"y": 40,
				"width": 115,
				"height": 46
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I392:1602;389:1535",
				  "listSpacing": 0,
				  "parent": {
					"id": "392:1602"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  3
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  4769
					],
					[
					  0,
					  1,
					  43
					]
				  ],
				  "x": 28,
				  "y": 3,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 4770.6552734375,
					"y": 56.909088134765625,
					"width": 55.82373046875,
					"height": 13.282669067382812
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0.4117647111415863,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 3,
			  "paddingBottom": 3,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "389:1531"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "1",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Primary"
			  },
			  "type": "INSTANCE",
			  "makersVariantProps": "0"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1606",
			  "parent": {
				"id": "392:1600"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  131
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4872
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 131,
			  "y": 0,
			  "width": 115,
			  "height": 46,
			  "absoluteRenderBounds": {
				"x": 4872,
				"y": 40,
				"width": 115,
				"height": 46
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I392:1606;389:1537",
				  "listSpacing": 0,
				  "parent": {
					"id": "392:1606"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  3
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  4900
					],
					[
					  0,
					  1,
					  43
					]
				  ],
				  "x": 28,
				  "y": 3,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 4901.6552734375,
					"y": 56.909088134765625,
					"width": 55.82373046875,
					"height": 13.282669067382812
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 3,
			  "paddingBottom": 3,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "389:1533"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "1",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Secondary"
			  },
			  "type": "INSTANCE",
			  "makersVariantProps": "2"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "95fe574b0e0fe29cd02ca63f9e6b591101caab42",
		  "reactions": [],
		  "variantProperties": {
			"Direction": "Horizontal"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-buttons-group",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1601",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Direction=Vertical",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  86
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4741
			],
			[
			  0,
			  1,
			  106
			]
		  ],
		  "x": 20,
		  "y": 86,
		  "width": 115,
		  "height": 108,
		  "absoluteRenderBounds": {
			"x": 4741,
			"y": 106,
			"width": 115,
			"height": 108
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1604",
			  "parent": {
				"id": "392:1601"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4741
				],
				[
				  0,
				  1,
				  106
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 115,
			  "height": 46,
			  "absoluteRenderBounds": {
				"x": 4741,
				"y": 106,
				"width": 115,
				"height": 46
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I392:1604;389:1535",
				  "listSpacing": 0,
				  "parent": {
					"id": "392:1604"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  3
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  4769
					],
					[
					  0,
					  1,
					  109
					]
				  ],
				  "x": 28,
				  "y": 3,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 4770.6552734375,
					"y": 122.90908813476562,
					"width": 55.82373046875,
					"height": 13.282669067382812
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0.4117647111415863,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 3,
			  "paddingBottom": 3,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "389:1531"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "1",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Primary"
			  },
			  "type": "INSTANCE",
			  "makersVariantProps": "0"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1608",
			  "parent": {
				"id": "392:1601"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  62
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4741
				],
				[
				  0,
				  1,
				  168
				]
			  ],
			  "x": 0,
			  "y": 62,
			  "width": 115,
			  "height": 46,
			  "absoluteRenderBounds": {
				"x": 4741,
				"y": 168,
				"width": 115,
				"height": 46
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I392:1608;389:1537",
				  "listSpacing": 0,
				  "parent": {
					"id": "392:1608"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  3
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  4769
					],
					[
					  0,
					  1,
					  171
					]
				  ],
				  "x": 28,
				  "y": 3,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 4770.6552734375,
					"y": 184.90908813476562,
					"width": 55.82373046875,
					"height": 13.282669067382812
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 3,
			  "paddingBottom": 3,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "389:1533"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "1",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Secondary"
			  },
			  "type": "INSTANCE",
			  "makersVariantProps": "2"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "6159a4b7a61d2fe3cc92197a2088473f78717fd8",
		  "reactions": [],
		  "variantProperties": {
			"Direction": "Vertical"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BUTTONS_GROUP",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1587",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3161
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3161,
			"y": 40,
			"width": 486.666748046875,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1590",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3162.7216796875,
				"y": 44.70170593261719,
				"width": 113.266845703125,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1593",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3161.9375,
				"y": 104.09091186523438,
				"width": 438.3623046875,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1596",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1587"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3161.818115234375,
				"y": 297,
				"width": 446.34375,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "daabc7f58e434860155df8025c923543cf294c7a",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1588",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  526.6666259765625
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3667.66650390625
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 526.6666259765625,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3667.66650390625,
			"y": 40,
			"width": 486.66650390625,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1591",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3854.455810546875,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1594",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3693.463623046875,
				"y": 104.09091186523438,
				"width": 436.265380859375,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1597",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1588"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3667.66650390625
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3688.432373046875,
				"y": 297,
				"width": 445.752685546875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "6fc60f3e20c210a61683606592d9522b1e348ce3",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1589",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  1033.333251953125
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4174.3330078125
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 1033.333251953125,
		  "y": 20,
		  "width": 486.6666564941406,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 4174.3330078125,
			"y": 40,
			"width": 486.66650390625,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1592",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 486.6666564941406,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 4546.18994140625,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1595",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 486.6666564941406,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 4222.89306640625,
				"y": 104.09091186523438,
				"width": 436.43212890625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1598",
			  "listSpacing": 0,
			  "parent": {
				"id": "392:1589"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4174.3330078125
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 486.6666564941406,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 4214.45556640625,
				"y": 297,
				"width": 445.7529296875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "22c6e3c72b28ba7eb1e1d13f1fc5291db9f0da23",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_TEXT_CONTENT",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "FIXED",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-hero",
		  "makersComponent": "hero",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1613",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Split",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  5067
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 1440,
		  "height": 519,
		  "absoluteRenderBounds": {
			"x": 5067,
			"y": 40,
			"width": 1440,
			"height": 519
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "columns",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1618",
			  "parent": {
				"id": "392:1613"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  120
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  5187
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 120,
			  "y": 0,
			  "width": 1200,
			  "height": 519,
			  "absoluteRenderBounds": {
				"x": 5187,
				"y": 40,
				"width": 1200,
				"height": 519
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1623",
				  "parent": {
					"id": "392:1618"
				  },
				  "name": "Text Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  65
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5187
					],
					[
					  0,
					  1,
					  105
					]
				  ],
				  "x": 0,
				  "y": 65,
				  "width": 500,
				  "height": 389,
				  "absoluteRenderBounds": {
					"x": 5187,
					"y": 105,
					"width": 500,
					"height": 389
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1633",
					  "parent": {
						"id": "392:1623"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  10
						],
						[
						  0,
						  1,
						  10
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5197
						],
						[
						  0,
						  1,
						  115
						]
					  ],
					  "x": 10,
					  "y": 10,
					  "width": 480,
					  "height": 299,
					  "absoluteRenderBounds": {
						"x": 5197,
						"y": 115,
						"width": 480,
						"height": 299
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1633;392:1590",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1633"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5197
							],
							[
							  0,
							  1,
							  115
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 480,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 5198.7216796875,
							"y": 119.70170593261719,
							"width": 113.26708984375,
							"height": 18.5625
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1633;392:1593",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1633"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5197
							],
							[
							  0,
							  1,
							  168
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 480,
						  "height": 174,
						  "absoluteRenderBounds": {
							"x": 5197.9375,
							"y": 179.09091186523438,
							"width": 438.3623046875,
							"height": 161.272705078125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to make and publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1633;392:1596",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1633"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  251
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5197
							],
							[
							  0,
							  1,
							  366
							]
						  ],
						  "x": 0,
						  "y": 251,
						  "width": 480,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 5197.818359375,
							"y": 372,
							"width": 446.34375,
							"height": 39.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1587"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Left"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "makers-buttons-group",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1650",
					  "parent": {
						"id": "392:1623"
					  },
					  "name": "MAKERS_BUTTONS_GROUP",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  10
						],
						[
						  0,
						  1,
						  333
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5197
						],
						[
						  0,
						  1,
						  438
						]
					  ],
					  "x": 10,
					  "y": 333,
					  "width": 246,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 5197,
						"y": 438,
						"width": 246,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1650;392:1602",
						  "parent": {
							"id": "392:1650"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5197
							],
							[
							  0,
							  1,
							  438
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 5197,
							"y": 438,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1650;392:1602;389:1535",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1650;392:1602"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  5225
								],
								[
								  0,
								  1,
								  441
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 5226.6552734375,
								"y": 454.9090881347656,
								"width": 55.82373046875,
								"height": 13.282684326171875
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0.4117647111415863,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1531"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Primary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1650;392:1606",
						  "parent": {
							"id": "392:1650"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  131
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5328
							],
							[
							  0,
							  1,
							  438
							]
						  ],
						  "x": 131,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 5328,
							"y": 438,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1650;392:1606;389:1537",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1650;392:1606"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.008333325386047363,
									"g": 0.28684401512145996,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  5356
								],
								[
								  0,
								  1,
								  441
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 5357.6552734375,
								"y": 454.9090881347656,
								"width": 55.82373046875,
								"height": 13.282684326171875
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.008333325386047363,
								"g": 0.28684401512145996,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1533"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Secondary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "2"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1600"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Direction": "Horizontal"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 10,
				  "paddingRight": 10,
				  "paddingTop": 10,
				  "paddingBottom": 10,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1660",
				  "parent": {
					"id": "392:1618"
				  },
				  "name": "Image Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  540
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5727
					],
					[
					  0,
					  1,
					  40
					]
				  ],
				  "x": 540,
				  "y": 0,
				  "width": 660,
				  "height": 519,
				  "absoluteRenderBounds": {
					"x": 5727,
					"y": 40,
					"width": 660,
					"height": 519
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/fa73f9778ca6a035b67d5e72960492231a74bf88.jpg",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1662",
					  "parent": {
						"id": "392:1660"
					  },
					  "name": "Image",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5727
						],
						[
						  0,
						  1,
						  40
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 660,
					  "height": 519,
					  "absoluteRenderBounds": {
						"x": 5727,
						"y": 40,
						"width": 660,
						"height": 519
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "IMAGE",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "scaleMode": "FILL",
						  "imageTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "scalingFactor": 0.5,
						  "rotation": 0,
						  "filters": {
							"exposure": 0,
							"contrast": 0,
							"saturation": 0,
							"temperature": 0,
							"tint": 0,
							"highlights": 0,
							"shadows": 0
						  },
						  "imageHash": "${image1}"
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "SPACE_BETWEEN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "2dc72439f9b306cc27406f27ba34c7c2c4c0bf0e",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Split"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-hero",
		  "makersComponent": "hero",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1614",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Split Inverted",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  563
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  5067
			],
			[
			  0,
			  1,
			  583
			]
		  ],
		  "x": 20,
		  "y": 563,
		  "width": 1440,
		  "height": 519,
		  "absoluteRenderBounds": {
			"x": 5067,
			"y": 583,
			"width": 1440,
			"height": 519
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "columns",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1619",
			  "parent": {
				"id": "392:1614"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  120
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  5187
				],
				[
				  0,
				  1,
				  583
				]
			  ],
			  "x": 120,
			  "y": 0,
			  "width": 1200,
			  "height": 519,
			  "absoluteRenderBounds": {
				"x": 5187,
				"y": 583,
				"width": 1200,
				"height": 519
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1624",
				  "parent": {
					"id": "392:1619"
				  },
				  "name": "Image Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5187
					],
					[
					  0,
					  1,
					  583
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 660,
				  "height": 519,
				  "absoluteRenderBounds": {
					"x": 5187,
					"y": 583,
					"width": 660,
					"height": 519
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/fa73f9778ca6a035b67d5e72960492231a74bf88.png",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1637",
					  "parent": {
						"id": "392:1624"
					  },
					  "name": "Image",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5187
						],
						[
						  0,
						  1,
						  583
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 660,
					  "height": 519,
					  "absoluteRenderBounds": {
						"x": 5187,
						"y": 583,
						"width": 660,
						"height": 519
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "IMAGE",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "scaleMode": "FILL",
						  "imageTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "scalingFactor": 0.5,
						  "rotation": 0,
						  "filters": {
							"exposure": 0,
							"contrast": 0,
							"saturation": 0,
							"temperature": 0,
							"tint": 0,
							"highlights": 0,
							"shadows": 0
						  },
						  "imageHash": "${image1}"
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1669",
				  "parent": {
					"id": "392:1619"
				  },
				  "name": "Text Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  700
					],
					[
					  0,
					  1,
					  65
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5887
					],
					[
					  0,
					  1,
					  648
					]
				  ],
				  "x": 700,
				  "y": 65,
				  "width": 500,
				  "height": 389,
				  "absoluteRenderBounds": {
					"x": 5887,
					"y": 648,
					"width": 500,
					"height": 389
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1673",
					  "parent": {
						"id": "392:1669"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  10
						],
						[
						  0,
						  1,
						  10
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5897
						],
						[
						  0,
						  1,
						  658
						]
					  ],
					  "x": 10,
					  "y": 10,
					  "width": 480,
					  "height": 299,
					  "absoluteRenderBounds": {
						"x": 5897,
						"y": 658,
						"width": 480,
						"height": 299
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1673;392:1592",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1673"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5897
							],
							[
							  0,
							  1,
							  658
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 480,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 6262.1904296875,
							"y": 662.7017211914062,
							"width": 113.26708984375,
							"height": 18.5625
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "RIGHT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1673;392:1595",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1673"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5897
							],
							[
							  0,
							  1,
							  711
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 480,
						  "height": 174,
						  "absoluteRenderBounds": {
							"x": 5938.8935546875,
							"y": 722.0908813476562,
							"width": 436.431640625,
							"height": 161.27276611328125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to make and publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "RIGHT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1673;392:1598",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1673"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  251
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5897
							],
							[
							  0,
							  1,
							  909
							]
						  ],
						  "x": 0,
						  "y": 251,
						  "width": 480,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 5930.4560546875,
							"y": 915,
							"width": 445.7529296875,
							"height": 39.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "RIGHT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MAX",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1589"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Right"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "2"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "makers-buttons-group",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1685",
					  "parent": {
						"id": "392:1669"
					  },
					  "name": "MAKERS_BUTTONS_GROUP",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  244
						],
						[
						  0,
						  1,
						  333
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  6131
						],
						[
						  0,
						  1,
						  981
						]
					  ],
					  "x": 244,
					  "y": 333,
					  "width": 246,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 6131,
						"y": 981,
						"width": 246,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1685;392:1602",
						  "parent": {
							"id": "392:1685"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  6131
							],
							[
							  0,
							  1,
							  981
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 6131,
							"y": 981,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1685;392:1602;389:1535",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1685;392:1602"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  6159
								],
								[
								  0,
								  1,
								  984
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 6160.6552734375,
								"y": 997.9091186523438,
								"width": 55.82373046875,
								"height": 13.28265380859375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0.4117647111415863,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1531"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Primary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1685;392:1606",
						  "parent": {
							"id": "392:1685"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  131
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  6262
							],
							[
							  0,
							  1,
							  981
							]
						  ],
						  "x": 131,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 6262,
							"y": 981,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1685;392:1606;389:1537",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1685;392:1606"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.008333325386047363,
									"g": 0.28684401512145996,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  6290
								],
								[
								  0,
								  1,
								  984
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 6291.6552734375,
								"y": 997.9091186523438,
								"width": 55.82373046875,
								"height": 13.28265380859375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.008333325386047363,
								"g": 0.28684401512145996,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1533"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Secondary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "2"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1600"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Direction": "Horizontal"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 10,
				  "paddingRight": 10,
				  "paddingTop": 10,
				  "paddingBottom": 10,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MAX",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "SPACE_BETWEEN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "199ca7a17e5876aa44f103fc5d325a257690ec36",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Split Inverted"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-hero",
		  "makersComponent": "hero",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1615",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Centered",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  1106
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  5067
			],
			[
			  0,
			  1,
			  1126
			]
		  ],
		  "x": 20,
		  "y": 1106,
		  "width": 1440,
		  "height": 383,
		  "absoluteRenderBounds": {
			"x": 5067,
			"y": 1126,
			"width": 1440,
			"height": 383
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1620",
			  "parent": {
				"id": "392:1615"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  320
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  5387
				],
				[
				  0,
				  1,
				  1126
				]
			  ],
			  "x": 320,
			  "y": 0,
			  "width": 800,
			  "height": 383,
			  "absoluteRenderBounds": {
				"x": 5387,
				"y": 1126,
				"width": 800,
				"height": 383
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1625",
				  "parent": {
					"id": "392:1620"
				  },
				  "name": "MAKERS_TEXT_CONTENT",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5387
					],
					[
					  0,
					  1,
					  1174
					]
				  ],
				  "x": 0,
				  "y": 48,
				  "width": 800,
				  "height": 217,
				  "absoluteRenderBounds": {
					"x": 5387,
					"y": 1174,
					"width": 800,
					"height": 217
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I392:1625;392:1591",
					  "listSpacing": 0,
					  "parent": {
						"id": "392:1625"
					  },
					  "name": "Education",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.03947368264198303,
							"g": 0.16421058773994446,
							"b": 0.2605263292789459
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5387
						],
						[
						  0,
						  1,
						  1174
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 800,
					  "height": 29,
					  "absoluteRenderBounds": {
						"x": 5730.4560546875,
						"y": 1178.70166015625,
						"width": 113.26708984375,
						"height": 18.5625
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Education",
					  "hasMissingFont": false,
					  "fontSize": 24,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I392:1625;392:1594",
					  "listSpacing": 0,
					  "parent": {
						"id": "392:1625"
					  },
					  "name": "Learn how to make and publish sites with Figma",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0,
							"g": 0,
							"b": 0
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  53
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5387
						],
						[
						  0,
						  1,
						  1227
						]
					  ],
					  "x": 0,
					  "y": 53,
					  "width": 800,
					  "height": 116,
					  "absoluteRenderBounds": {
						"x": 5427.3388671875,
						"y": 1236.0965576171875,
						"width": 719.556640625,
						"height": 105.26708984375
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Learn how to make and publish sites with Figma",
					  "hasMissingFont": false,
					  "fontSize": 48,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "AUTO"
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I392:1625;392:1597",
					  "listSpacing": 0,
					  "parent": {
						"id": "392:1625"
					  },
					  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.03947368264198303,
							"g": 0.16421058773994446,
							"b": 0.2605263292789459
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  193
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5387
						],
						[
						  0,
						  1,
						  1367
						]
					  ],
					  "x": 0,
					  "y": 193,
					  "width": 800,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 5427.7685546875,
						"y": 1373,
						"width": 718.69287109375,
						"height": 15.45458984375
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
					  "hasMissingFont": false,
					  "fontSize": 16,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PERCENT",
						"value": 150
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Regular"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "392:1588"
				  },
				  "scaleFactor": 1,
				  "reactions": [],
				  "variantProperties": {
					"Align": "Center"
				  },
				  "type": "INSTANCE",
				  "makersVariantProps": "1"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "makers-buttons-group",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1645",
				  "parent": {
					"id": "392:1620"
				  },
				  "name": "MAKERS_BUTTONS_GROUP",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  277
					],
					[
					  0,
					  1,
					  289
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5664
					],
					[
					  0,
					  1,
					  1415
					]
				  ],
				  "x": 277,
				  "y": 289,
				  "width": 246,
				  "height": 46,
				  "absoluteRenderBounds": {
					"x": 5664,
					"y": 1415,
					"width": 246,
					"height": 46
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I392:1645;392:1602",
					  "parent": {
						"id": "392:1645"
					  },
					  "name": "MAKERS_BUTTON",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5664
						],
						[
						  0,
						  1,
						  1415
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 115,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 5664,
						"y": 1415,
						"width": 115,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1645;392:1602;389:1535",
						  "listSpacing": 0,
						  "parent": {
							"id": "I392:1645;392:1602"
						  },
						  "name": "Button",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  28
							],
							[
							  0,
							  1,
							  3
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5692
							],
							[
							  0,
							  1,
							  1418
							]
						  ],
						  "x": 28,
						  "y": 3,
						  "width": 59,
						  "height": 40,
						  "absoluteRenderBounds": {
							"x": 5693.6552734375,
							"y": 1431.9090576171875,
							"width": 55.82373046875,
							"height": 13.28271484375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Button",
						  "hasMissingFont": false,
						  "fontSize": 18,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PIXELS",
							"value": 40
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0,
							"g": 0.4117647111415863,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 10,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 10,
					  "topRightRadius": 10,
					  "bottomLeftRadius": 10,
					  "bottomRightRadius": 10,
					  "paddingLeft": 28,
					  "paddingRight": 28,
					  "paddingTop": 3,
					  "paddingBottom": 3,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "389:1531"
					  },
					  "scaleFactor": 1,
					  "reactions": [
						{
						  "action": {
							"type": "NODE",
							"destinationId": "1",
							"navigation": "CHANGE_TO",
							"transition": {
							  "type": "SMART_ANIMATE",
							  "easing": {
								"type": "EASE_IN_AND_OUT"
							  },
							  "duration": 0.30000001192092896
							},
							"preserveScrollPosition": false
						  },
						  "trigger": {
							"type": "ON_HOVER"
						  }
						}
					  ],
					  "variantProperties": {
						"State": "Default",
						"Type": "Primary"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I392:1645;392:1606",
					  "parent": {
						"id": "392:1645"
					  },
					  "name": "MAKERS_BUTTON",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  131
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5795
						],
						[
						  0,
						  1,
						  1415
						]
					  ],
					  "x": 131,
					  "y": 0,
					  "width": 115,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 5795,
						"y": 1415,
						"width": 115,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1645;392:1606;389:1537",
						  "listSpacing": 0,
						  "parent": {
							"id": "I392:1645;392:1606"
						  },
						  "name": "Button",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.008333325386047363,
								"g": 0.28684401512145996,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  28
							],
							[
							  0,
							  1,
							  3
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5823
							],
							[
							  0,
							  1,
							  1418
							]
						  ],
						  "x": 28,
						  "y": 3,
						  "width": 59,
						  "height": 40,
						  "absoluteRenderBounds": {
							"x": 5824.6552734375,
							"y": 1431.9090576171875,
							"width": 55.82373046875,
							"height": 13.28271484375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Button",
						  "hasMissingFont": false,
						  "fontSize": 18,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PIXELS",
							"value": 40
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "WIDTH_AND_HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.008333325386047363,
							"g": 0.28684401512145996,
							"b": 1
						  }
						}
					  ],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 10,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 10,
					  "topRightRadius": 10,
					  "bottomLeftRadius": 10,
					  "bottomRightRadius": 10,
					  "paddingLeft": 28,
					  "paddingRight": 28,
					  "paddingTop": 3,
					  "paddingBottom": 3,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "389:1533"
					  },
					  "scaleFactor": 1,
					  "reactions": [
						{
						  "action": {
							"type": "NODE",
							"destinationId": "1",
							"navigation": "CHANGE_TO",
							"transition": {
							  "type": "SMART_ANIMATE",
							  "easing": {
								"type": "EASE_IN_AND_OUT"
							  },
							  "duration": 0.30000001192092896
							},
							"preserveScrollPosition": false
						  },
						  "trigger": {
							"type": "ON_HOVER"
						  }
						}
					  ],
					  "variantProperties": {
						"State": "Default",
						"Type": "Secondary"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "2"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "392:1600"
				  },
				  "scaleFactor": 1,
				  "reactions": [],
				  "variantProperties": {
					"Direction": "Horizontal"
				  },
				  "type": "INSTANCE",
				  "makersVariantProps": "0"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 48,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "afa17d493d990e9a14bfa8533153914fe561b455",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Centered"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-hero",
		  "makersComponent": "hero",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1616",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Stack",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  1513
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  5067
			],
			[
			  0,
			  1,
			  1533
			]
		  ],
		  "x": 20,
		  "y": 1513,
		  "width": 1440,
		  "height": 931,
		  "absoluteRenderBounds": {
			"x": 5067,
			"y": 1533,
			"width": 1440,
			"height": 931
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1621",
			  "parent": {
				"id": "392:1616"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  220
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  5287
				],
				[
				  0,
				  1,
				  1533
				]
			  ],
			  "x": 220,
			  "y": 0,
			  "width": 1000,
			  "height": 931,
			  "absoluteRenderBounds": {
				"x": 5287,
				"y": 1533,
				"width": 1000,
				"height": 931
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "div",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1629",
				  "parent": {
					"id": "392:1621"
				  },
				  "name": "Text Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  100
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5387
					],
					[
					  0,
					  1,
					  1533
					]
				  ],
				  "x": 100,
				  "y": 0,
				  "width": 800,
				  "height": 383,
				  "absoluteRenderBounds": {
					"x": 5387,
					"y": 1533,
					"width": 800,
					"height": 383
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1638",
					  "parent": {
						"id": "392:1629"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  48
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5387
						],
						[
						  0,
						  1,
						  1581
						]
					  ],
					  "x": 0,
					  "y": 48,
					  "width": 800,
					  "height": 217,
					  "absoluteRenderBounds": {
						"x": 5387,
						"y": 1581,
						"width": 800,
						"height": 217
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1638;392:1591",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1638"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5387
							],
							[
							  0,
							  1,
							  1581
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 800,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 5730.4560546875,
							"y": 1585.70166015625,
							"width": 113.26708984375,
							"height": 18.5625
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1638;392:1594",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1638"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5387
							],
							[
							  0,
							  1,
							  1634
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 800,
						  "height": 116,
						  "absoluteRenderBounds": {
							"x": 5427.3388671875,
							"y": 1643.0965576171875,
							"width": 719.556640625,
							"height": 105.26708984375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to make and publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1638;392:1597",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1638"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  193
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5387
							],
							[
							  0,
							  1,
							  1774
							]
						  ],
						  "x": 0,
						  "y": 193,
						  "width": 800,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 5427.7685546875,
							"y": 1780,
							"width": 718.69287109375,
							"height": 15.45458984375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1588"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "makers-buttons-group",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1655",
					  "parent": {
						"id": "392:1629"
					  },
					  "name": "MAKERS_BUTTONS_GROUP",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  277
						],
						[
						  0,
						  1,
						  289
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5664
						],
						[
						  0,
						  1,
						  1822
						]
					  ],
					  "x": 277,
					  "y": 289,
					  "width": 246,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 5664,
						"y": 1822,
						"width": 246,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1655;392:1602",
						  "parent": {
							"id": "392:1655"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5664
							],
							[
							  0,
							  1,
							  1822
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 5664,
							"y": 1822,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1655;392:1602;389:1535",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1655;392:1602"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  5692
								],
								[
								  0,
								  1,
								  1825
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 5693.6552734375,
								"y": 1838.9090576171875,
								"width": 55.82373046875,
								"height": 13.28271484375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0.4117647111415863,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1531"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Primary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1655;392:1606",
						  "parent": {
							"id": "392:1655"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  131
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5795
							],
							[
							  0,
							  1,
							  1822
							]
						  ],
						  "x": 131,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 5795,
							"y": 1822,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1655;392:1606;389:1537",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1655;392:1606"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.008333325386047363,
									"g": 0.28684401512145996,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  5823
								],
								[
								  0,
								  1,
								  1825
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 5824.6552734375,
								"y": 1838.9090576171875,
								"width": 55.82373046875,
								"height": 13.28271484375
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.008333325386047363,
								"g": 0.28684401512145996,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1533"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Secondary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "2"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1600"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Direction": "Horizontal"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 48,
				  "paddingBottom": 48,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1661",
				  "parent": {
					"id": "392:1621"
				  },
				  "name": "Image Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  383
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5287
					],
					[
					  0,
					  1,
					  1916
					]
				  ],
				  "x": 0,
				  "y": 383,
				  "width": 1000,
				  "height": 500,
				  "absoluteRenderBounds": {
					"x": 5287,
					"y": 1916,
					"width": 1000,
					"height": 500
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/fa73f9778ca6a035b67d5e72960492231a74bf88.png",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1663",
					  "parent": {
						"id": "392:1661"
					  },
					  "name": "Image",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5287
						],
						[
						  0,
						  1,
						  1916
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 1000,
					  "height": 500,
					  "absoluteRenderBounds": {
						"x": 5287,
						"y": 1916,
						"width": 1000,
						"height": 500
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "IMAGE",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "scaleMode": "FILL",
						  "imageTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "scalingFactor": 0.5,
						  "rotation": 0,
						  "filters": {
							"exposure": 0,
							"contrast": 0,
							"saturation": 0,
							"temperature": 0,
							"tint": 0,
							"highlights": 0,
							"shadows": 0
						  },
						  "imageHash": "${image1}"
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 12,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 12,
					  "topRightRadius": 12,
					  "bottomLeftRadius": 12,
					  "bottomRightRadius": 12,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 10,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 10,
				  "topRightRadius": 10,
				  "bottomLeftRadius": 10,
				  "bottomRightRadius": 10,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "ee3c3a0e810b285524d563e0172215c7db8989ba",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Stack"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-hero",
		  "makersComponent": "hero",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "392:1617",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Background Image",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  2468
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  5067
			],
			[
			  0,
			  1,
			  2488
			]
		  ],
		  "x": 20,
		  "y": 2468,
		  "width": 1440,
		  "height": 447,
		  "absoluteRenderBounds": {
			"x": 5067,
			"y": 2488,
			"width": 1440,
			"height": 447
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/fa73f9778ca6a035b67d5e72960492231a74bf88.png",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "392:1622",
			  "parent": {
				"id": "392:1617"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  220
				],
				[
				  0,
				  1,
				  32
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  5287
				],
				[
				  0,
				  1,
				  2520
				]
			  ],
			  "x": 220,
			  "y": 32,
			  "width": 1000,
			  "height": 383,
			  "absoluteRenderBounds": {
				"x": 5287,
				"y": 2520,
				"width": 1000,
				"height": 383
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "div",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "392:1664",
				  "parent": {
					"id": "392:1622"
				  },
				  "name": "Text Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  100
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  5387
					],
					[
					  0,
					  1,
					  2520
					]
				  ],
				  "x": 100,
				  "y": 0,
				  "width": 800,
				  "height": 383,
				  "absoluteRenderBounds": {
					"x": 5387,
					"y": 2520,
					"width": 800,
					"height": 383
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1665",
					  "parent": {
						"id": "392:1664"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  48
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5387
						],
						[
						  0,
						  1,
						  2568
						]
					  ],
					  "x": 0,
					  "y": 48,
					  "width": 800,
					  "height": 217,
					  "absoluteRenderBounds": {
						"x": 5387,
						"y": 2568,
						"width": 800,
						"height": 217
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1665;392:1591",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1665"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5387
							],
							[
							  0,
							  1,
							  2568
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 800,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 5730.4560546875,
							"y": 2572.70166015625,
							"width": 113.26708984375,
							"height": 18.5625
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1665;392:1594",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1665"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5387
							],
							[
							  0,
							  1,
							  2621
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 800,
						  "height": 116,
						  "absoluteRenderBounds": {
							"x": 5427.3388671875,
							"y": 2630.0966796875,
							"width": 719.556640625,
							"height": 105.266845703125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to make and publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1665;392:1597",
						  "listSpacing": 0,
						  "parent": {
							"id": "392:1665"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  193
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5387
							],
							[
							  0,
							  1,
							  2761
							]
						  ],
						  "x": 0,
						  "y": 193,
						  "width": 800,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 5427.7685546875,
							"y": 2767,
							"width": 718.69287109375,
							"height": 15.45458984375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1588"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "makers-buttons-group",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "392:1680",
					  "parent": {
						"id": "392:1664"
					  },
					  "name": "MAKERS_BUTTONS_GROUP",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  277
						],
						[
						  0,
						  1,
						  289
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  5664
						],
						[
						  0,
						  1,
						  2809
						]
					  ],
					  "x": 277,
					  "y": 289,
					  "width": 246,
					  "height": 46,
					  "absoluteRenderBounds": {
						"x": 5664,
						"y": 2809,
						"width": 246,
						"height": 46
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1680;392:1602",
						  "parent": {
							"id": "392:1680"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5664
							],
							[
							  0,
							  1,
							  2809
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 5664,
							"y": 2809,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1680;392:1602;389:1535",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1680;392:1602"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  5692
								],
								[
								  0,
								  1,
								  2812
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 5693.6552734375,
								"y": 2825.9091796875,
								"width": 55.82373046875,
								"height": 13.282470703125
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0.4117647111415863,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1531"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Primary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I392:1680;392:1606",
						  "parent": {
							"id": "392:1680"
						  },
						  "name": "MAKERS_BUTTON",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  131
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  5795
							],
							[
							  0,
							  1,
							  2809
							]
						  ],
						  "x": 131,
						  "y": 0,
						  "width": 115,
						  "height": 46,
						  "absoluteRenderBounds": {
							"x": 5795,
							"y": 2809,
							"width": 115,
							"height": 46
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I392:1680;392:1606;389:1537",
							  "listSpacing": 0,
							  "parent": {
								"id": "I392:1680;392:1606"
							  },
							  "name": "Button",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.008333325386047363,
									"g": 0.28684401512145996,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  28
								],
								[
								  0,
								  1,
								  3
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  5823
								],
								[
								  0,
								  1,
								  2812
								]
							  ],
							  "x": 28,
							  "y": 3,
							  "width": 59,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 5824.6552734375,
								"y": 2825.9091796875,
								"width": 55.82373046875,
								"height": 13.282470703125
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Button",
							  "hasMissingFont": false,
							  "fontSize": 18,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PIXELS",
								"value": 40
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "CENTER",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "WIDTH_AND_HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.008333325386047363,
								"g": 0.28684401512145996,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 10,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 10,
						  "topRightRadius": 10,
						  "bottomLeftRadius": 10,
						  "bottomRightRadius": 10,
						  "paddingLeft": 28,
						  "paddingRight": 28,
						  "paddingTop": 3,
						  "paddingBottom": 3,
						  "primaryAxisAlignItems": "CENTER",
						  "counterAxisAlignItems": "CENTER",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "389:1533"
						  },
						  "scaleFactor": 1,
						  "reactions": [
							{
							  "action": {
								"type": "NODE",
								"destinationId": "1",
								"navigation": "CHANGE_TO",
								"transition": {
								  "type": "SMART_ANIMATE",
								  "easing": {
									"type": "EASE_IN_AND_OUT"
								  },
								  "duration": 0.30000001192092896
								},
								"preserveScrollPosition": false
							  },
							  "trigger": {
								"type": "ON_HOVER"
							  }
							}
						  ],
						  "variantProperties": {
							"State": "Default",
							"Type": "Secondary"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "2"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "392:1600"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Direction": "Horizontal"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 48,
				  "paddingBottom": 48,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "IMAGE",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "scaleMode": "FILL",
				  "imageTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "scalingFactor": 0.5,
				  "rotation": 0,
				  "filters": {
					"exposure": 0,
					"contrast": 0,
					"saturation": 0,
					"temperature": 0,
					"tint": 0,
					"highlights": 0,
					"shadows": 0
				  },
				  "imageHash": "${image1}"
				},
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 0.30000001192092896,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 20,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 20,
			  "topRightRadius": 20,
			  "bottomLeftRadius": 20,
			  "bottomRightRadius": 20,
			  "paddingLeft": 24,
			  "paddingRight": 24,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 32,
		  "paddingBottom": 32,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "455c10e0390949a73ced8003c6f56de1b914d45b",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Background Image"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_MAIN",
	  "styles": {
		"itemSpacing": 24,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	}
  ]`;
