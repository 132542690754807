<template>
    <div class="makers-auth-page">
        <makers-component-header header-name="Support" back-page="home"/>
        <main>
            <div class="form-wrapper">
                <template v-if="showSuccessMessage">
                    <h1>Thank you.</h1>
                    <h2>Expect our answer within 24 hours.</h2>
                </template>
                <template v-else>
                    <h1>How can we help?</h1>
                    <h2>We are right here for you.</h2>
                </template>
                <div v-if="hasErrors" class="errors-wrapper">
                    <div class="error type type--small" v-for="(error, index) in hasErrors" :key="index">
                        {{error}}
                    </div>
                </div>
                <form v-if="!showSuccessMessage" @submit.prevent="handleSupportRequest()">
                    <div v-if="user.user == null" class="field">
                        <div class="input">
                            <input ref="emailLoginInput" v-model="supportForm.email" @input="removeErrors()" type="email" class="input__field  input__with_border" placeholder="Your Email" required>
                        </div>
                    </div>
                    <div class="field">
                        <div class="input">
                            <textarea ref="supportInput" @input="removeErrors()" v-model="supportForm.message" type="text" class="input__field  input__with_border" placeholder="Let us know how we can help..." required></textarea>
                        </div>
                    </div>
                    <button type="submit" class="button button--primary" :disabled="isLoading">
                        <template v-if="!isLoading">Send message</template>
                        <div v-else class="icon icon--spinner icon--white"></div>
                    </button>
                </form>
            </div>
        </main>
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
	import { dispatch } from '../figma/ui-message-handler';
    export default {
        data() {
            return {
                isLoading: false,
                hasErrors: false,
                showSuccessMessage: false,
                supportForm: {
                    email: null,
                    message: null,
                }
            }
        },
        mounted() {
            this.focusInput('supportInput');

            if (this.user.user != null) {
                this.supportForm.email = this.user.user.email;
            }
        },
        computed: {
            ...mapState(['user']),
        },
        methods: {
            ...mapActions(['sendSupportMessage']),

            handleSupportRequest() {
                this.isLoading = true;
                const body = {
                    email: this.supportForm.email,
                    message: this.supportForm.message,
                };
                this.sendSupportMessage(body)
                    .then(() => {
                        this.showSuccessMessage = true;
                        dispatch("notify", "✅ Thanks You. Message Sent Successfully");
                    }).catch((error) => {
                        this.hasErrors = Object.values(error.response.data.errors).flat();
                        this.isLoading = false;
                    });
            },

            removeErrors() {
                if (this.hasErrors) {
                    this.hasErrors = false;
                }
            },
            focusInput(ref) {
                setTimeout(() => {
                    if (this.$refs[ref] != null) {
                        this.$refs[ref].focus();
                    }
                }, 50);
            }
        }
    }
</script>
