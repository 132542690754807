<template>
    <div class="makers-header component">
        <makers-header-decoration></makers-header-decoration>
        <header>
            <makers-header-back-button :back-page="backPage" />
            <div class="has-text-centered type type--medium type--black">{{headerName}}</div>
            <makers-header-options-menu />
        </header>
    </div>
</template>
<script>
    export default {
        props: ['headerName', 'backPage'],
    }
</script>
