<template>
    <div class="makers-publish-content">
        <div class="intro">
            <h1>URL routes.</h1>
            <h2>Review your URLs. The home page will be the entry point of your site.</h2>
        </div>
        <section>
            <div class="section-title">Pages <span>{{this.pages.length}}</span></div>
            <div class="set-website-pages-url">
                <div class="page" v-for="(page, index) in pages" :key="index">
                    <div class="page-title">
                        {{page.title}}
                        <span v-if="homePage.id === page.id" class="tag tag--gray">Home Page</span>
                    </div>
                    <div class="end">
                        <div class="oval"></div>
                        <div class="domain">https://{{$parent.form.domain}}<template v-if="$parent.form.domain != null && $parent.form.domain != ''">/</template></div>
                        <div v-if="homePage.id !== page.id" class="url">
                            <input v-model="page.url" type="text" class="input__field  input__with_border" placeholder="Page URL" required>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data() {
            return {

            }
        },
        computed: {
            ...mapState(['pages', 'homePage']),
        },
    }
</script>