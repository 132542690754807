<template>
    <div class="header-buttons-wrapper" :class="{hide: pages.length <= 0, 'no-publish-btn': site.site == null}">
        <div class="preview">
            <template v-if="!isAuthenticated">
                <makers-authentication-dropdown :is-publish="false"></makers-authentication-dropdown>
            </template>
            <button v-else class="button button--primary" :disabled="isLoading" @click="handlePreview()">
                <template v-if="!isLoading">
                    Preview website
                    <!-- <div class="icon icon--small"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8.66667v4.00003c0 .3536-.1405.6927-.3905.9428-.2501.25-.5892.3905-.9428.3905H3.33333c-.35362 0-.69276-.1405-.94281-.3905C2.14048 13.3594 2 13.0203 2 12.6667V5.33333c0-.35362.14048-.69276.39052-.94281C2.64057 4.14048 2.97971 4 3.33333 4h4M10 2h4v4M6.66675 9.33333L14.0001 2" stroke="#FFF" stroke-width="1.32493" stroke-linecap="round" stroke-linejoin="round"/></svg></div> -->
                </template>
                <div v-else class="icon icon--spinner icon--white"></div>
            </button>
        </div>
        <!-- <div v-if="isPublished" class="publish">
            <template v-if="!isAuthenticated">
                <makers-authentication-dropdown :is-publish="true"></makers-authentication-dropdown>
            </template>
            <button v-else class="button button--primary" :disabled="isLoading" @click="handlePublish()" >Update</button>
        </div> -->

        <makers-rocket-loading-modal :is-loading="isLoading"></makers-rocket-loading-modal>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';
    export default {
		props: ['controller'],
        computed: {
            ...mapState(['isLoading', 'site', 'isPublished', 'pages']),
            ...mapGetters(['isAuthenticated']),
        },
        methods: {
            ...mapMutations(['setCurrentPage', 'setIsLoading', 'setIsPreviewWorkflow']),

			handlePreview() {
				this.setIsLoading(true);
				this.setIsPreviewWorkflow(true);
				this.controller.getAllNodesSaveInDatabaseAndOpenBrowser();
			},
            handlePublish() {
                const newPage = (this.isPublished) ? { name: 'update-site'} : { name: 'publish' };
                this.setCurrentPage(newPage);
            },
        }
    }
</script>
