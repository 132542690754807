<script>
	import { mapActions, mapMutations, mapState } from "vuex";
	import { dispatch, handleEvent } from "../../figma/ui-message-handler";
	export default {
		render() {},
		computed: {
			...mapState([
				"user",
				"site",
				"fonts",
				"domain",
				"fileKey",
				"documentName",
				"figmaFileJson",
				"isPreviewWorkflow",
			]),
		},
		methods: {
			...mapActions([
				"getSites",
				"createSite",
				"updateSite",
				"publishSite",
				"getSiteByFigmaId",
			]),
			...mapMutations(["setIsLoading", "setFigmaFileJson", "setNotification"]),

			async getAllNodesSaveInDatabaseAndOpenBrowser() {
				dispatch("getAllNodes");
				const vectorNodeType = await new Promise(resolve => handleEvent('vectorNode', value => resolve(value)));
				if (vectorNodeType != null) {
					this.setNotification({
						show: true,
						slug: this.slugAccordingToNodeType(vectorNodeType),
						message: this.messagesAccordingToNodeType(vectorNodeType) // You have Vector nodes that you are not exporting as SVG. Go to its parent, select it and toggle "Preview as SVG".
					});
					this.setIsLoading(false);
					return;
				}
				const nodes = await new Promise(resolve => handleEvent('setAllNodes', value => resolve(value) ));
				if (nodes) {
					// Get all makers pages
					const pagesTitles = nodes.filter(node => node.makersComponent === 'page').map(node => node.makersPageTitle);
					if ( this.hasDuplicates(pagesTitles) ) {
						this.setNotification({
							show: true,
							slug: null,
							message: 'You have one or more pages with the same name. Please change it - don\'t forget to click "Save"'
						});
						this.setIsLoading(false);
						return;
					}
					// If there's a page name duplicated, return
					this.setFigmaFileJson(nodes); // Save in Vuex
					this.saveInDatabaseAndOpenBrowser();
				}
			},
			async saveInDatabaseAndOpenBrowser() {
				const site = await this.getSiteByFigmaId(this.fileKey);
				if (site) {
					const body = this.getUpdateSitePayloadBody(site);
					const payload = { id: site.id, body };
					this.updateSite(payload)
						.then(() => {
							if (this.isPreviewWorkflow === true) {
								window.open(`${process.env.VUE_APP_URL}/s/${site.id}`, "_blank");
							} else {
								this.setPublishSite(site);
							}
							this.setIsLoading(false);
						})
						.catch((e) => {
							console.log("ERROR Updating Site: ", e);
							dispatch("notify", "❌ Something went wrong updating your site. Please try again 🙌");
							this.setIsLoading(false);
						});
				} else {
					const body = this.getCreateSitePayloadBody();
					this.createSite(body)
						.then((s) => {
							if (this.isPreviewWorkflow === true) {
								window.open(`${process.env.VUE_APP_URL}/s/${s.id}`, "_blank");
							} else {
								this.setPublishSite(s);
							}
							this.setIsLoading(false);
						})
						.catch(() => {
							dispatch("notify", "❌ Something went wrong. Please try again 🙌");
							this.setIsLoading(false);
						});
				}
			},

			setPublishSite(site) {
				if (this.site.sites == null || this.site.sites.length == 0) {
					this.getSites().then(() => {
						this.setSiteStatus("building", site.id);
						this.publishSite({ id: site.id });
					});
				} else {
					this.setSiteStatus("building", site.id);
					this.publishSite({ id: site.id });
				}
			},
			setSiteStatus(status, id) {
				const index = this.site.sites.findIndex((s) => s.id === id);
				this.$set(this.site.sites[index], "status", status);
			},

			/*
			|-------------------------------------
			| HELPERS
			|-------------------------------------
			*/
			hasDuplicates(array) { return (new Set(array)).size !== array.length },
			getPagesTitlesCount(names) { return names.reduce((a, b) => ({ ...a, [b]: (a[b] || 0) + 1 }), {}) },
			getDuplicatesArray(objects) { return Object.keys(objects).filter((a) => objects[a] > 1) },

			getUpdateSitePayloadBody(site) {
				const body = new FormData();
				body.append("name", this.documentName);
				body.append("json", JSON.stringify(this.figmaFileJson));
				body.append("fonts", JSON.stringify(this.fonts));
				if (site.user_id === "temp") {
					body.append("user_id", this.user.user.id);
				}
				return body;
			},
			getCreateSitePayloadBody() {
				const body = new FormData();
				body.append("user_id", this.user.user.id);
				body.append("figma_id", this.fileKey);
				body.append("name", this.documentName);
				body.append("json", JSON.stringify(this.figmaFileJson));
				body.append("fonts", JSON.stringify(this.fonts));
				return body;
			},

			messagesAccordingToNodeType(type) {
				switch (type) {
					default:
						return 'It looks like you have Vector nodes that you are not previewing as SVG.';
				}
			},
			slugAccordingToNodeType(type) {
				switch (type) {
					case "GROUP": return "how-to-export-svg";
					default:  return "how-to-export-svg";
				}
			},
		}
	}
</script>
