import store from '../store';
import { dispatch } from "../figma/ui-message-handler";

store.subscribe((mutation) => {
    // If we are setting our token, set our axios headers and store it
    switch (mutation.type) {
        case "setToken":
            if (mutation.payload) {
                axios.defaults.headers.common["Authorization"] = `Bearer ${mutation.payload }`;
                dispatch('setClientStorage', {name: 'token', data: mutation.payload});
            } else {
                axios.defaults.headers.common["Authorization"] = null;
                dispatch('setClientStorage', {name: 'token', data: null});
            }
            break;

        default:
            break;
    }
});
