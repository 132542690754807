<template>
	<div class="makers-selected-node-wrapper" :class="{'is-page-selected': isPageSelected}">
		<template v-if="isPageSelected">
			<div class="main-title">Selected Page</div>
			<div class="makers-selected-node site-pages">
				<div class="page" @click="openEditPage()">
					<div class="start">
						<div class="image-container">
							<div class="image" :style="{'background-image': `url('${currentSelection.makersPageImage}')`}"></div>
						</div>
						<div class="details">
							<div class="type type--small type--bold">{{currentSelection.makersPageTitle}}</div>
							<div class="type type--xsmall">{{currentSelection.makersPageDescription}}</div>
						</div>
					</div>
					<div class="end">
						<div class="icon">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 16.6666h7.5M13.75 2.91669c.3315-.33152.7812-.51776 1.25-.51776.2321 0 .462.04572.6765.13456.2145.08884.4093.21905.5735.3832s.2944.35903.3832.57351c.0888.21447.1346.44435.1346.67649 0 .23215-.0458.46202-.1346.6765-.0888.21447-.219.40935-.3832.5735L5.83333 15.8334 2.5 16.6667l.83333-3.3333L13.75 2.91669Z" stroke="#4A4A4A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
						</div>
					</div>
				</div>
			</div>
		</template>
		<div v-else>
			<div class="main-title">Selected Element</div>
			<div class="makers-selected-element">
				<div class="make-clickable">
					<div class="start">
						<div class="icon-wrapper">
							<div :class="`icon icon--${setIcon}`"></div>
						</div>
						<div class="name type type--medium type-xsmall text-ellipsis">{{currentSelection.name}}</div>
					</div>
					<div class="flex justify-content-end">
						<makers-clickable-dropdown></makers-clickable-dropdown>
					</div>
				</div>
				<div v-if="!isFirstChild && !isTextNode" class="export-as-svg">
					<makers-export-as-svg :current-selection="currentSelection" />
					<a :href="`${baseDomain}/tutorials/${exportSvgSlug}`" target="_blank" class="learn-more flex align-items-center">
						<div>Learn more</div>
					</a>
				</div>
				<makers-set-screen-size-visibility v-if="isFirstChild" :current-selection="currentSelection" />
				<makers-set-image-alt v-if="isImageNode" :current-selection="currentSelection" />
				<makers-set-navbar-position v-if="isNavbarNode" :current-selection="currentSelection" />
				<makers-set-text-tag v-if="isTextNode" :current-selection="currentSelection" />
			</div>
		</div>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	import { dispatch } from '../figma/ui-message-handler';
	export default {
		props: ['currentSelection'],
		data() {
			return {
				baseDomain: process.env.VUE_APP_URL,
				exportSvgSlug: 'how-to-export-svg'
			}
		},
		mounted() {
			dispatch("getMakersPagesWithFirstChildren");
		},
		computed: {
			...mapState(['pagesWithChildren']),

			isPageSelected() {
				return this.currentSelection.makersComponent === 'page';
			},
			setIcon() {
                switch (this.currentSelection.type) {
                    case 'INSTANCE': return 'instance';
                    case 'FRAME': return 'component';
                    case 'TEXT': return 'instance';
					default: return 'component';
                }
            },
			isFirstChild() {
				if (this.pagesWithChildren == null) return false;
				return this.pagesWithChildren.some(section => section.id === this.currentSelection.id && section.type === 'section')
			},
			isImageNode() {
				return this.currentSelection.makersBackgroundImage != '' && this.currentSelection.makersBackgroundImage != null;
			},
			isNavbarNode() {
				return this.currentSelection.makersComponent === 'navbar';
			},
			isTextNode() {
				return this.currentSelection.type === 'TEXT';
			}
		},
		methods: {
			...mapMutations(['setCurrentPage']),

			openEditPage() {
				const pageParams = {
					name: 'component',
					params: {
						component: {
							name: 'page',
							node: this.currentSelection,
						}
					}
				};
				this.setCurrentPage(pageParams);
            },
		}
	}
</script>
