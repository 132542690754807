export const stats = () => {
	return statsJson;
}
const image1 = `https://images.unsplash.com/photo-1618367588411-d9a90fefa881?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80`;
const statsJson = `[
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2801",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3161
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3161,
			"y": 40,
			"width": 473.333251953125,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2804",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3162.7216796875,
				"y": 44.70170593261719,
				"width": 113.266845703125,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2807",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3161.9375,
				"y": 104.09091186523438,
				"width": 438.3623046875,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2810",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3161.818115234375,
				"y": 297,
				"width": 446.34375,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "fbe06706aa8b1211c459b8605991cc2dbb07c9ea",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2802",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  513.333251953125
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3654.333251953125
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 513.333251953125,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3654.333251953125,
			"y": 40,
			"width": 473.333251953125,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2805",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3834.455810546875,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2808",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3673.463623046875,
				"y": 104.09091186523438,
				"width": 436.265869140625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2811",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3668.432373046875,
				"y": 297,
				"width": 445.752685546875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "df7a4ca08db360885c9a998d4a7bd338e5a422f2",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2803",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  1006.66650390625
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4147.66650390625
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 1006.66650390625,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 4147.66650390625,
			"y": 40,
			"width": 473.33349609375,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2806",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 4506.18994140625,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2809",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 4182.89306640625,
				"y": 104.09091186523438,
				"width": 436.43212890625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2812",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 4174.45556640625,
				"y": 297,
				"width": 445.7529296875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "a8b46f08a0353605aa40b2aa95b6a1282389d2b8",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_TEXT_CONTENT",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "FIXED",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-stats",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "451:3079",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Three columns",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  12469
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 1440,
		  "height": 524,
		  "absoluteRenderBounds": {
			"x": 12469,
			"y": 40,
			"width": 1440,
			"height": 524
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "451:3082",
			  "parent": {
				"id": "451:3079"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  320
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  12789
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 320,
			  "y": 0,
			  "width": 800,
			  "height": 337,
			  "absoluteRenderBounds": {
				"x": 12789,
				"y": 40,
				"width": 800,
				"height": 337
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "451:3085",
				  "parent": {
					"id": "451:3082"
				  },
				  "name": "Text Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  12789
					],
					[
					  0,
					  1,
					  88
					]
				  ],
				  "x": 0,
				  "y": 48,
				  "width": 800,
				  "height": 241,
				  "absoluteRenderBounds": {
					"x": 12789,
					"y": 88,
					"width": 800,
					"height": 241
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "451:3088",
					  "parent": {
						"id": "451:3085"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  12789
						],
						[
						  0,
						  1,
						  88
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 800,
					  "height": 241,
					  "absoluteRenderBounds": {
						"x": 12789,
						"y": 88,
						"width": 800,
						"height": 241
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I451:3088;394:2805",
						  "listSpacing": 0,
						  "parent": {
							"id": "451:3088"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.9166666865348816,
								"g": 0.3983135521411896,
								"b": 0.3361111283302307
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12789
							],
							[
							  0,
							  1,
							  88
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 800,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 13152.0869140625,
							"y": 93.30681610107422,
							"width": 73.8193359375,
							"height": 17.95738983154297
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "STATS",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "UPPER",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I451:3088;394:2808",
						  "listSpacing": 0,
						  "parent": {
							"id": "451:3088"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12789
							],
							[
							  0,
							  1,
							  141
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 800,
						  "height": 116,
						  "absoluteRenderBounds": {
							"x": 12861.8291015625,
							"y": 150.4545440673828,
							"width": 653.6298828125,
							"height": 95.125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Trusted by makers from 180 countries",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I451:3088;394:2811",
						  "listSpacing": 0,
						  "parent": {
							"id": "451:3088"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  163.33335876464844
							],
							[
							  0,
							  1,
							  193
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12952.3330078125
							],
							[
							  0,
							  1,
							  281
							]
						  ],
						  "x": 163.33335876464844,
						  "y": 193,
						  "width": 473.3332824707031,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 12966.4326171875,
							"y": 287,
							"width": 445.751953125,
							"height": 39.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2802"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 48,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "451:3103",
			  "parent": {
				"id": "451:3079"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  337
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  12469
				],
				[
				  0,
				  1,
				  377
				]
			  ],
			  "x": 0,
			  "y": 337,
			  "width": 1440,
			  "height": 187,
			  "absoluteRenderBounds": {
				"x": 12469,
				"y": 365,
				"width": 1440,
				"height": 199
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "div",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "451:3105",
				  "parent": {
					"id": "451:3103"
				  },
				  "name": "Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  170
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  12639
					],
					[
					  0,
					  1,
					  377
					]
				  ],
				  "x": 170,
				  "y": 0,
				  "width": 1100,
				  "height": 123,
				  "absoluteRenderBounds": {
					"x": 12623,
					"y": 365,
					"width": 1116,
					"height": 155
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "columns",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "451:3107",
					  "parent": {
						"id": "451:3105"
					  },
					  "name": "Features Grid",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  12639
						],
						[
						  0,
						  1,
						  377
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 1100,
					  "height": 123,
					  "absoluteRenderBounds": {
						"x": 12623,
						"y": 365,
						"width": 1116,
						"height": 155
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3109",
						  "parent": {
							"id": "451:3107"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12639
							],
							[
							  0,
							  1,
							  377
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 356,
						  "height": 123,
						  "absoluteRenderBounds": {
							"x": 12623,
							"y": 365,
							"width": 372,
							"height": 155
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 1,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3111",
							  "parent": {
								"id": "451:3109"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  12639
								],
								[
								  0,
								  1,
								  377
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 123,
							  "absoluteRenderBounds": {
								"x": 12623,
								"y": 365,
								"width": 372,
								"height": 155
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3113",
								  "parent": {
									"id": "451:3111"
								  },
								  "name": "Info",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  2
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  12641
									],
									[
									  0,
									  1,
									  379
									]
								  ],
								  "x": 2,
								  "y": 2,
								  "width": 336,
								  "height": 119,
								  "absoluteRenderBounds": {
									"x": 12641,
									"y": 379,
									"width": 336,
									"height": 119
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3115",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3113"
									  },
									  "name": "99.9%",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.9166666865348816,
											"g": 0.3983135521411896,
											"b": 0.3361111283302307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  118.5
										],
										[
										  0,
										  1,
										  24
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  12759.5
										],
										[
										  0,
										  1,
										  403
										]
									  ],
									  "x": 118.5,
									  "y": 24,
									  "width": 99,
									  "height": 39,
									  "absoluteRenderBounds": {
										"x": 12761.0908203125,
										"y": 409.9204406738281,
										"width": 94.7958984375,
										"height": 24.886383056640625
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "99.9%",
									  "hasMissingFont": false,
									  "fontSize": 32,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3117",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3113"
									  },
									  "name": "Subtitle",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.40392157435417175,
											"g": 0.5137255191802979,
											"b": 0.5960784554481506
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  134.5
										],
										[
										  0,
										  1,
										  73
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  12775.5
										],
										[
										  0,
										  1,
										  452
										]
									  ],
									  "x": 134.5,
									  "y": 73,
									  "width": 67,
									  "height": 22,
									  "absoluteRenderBounds": {
										"x": 12777.0400390625,
										"y": 456.38494873046875,
										"width": 64.5224609375,
										"height": 17.296875
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Up time",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Medium"
									  },
									  "autoRename": false,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 16,
								  "paddingRight": 16,
								  "paddingTop": 24,
								  "paddingBottom": 24,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 10,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.8382639288902283,
									"g": 0.8612004518508911,
									"b": 0.8784027695655823
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 2,
							  "paddingRight": 2,
							  "paddingTop": 2,
							  "paddingBottom": 2,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3119",
						  "parent": {
							"id": "451:3107"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  372
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  13011
							],
							[
							  0,
							  1,
							  377
							]
						  ],
						  "x": 372,
						  "y": 0,
						  "width": 356,
						  "height": 123,
						  "absoluteRenderBounds": {
							"x": 12995,
							"y": 365,
							"width": 372,
							"height": 155
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 1,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3121",
							  "parent": {
								"id": "451:3119"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13011
								],
								[
								  0,
								  1,
								  377
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 123,
							  "absoluteRenderBounds": {
								"x": 12995,
								"y": 365,
								"width": 372,
								"height": 155
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3123",
								  "parent": {
									"id": "451:3121"
								  },
								  "name": "Info",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  2
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13013
									],
									[
									  0,
									  1,
									  379
									]
								  ],
								  "x": 2,
								  "y": 2,
								  "width": 336,
								  "height": 119,
								  "absoluteRenderBounds": {
									"x": 13013,
									"y": 379,
									"width": 336,
									"height": 119
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3125",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3123"
									  },
									  "name": "100 GB",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.9166666865348816,
											"g": 0.3983135521411896,
											"b": 0.3361111283302307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  111.5
										],
										[
										  0,
										  1,
										  24
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13124.5
										],
										[
										  0,
										  1,
										  403
										]
									  ],
									  "x": 111.5,
									  "y": 24,
									  "width": 113,
									  "height": 39,
									  "absoluteRenderBounds": {
										"x": 13126.3125,
										"y": 410.4090881347656,
										"width": 109.8212890625,
										"height": 24.102264404296875
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "100 GB",
									  "hasMissingFont": false,
									  "fontSize": 32,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3127",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3123"
									  },
									  "name": "Subtitle",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.40392157435417175,
											"g": 0.5137255191802979,
											"b": 0.5960784554481506
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  121.5
										],
										[
										  0,
										  1,
										  73
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13134.5
										],
										[
										  0,
										  1,
										  452
										]
									  ],
									  "x": 121.5,
									  "y": 73,
									  "width": 93,
									  "height": 22,
									  "absoluteRenderBounds": {
										"x": 13136.2431640625,
										"y": 456.38494873046875,
										"width": 89.6865234375,
										"height": 13.832366943359375
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Bandwidth",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Medium"
									  },
									  "autoRename": false,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 16,
								  "paddingRight": 16,
								  "paddingTop": 24,
								  "paddingBottom": 24,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 10,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.8382639288902283,
									"g": 0.8612004518508911,
									"b": 0.8784027695655823
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 2,
							  "paddingRight": 2,
							  "paddingTop": 2,
							  "paddingBottom": 2,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3129",
						  "parent": {
							"id": "451:3107"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  744
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  13383
							],
							[
							  0,
							  1,
							  377
							]
						  ],
						  "x": 744,
						  "y": 0,
						  "width": 356,
						  "height": 123,
						  "absoluteRenderBounds": {
							"x": 13367,
							"y": 365,
							"width": 372,
							"height": 155
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 1,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3131",
							  "parent": {
								"id": "451:3129"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13383
								],
								[
								  0,
								  1,
								  377
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 123,
							  "absoluteRenderBounds": {
								"x": 13367,
								"y": 365,
								"width": 372,
								"height": 155
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3133",
								  "parent": {
									"id": "451:3131"
								  },
								  "name": "Info",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  2
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13385
									],
									[
									  0,
									  1,
									  379
									]
								  ],
								  "x": 2,
								  "y": 2,
								  "width": 336,
								  "height": 119,
								  "absoluteRenderBounds": {
									"x": 13385,
									"y": 379,
									"width": 336,
									"height": 119
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3135",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3133"
									  },
									  "name": "200k",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.9166666865348816,
											"g": 0.3983135521411896,
											"b": 0.3361111283302307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  126.5
										],
										[
										  0,
										  1,
										  24
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13511.5
										],
										[
										  0,
										  1,
										  403
										]
									  ],
									  "x": 126.5,
									  "y": 24,
									  "width": 83,
									  "height": 39,
									  "absoluteRenderBounds": {
										"x": 13513.2255859375,
										"y": 410.4090881347656,
										"width": 81.0830078125,
										"height": 24.102264404296875
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "200k",
									  "hasMissingFont": false,
									  "fontSize": 32,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3137",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3133"
									  },
									  "name": "Subtitle",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.40392157435417175,
											"g": 0.5137255191802979,
											"b": 0.5960784554481506
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  110
										],
										[
										  0,
										  1,
										  73
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13495
										],
										[
										  0,
										  1,
										  452
										]
									  ],
									  "x": 110,
									  "y": 73,
									  "width": 116,
									  "height": 22,
									  "absoluteRenderBounds": {
										"x": 13496.6767578125,
										"y": 456.2954406738281,
										"width": 113.2490234375,
										"height": 13.9027099609375
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Lines of code",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Medium"
									  },
									  "autoRename": false,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 16,
								  "paddingRight": 16,
								  "paddingTop": 24,
								  "paddingBottom": 24,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 10,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.8382639288902283,
									"g": 0.8612004518508911,
									"b": 0.8784027695655823
								  }
								}
							  ],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 2,
							  "paddingRight": 2,
							  "paddingTop": 2,
							  "paddingBottom": 2,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "fd24d3b2baddf873b41335bd90fe0052ea926cc3",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Three columns"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-stats",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "451:3080",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Brand background",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  564
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  12469
			],
			[
			  0,
			  1,
			  584
			]
		  ],
		  "x": 20,
		  "y": 564,
		  "width": 1440,
		  "height": 438,
		  "absoluteRenderBounds": {
			"x": 12469,
			"y": 584,
			"width": 1440,
			"height": 438
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "451:3083",
			  "parent": {
				"id": "451:3080"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  270
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  12739
				],
				[
				  0,
				  1,
				  584
				]
			  ],
			  "x": 270,
			  "y": 0,
			  "width": 900,
			  "height": 255,
			  "absoluteRenderBounds": {
				"x": 12739,
				"y": 584,
				"width": 900,
				"height": 255
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "451:3086",
				  "parent": {
					"id": "451:3083"
				  },
				  "name": "Text Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  12739
					],
					[
					  0,
					  1,
					  632
					]
				  ],
				  "x": 0,
				  "y": 48,
				  "width": 900,
				  "height": 159,
				  "absoluteRenderBounds": {
					"x": 12739,
					"y": 632,
					"width": 900,
					"height": 159
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "451:3092",
					  "parent": {
						"id": "451:3086"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  12739
						],
						[
						  0,
						  1,
						  632
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 900,
					  "height": 159,
					  "absoluteRenderBounds": {
						"x": 12739,
						"y": 632,
						"width": 900,
						"height": 159
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I451:3092;394:2805",
						  "listSpacing": 0,
						  "parent": {
							"id": "451:3092"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12739
							],
							[
							  0,
							  1,
							  632
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 900,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 13152.0869140625,
							"y": 637.3068237304688,
							"width": 73.8193359375,
							"height": 17.9573974609375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "STATS",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "UPPER",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I451:3092;394:2808",
						  "listSpacing": 0,
						  "parent": {
							"id": "451:3092"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12739
							],
							[
							  0,
							  1,
							  685
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 900,
						  "height": 58,
						  "absoluteRenderBounds": {
							"x": 12744.1025390625,
							"y": 694.0966186523438,
							"width": 889.82421875,
							"height": 46.7215576171875
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Trusted by makers from 180 countries",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I451:3092;394:2811",
						  "listSpacing": 0,
						  "parent": {
							"id": "451:3092"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  135
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12739
							],
							[
							  0,
							  1,
							  767
							]
						  ],
						  "x": 0,
						  "y": 135,
						  "width": 900,
						  "height": 24,
						  "absoluteRenderBounds": {
							"x": 12829.7685546875,
							"y": 773,
							"width": 718.693359375,
							"height": 15.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2802"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 48,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "451:3104",
			  "parent": {
				"id": "451:3080"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  255
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  12469
				],
				[
				  0,
				  1,
				  839
				]
			  ],
			  "x": 0,
			  "y": 255,
			  "width": 1440,
			  "height": 183,
			  "absoluteRenderBounds": {
				"x": 12469,
				"y": 839,
				"width": 1440,
				"height": 183
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "div",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "451:3106",
				  "parent": {
					"id": "451:3104"
				  },
				  "name": "Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  170
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  12639
					],
					[
					  0,
					  1,
					  839
					]
				  ],
				  "x": 170,
				  "y": 0,
				  "width": 1100,
				  "height": 135,
				  "absoluteRenderBounds": {
					"x": 12639,
					"y": 839,
					"width": 1100,
					"height": 135
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "columns",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "451:3108",
					  "parent": {
						"id": "451:3106"
					  },
					  "name": "Features Grid",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  12639
						],
						[
						  0,
						  1,
						  839
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 1100,
					  "height": 135,
					  "absoluteRenderBounds": {
						"x": 12639,
						"y": 839,
						"width": 1100,
						"height": 135
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3110",
						  "parent": {
							"id": "451:3108"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  12639
							],
							[
							  0,
							  1,
							  839
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 356,
						  "height": 135,
						  "absoluteRenderBounds": {
							"x": 12639,
							"y": 839,
							"width": 356,
							"height": 135
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 1,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3112",
							  "parent": {
								"id": "451:3110"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  12639
								],
								[
								  0,
								  1,
								  839
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 12639,
								"y": 839,
								"width": 340,
								"height": 135
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3114",
								  "parent": {
									"id": "451:3112"
								  },
								  "name": "Info",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  2
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  12641
									],
									[
									  0,
									  1,
									  841
									]
								  ],
								  "x": 2,
								  "y": 2,
								  "width": 336,
								  "height": 131,
								  "absoluteRenderBounds": {
									"x": 12641,
									"y": 841,
									"width": 336,
									"height": 131
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3116",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3114"
									  },
									  "name": "99.9%",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 1,
											"g": 1,
											"b": 1
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  103
										],
										[
										  0,
										  1,
										  24
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  12744
										],
										[
										  0,
										  1,
										  865
										]
									  ],
									  "x": 103,
									  "y": 24,
									  "width": 130,
									  "height": 51,
									  "absoluteRenderBounds": {
										"x": 12746.119140625,
										"y": 874.3955688476562,
										"width": 124.4189453125,
										"height": 32.66339111328125
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "99.9%",
									  "hasMissingFont": false,
									  "fontSize": 42,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3118",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3114"
									  },
									  "name": "Subtitle",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 1,
											"g": 1,
											"b": 1
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  134.5
										],
										[
										  0,
										  1,
										  85
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  12775.5
										],
										[
										  0,
										  1,
										  926
										]
									  ],
									  "x": 134.5,
									  "y": 85,
									  "width": 67,
									  "height": 22,
									  "absoluteRenderBounds": {
										"x": 12777.0400390625,
										"y": 930.3849487304688,
										"width": 64.5224609375,
										"height": 17.296875
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Up time",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Medium"
									  },
									  "autoRename": false,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 16,
								  "paddingRight": 16,
								  "paddingTop": 24,
								  "paddingBottom": 24,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 10,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 2,
							  "paddingRight": 2,
							  "paddingTop": 2,
							  "paddingBottom": 2,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3120",
						  "parent": {
							"id": "451:3108"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  372
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  13011
							],
							[
							  0,
							  1,
							  839
							]
						  ],
						  "x": 372,
						  "y": 0,
						  "width": 356,
						  "height": 135,
						  "absoluteRenderBounds": {
							"x": 13011,
							"y": 839,
							"width": 356,
							"height": 135
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 1,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3122",
							  "parent": {
								"id": "451:3120"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13011
								],
								[
								  0,
								  1,
								  839
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 13011,
								"y": 839,
								"width": 340,
								"height": 135
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3124",
								  "parent": {
									"id": "451:3122"
								  },
								  "name": "Info",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  2
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13013
									],
									[
									  0,
									  1,
									  841
									]
								  ],
								  "x": 2,
								  "y": 2,
								  "width": 336,
								  "height": 131,
								  "absoluteRenderBounds": {
									"x": 13013,
									"y": 841,
									"width": 336,
									"height": 131
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3126",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3124"
									  },
									  "name": "100 GB",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 1,
											"g": 1,
											"b": 1
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  94
										],
										[
										  0,
										  1,
										  24
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13107
										],
										[
										  0,
										  1,
										  865
										]
									  ],
									  "x": 94,
									  "y": 24,
									  "width": 148,
									  "height": 51,
									  "absoluteRenderBounds": {
										"x": 13109.22265625,
										"y": 875.0369262695312,
										"width": 144.1396484375,
										"height": 31.63421630859375
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "100 GB",
									  "hasMissingFont": false,
									  "fontSize": 42,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3128",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3124"
									  },
									  "name": "Subtitle",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 1,
											"g": 1,
											"b": 1
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  121.5
										],
										[
										  0,
										  1,
										  85
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13134.5
										],
										[
										  0,
										  1,
										  926
										]
									  ],
									  "x": 121.5,
									  "y": 85,
									  "width": 93,
									  "height": 22,
									  "absoluteRenderBounds": {
										"x": 13136.2431640625,
										"y": 930.3849487304688,
										"width": 89.6865234375,
										"height": 13.8323974609375
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Bandwidth",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Medium"
									  },
									  "autoRename": false,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 16,
								  "paddingRight": 16,
								  "paddingTop": 24,
								  "paddingBottom": 24,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 10,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 2,
							  "paddingRight": 2,
							  "paddingTop": 2,
							  "paddingBottom": 2,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3130",
						  "parent": {
							"id": "451:3108"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  744
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  13383
							],
							[
							  0,
							  1,
							  839
							]
						  ],
						  "x": 744,
						  "y": 0,
						  "width": 356,
						  "height": 135,
						  "absoluteRenderBounds": {
							"x": 13383,
							"y": 839,
							"width": 356,
							"height": 135
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 1,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3132",
							  "parent": {
								"id": "451:3130"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13383
								],
								[
								  0,
								  1,
								  839
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 135,
							  "absoluteRenderBounds": {
								"x": 13383,
								"y": 839,
								"width": 340,
								"height": 135
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3134",
								  "parent": {
									"id": "451:3132"
								  },
								  "name": "Info",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  2
									],
									[
									  0,
									  1,
									  2
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13385
									],
									[
									  0,
									  1,
									  841
									]
								  ],
								  "x": 2,
								  "y": 2,
								  "width": 336,
								  "height": 131,
								  "absoluteRenderBounds": {
									"x": 13385,
									"y": 841,
									"width": 336,
									"height": 131
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3136",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3134"
									  },
									  "name": "200k",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 1,
											"g": 1,
											"b": 1
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  113.5
										],
										[
										  0,
										  1,
										  24
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13498.5
										],
										[
										  0,
										  1,
										  865
										]
									  ],
									  "x": 113.5,
									  "y": 24,
									  "width": 109,
									  "height": 51,
									  "absoluteRenderBounds": {
										"x": 13500.796875,
										"y": 875.0369262695312,
										"width": 106.419921875,
										"height": 31.63421630859375
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "200k",
									  "hasMissingFont": false,
									  "fontSize": 42,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3138",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3134"
									  },
									  "name": "Subtitle",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 1,
											"g": 1,
											"b": 1
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  110
										],
										[
										  0,
										  1,
										  85
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13495
										],
										[
										  0,
										  1,
										  926
										]
									  ],
									  "x": 110,
									  "y": 85,
									  "width": 116,
									  "height": 22,
									  "absoluteRenderBounds": {
										"x": 13496.6767578125,
										"y": 930.2954711914062,
										"width": 113.2490234375,
										"height": 13.9027099609375
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Lines of code",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "AUTO"
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Medium"
									  },
									  "autoRename": false,
									  "textAlignHorizontal": "CENTER",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "WIDTH_AND_HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 16,
								  "paddingRight": 16,
								  "paddingTop": 24,
								  "paddingBottom": 24,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 10,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 2,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 2,
							  "paddingRight": 2,
							  "paddingTop": 2,
							  "paddingBottom": 2,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 0,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.9166666865348816,
				"g": 0.3983135521411896,
				"b": 0.3361111283302307
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "19d8009b044a3435d4fc61649113fe50f401cfaa",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Brand background"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-stats",
		  "makersComponent": "hero",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "451:3081",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Split",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  1022
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  12469
			],
			[
			  0,
			  1,
			  1042
			]
		  ],
		  "x": 20,
		  "y": 1022,
		  "width": 1440,
		  "height": 581,
		  "absoluteRenderBounds": {
			"x": 12469,
			"y": 1042,
			"width": 1440,
			"height": 581
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "columns, makers-container-left-absolute-image",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "451:3084",
			  "parent": {
				"id": "451:3081"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  12469
				],
				[
				  0,
				  1,
				  1042
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 1420,
			  "height": 581,
			  "absoluteRenderBounds": {
				"x": 12469,
				"y": 1042,
				"width": 1420,
				"height": 581
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "451:3087",
				  "parent": {
					"id": "451:3084"
				  },
				  "name": "Image Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  12469
					],
					[
					  0,
					  1,
					  1042
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 720,
				  "height": 581,
				  "absoluteRenderBounds": {
					"x": 12469,
					"y": 1042,
					"width": 720,
					"height": 581
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "nav",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/3214b7dc4e4d62b220137c33d8c10b82b8eb555f.png",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "451:3096",
					  "parent": {
						"id": "451:3087"
					  },
					  "name": "Image",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  12469
						],
						[
						  0,
						  1,
						  1042
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 720,
					  "height": 581,
					  "absoluteRenderBounds": {
						"x": 12469,
						"y": 1042,
						"width": 720,
						"height": 581
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "IMAGE",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "scaleMode": "FILL",
						  "imageTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "scalingFactor": 0.5,
						  "rotation": 0,
						  "filters": {
							"exposure": 0,
							"contrast": 0,
							"saturation": 0,
							"temperature": 0,
							"tint": 0,
							"highlights": 0,
							"shadows": 0
						  },
						  "imageHash": "${image1}"
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "451:3139",
				  "parent": {
					"id": "451:3084"
				  },
				  "name": "Text Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  744
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  13213
					],
					[
					  0,
					  1,
					  1042
					]
				  ],
				  "x": 744,
				  "y": 0,
				  "width": 676,
				  "height": 581,
				  "absoluteRenderBounds": {
					"x": 13213,
					"y": 1042,
					"width": 676,
					"height": 581
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "451:3140",
					  "parent": {
						"id": "451:3139"
					  },
					  "name": "Text Side",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  13213
						],
						[
						  0,
						  1,
						  1042
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 500,
					  "height": 581,
					  "absoluteRenderBounds": {
						"x": 13213,
						"y": 1042,
						"width": 500,
						"height": 581
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3141",
						  "parent": {
							"id": "451:3140"
						  },
						  "name": "MAKERS_TEXT_CONTENT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  48
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  13213
							],
							[
							  0,
							  1,
							  1090
							]
						  ],
						  "x": 0,
						  "y": 48,
						  "width": 500,
						  "height": 241,
						  "absoluteRenderBounds": {
							"x": 13213,
							"y": 1090,
							"width": 500,
							"height": 241
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I451:3141;394:2804",
							  "listSpacing": 0,
							  "parent": {
								"id": "451:3141"
							  },
							  "name": "Education",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.9166666865348816,
									"g": 0.3983135521411896,
									"b": 0.3361111283302307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13213
								],
								[
								  0,
								  1,
								  1090
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 500,
							  "height": 29,
							  "absoluteRenderBounds": {
								"x": 13214.0908203125,
								"y": 1095.3067626953125,
								"width": 73.8193359375,
								"height": 17.9573974609375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "STATS",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": false,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I451:3141;394:2807",
							  "listSpacing": 0,
							  "parent": {
								"id": "451:3141"
							  },
							  "name": "Learn how to make and publish sites with Figma",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  53
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13213
								],
								[
								  0,
								  1,
								  1143
								]
							  ],
							  "x": 0,
							  "y": 53,
							  "width": 500,
							  "height": 116,
							  "absoluteRenderBounds": {
								"x": 13214.73828125,
								"y": 1152.0965576171875,
								"width": 483.2431640625,
								"height": 105.26708984375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Learn how to publish sites with Figma",
							  "hasMissingFont": false,
							  "fontSize": 48,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Bold"
							  },
							  "autoRename": false,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I451:3141;394:2810",
							  "listSpacing": 0,
							  "parent": {
								"id": "451:3141"
							  },
							  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.03947368264198303,
									"g": 0.16421058773994446,
									"b": 0.2605263292789459
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  193
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13213
								],
								[
								  0,
								  1,
								  1283
								]
							  ],
							  "x": 0,
							  "y": 193,
							  "width": 500,
							  "height": 48,
							  "absoluteRenderBounds": {
								"x": 13213.818359375,
								"y": 1289,
								"width": 446.34375,
								"height": 39.45458984375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": false,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 24,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "394:2801"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Align": "Left"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "451:3145",
						  "parent": {
							"id": "451:3140"
						  },
						  "name": "Rows Container",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  313
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  13213
							],
							[
							  0,
							  1,
							  1355
							]
						  ],
						  "x": 0,
						  "y": 313,
						  "width": 500,
						  "height": 220,
						  "absoluteRenderBounds": {
							"x": 13213,
							"y": 1355,
							"width": 500,
							"height": 220
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "columns-sm",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3146",
							  "parent": {
								"id": "451:3145"
							  },
							  "name": "Row",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  24
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13213
								],
								[
								  0,
								  1,
								  1379
								]
							  ],
							  "x": 0,
							  "y": 24,
							  "width": 500,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 13213,
								"y": 1379,
								"width": 500,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3147",
								  "parent": {
									"id": "451:3146"
								  },
								  "name": "Text",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13213
									],
									[
									  0,
									  1,
									  1379
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 245,
								  "height": 86,
								  "absoluteRenderBounds": {
									"x": 13213,
									"y": 1379,
									"width": 245,
									"height": 86
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 1,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3148",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3147"
									  },
									  "name": "99.9%",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0,
											"g": 0,
											"b": 0
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13213
										],
										[
										  0,
										  1,
										  1379
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 245,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 13214.3125,
										"y": 1382.0596923828125,
										"width": 69.6474609375,
										"height": 18.4261474609375
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "99.9%",
									  "hasMissingFont": false,
									  "fontSize": 24,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PIXELS",
										"value": 24
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Semi Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3149",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3147"
									  },
									  "name": "Servers with almost 100% up time.",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.03947368264198303,
											"g": 0.16421058773994446,
											"b": 0.2605263292789459
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  32
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13213
										],
										[
										  0,
										  1,
										  1411
										]
									  ],
									  "x": 0,
									  "y": 32,
									  "width": 245,
									  "height": 54,
									  "absoluteRenderBounds": {
										"x": 13214.0224609375,
										"y": 1417.5,
										"width": 216.369140625,
										"height": 44.1817626953125
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Servers with almost 100% up time.",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PERCENT",
										"value": 150
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Regular"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 8,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3150",
								  "parent": {
									"id": "451:3146"
								  },
								  "name": "Text",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  255
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13468
									],
									[
									  0,
									  1,
									  1379
									]
								  ],
								  "x": 255,
								  "y": 0,
								  "width": 245,
								  "height": 86,
								  "absoluteRenderBounds": {
									"x": 13468,
									"y": 1379,
									"width": 245,
									"height": 86
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 1,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3151",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3150"
									  },
									  "name": "200k",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0,
											"g": 0,
											"b": 0
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13468
										],
										[
										  0,
										  1,
										  1379
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 245,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 13469.44921875,
										"y": 1382.3067626953125,
										"width": 58.9638671875,
										"height": 18.0341796875
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "200k",
									  "hasMissingFont": false,
									  "fontSize": 24,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PIXELS",
										"value": 24
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Semi Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3152",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3150"
									  },
									  "name": "Lines of code created to deliver this to you.",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.03947368264198303,
											"g": 0.16421058773994446,
											"b": 0.2605263292789459
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  32
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13468
										],
										[
										  0,
										  1,
										  1411
										]
									  ],
									  "x": 0,
									  "y": 32,
									  "width": 245,
									  "height": 54,
									  "absoluteRenderBounds": {
										"x": 13468.9208984375,
										"y": 1417.29541015625,
										"width": 205.2275390625,
										"height": 44.3863525390625
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Lines of code created to deliver this to you.",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PERCENT",
										"value": 150
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Regular"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 8,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "FIXED",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "columns-sm",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "451:3153",
							  "parent": {
								"id": "451:3145"
							  },
							  "name": "Row",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  134
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  13213
								],
								[
								  0,
								  1,
								  1489
								]
							  ],
							  "x": 0,
							  "y": 134,
							  "width": 500,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 13213,
								"y": 1489,
								"width": 500,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3154",
								  "parent": {
									"id": "451:3153"
								  },
								  "name": "Text",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13213
									],
									[
									  0,
									  1,
									  1489
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 245,
								  "height": 86,
								  "absoluteRenderBounds": {
									"x": 13213,
									"y": 1489,
									"width": 245,
									"height": 86
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 1,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3155",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3154"
									  },
									  "name": "100 GB",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0,
											"g": 0,
											"b": 0
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13213
										],
										[
										  0,
										  1,
										  1489
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 245,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 13214.3037109375,
										"y": 1492.3067626953125,
										"width": 81.0576171875,
										"height": 18.0341796875
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "100 GB",
									  "hasMissingFont": false,
									  "fontSize": 24,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PIXELS",
										"value": 24
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Semi Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3156",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3154"
									  },
									  "name": "Full service with your bandwidth.",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.03947368264198303,
											"g": 0.16421058773994446,
											"b": 0.2605263292789459
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  32
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13213
										],
										[
										  0,
										  1,
										  1521
										]
									  ],
									  "x": 0,
									  "y": 32,
									  "width": 245,
									  "height": 54,
									  "absoluteRenderBounds": {
										"x": 13214.5849609375,
										"y": 1527.5,
										"width": 177.708984375,
										"height": 40.7301025390625
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "Full service with your bandwidth.",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PERCENT",
										"value": 150
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Regular"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 8,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "451:3157",
								  "parent": {
									"id": "451:3153"
								  },
								  "name": "Text",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  255
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  13468
									],
									[
									  0,
									  1,
									  1489
									]
								  ],
								  "x": 255,
								  "y": 0,
								  "width": 245,
								  "height": 59,
								  "absoluteRenderBounds": {
									"x": 13468,
									"y": 1489,
									"width": 245,
									"height": 59
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 1,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3158",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3157"
									  },
									  "name": "1986",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0,
											"g": 0,
											"b": 0
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13468
										],
										[
										  0,
										  1,
										  1489
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 245,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 13469.3037109375,
										"y": 1492.28125,
										"width": 55.5234375,
										"height": 17.98291015625
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "1986",
									  "hasMissingFont": false,
									  "fontSize": 24,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PIXELS",
										"value": 24
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Semi Bold"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "451:3159",
									  "listSpacing": 0,
									  "parent": {
										"id": "451:3157"
									  },
									  "name": "The year of the greatest.",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.03947368264198303,
											"g": 0.16421058773994446,
											"b": 0.2605263292789459
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "OUTSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  32
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  13468
										],
										[
										  0,
										  1,
										  1521
										]
									  ],
									  "x": 0,
									  "y": 32,
									  "width": 245,
									  "height": 27,
									  "absoluteRenderBounds": {
										"x": 13468.869140625,
										"y": 1527.29541015625,
										"width": 208.6015625,
										"height": 17.5909423828125
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "exportSettings": [],
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "characters": "The year of the greatest.",
									  "hasMissingFont": false,
									  "fontSize": 18,
									  "paragraphIndent": 0,
									  "paragraphSpacing": 0,
									  "textCase": "ORIGINAL",
									  "textDecoration": "NONE",
									  "letterSpacing": {
										"unit": "PERCENT",
										"value": 0
									  },
									  "lineHeight": {
										"unit": "PERCENT",
										"value": 150
									  },
									  "fontName": {
										"family": "Inter",
										"style": "Regular"
									  },
									  "autoRename": true,
									  "textAlignHorizontal": "LEFT",
									  "textAlignVertical": "TOP",
									  "textAutoResize": "HEIGHT",
									  "textStyleId": "",
									  "reactions": [],
									  "type": "TEXT",
									  "children": []
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 8,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "reactions": [],
								  "type": "FRAME"
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "FIXED",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 24,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 24,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 48,
					  "paddingBottom": 48,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "7fda4a143ecea6715ad689de519688eaac3feeb5",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Split"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_STATS",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	}
  ]`;
