<template>
    <b-dropdown
        trap-focus
        append-to-body
        aria-role="menu"
        position="is-bottom-left"
    >
        <template v-if="isPublish" #trigger>
            <button class="button button--primary" @click="focusInput('emailLoginInput')">Publish</button>
        </template>
        <template v-else #trigger>
            <button class="button button--primary" @click="focusInput('emailLoginInput')">
                Preview website
            </button>
        </template>
        <b-dropdown-item class="makers-auth-dropdown" aria-role="menu-item" :focusable="false" custom>
            <div class="form-wrapper">
                <section v-if="isLoginVisible" class="login-form">
                    <p class="type type--medium type--large">Log in</p>
                    <p v-if="hasLoginError" class="errors-wrapper error type type--small">Invalid credentials. Double check and try again.</p>
                    <form @submit.prevent="handleLogIn()">
                        <div class="field">
                            <div class="input">
                                <input ref="emailLoginInput" v-model="loginForm.email" @input="removeErrors()" type="email" class="input__field  input__with_border" placeholder="Email" required>
                            </div>
                        </div>
                        <div class="field">
                            <div class="input">
                                <input v-model="loginForm.password" @input="removeErrors()" type="password" class="input__field  input__with_border" placeholder="Password" required>
                            </div>
                        </div>
                        <button type="submit" class="button button--primary" :disabled="isLoading">
                            <template v-if="!isLoading">Log in</template>
                            <div v-else class="icon icon--spinner icon--white"></div>
                        </button>
                    </form>
                    <p class="switch-text type type--small type--medium has-text-centered">Don't have an account? <button class="button button--tertiary" @click.stop="changeTo(false, 'emailRegisterInput')">Create one</button></p>
                </section>
                <section v-else class="login-form">
                    <p class="type type--medium type--large">Create account</p>
                    <div v-if="hasRegisterError" class="errors-wrapper">
                        <div class="error type type--small" v-for="(error, index) in hasRegisterError" :key="index">
                            {{error}}
                        </div>
                    </div>
                    <form @submit.prevent="handleRegister()">
                        <div class="field">
                            <div class="input">
                                <input ref="emailRegisterInput" @input="removeErrors()" v-model="registerForm.name" type="text" class="input__field  input__with_border" placeholder="Name" required>
                            </div>
                        </div>
                        <div class="field">
                            <div class="input">
                                <input v-model="registerForm.email" @input="removeErrors()" type="email" class="input__field  input__with_border" placeholder="Email" required>
                            </div>
                        </div>
                        <div class="field">
                            <div class="input">
                                <input v-model="registerForm.password" @input="removeErrors()" type="password" class="input__field input__with_border" placeholder="Password" required>
                            </div>
                        </div>
                        <button type="submit" class="button button--primary" :disabled="isLoading">
                            <template v-if="!isLoading">Create account</template>
                            <div v-else class="icon icon--spinner icon--white"></div>
                        </button>
                    </form>
                    <p class="switch-text type type--small type--medium has-text-centered">Already have an account? <button class="button button--tertiary" @click.stop="changeTo(true, 'emailLoginInput')">Log in</button></p>
                    <div class="terms type has-text-centered">By clicking "Create account", you agree to our <a href="#">Terms</a> and <a href="#">Privacy Policy</a>.</div>
                </section>
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>
<script>
    import { mapActions } from 'vuex';
	import { dispatch } from '../../figma/ui-message-handler';
    export default {
        props: ['isPublish'],
        data() {
            return {
                isLoading: false,
                isLoginVisible: true,

                hasLoginError: false,
                loginForm: {
                    email: null,
                    password: null,
                },
                hasRegisterError: false,
                registerForm: {
                    name: null,
                    email: null,
                    password: null,
                }
            }
        },
        methods: {
            ...mapActions(['authenticateUser', 'createUser']),

            handleLogIn() {
                this.isLoading = true;
                const body = {
                    email: this.loginForm.email,
                    password: this.loginForm.password,
                };
                this.authenticateUser(body)
                    .then(() => {
						dispatch("notify", "✅ Welcome back! You look awesome today 😎");
						this.handleSuccess();
					})
                    .catch(() => {
                        this.isLoading = false;
                        this.hasLoginError = true;
                    });
            },
            handleRegister() {
                this.isLoading = true;
                const body = {
                    name: this.registerForm.name,
                    email: this.registerForm.email,
                    password: this.registerForm.password,
                };
                this.createUser(body)
                    .then(() => {
						dispatch("notify", "✅ Welcome to Makers! It's great to have you.");
						this.handleSuccess();
					})
                    .catch((error) => {
                        this.hasRegisterError = Object.values(error.response.data.errors).flat();
                        this.isLoading = false;
                    });
            },
            handleSuccess() {
                (this.isPublish) ? this.$parent.handlePublish() : this.$parent.handlePreviewSite();
            },

            removeErrors() {
                if (this.hasRegisterError || this.hasLoginError) {
                    this.hasLoginError = false;
                    this.hasRegisterError = false;
                }
            },

            changeTo(value, input) {
                this.removeErrors();
                this.isLoginVisible = value;
                this.focusInput(input);
            },
            focusInput(ref) {
                setTimeout(() => {
                    if (this.$refs[ref] != null) {
                        this.$refs[ref].focus();
                    }
                }, 50);
            }
        }
    }
</script>
