<template>
    <div class="makers-project-pages">
        <div
            :key="index"
            @click="setAsHomePage(page)"
            class="page" v-for="(page, index) in pages"
            :class="{'is-selected': homePage.id === page.id}"
        >
            <div class="makers-page-image" :style="{'background-image': `url('${page.title}')`}"></div>
            <div class="makers-page-details">
                <div class="type type--small type--bold">{{page.title}}</div>
                <div class="type type--small">{{page.description}}</div>
                <div class="type type--small">{{page.url}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    export default {
        props: ['pages', 'siteStatus'],
        mounted() {
            setTimeout(() => {
                if (this.pages.length === 1) {
                    this.setAsHomePage(this.pages[0]);
                }
            }, 100);
        },
        computed: {
            ...mapState(['homePage']),
        },
        methods: {
            ...mapMutations(['setHomePage']),
            setAsHomePage(page) {
                this.setHomePage(page);
                this.$parent.changeCurrentStep('next');
            },
        }
    }
</script>
