<template>
    <section class="makers-site-pages">
        <div v-if="isPublishWorkflow" class="intro">
            <h1>Pages.</h1>
            <h2>Choose an entry page for your site or select one to edit its metadata.</h2>
        </div>
        <div v-else class="section-title">Pages</div>
        <div class="site-pages">
            <div class="page" v-for="(page, index) in pages" :key="index" @click="onPageClick(page)">
                <div class="start">
                    <div class="checkbox" @click.stop="">
                        <input :id="page.id" @change="setAsHomePage(page)" name="homePage" type="radio" class="checkbox__box" :checked="homePage.id === page.id">
                        <label :for="page.id" class="checkbox__label"></label>
                    </div>
                    <div class="image-container">
                        <div class="image" :style="{'background-image': `url('${page.makersPageImage}')`}"></div>
                    </div>
                    <div class="details">
                        <div class="type type--small type--bold">{{page.makersPageTitle}}</div>
                        <div class="type type--xsmall">{{page.makersPageDescription}}</div>
                    </div>
                </div>
                <div class="end">
                    <div class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 16.6666h7.5M13.75 2.91669c.3315-.33152.7812-.51776 1.25-.51776.2321 0 .462.04572.6765.13456.2145.08884.4093.21905.5735.3832s.2944.35903.3832.57351c.0888.21447.1346.44435.1346.67649 0 .23215-.0458.46202-.1346.6765-.0888.21447-.219.40935-.3832.5735L5.83333 15.8334 2.5 16.6667l.83333-3.3333L13.75 2.91669Z" stroke="#4A4A4A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { dispatch} from '../figma/ui-message-handler';
    import { mapActions, mapMutations, mapState } from 'vuex';
    export default {
        props: ['isPublishWorkflow'],
		mounted() {
			dispatch("getMakersPages");
		},
        computed: {
            ...mapState(['pages', 'site', 'homePage']),
        },
        methods: {
            ...mapActions(['updateSiteHomePage']),
            ...mapMutations(['setCurrentPage', 'setHomePage']),

            onPageClick(page) {
                const payload = {
                    name: 'component',
                    params: {
                        backPage: (this.isPublishWorkflow) ? 'publish' : 'update-site',
						isPublishWorkflow: (this.isPublishWorkflow),
                        component: {
                            name: 'page',
                            node: page,
                        }
                    }
                }
                this.setCurrentPage(payload);
            },
            async setAsHomePage(page) {
				// 1. Update the makersPageUrl of the previous home page
				const oldHomePage = this.homePage;
				if (oldHomePage.makersPageTitle != null) {
					const payload = { id: oldHomePage.id, key: 'makersPageUrl', value: this.sanitizePageTitle(oldHomePage.makersPageTitle) };
					dispatch('updateNode', payload);
				}

				// 2. Add new Home Page
				this.setHomePage(page);
                const body = JSON.stringify({
                    id: this.site.site.id,
                    homePageId: page.id,
                });
                const message = await this.updateSiteHomePage(body);
                dispatch("notify", message);
            },

			sanitizePageTitle(string) {
				const noSpaces = string.toLowerCase().replaceAll(" ", "-");
				const sanitized = noSpaces.replace(/[^\w\s]/gi, '');
				return sanitized
			},
        }
    }
</script>
