<template>
	<div class="">
		<template v-if="isSubscribed">
			<div v-if="useCurrentCard" class="current-credit-card">
				<label style="font-size: 15px; color: #30313D">Credit Card Details</label>
				<div class="flex justify-content-between align-items-center">
					<div class="start">
						**** **** **** {{this.user.user.card_last_four}}
					</div>
					<div class="type type--xsmall type--black">{{this.user.user.card_brand.toUpperCase()}}</div>
				</div>
				<button class="button button--primary type type--small" @click="buyTemplate()">
					<template v-if="!isLoading">Buy Template With Current Card</template>
					<div v-else class="icon icon--spinner icon--white"></div>
				</button>
				<div class="disclaimer type type--xsmall">
					Your payment details will never touch our servers. You will be charged ${{template.price}}.
				</div>
				<button class="button button--tertiary" @click="useCurrentCard = !useCurrentCard">Use different card</button>
			</div>
			<div v-else class="new-credit-card">
				<makers-stripe-payments-element
					:template="template"
					:price="template.price"
					button-name="Buy template"
					:is-loading="isPaymentLoading"
					:error-message="errorMessage"
					@updatePaymentLoading="(value) => $emit('updatePaymentLoading', value)"
					@handlePayButtonClick="(data) => $emit('handlePayButtonClick', data)"
				/>
				<button class="button button--tertiary" @click="useCurrentCard = !useCurrentCard">Use same card as subscription</button>
			</div>
		</template>
		<template v-else>
			<makers-stripe-payments-element
				:template="template"
				:price="template.price"
				button-name="Buy template"
				:is-loading="isPaymentLoading"
				:error-message="errorMessage"
				@updatePaymentLoading="(value) => $emit('updatePaymentLoading', value)"
				@handlePayButtonClick="(data) => $emit('handlePayButtonClick', data)"
			/>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex'
	export default {
		props: ['template', 'isSubscribed', 'buttonName', 'isPaymentLoading', 'errorMessage'],
		data() {
			return {
				isLoading: false,
				useCurrentCard: true,
			}
		},
		mounted() {
		},
		computed: {
			...mapState(['user']),
		},
		methods: {
			async buyTemplate() {
				// Charge user in Stripe from current card
				this.isLoading = true;
				const response = await axios.post('/charge', { body: JSON.stringify(this.template) });
				if (response.data.status === 'success') {
					this.$emit("handlePaymentSuccess", this.user.user.email);
				}
				this.isLoading = false;
			}
		}
	}
</script>
