<script>
	import { mapState, mapActions, mapMutations } from 'vuex';
  import { dispatch, handleEvent } from '../../figma/ui-message-handler';
	export default {
		render() {},
		mounted() {
			this.init();
			handleEvent("setProjectFonts", (fonts) => this.setProjectFonts(fonts));
			handleEvent("setMakersPagesWithFirstChildren", pages => this.setPagesWithChildren(pages));
		},
		computed: {
			...mapState(['user', 'pages', 'site']),
		},
		methods: {
			...mapActions(['getIsSubscribed', 'getSiteByFigmaId']),
			...mapMutations([
				'setPages',
				'setFileKey',
				'deletePage',
				'setHomePage',
				'setSiteStatus',
				'setCurrentPage',
				'setIsPublished',
				'setDocumentName',
				'setProjectFonts',
				'setPagesWithChildren',
				'setShowSiteStatusFlashCard',
			]),

			async init() {
				// 1. Get Authenticated User
				this.getIsSubscribed();
				// 2. Get Document Name
				dispatch("getDocumentName");
				handleEvent("setDocumentName", (name) => this.setDocumentName(name));
				// 3. Get File Key
				dispatch("getFileKey"); // This key will allow us to update or create Site
				handleEvent("setFileKey", async (key) => {
					this.setFileKey(key);
					if (this.user != null) {
						const site = await this.getSiteByFigmaId(key);
						this.setSiteInformation(site); // Listen only if there's a site
					};
				});
				// 4. Check if it has Makers Pages
				dispatch("getMakersPages");
				handleEvent("setMakersPages", (pages) => {
					this.setPages(pages);
					this.$emit('loading', false);
				});

				// 5. Page actions
				handleEvent("showPage", (page) => this.setCurrentPage(page));
				handleEvent("removePage", (pageId) => this.deletePage(pageId));
				handleEvent("pushNewPage", (newPage) => this.pages.push(newPage));
				handleEvent("setHomePageById", (node) => this.setHomePage(node));
				handleEvent("pushedFirstPage", (firstPage) => this.setHomePage(firstPage));
			},

			setSiteInformation(site) {
                if (site == null) {return}
                if (site.domain != null) {
                    this.setIsPublished((site.domain.is_published) === 'true'); // To make it a boolean
                }
				this.setSiteStatus(site.status);
                dispatch("getHomePageById", site.home_page_id);
				this.$emit("domain", site.domain);
				this.getSiteFonts(site);
                this.listenToSiteStatusChanged(site);
            },
			listenToSiteStatusChanged(site) {
                Echo.private(`sites.${site.id}`)
                    .listen('SiteStatusUpdated', ( {status, id} ) => {
						// this.siteStatus = status;
						this.setSiteStatus(status);
						this.$emit("status", status);
                        this.setShowSiteStatusFlashCard(true);
                        this.updateSiteStatusInStore(status, id);
                    });
            },
            updateSiteStatusInStore(status, id) {
                const index = this.site.sites.findIndex(s => s.id === id);
                this.$set(this.site.sites[index], 'status', status);
            },
			getSiteFonts(site) {
				this.setProjectFonts(JSON.parse(site.fonts));
			}
		}
	}
</script>
