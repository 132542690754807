<template>
    <div class="makers-publish-content">
        <div class="intro">
            <h1>Pages.</h1>
            <h2>Review titles, descriptions and social media images.</h2>
        </div>
        <section>
            <div class="makers-project-pages">
                <makers-update-page-screen :page="page" :key="index" v-for="(page, index) in pages"></makers-update-page-screen>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        props: ['pages'],
        computed: {
            ...mapState(['homePage']),
        },
    }
</script>