<template>
	<div class="makers-header-site-status" :class="{hide: pages.length <= 0 || user.user == null}">
		<b-dropdown aria-role="list">
            <template #trigger>
				<button class="header-site-status">
					<template v-if="site == null">
						<div class="oval" :style="getStatusOvalColor(null)"></div>
						<div class="status" :style="getStatusColor(null)">{{getStatusName(null)}}</div>
					</template>
					<template v-else-if="site.site != null">
						<template v-if="siteStatus == null || siteStatus == ''">
							<div class="oval" :style="getStatusOvalColor('live')"></div>
							<div class="status" :style="getStatusColor('live')">Published</div>
						</template>
						<template v-else>
							<div class="oval" :style="getStatusOvalColor(siteStatus)"></div>
							<div class="status" :style="getStatusColor(siteStatus)">{{getStatusName(siteStatus)}}</div>
						</template>
					</template>
				</button>
            </template>
            <b-dropdown-item class="site-status-menu" aria-role="listitem">
				<div class="info">
					<div v-if="site.site == null">You haven't published your design yet</div>
					<template v-else>
						<div v-if="siteStatus != 'live' && siteStatus != null && siteStatus != ''" :style="getStatusColor(siteStatus)">We are {{getStatusName(siteStatus).toLowerCase()}} your site...</div>
						<!-- The :key prop is fundamental to update the component -->
						<vue-moments-ago v-else :key="updatedAt" :date="updatedAt" prefix="Published" suffix="ago"  lang="en" />
					</template>
				</div>
				<template v-if="site.site != null">
					<template v-if="siteStatus == 'live' || siteStatus == null || siteStatus == ''">
						<div class="link-wrapper">
							<a v-if="siteStatus == 'live'" class="link text-ellipsis" :href="`https://${site.site.domain.name}`" target="_blank">{{`https://${site.site.domain.name}`}}</a>
							<a v-else class="link text-ellipsis" :href="`${baseDomain}/s/${site.site.id}`" target="_blank">{{baseDomain}}/s/{{site.site.id}}</a>
							<div class="icon icon--small"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8.66667v4.00003c0 .3536-.1405.6927-.3905.9428-.2501.25-.5892.3905-.9428.3905H3.33333c-.35362 0-.69276-.1405-.94281-.3905C2.14048 13.3594 2 13.0203 2 12.6667V5.33333c0-.35362.14048-.69276.39052-.94281C2.64057 4.14048 2.97971 4 3.33333 4h4M10 2h4v4M6.66675 9.33333L14.0001 2" stroke="#678398" stroke-width="1.32493" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
						</div>
						<div class="custom-domain">
							<button v-if="siteStatus != 'live'" class="button button--primary" @click="setCurrentPage({name: 'publish'})">
								Add a Custom Domain
								<span class="makers-pro-badge">PRO</span>
							</button>
							<button v-else class="button button--primary" @click="setCurrentPage({name: 'update-site'})">Update site</button>
						</div>
					</template>
				</template>
            </b-dropdown-item>
        </b-dropdown>
	</div>
</template>
<script>
	import VueMomentsAgo from 'vue-moments-ago';
	import { mapMutations, mapState } from 'vuex';
	import { setStatusColor, setStatusName } from "../../helpers/utilities";
	export default {
		components: {
			VueMomentsAgo
		},
		data() {
			return {
				baseDomain: process.env.VUE_APP_URL,
				updatedAt: '',
			}
		},
		mounted() {
			this.updatedAt = (this.site.site != null) ? this.site.site.updated_at : '';
		},
		computed: {
			...mapState(['user', 'site', 'pages', 'siteStatus']),

			isSubscribed() {
				if (this.user.user == null) return false;
				return this.user.isSubscribed;
			},
		},
		watch: {
			site: {
                handler: function(newVal) {
                    this.updatedAt = newVal.site.updated_at;
                },
                deep: true
            },
		},
		methods: {
			...mapMutations(['setCurrentPage']),

			getStatusOvalColor(status, hasTransparency = false) {
                return (hasTransparency) ? {background: setStatusColor(status).transparent} : {background: setStatusColor(status).color};
            },
            getStatusColor(status) {
                return {color: setStatusColor(status).text};
            },
            getStatusName(status) {
                return setStatusName(status);
            },
		}
	}
</script>
