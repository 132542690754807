<template>
    <div class="makers-publish-content">
        <div class="intro">
            <h1>Custom Domain.</h1>
            <h2>Type your custom domain - it can also be a subdomain.</h2>
        </div>
        <section class="makers-publish-workflow">
            <div class="input-wrapper">
                <div class="pre">
                    <div class="oval"></div>
                    <span class="type type--xsmall">https://</span>
                </div>
                <input v-model="$parent.form.domain" @input="onDomainInput()" type="text" class="input__field input__with_border" placeholder="your.domain.com">
            </div>
            <p v-if="hasDomainError" class="errors-wrapper error type type--xsmall mt-xxsmall">Not a valid domain.</p>
            <p v-if="hasDomainErrorFromServer" class="errors-wrapper error type type--xsmall mt-xxsmall">Domain already in use. Please use a different domain.</p>

            <template v-if="isValidateDomainName($parent.form.domain)">
                <div class="makers-dns-records">
                    <div class="dns-records">
                        <p class="type type--large type--bold">DNS Records</p>
                        <p class="type type--small">In your registrar (DNS provider) set the following A record:</p>
                        <div v-if="hasSubdomain === false" class="dns-table">
                            <div class="row header">
                                <div class="type type--small type--bold">Type</div>
                                <div class="type type--small type--bold">Name</div>
                                <div class="type type--small type--bold">Value</div>
                                <div class="type type--small type--bold">TTL</div>
                            </div>
                            <div class="row body">
                                <div>A</div>
                                <div>@</div>
                                <div>76.76.21.21</div>
                                <div>30 min (1800 sec)</div>
                            </div>
                        </div>
                        <div v-else class="dns-table cname">
                            <div class="row header">
                                <div class="type type--small type--bold">Type</div>
                                <div class="type type--small type--bold">Name</div>
                                <div class="type type--small type--bold">Value</div>
                            </div>
                            <div class="row body">
                                <div>CNAME</div>
                                <div>{{subdomain}}</div>
                                <div>cname.vercel-dns.com</div>
                            </div>
                        </div>

                        <!-- <button class="button button--primary mt-xsmall">
                            <div class="icon icon--play icon--white"></div>
                            Watch Tutorial
                        </button> -->
                    </div>

                    <div class="instructions">
                        <p class="type type--medium type--bold">Instructions</p>
                        <p class="type type--small">Where did you purchase {{$parent.form.domain}}? Select your registrar to see their instructions.</p>
                        <div class="registrar-options">
                            <a  class="registrar" 
                                :href="registrar.url" 
                                :title="registrar.name"
                                target="_blank" rel="noopener noreferrer nofollow" 
                                v-for="(registrar, index) in registrars" :key="index"
                                :style="{'backgroundImage': `url(${registrar.logo})`}"
                            ></a>
                        </div>
                    </div>
                </div>
            </template>
        </section>
    </div>
</template>

<script>
    import { validateDomainName } from '../../helpers/utilities';
    export default {
        props: ['hasDomainErrorFromServer'],
        data() {
            return {
                hasDomainError: false,
                registrars: [
                    {name: "Namecheap", logo: "https://files.namecheap.com/cdn/1091/assets/img/logos/namecheap.svg", url: "https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain"},
                    {name: "GoDaddy", logo: "https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/registrars/logo-godaddy.png", url: "https://www.godaddy.com/help/add-an-a-record-19238"},
                    {name: "Cloudflare", logo: "https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/registrars/logo-cloudflare.png", url: "https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare"},
                    {name: "Google Domains", logo: "https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/registrars/logo-google-domains.jpg", url: "https://support.google.com/domains/answer/9211383?hl=en"},
                    {name: "Domain.com", logo: "https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/registrars/logo-domain.png", url: "https://www.domain.com/help/article/dns-management-how-to-update-a-records"},
                    {name: "Bluehost", logo: "https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/registrars/logo-bluehost.png", url: "https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries"},
                    {name: "Hover", logo: "https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/registrars/logo-hover.png", url: "https://help.hover.com/hc/en-us/articles/217282457-Managing-DNS-records-#h_43dbccfb-a3d3-408b-bd6a-204dab1616cb"},
                    {name: "Dynadot", logo: "https://zecoda.nyc3.digitaloceanspaces.com/makers/assets/registrars/logo-dynadot.png", url: "https://www.dynadot.com/community/help/question/create-A-record"},
                ],
            }
        },
        mouted() {
            this.onDomainInput();
        },
        computed: {
            hasSubdomain() {
                if (this.$parent.isDomainNameValid) {
                    const hasSubdomain = Boolean(new URL(`https://${this.$parent.form.domain}`).hostname.split('.').slice(0, -2).join('.'))
                    return hasSubdomain;
                }
            },
            subdomain() {
                if (this.hasSubdomain) {
                    return new URL(`https://${this.$parent.form.domain}`).hostname.split('.').slice(0, -2).join('.');
                }
            }
        },
        methods: {
            onDomainInput() {
                if (this.$parent.form.domain.length > 5) {
                    this.$parent.isDomainNameValid = validateDomainName(this.$parent.form.domain);
                    this.hasDomainError = (!this.$parent.isDomainNameValid) ? true : false;
                } else {
                    this.$parent.isDomainNameValid = false;
                }

                if (this.$parent.hasDomainErrorFromServer) this.$parent.hasDomainErrorFromServer = false;
            },
            isValidateDomainName(domain) {
                return validateDomainName(domain);
            }
            
        }
    }
</script>