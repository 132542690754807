<template>
	<div class="makers-thank-you-message">
		<div class="header">
			<div class="icon">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17l-5-5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
			</div>
			<p>You made our day!</p>
			<div class="title">Thank you for purchasing your {{service}}.</div>
		</div>
		<div class="body">
			<p class="type type--gray type--semibold">Order Details:</p>
			<p class="type type--black type--medium mt-xxsmall mb-xsmall">{{message}}</p>
			<div class="main-message">
				<p>I hope you enjoy this custom template.</p>
				<p>Thank you for supporting Makers. Your contribution is a big deal for our small company.</p><br />
				<p>Enjoy making sites with Figma!</p><br/>
				<p>John da Maia,</p>
				<p>Founder of <a href="https://makers.so" target="_blank">makers.so</a></p>
			</div>
			<hr>
			<div class="social-links flex justify-content-between align-items-center">
				<div class="start">
					Follow our journey on
				</div>
				<div class="end">
					<a href="https://twitter.com/joaodmj" target="_blank" class="social-circle">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z"/></svg>
					</a>
					<a href="https://www.figma.com/community/plugin/991438050654881175/Makers-%E2%80%A2-From-Figma-to-Site" target="_blank" class="social-circle">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#678398"/><path fill="#678398" d="M4 4h16.1177v16.1177H4z"/><path d="M7.35785 7.69363c0-.62339.24764-1.22124.68844-1.66205.44081-.4408 1.03866-.68844 1.66205-.68844h2.35046v4.70096H9.70834c-.62339 0-1.22124-.24761-1.66205-.68842-.4408-.4408-.68844-1.03866-.68844-1.66205Z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.0588 5.34314h2.3505c.3087 0 .6144.0608.8995.17892.2852.11812.5443.29126.7626.50952.2182.21827.3914.47738.5095.76256.1181.28517.1789.59082.1789.89949 0 .30868-.0608.61432-.1789.8995-.1181.28517-.2913.54429-.5095.76255-.2183.21827-.4774.3914-.7626.50953-.2851.11812-.5908.17889-.8995.17889h-2.3505V5.34314ZM12.0588 12.3947c0-.3087.0608-.6143.179-.8995.1181-.2852.2912-.5443.5095-.7626.2182-.2182.4774-.3914.7625-.5095.2852-.1181.5909-.1789.8995-.1789.3087 0 .6144.0608.8995.1789.2852.1181.5443.2913.7626.5095.2182.2183.3914.4774.5095.7626.1181.2852.1789.5908.1789.8995s-.0608.6143-.1789.8995c-.1181.2852-.2913.5443-.5095.7625-.2183.2183-.4774.3914-.7626.5096-.2851.1181-.5908.1789-.8995.1789-.3086 0-.6143-.0608-.8995-.1789-.2851-.1182-.5443-.2913-.7625-.5096-.2183-.2182-.3914-.4773-.5095-.7625-.1182-.2852-.179-.5908-.179-.8995v0ZM7.35785 17.0956c0-.6234.24764-1.2212.68844-1.662.44081-.4408 1.03866-.6885 1.66205-.6885h2.35046v2.3505c0 .6234-.2476 1.2213-.6884 1.6621-.4408.4408-1.0387.6884-1.66206.6884-.62339 0-1.22124-.2476-1.66205-.6884-.4408-.4408-.68844-1.0387-.68844-1.6621Z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.35785 12.3947c0-.6234.24764-1.2213.68844-1.6621.44081-.4408 1.03866-.6884 1.66205-.6884h2.35046v4.701H9.70834c-.62339 0-1.22124-.2477-1.66205-.6885-.4408-.4408-.68844-1.0386-.68844-1.662Z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['service', 'message'],
		data() {
			return {

			}
		},
		mounted() {
		},
		computed: {
		},
		methods: {
		}
	}
</script>
