<template>
	<div class="makers-notification">
		<div class="icon-wrapper">
			<div class="icon icon--warning-large icon--red"></div>
		</div>
		<div class="message">
			{{ message }}
			<div v-if="slug != null" class="link">
				<a :href="`${baseDomain}/tutorials/${slug}`" target="_blank" class="flex align-items-center" @click="setNotification({show: false})">
					<div>LEARN MORE</div>
				</a>
			</div>
			<button class="close-button" @click="setNotification({show: false})">
				<div class="icon icon--close"></div>
			</button>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex';
	export default {
		props: ['message', 'slug'],
		data() {
			return {
				baseDomain: process.env.VUE_APP_URL,
			}
		},
		mounted() {
		},
		created() {
		},
		methods: {
			...mapMutations(['setNotification']),
		}
	}
</script>
