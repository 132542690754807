import headers from './headers';

// CREATE
async function createSite(body) {
    const URL = `site/`;
    return await axios.post(URL, body, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}});
}

async function createDomain(body) {
    const URL = `domain/`;
    return await axios.post(URL, body, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}});
}

// READ
async function getSiteByFigmaId(id) {
    const URL = `site/${id}`;
    return await axios.get(URL, {headers});
}
async function getSites() {
    const URL = `site`;
    return await axios.get(URL, {headers});
}

// UPDATE
async function updateSite(data) {
    const URL = `site/${data.id}`;
    return await axios.post(URL, data.body, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}});
}
async function publishSite(data) {
    const URL = `publish-site`;
    return await axios.post(URL, data, {headers});
}
async function storeImages(data) {
    const URL = `store-images`;
    return await axios.post(URL, data, {headers});
}
async function updateSiteHomePage(data) {
    const URL = `set-home-page`;
    return await axios.post(URL, data, {headers});
}

// DELETE
async function deleteSite(id) {
    const URL = `site/${id}`;
    return await axios.delete(URL, {headers});
}
async function deleteSiteAll(id) {
    const URL = `site/delete/${id}`;
    return await axios.delete(URL, {headers});
}

export default {
    getSites: () => getSites(),
    deleteSite: (data) => deleteSite(data),
    updateSite: (data) => updateSite(data),
    createSite: (data) => createSite(data),
    publishSite: (data) => publishSite(data),
    storeImages: (data) => storeImages(data),
    createDomain: (data) => createDomain(data),
    deleteSiteAll: (data) => deleteSiteAll(data),
    getSiteByFigmaId: (data) => getSiteByFigmaId(data),
    updateSiteHomePage: (data) => updateSiteHomePage(data),
}