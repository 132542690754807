<template>
    <b-dropdown
        trap-focus
        append-to-body
        aria-role="menu"
        position="is-bottom-left"
		:class="{'is-disabled': isSvg}"
    >
        <template #trigger>
            <button class="button button--secondary" :disabled="isSvg">
				{{ (selectedPage !== null || externalUrl !== null) ? 'Edit link' : 'Add link' }}
				<div class="icon icon--hyperlink" :class="(isSvg) ? 'icon--black3' : ''"></div>
			</button>
        </template>
        <div class="makers-clickable-dropdown">
            <div class="local">
                <b-dropdown class="makers-select-menu" v-model="selectedPage" aria-role="list" >
                    <template #trigger>
                        <button class="button button--tertiary">
                            <div class="text-ellipsis">{{ (selectedPage) ? selectedPage.name : 'Select Page' }}</div>
                            <div class="caret">
                                <div class="icon icon--forward"></div>
                            </div>
                        </button>
                    </template>
                    <b-dropdown-item
						aria-role="listitem"
						:key="index" :value="page"
						@click="selectedPage = page"
						v-for="(page, index) in pagesWithChildren"
						:style="{'padding-left': (page.type === 'section') ? '24px' : ''}"
					>
						<div class="icon" :class="(page.type === 'section') ? 'icon--frame' : 'icon--draft'"></div>
                        <div class="type text-ellipsis" :class="(page.type === 'section') ? 'type--xxsmall' : 'type--small'">{{page.name}}</div>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <small>OR</small>
            <div class="external">
                <div class="field">
                    <div class="input">
                        <input v-model="externalUrl" @input="handleExternalUrlInput()" type="text" class="input__field  input__with_border" placeholder="https://... (Type external URL)">
                    </div>
                    <p v-if="isValidURL === false" class="errors-wrapper error type type--small">Not a valid URL.</p>
                </div>
            </div>
            <b-dropdown-item v-if="selectedPage !== null || isValidURL" class="buttons-wrapper">
                <button class="button button--secondary" @click="removeClickable()">Remove Link</button>
                <button class="button button--primary" @click="handleSave()">Save</button>
            </b-dropdown-item>
        </div>
    </b-dropdown>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import { removeAccent } from '../helpers/utilities';
    import { dispatch, handleEvent} from '../figma/ui-message-handler';
    export default {
        data() {
            return {
                selectedPage: null,
				// pagesWithChildren: null,

                isValidURL: null,
                externalUrl: null,
            }
        },
        mounted() {
			// dispatch("getMakersPagesWithFirstChildren");
			// handleEvent("setMakersPagesWithFirstChildren", pages => this.pagesWithChildren = pages);
            this.readCurrentSelectedNode();

			// console.log("pagesWithChildren", this.pagesWithChildren);
        },
        beforeDestroy() {
            this.isValidURL = null;
            this.externalUrl = null;
        },
        watch: {
            selectedPage(newVal) {
                if (newVal !== null) {
                    this.isValidURL = null;
                    this.externalUrl = null;
                }
            },
            currentSelection() { // Reset values when a new node is selected
                this.isValidURL = null;
                this.externalUrl = null;
                this.selectedPage = null;
                this.readCurrentSelectedNode();
            }
        },
        computed: {
            ...mapState(['pages', 'currentSelection', 'homePage', 'site', 'fileKey', 'documentName', 'user', 'figmaFileJson', 'fonts', 'pagesWithChildren']),

			isSvg() {
				return this.currentSelection.makersExportAsSvg === "true";
			},

			isSelectedPageHomePage() {
				if (this.selectedPage == null) return null;
				if (this.homePage == null) return null;

				const makersPageId = (this.selectedPage.type === 'section') ? this.selectedPage.parent_id : this.selectedPage.id;
				return (makersPageId === this.homePage.id);
			}
        },
        methods: {
            ...mapActions(['createSite']),

            readCurrentSelectedNode() {
                const selectedNode = this.currentSelection;
                if (selectedNode.makersHtmlTag === 'a') {
					if (selectedNode.makersAttrs == null || selectedNode.makersAttrs == "") {return;}
                    const attributes = JSON.parse(selectedNode.makersAttrs);
                    if (attributes.target === "_blank") {
                        this.selectedPage = null;
                        this.externalUrl = attributes.href;
						this.isValidURL = true;
                    } else {
                        this.selectedPage = (this.pagesWithChildren != null) ? this.pagesWithChildren.find(p => p.makersPageUrl === attributes.url) : null;
                        this.externalUrl = null;
                    }
                }
            },
            removeClickable() {
                this.externalUrl = null;
                this.selectedPage = null;

                const attrsData = { id: this.currentSelection.id, key: 'makersAttrs', value: '' };
                const tagData = { id: this.currentSelection.id, key: 'makersHtmlTag', value: 'div' };
                dispatch('updateNode', attrsData);
                dispatch('updateNode', tagData);
            },
            handleSave() {
                // i. Make node an anchor tag
                let attrsData;
                const tagData = { id: this.currentSelection.id, key: 'makersHtmlTag', value: 'a' };
                dispatch('updateNode', tagData);

                // ii. What have the user filled? Local or External links?
                if (this.selectedPage !== null) { // Went for local link
                    attrsData = { id: this.currentSelection.id, key: 'makersAttrs',
                        value: JSON.stringify({
                            href: this.setSelectedPageHref(), target: '_self', url: this.selectedPage.makersPageUrl,
                        })
                    }
                } else {
                    attrsData = { id: this.currentSelection.id, key: 'makersAttrs',
                        value: JSON.stringify({
                            href: this.externalUrl, target: '_blank', url: this.externalUrl,
                        })
                    }
                }
                dispatch('updateNode', attrsData);
            },
            handleExternalUrlInput() {
                if (this.selectedPage !== null) {this.selectedPage = null;}

                this.isValidURL = (this.externalUrl.length > 5) ? this.validateURL(this.externalUrl) : null;
            },
            setSelectedPageHref() {
                if (this.site.site !== null && this.site.site !== {}) {
					return `/s/${this.site.site.id}/${this.selectedPage.makersPageUrl}`;
                } else {
                    // Create new site
                    const body = this.getCreateSitePayloadBody();
                    this.createSite(body)
                        .then((s) => `/s/${s.id}/${this.selectedPage.makersPageUrl}`);
                }
            },
            validateURL(string) {
				if (string.includes("tel:")) return true; // Allow for tel links
                return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(string);
            },

            getCreateSitePayloadBody() {
                const body = new FormData();
                body.append('user_id', this.user.user.id);
                body.append('figma_id', this.fileKey);
                body.append('name', this.documentName);
                body.append('json', JSON.stringify(this.figmaFileJson));
                body.append('fonts', JSON.stringify(this.fonts));
                return body;
            },
        }
    }
</script>
