<template>
    <div class="makers-components-list">

		<makers-selected-node v-if="currentSelection != null" :currentSelection="currentSelection" />

		<section class="tabs">
			<nav>
				<button
					:key="tab.id"
					v-for="tab in tabs"
					class="navigation-item"
					@click="selectedTab = tab.id"
					:class="{'navigation-item--is-selected': tab.id === selectedTab}"
				>
					{{ tab.name }}
				</button>
			</nav>
			<section class="tabs-body">
				<makers-components-tab v-if="selectedTab === 'components'" :currentSelection="currentSelection" @addNewPage="addNewPage"/>
				<makers-templates-tab v-else-if="selectedTab === 'templates'" />
				<makers-tutorials-tab v-else-if="selectedTab === 'tutorials'" />
			</section>
		</section>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    export default {
        data() {
            return {
                isLoading: false,
                isLoadingComponent: false,
                selectedComponent: null,
				tabs: [
					{id: 'components', name: 'Components'},
					{id: 'templates', name: 'Templates'},
					{id: 'tutorials', name: 'Tutorials'},
				],
				selectedTab: 'components'
            }
        },
        computed: {
            ...mapState([
				'pages',
                'components',
                'currentSelection',
            ]),

            setIcon() {
                switch (this.currentSelection.type) {
                    case 'FRAME': return 'component';
                    case 'TEXT': return 'instance';
                }
            }
        },
        methods: {
            ...mapMutations(['setCurrentPage']),

            /******** Components ********/
			addNewPage() {
				this.$emit("addNewPage");
			},

            /******** Pages ********/
            openEditPage() {
				const pageParams = {
					name: 'component',
					params: {
						component: {
							name: 'page',
							node: this.currentSelection,
						}
					}
				};
				this.setCurrentPage(pageParams);
            },
        }
    }
</script>
