import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import user from './modules/user';
import site from './modules/site';
import api from '../api/site_api';
import {components} from '../helpers/components';

export default new Vuex.Store({
    modules: {
        user,
        site,
    },
    state: {
        pages: [],
		fonts: [],
        components,
        homePage: '',
        domain: null,
		tutorials: [],
		templates: [],
        fileKey: null,
        isLoading: false,
		siteStatus: null,
        isPublished: null,
        documentName: null,
        figmaFileJson: null,
		hasMakersPages: null,
        filteredComponents: [],
		newOrUpdatedImages: [],
        currentSelection: null,
		pagesWithChildren: null,
        isPreviewWorkflow: true,
        showEditPageButton: false,
        showSiteStatusFlashCard: false,
		notification: {show: false},
        currentPage: { name: 'home', params: {} },
    },
    actions: {
        async createDomain( {commit}, body) {
            return await api.createDomain(body)
                .then((response) => {
                    commit('setDomain', response.data.name);
                    return response.data.name;
                });
        }
    },
    mutations: {
        setCurrentPage(state, value) {
            state.currentPage = value;
        },
        setPages(state, value) {
            state.pages = value;
        },
		setHasMakersPages(state, value) {
            state.hasMakersPages = value;
        },
        setDocumentName(state, value) {
            state.documentName = value;
        },
        setFigmaFileJson(state, value) {
            state.figmaFileJson = value;
        },
        setIsPublished(state, value) {
            state.isPublished = value;
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        setHomePage(state, value) {
            state.homePage = value;
        },
		setTemplates(state, value) {
			state.templates = value;
		},
		setTutorials(state, value) {
			state.tutorials = value;
		},
        setDomain(state, value) {
            state.domain = value;
        },
        setFileKey(state, value) {
            state.fileKey = value;
        },
		setSiteStatus(state, value) {
			state.siteStatus = value;
		},
		setProjectFonts(state, value) {
            state.fonts = value;
        },
		setPagesWithChildren(state, value) {
            state.pagesWithChildren = value;
        },
        setFilteredComponents(state, value) {
            state.filteredComponents = value;
        },
        setCurrentSelection(state, value) {
            state.currentSelection = value;
        },
        setShowEditPageButton(state, value) {
            state.showEditPageButton = value;
        },
        setIsPreviewWorkflow(state, value) {
            state.isPreviewWorkflow = value;
        },
        setShowSiteStatusFlashCard(state, value) {
            state.showSiteStatusFlashCard = value;
        },
		pushToNewOrUpdatedImages(state, value) {
			state.newOrUpdatedImages.push(value);
		},
		setNotification(state, value) {
			state.notification = value;
		},

        deletePage(state, pageId) {
            const index = state.pages.findIndex(p => p.id === pageId);
            state.pages.splice(index, 1);
        }
    },
})
