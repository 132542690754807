<template>
    <div class="makers-home-back-button" @click="setCurrentPage({name: backPage || 'home'})">
        <div class="button-wrapper">
            <button class="button button--tertiary">
                <div class="icon icon--back"></div>
            </button>
        </div>
    </div>
</template> 
<script>
    import { mapMutations } from 'vuex';
    export default {
        props: ['backPage'],
        methods: {
            ...mapMutations(['setCurrentPage']),
        }
    }
</script>