<template>
    <div class="makers-subscriptions-list">
        <div class="section-item" v-for="(site, index) in subscriptions" :key="index">
            <div class="value">{{site.name}}</div>
            <div class="end">
                <div class="title" v-text="(site.domain != null ? `https://${site.domain.name}` : '')"></div>
                <div class="price">${{price}}/month</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['subscriptions'],
        data() {
            return {
                price: 12
            }
        },
    }
</script>