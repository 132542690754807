<template>
    <div class="makers-sites-page makers-auth-page">
        <makers-component-header header-name="Sites" back-page="home"/>
        <main>
            <template v-if="sites.length === 0">
                <div v-if="isGlobalLoading === true" class="global-loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                <div v-else class="content-wrapper">
                    <h1>No sites yet.</h1>
                    <h2>Start building your site today. Publish it with one click.</h2>
                    <button class="button button--primary" @click="setCurrentPage({name: 'home'})">
                        Create your site
                    </button>
                </div>
            </template>
            <div v-else class="sites-cards-wrapper">
                <div class="site-card" v-for="(site, index) in sortedSites" :key="index">
					<div class="site-info">
						<div class="name text-ellipsis">{{site.name}}</div>
						<div class="info">
							<vue-moments-ago v-if="site.status == null || site.status === 'live'" :key="updatedAt(site)" :date="updatedAt(site)" prefix="Published" suffix="ago"  lang="en" />
							<div v-else :style="getStatusColor(site.status)" class="type type-large type--bold">{{site.status | capitalize}}...</div>
						</div>
						<div class="link-wrapper">
							<a v-if="site.domain != null" class="link text-ellipsis" :href="`https://${site.domain.name}`" target="_blank">{{`https://${site.domain.name}`}}</a>
							<a v-else class="link text-ellipsis" :href="`${baseDomain}/s/${site.id}`" target="_blank">{{baseDomain}}/s/{{site.id}}</a>

							<div class="icon icon--small"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8.66667v4.00003c0 .3536-.1405.6927-.3905.9428-.2501.25-.5892.3905-.9428.3905H3.33333c-.35362 0-.69276-.1405-.94281-.3905C2.14048 13.3594 2 13.0203 2 12.6667V5.33333c0-.35362.14048-.69276.39052-.94281C2.64057 4.14048 2.97971 4 3.33333 4h4M10 2h4v4M6.66675 9.33333L14.0001 2" stroke="#678398" stroke-width="1.32493" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
						</div>
					</div>
                    <div class="options-menu">
                        <b-dropdown v-if="site.status != null" aria-role="list">
                            <template #trigger>
                                <button class="button button--tertiary">
                                    <div class="icon icon--ellipses icon--black3"></div>
                                </button>
                            </template>
                            <b-dropdown-item
                                :value="item"
                                aria-role="listitem"
                                @click="handleClick(item, site)"
                                v-for="(item, index) in sitesOptions" :key="index"
								:class="{
									'is-only': item.id === 'dns', // To cancel margin-bottom for only child
									hide: 	(site.status === 'live' && item.id === 'dns') ||
											(site.status !== 'live' && (item.id === 'update' || item.id === 'delete'))
								}"
                            >
                                <div class="type type--xsmall">{{item.text}}</div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>

                    <!-- <div class="end">
                        <div class="status-wrapper">
                            <div v-if="site.status == null" class="oval" :style="getStatusOvalColor(site.status)"></div>
                            <div v-else class="circles">
                                <div class="pulsing-circle pulsing" :style="getStatusOvalColor(site.status, true)"></div>
                                <div class="pulsing-circle" :style="getStatusOvalColor(site.status)"></div>
                            </div>
                            <div class="status" :style="getStatusColor(site.status)">{{getStatusName(site.status)}}</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </main>
        <div class="modal" :class="{'is-active': isModalOpen}">
            <div class="modal-background" @click="isModalOpen = false"></div>
            <div class="modal-content">
                <header>
                    <div class="title">Are you sure?</div>
                    <button class="close-button" @click="isModalOpen = false">
                        <div class="icon icon--close"></div>
                    </button>
                </header>
                <div class="main-content">
                    If you proceed you will lose all information associated with this site. All site data will be deleted as well. Are you sure you want to proceed?
                </div>
                <footer>
                    <button class="button button--secondary" :disabled="isDeleteSiteLoading" @click="deleteSiteConfirmed()">
                        <template v-if="!isDeleteSiteLoading">Yes, delete this site</template>
                        <div v-else class="icon icon--spinner"></div>
                    </button>
                </footer>
            </div>
        </div>
		<div v-if="selectedSite != null" class="modal" :class="{'is-active': isDnsModalOpen}">
            <div class="modal-background" @click="isDnsModalOpen = false; selectedSite = null;"></div>
            <div class="modal-content">
                <header>
                    <div class="title">DNS Records</div>
                    <button class="close-button" @click="isDnsModalOpen = false; selectedSite = null;">
                        <div class="icon icon--close"></div>
                    </button>
                </header>
                <div class="main-content makers-dns-records" style="margin-top: 0">
                    <div class="dns-records">
                        <p class="type type--small">In your registrar (DNS provider) set the following A record:</p>
                        <div v-if="! (hasSubdomain(selectedSite.domain.name) )" class="dns-table">
                            <div class="row header">
                                <div class="type type--small type--bold">Type</div>
                                <div class="type type--small type--bold">Name</div>
                                <div class="type type--small type--bold">Value</div>
                                <div class="type type--small type--bold">TTL</div>
                            </div>
                            <div class="row body">
                                <div>A</div>
                                <div>@</div>
                                <div>76.76.21.21</div>
                                <div>30 min (1800 sec)</div>
                            </div>
                        </div>
                        <div v-else class="dns-table cname">
                            <div class="row header">
                                <div class="type type--small type--bold">Type</div>
                                <div class="type type--small type--bold">Name</div>
                                <div class="type type--small type--bold">Value</div>
                            </div>
                            <div class="row body">
                                <div>CNAME</div>
                                <div>{{subdomain}}</div>
                                <div>cname.vercel-dns.com</div>
                            </div>
                        </div>

                        <!-- <button class="button button--primary mt-xsmall">
                            <div class="icon icon--play icon--white"></div>
                            Watch Tutorial
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import VueMomentsAgo from 'vue-moments-ago';
    import { dispatch } from '../figma/ui-message-handler';
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { setStatusColor, setStatusName } from "../helpers/utilities";
    export default {
		props: ['controller'],
		components: {
			VueMomentsAgo
		},
        data() {
            return {
                isGlobalLoading: true,
                sitesOptions: [
                    { id: 'dns', text: 'DNS records' },
                    { id: 'update', text: 'Update site' },
                    { id: 'delete', text: 'Delete site' },
                ],
                selectedSite: null,
                isDeleteSiteLoading: false,
                isModalOpen: false,
				isDnsModalOpen: false,

				baseDomain: process.env.VUE_APP_URL,
            }
        },
        mounted() {
            this.getSites().then(() => this.isGlobalLoading = false);
            if (this.sites.length > 0) {this.isGlobalLoading = false;}
        },
        computed: {
            ...mapState(['site', 'isLoading']),

            sites() {
                return this.site.sites;
            },
			sortedSites() {
				return this.sites.sort( (a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				});
			},
			subdomain() {
				if (this.selectedSite === null) return;
				return new URL(`https://${this.selectedSite.domain.name}`).hostname.split('.').slice(0, -2).join('.');
			}
        },
        watch: {
            isModalOpen(newVal) {
                const html = document.getElementsByTagName('HTML')[0];
                (newVal) ? html.classList.add("is-clipped") : html.classList.remove("is-clipped");
            }
        },
        methods: {
            ...mapActions(['getSites', 'deleteSiteAll']),
            ...mapMutations(['setCurrentPage', 'setIsLoading', 'setIsPreviewWorkflow', 'setSite']),

            handleClick(item, site) {
                switch (item.id) {
					case 'dns': this.selectedSite = site; this.isDnsModalOpen = true; break;
                    case 'update': this.setCurrentPage({name: 'update-site'}); break;
                    case 'delete': this.selectedSite = site; this.isModalOpen = true; break;
                }
            },
            handlePreview(site) {
				if (this.site.site.id === site.id) {
					this.setIsLoading(true);
					this.setIsPreviewWorkflow(true);
					this.controller.getAllNodesSaveInDatabaseAndOpenBrowser();
				} else {
					window.open(`${process.env.VUE_APP_URL}/s/${site.id}`, "_blank");
				}
            },
            async deleteSiteConfirmed() {
                // DELETE SITE
                this.isDeleteSiteLoading = true;
                const result = await this.deleteSiteAll(this.selectedSite.id);
                if (result) {
                    this.getSites().then(() => {
                        // If the site I'm deleting is the one I'm currently
                        // working on, Set Site in Vuex as null.
                        // if (this.site.site.id === this.selectedSite.id) {
                        //     this.setSite(null);
                        // }
                        this.selectedSite = null;
                        this.isModalOpen = false;
                        dispatch("notify", "✅ Site Deleted Successfully");
                        this.isGlobalLoading = false;
                        this.isDeleteSiteLoading = false;
                    });
                }
            },

			hasSubdomain(domain) {
				return Boolean(new URL(`https://${domain}`).hostname.split('.').slice(0, -2).join('.'))
            },
            /*
			|--------------------------------------------------------------------------
			| GETTERS
			|--------------------------------------------------------------------------
			*/
            getStatusOvalColor(status, hasTransparency = false) {
                return (hasTransparency) ? {background: setStatusColor(status).transparent} : {background: setStatusColor(status).color};
            },
            getStatusColor(status) {
                return {color: setStatusColor(status).text};
            },
            getStatusName(status) {
                return setStatusName(status);
            },

			updatedAt(site) {
				return site.updated_at;
			}
        },
		filters: {
			capitalize: (s) => s.charAt(0).toUpperCase() + s.slice(1),
		}
    }
</script>
