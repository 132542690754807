<template>
    <div class="makers-publish-content">
        <div v-if="showIntro" class="intro">
            <h1>Favicon & Analytics.</h1>
            <h2>Upload a custom favicon. Add a Google Analytics Tag to your site.</h2>
        </div>
        <section>
            <div class="section-title">Favicon</div>
            <div class="favicon-section-item section-item" @click="openUploadImage()">
                <input
                    type="file"
                    ref="uploadFavicon"
                    accept="image/png, image/jpeg"
                    @change="handleImageSelection()"
                />
                <div class="start">
                    <div class="icon" :class="{'icon--spinner': isLoading}">
                        <img v-if="!isLoading" :src="site.site.favicon" alt="Site favicon">
                    </div>
                    Upload new favicon
                </div>
                <div class="end">
                    <div class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 15v4c0 .5304-.2107 1.0391-.5858 1.4142S19.5304 21 19 21H5c-.53043 0-1.03914-.2107-1.41421-.5858C3.21071 20.0391 3 19.5304 3 19v-4M17 8l-5-5-5 5M12 3v12" stroke="#4A4A4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="section-title">Google Tag Manager</div>
            <div class="analytics-section-item section-item">
                <input v-model="domain.analytics_id" @keyup.enter="saveAnalyticsId()" type="text" class="input__field" placeholder="GTM-XXXX" required>
                <span v-if="domain.analytics_id != null && domain.analytics_id.length > 5" class="type type--xsmall">Press Enter to Save</span>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { validFileType } from '../../helpers/utilities';
    import { dispatch } from '../../figma/ui-message-handler';
    export default {
        props: ['showTitle'],
        data() {
            return {
                isLoading: false,
                selectedFile: null,
            }
        },
        computed: {
            ...mapState(['site', 'fileKey']),
            domain() {
                if (this.site.site == null || this.site.site.domain == null) {return {analytics_id: ''}};
                return this.site.site.domain;
            },
            showIntro() {
                return (this.showTitle == null) ? false : this.showTitle;
            }
        },
        methods: {
            /*
            |--------------------------------------------------------------------------
            | ANALYTICS
            |--------------------------------------------------------------------------
            */
            saveAnalyticsId() {
                if (this.domain.analytics_id == null || this.domain.analytics_id == "") {return;}
                const body = JSON.stringify({'analytics_id': this.domain.analytics_id});
                axios.post(`domain/${this.domain.id}`, body, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
                    .then( () => dispatch("notify", "✅ Google Tag Manager Set Successfully"))
                    .catch( () => dispatch("notify", "❌ Something went wrong. Please upload a new favicon again 🙌"));
            },
            /*
            |--------------------------------------------------------------------------
            | FAVICON
            |--------------------------------------------------------------------------
            */
            handleImageSelection() {
                const input = this.$refs.uploadFavicon;
                this.selectedFile = input.files[0];
                const isImage = validFileType(this.selectedFile, ['image/png', 'image/jpeg'])
                if (isImage) {
                    const imageUrl = URL.createObjectURL(this.selectedFile);
                    this.saveImageInCloud(imageUrl);
                } else {
                    dispatch("notify", "❌ Not a valid image format. Please use png, jpeg or svg 🙌")
                }
            },
            saveImageInCloud(imageUrl) {
                this.isLoading = true;
                const URL = 'site/upload/favicon';
                const data = new FormData();
                data.append('id', this.site.site.id);
                data.append('fileKey', this.fileKey);
                data.append('image', this.selectedFile);
                axios.post(URL, data, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}})
                    .then( (response) => {
                        this.isLoading = false;
                        this.$set(this.site.site, 'favicon', imageUrl); // For UI purposes
                        dispatch("notify", response.data);
                    })
                    .catch( () => {
                        this.isLoading = false;
                        dispatch("notify", "❌ Something went wrong. Please upload a new favicon again 🙌");
                    });
            },
            openUploadImage() {
                this.$refs.uploadFavicon.click();
            }
        }
    }
</script>
