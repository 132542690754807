window.axios = require("axios");
window.axios.defaults.baseURL = `${process.env.VUE_APP_URL}/api`;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

import "./store/subscriber";
import Echo from "laravel-echo";

window.Pusher = require("pusher-js");
window.Echo = new Echo({
  forceTLS: true,
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  authEndpoint: `${process.env.VUE_APP_URL}/api/broadcast`,
});
