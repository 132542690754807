<template>
    <div class="makers-account-page">
        <makers-component-header header-name="Account" back-page="home"/>
        <main>
			<div class="makers-content-wrapper">
				<section id="personal-info">
					<div class="section-title">Personal Info</div>
					<div class="section-item" v-for="(item, index) in personalInfo" :key="index">
						<div class="title">{{item.title}}</div>
						<div class="value">{{item.value}}</div>
					</div>
				</section>
				<section id="subscriptions">
					<div class="section-title">Sites</div>
					<makers-subscriptions-list v-if="subscriptions.length > 0" :subscriptions="subscriptions"></makers-subscriptions-list>
					<template v-else>
						<h2 class="type type--medium type--small">There's nothing here...</h2>
						<p class="type type--small">Start building your site today. Publish it with one click.</p>
						<button class="button button--primary" @click="setCurrentPage({name: 'home'})">
							Create your site
						</button>
					</template>
				</section>
				<!-- <section id="billing">
					<div class="section-title">Billing</div>
				</section> -->
				<section v-if="isSubscribed" id="update-credit-card">
					<div class="section-title">Update Credit Card</div>
					<makers-credit-card :is-to-subscribe="false"></makers-credit-card>
				</section>
				<section id="delete-account">
					<div class="section-title">Delete Account</div>
					<div class="type type--small">We’ll be really sad to see you go. If you decide to delete your account, we only ask to tell us why so that we can improve our product and don’t make the same mistakes.</div>
					<button class="button button--secondary">Delete account</button>
				</section>
			</div>
        </main>
    </div>
</template>
<script>
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    export default {
        data() {
            return {
                billing: [
                    {}
                ]
            }
        },
        mounted() {
            this.getSites();
        },
        computed: {
            ...mapState(['user', 'site']),
            ...mapGetters(['isSubscribed']),

            personalInfo() {
                return [
                    {title: 'Name', value: this.user.user.name},
                    {title: 'Email', value: this.user.user.email},
                ];
            },
            subscriptions() {
                return this.site.sites.filter(s => s.status === 'live');
            }
        },
        methods: {
            ...mapActions(['getSites']),
            ...mapMutations(['setCurrentPage']),
        }
    }
</script>
