<template>
	<div v-if="currentSelection != null" class="switch">
		<input @change="exportAsSvg()" id="exportAsSvg" class="switch__toggle" type="checkbox" :checked="(currentSelection.makersExportAsSvg === 'true')">
		<label class="switch__label type type--xsmall" for="exportAsSvg">Preview as SVG</label>
	</div>
</template>

<script>
	import { dispatch } from '../figma/ui-message-handler';
	export default {
		props: ['currentSelection'],
		methods: {
			exportAsSvg() {
				const exportAsSvg = (this.currentSelection.makersExportAsSvg == 'true');
				const newVal = (this.currentSelection.makersExportAsSvg == null && this.currentSelection.makersExportAsSvg == "" && this.currentSelection.makersExportAsSvg == "false")
								? "true"
								: !exportAsSvg;

				this.currentSelection.makersExportAsSvg = newVal.toString();
				const payload = { id: this.currentSelection.id, key: 'makersExportAsSvg', value: newVal.toString() };
                dispatch('updateNode', payload);
			},
		}
	}
</script>
