<template>
    <div class="makers-home-page">
        <makers-component-header header-name="Admin" back-page="home"/>
        <main>
            <div class="makers-admin">
                <section class="early-users-count">
                    <div v-if="isLoadingEarlyUsers" class="icon icon--spinner"></div>
                    <div class="counter" v-else>{{earlyUsersCount}}</div>
                </section>
				<section class="flex justify-content-between align-items-center mt-small">
					<div class="field">
						<div class="type">File Key: {{fileKey}}</div>
                        <div class="input">
                            <input v-model="form.fileKey" type="text" class="input__field  input__with_border" placeholder="File Key will be empty">
                        </div>
                    </div>
					<button class="button button--secondary" @click="resetFileKey()">
						Reset File Key
					</button>
				</section>
                <section>
                    <div class="section-title">Classes</div>
                    <div class="field">
                        <div class="input">
                            <input v-model="form.classes" @keyup.enter="addClasses()" type="text" class="input__field  input__with_border" placeholder="class, class, class...">
                        </div>
                    </div>
                </section>
                <section>
                    <div class="section-title">HTML tag</div>
                    <div class="field">
                        <div class="input">
                            <input v-model="form.tag" @keyup.enter="addHtmlTag()" type="text" class="input__field  input__with_border" placeholder="HTML tag">
                        </div>
                    </div>
                </section>
                <section>
                    <div class="section-title">Attributes</div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <div class="input">
                                    <input v-model="form.attributes.keys" type="text" class="input__field  input__with_border" placeholder="key, key, key">
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <div class="input">
                                    <input v-model="form.attributes.values" @keyup.enter="addAttributes()" type="text" class="input__field  input__with_border" placeholder="value, value, value">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="section-title">Makers Component</div>
                    <div class="field">
                        <div class="input">
                            <input v-model="form.component" @keyup.enter="addComponent()" type="text" class="input__field  input__with_border" placeholder="Makers Component Name">
                        </div>
                    </div>
                </section>
				<section>
					<div class="section-title">Text Nodes</div>
					<div class="button button--secondary mr-xsmall mb-xsmall justify-content-center" @click="handleTextNodes()" :class="{'is-loading': isLoading}" :disabled="isLoading">
						<div v-if="isLoading" class="icon icon--spinner"></div>
						<template v-else>Handle Text Nodes</template>
					</div>
				</section>
				<section>
					<div class="section-title">Has Vectors?</div>
					<div class="button button--secondary mr-xsmall mb-xsmall justify-content-center" @click="hasVectors()" :class="{'is-loading': isLoading}" :disabled="isLoading">
						<div v-if="isLoading" class="icon icon--spinner"></div>
						<template v-else>Check if Vectors</template>
					</div>
				</section>
                <section v-if="currentSelection != null && currentSelection.type === 'COMPONENT_SET'">
					<div class="section-title">Create Component Set</div>
					<div class="button button--secondary mr-xsmall mb-xsmall" @click="createComponentSet()" :class="{'is-loading': isLoading}" :disabled="isLoading">
						<div v-if="isLoading" class="icon icon--spinner"></div>
						<template v-else>Create Component Set Structure</template>
					</div>
					<json-viewer v-if="componentSetStruture != null" :value="componentSetStruture" :expand-depth=1 expanded copyable boxed sort></json-viewer>
				</section>
				<section>
                    <div class="section-title">Node details</div>
					<div class="flex mb-xsmall">
						<button v-if="currentSelection != null" class="button button--primary mr-xsmall" @click="getNodeDetails()">Get Details</button>
						<!-- <button class="button button--primary mr-xsmall" @click="getPagesWithChildren()">Pages w Children</button> -->
						<div v-if="currentSelection != null" class="switch">
							<input @change="exportAsSvg()" id="exportAsSvg" class="switch__toggle" type="checkbox" :checked="(currentSelection.makersExportAsSvg === 'true')">
							<label class="switch__label type type--small type--medium" for="exportAsSvg">Export as SVG</label>
						</div>
					</div>
                    <json-viewer v-if="currentSelectionDetails != null" :value="currentSelectionDetails" :expand-depth=1 expanded copyable boxed sort></json-viewer>
                </section>
                <section v-if="currentSelection != null">
                    <div class="section-title">JSON Viewer</div>
                    <json-viewer :value="currentSelection" :expand-depth=1 expanded copyable boxed sort></json-viewer>
                </section>
            </div>
        </main>
        <div class="makers-footer">
            <div class="start"></div>
            <div class="end">
                <button class="button button--primary" @click="saveAll()">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
    import JsonViewer from 'vue-json-viewer';
    import { mapState, mapMutations } from 'vuex';
    import { dispatch, handleEvent } from '../figma/ui-message-handler';
    export default {
        components: {JsonViewer},
        data() {
            return {
				isLoading: false,

                earlyUsersCount: '',
                isLoadingEarlyUsers: false,
				componentSetStruture: null,
				currentSelectionDetails: null,
                form: {
                    tag: '',
                    classes: '',
					fileKey: '',
                    component: '',
                    attributes: {keys: '', values: ''},
                }
            }
        },
        mounted() {
            this.getEarlyUsersCount();
        },
        computed: {
            ...mapState(['pages', 'currentSelection', 'fileKey']),
        },
        methods: {
			...mapMutations(['setNotification', 'setFileKey']),

            saveAll() {
                if (this.currentSelection == null) {
                    dispatch("notify", "❌ Select a component to update");
                    return;
                }
                if (this.form.tag != '') this.addHtmlTag();
                if (this.form.classes != '') this.addClasses();
                if (this.form.component != '') this.addComponent();
                if (this.form.attributes.keys != '') this.addAttributes();

                this.resetFields();
            },
            addClasses() {
                const classes = { id: this.currentSelection.id, key: 'makersClasses', value: this.form.classes };
                dispatch('updateNode', classes);
                this.form.classes = '';
            },
            addHtmlTag() {
                const tag = { id: this.currentSelection.id, key: 'makersHtmlTag', value: this.form.tag };
                dispatch('updateNode', tag);
                this.form.tag = '';
            },
            addComponent() {
                const component = { id: this.currentSelection.id, key: 'makersComponent', value: this.form.component };
                dispatch('updateNode', component);
                this.form.component = '';
            },
            addAttributes() {
                const keys = this.form.attributes.keys.split(',');
                const values = this.form.attributes.values.split(',');
                const json = [];
                keys.forEach((key, i) => {
                    json.push(`'${key.replaceAll(" ", "")}': '${values[i].replaceAll(" ", "")}'`);
                });
                const attrs = { id: this.currentSelection.id, key: 'makersAttrs', value: `{${json.join()}}` };
                dispatch('updateNode', attrs);
                this.form.attributes.keys = '';
                this.form.attributes.values = '';
            },
            resetFields() {
                this.form.tag = '';
                this.form.classes = '';
                this.form.component = '';
                this.form.attributes.keys = '';
                this.form.attributes.values = '';
            },
			resetFileKey() {
				console.log("RESET the FILE KEY to empty string");
				const payload = { id: "0:0", key: 'makersFileKey', value: this.form.fileKey };
                dispatch('updateNode', payload);
				this.setFileKey(this.form.fileKey);
			},

			async getNodeDetails() {
				dispatch("getFullNodeObject", this.currentSelection);
				this.currentSelectionDetails = await new Promise((resolve) => handleEvent('setCurrentSelectionDetails', (value) => resolve(JSON.parse(value)) ));
			},

			exportAsSvg() {
				const exportAsSvg = (this.currentSelection.makersExportAsSvg == 'true');
				const newVal = (this.currentSelection.makersExportAsSvg == null && this.currentSelection.makersExportAsSvg == "")
								? "true"
								: !exportAsSvg;

				this.currentSelection.makersExportAsSvg = newVal.toString();
				const payload = { id: this.currentSelection.id, key: 'makersExportAsSvg', value: newVal.toString() };
                dispatch('updateNode', payload);
			},
			async hasVectors() {
				dispatch('hasVectors');
				const vectorType = await new Promise((resolve) => handleEvent('hasVectorsResponse', (value) => resolve(value) ));
				if (vectorType == null) return;

				// Show notification
				this.setNotification({
					show: true,
					slug: this.slugAccordingToNodeType(vectorType),
					message: this.messagesAccordingToNodeType(vectorType) // You have Vector nodes that you are not exporting as SVG. Go to its parent, select it and toggle "Preview as SVG".
				});
			},

			handleTextNodes() {
				if (this.currentSelection == null) console.log("SELECT A NODE!")
				dispatch("handleTextNodes", this.currentSelection);
			},

			messagesAccordingToNodeType(type) {
				switch (type) {
					case "GROUP":
						return "You have a Group node on your project. You have to use Frame nodes instead.";
					default:
						return 'You have Vector nodes that you are not exporting as SVG. Go to its parent, select it and toggle "Preview as SVG".';
				}
			},
			slugAccordingToNodeType(type) {
				switch (type) {
					case "GROUP": return "how-to-export-svg";
					default:  return "how-to-export-svg";
				}
			},

			getPagesWithChildren() {
				// This is for the MakeClickable component
				dispatch('getMakersPagesWithFirstChildren');
			},

			add(component) {
				dispatch("addComponent", {id: component});
			},

			async createComponentSet() {
                // dispatch("addComponent", {id: "nav_bar"});
				this.isLoading = true;
				dispatch("getComponentSetStructure");
				this.componentSetStruture = await new Promise((resolve) => handleEvent('setComponentSetStructure', (value) => {
					resolve(JSON.parse(value));
					this.isLoading = false;
				}));
			},

            async getEarlyUsersCount() {
                this.isLoadingEarlyUsers = true;
                const result = await axios.get('early-users');
                this.earlyUsersCount = result.data;
                if (result) {this.isLoadingEarlyUsers = false;}
            },
        },
        filters: {
            pretty: (value) => JSON.parse(value),
        }
    }

// concatenation of the component name (i.e. "Style=Left") with the component set name (COMPONENT_SET.name ++ COMPONENT.name)
</script>
