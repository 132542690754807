require('./bootstrap');
import { dispatch, handleEvent } from "./figma/ui-message-handler";

import Vue from 'vue';
import Vuex from 'vuex';
import Buefy from 'buefy';
import './styles/app.scss';
import App from './App.vue';
import store from './store';
import './registerServiceWorker';

Vue.use(Vuex);
Vue.use(Buefy);

// VUE INSTANCE
Vue.config.productionTip = false;
const files = require.context('./', true, /\.vue$/i);
files.keys().map((key) => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Re-authenticate if client storage token (check if made on user.js)
dispatch('getClientStorage', 'token');
handleEvent('clientStorageResponse', token => {
    store.dispatch('attemptAuthentication', token).then(() => {
        new Vue({
            el: '#app',
            store,
            render: (h) => h(App),
        });
        dispatch('handleRelaunchDataActions');
    });
});
