<template>
	<div class="set-screen-size-visibility">
		<div class="start">
			<div class="icon-wrapper">
				<div :class="`icon icon--${(hasSomeScreenSizeClass()) ? 'hidden' : 'visible'}`"></div>
			</div>
			<div class="type type--xsmall">Hide element on:</div>
		</div>
		<div class="buttons-group">
			<button
				class="button"
				@click="setScreenSizeVisibility(size)"
				v-for="(size, index) in sizes" :key="index"
				:class="{'button--active': hasScreenSizeClass(size.class) }"
			>
				{{size.display}}

				<span
					class="tooltip"
					:class="{'tooltip--align-right': size.id === 'phone'}"
					v-text="`${ hasScreenSizeClass(size.class) ? 'This element will not be visible' : 'Hide the selected element' } when screen size is ${size.display}`"></span>
			</button>
		</div>
	</div>
</template>

<script>
	import { dispatch } from '../figma/ui-message-handler';
	export default {
		props: ['currentSelection'],
		data() {
			return {
				makersClasses: '',
				sizes: [
					{id: 'desktop', display: 'Desktop', class: 'makers-lg-hide', size: 992},
					{id: 'tablet', display: 'Tablet', class: 'makers-md-hide', size: 768},
					{id: 'phone', display: 'Phone', class: 'makers-sm-hide', size: 572},
				]
			}
		},
		mounted() {
			if (this.currentSelection.makersClasses == "" || this.currentSelection.makersClasses == null) return [];
			this.makersClasses = this.currentSelection.makersClasses.replaceAll(" ", "").split(',');
		},
		watch: {
			currentSelection(newVal) {
				this.makersClasses = newVal.makersClasses.replaceAll(" ", "").split(',');
			}
		},
		methods: {
			setScreenSizeVisibility(size) {
				this.makersClasses = this.makersClasses.includes(size.class) ? this.makersClasses.filter( c => c !== size.class) : [...this.makersClasses, size.class];
				const payload = { id: this.currentSelection.id, key: 'makersClasses', value: this.makersClasses.join() };
                dispatch('updateNode', payload);
			},

			hasScreenSizeClass(sizeClass) {
				return this.makersClasses.includes(sizeClass);
			},

			hasSomeScreenSizeClass() {
				if (this.makersClasses == "") return false;
				return this.makersClasses.some(c => c === 'makers-lg-hide' || c === 'makers-md-hide' || c === 'makers-sm-hide');
			}
		}
	}
</script>
