export const features = () => {
	return featuresJson;
}
const image1 = `https://images.unsplash.com/photo-1579546928937-641f7ac9bced?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80`;
const featuresJson = `[
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20753.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20753",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=facebook",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6547
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6547,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20763",
			  "parent": {
				"id": "423:20753"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6548
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6548,
				"y": 40,
				"width": 23,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20773",
				  "parent": {
					"id": "423:20763"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  6
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6554
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 6,
				  "y": 2,
				  "width": 11,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 6553,
					"y": 41,
					"width": 13,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.326082468032837,
						  "y": 8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 0.9376819133758545,
						  "y": -0.9376819133758545
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.9376819133758545,
						  "y": 0.9376819133758545
						},
						"tangentEnd": {
						  "x": 1.7763568394002505e-15,
						  "y": -1.326082468032837
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0,
						  "y": -0.26521649956703186
						},
						"tangentEnd": {
						  "x": -0.18753637373447418,
						  "y": 0.18753637373447418
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.18753637373447418,
						  "y": -0.18753637373447418
						},
						"tangentEnd": {
						  "x": -0.26521649956703186,
						  "y": -8.881784197001252e-16
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 17,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 11,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.464466094970703,
						"y": 1.4644660949707031,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 8,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.292893409729004,
						"y": 4.292893409729004,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 11 0 L 8 0 C 6.673917531967163 8.881784197001252e-16 5.402148008346558 0.5267841815948486 4.464466094970703 1.4644660949707031 C 3.5267841815948486 2.4021480083465576 3.0000000000000018 3.673917531967163 3 5 L 3 8 L 0 8 L 0 12 L 3 12 L 3 20 L 7 20 L 7 12 L 10 12 L 11 8 L 7 8 L 7 5 C 7 4.734783500432968 7.10535703599453 4.480429783463478 7.292893409729004 4.292893409729004 C 7.480429783463478 4.10535703599453 7.734783500432968 3.999999999999999 8 4 L 11 4 L 11 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "82d12dc4faf76bd68bd955fc000841d61ee30627",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "facebook"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20754.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20754",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitter",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  64
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6591
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 64,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6591,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20764",
			  "parent": {
				"id": "423:20754"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6591
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6591,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20774",
				  "parent": {
					"id": "423:20764"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  2.9899444580078125
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6592
					],
					[
					  0,
					  1,
					  42.98994445800781
					]
				  ],
				  "x": 1,
				  "y": 2.9899444580078125,
				  "width": 22,
				  "height": 17.943681716918945,
				  "absoluteRenderBounds": {
					"x": 6591,
					"y": 41.98994445800781,
					"width": 23.9716796875,
					"height": 19.94371795654297
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.9576150178909302,
						  "y": 0.6754773855209351
						},
						"tangentEnd": {
						  "x": 1.1221078634262085,
						  "y": -0.33789074420928955
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.6022599339485168,
						  "y": -0.6924893856048584
						},
						"tangentEnd": {
						  "x": 0.890299916267395,
						  "y": 0.22276580333709717
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.890299916267395,
						  "y": -0.22276580333709717
						},
						"tangentEnd": {
						  "x": 0.8574152588844299,
						  "y": -0.32725775241851807
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8574152588844299,
						  "y": 0.32725775241851807
						},
						"tangentEnd": {
						  "x": 0.5154598355293274,
						  "y": -0.7593153119087219
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5154598355293274,
						  "y": 0.7593153119087219
						},
						"tangentEnd": {
						  "x": -0.012302441522479057,
						  "y": -0.9176639914512634
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -1.757366418838501,
						  "y": 0.045568279922008514
						},
						"tangentEnd": {
						  "x": 1.570265293121338,
						  "y": 0.7903668880462646
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -1.570265293121338,
						  "y": -0.7903668880462646
						},
						"tangentEnd": {
						  "x": 1.010317087173462,
						  "y": 1.4386355876922607
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 4.440892098500626e-16,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": -9,
						  "y": -4
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.0594723224639893,
						  "y": 1.3979662656784058
						},
						"tangentEnd": {
						  "x": 2.487156867980957,
						  "y": 0.09892935305833817
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 9,
						  "y": 5
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 11.5
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.0009208884439431131,
						  "y": -0.27854636311531067
						},
						"tangentEnd": {
						  "x": 0.052297499030828476,
						  "y": 0.2735944092273712
						}
					  },
					  {
						"start": 12,
						"end": 0,
						"tangentStart": {
						  "x": 1.0206031799316406,
						  "y": -1.0065069198608398
						},
						"tangentEnd": {
						  "x": -0.3391726016998291,
						  "y": 1.3927128314971924
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 22,
						"y": 0.010055708698928356,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.860000610351562,
						"y": 1.5400558710098267,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 16.567033767700195,
						"y": 0.1339818686246872,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.882081031799316,
						"y": 0.29450908303260803,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.772982597351074,
						"y": 1.9637724161148071,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 4.540055751800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11,
						"y": 5.540055274963379,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.931008815765381,
						"y": 4.405501842498779,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 1.010055661201477,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 14.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16.010055541992188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 4.5100555419921875,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.920000076293945,
						"y": 3.680055856704712,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 22 0.010055708698928356 C 21.04238498210907 0.6855330942198634 19.98210847377777 1.202165126800537 18.860000610351562 1.5400558710098267 C 18.257740676403046 0.8475664854049683 17.45733368396759 0.35674767196178436 16.567033767700195 0.1339818686246872 C 15.6767338514328 -0.08878393471240997 14.739496290683746 -0.032748669385910034 13.882081031799316 0.29450908303260803 C 13.024665772914886 0.6217668354511261 12.288442432880402 1.2044571042060852 11.772982597351074 1.9637724161148071 C 11.257522761821747 2.723087728023529 10.987697558477521 3.6223917603492737 11 4.540055751800537 L 11 5.540055274963379 C 9.242633581161499 5.585623554885387 7.501274108886719 5.195868730545044 5.931008815765381 4.405501842498779 C 4.360743522644043 3.6151349544525146 3.010317087173462 2.448691248893738 2 1.010055661201477 C 2.0000000000000004 1.0100556612014762 -2 10.010055541992188 7 14.010055541992188 C 4.940527677536011 15.408021807670593 2.487156867980957 16.108984895050526 0 16.010055541992188 C 9 21.010055541992188 20 16.010055541992188 20 4.5100555419921875 C 19.999079111556057 4.231509178876877 19.972297575324774 3.953650265932083 19.920000076293945 3.680055856704712 C 20.940603256225586 2.673548936843872 21.66082739830017 1.4027685401961207 22 0.010055708698928356 L 22 0.010055708698928356 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "1bd9496a1afd8b52b26d3ec29f94e67427c22d20",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitter"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20755.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20755",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=twitch",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  108
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6635
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 108,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6635,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20765",
			  "parent": {
				"id": "423:20755"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6635
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6635,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20775",
				  "parent": {
					"id": "423:20765"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  3
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6638
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 3,
				  "y": 2,
				  "width": 18,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 6637,
					"y": 41,
					"width": 20,
					"height": 22.000030517578125
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ],
						  [
							8,
							8
						  ],
						  [
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 18,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 9,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 9,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 18 0 L 0 0 L 0 16 L 5 16 L 5 20 L 9 16 L 14 16 L 18 12 L 18 0 Z M 8 9 L 8 5 L 8 9 Z M 13 9 L 13 5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "974c034fef5346bc4767fd992ba882231451bbb3",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "twitch"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20756.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20756",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=github",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  152
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6679
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 152,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6679,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20766",
			  "parent": {
				"id": "423:20756"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  1
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6680
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 1,
			  "y": 0,
			  "width": 22,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6680,
				"y": 40,
				"width": 22,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20776",
				  "parent": {
					"id": "423:20766"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1
					],
					[
					  0,
					  1,
					  0.97320556640625
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6681
					],
					[
					  0,
					  1,
					  40.97320556640625
					]
				  ],
				  "x": 1,
				  "y": 0.97320556640625,
				  "width": 19.5,
				  "height": 21.026792526245117,
				  "absoluteRenderBounds": {
					"x": 6680,
					"y": 40,
					"width": 21.5,
					"height": 23
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ],
						  [
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -5,
						  "y": 1.5
						},
						"tangentEnd": {
						  "x": 2,
						  "y": 0.5
						}
					  },
					  {
						"start": 1,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.03751164302229881,
						  "y": -0.476836234331131
						},
						"tangentEnd": {
						  "x": 0.1620737761259079,
						  "y": 0.45001333951950073
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.1620737761259079,
						  "y": -0.45001333951950073
						},
						"tangentEnd": {
						  "x": 0.332929790019989,
						  "y": 0.3434205651283264
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 3.140000104904175,
						  "y": -0.3499999940395355
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 5.460000038146973
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.00025664002168923616,
						  "y": -1.3961730003356934
						},
						"tangentEnd": {
						  "x": 0.9626920819282532,
						  "y": 1.0111987590789795
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.4558558762073517,
						  "y": -1.2214934825897217
						},
						"tangentEnd": {
						  "x": 0.5136240124702454,
						  "y": 1.1983493566513062
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0,
						  "y": -8.881784197001252e-16
						},
						"tangentEnd": {
						  "x": 2.7300000190734863,
						  "y": -1.8300000429153442
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -2.2919909954071045,
						  "y": -0.6211782693862915
						},
						"tangentEnd": {
						  "x": 2.2919909954071045,
						  "y": -0.6211782693862915
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -2.7300000190734863,
						  "y": -1.8300000429153442
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.5136240124702454,
						  "y": 1.1983493566513062
						},
						"tangentEnd": {
						  "x": -0.4558558762073517,
						  "y": -1.2214934825897217
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.9698728919029236,
						  "y": 1.0186996459960938
						},
						"tangentEnd": {
						  "x": -0.007477347739040852,
						  "y": -1.4065370559692383
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -1.3322676295501878e-15,
						  "y": 5.420000076293945
						},
						"tangentEnd": {
						  "x": -3.140000104904175,
						  "y": -0.38999998569488525
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.3290020823478699,
						  "y": 0.3399706780910492
						},
						"tangentEnd": {
						  "x": 0.161954864859581,
						  "y": -0.4445143938064575
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": -0.161954864859581,
						  "y": 0.4445143938064575
						},
						"tangentEnd": {
						  "x": -0.0331922248005867,
						  "y": -0.47193294763565063
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7,
						"y": 18.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15.026793479919434,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.811015129089355,
						"y": 15.750584602355957,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 13.0600004196167,
						"y": 14.546793937683105,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.5,
						"y": 7.546793460845947,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.90999984741211,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 14,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 1.5067931413650513,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.0900001525878906,
						"y": 0.026793109253048897,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3,
						"y": 3.796792984008789,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.5,
						"y": 7.576793193817139,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.939999580383301,
						"y": 14.576793670654297,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.195308685302734,
						"y": 15.76671314239502,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 17.15679168701172,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 21.026792526245117,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7 18.026792526245117 C 2 19.526792526245117 2 15.526793479919434 0 15.026793479919434 L 7 18.026792526245117 Z M 14 21.026792526245117 L 14 17.15679168701172 C 14.037511643022299 16.679955452680588 13.973088905215263 16.200597941875458 13.811015129089355 15.750584602355957 C 13.648941352963448 15.300571262836456 13.392930209636688 14.890214502811432 13.0600004196167 14.546793937683105 C 16.200000524520874 14.19679394364357 19.5 13.00679349899292 19.5 7.546793460845947 C 19.49974335997831 6.150620460510254 18.962692081928253 4.8079917430877686 18 3.796792984008789 C 18.45585587620735 2.5752995014190674 18.423623859882355 1.225142465904355 17.90999984741211 0.026793109253048897 C 17.90999984741211 0.02679310925304801 16.730000019073486 -0.32320690155029297 14 1.5067931413650513 C 11.708009004592896 0.8856148719787598 9.291990995407104 0.8856148719787598 7 1.5067931413650513 C 4.269999980926514 -0.32320690155029297 3.0900001525878906 0.026793109253048897 3.0900001525878906 0.026793109253048897 C 2.5763761401176453 1.225142465904355 2.5441441237926483 2.5752995014190674 3 3.796792984008789 C 2.0301271080970764 4.815492630004883 1.4925226522609591 6.1702561378479 1.5 7.576793193817139 C 1.4999999999999987 12.996793270111084 4.799999475479126 14.186793684959412 7.939999580383301 14.576793670654297 C 7.610997498035431 14.916764348745346 7.357263550162315 15.322198748588562 7.195308685302734 15.76671314239502 C 7.033353820443153 16.211227536201477 6.966807775199413 16.684858739376068 7 17.15679168701172 L 7 21.026792526245117"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "2456ffdac2afdcfb1b486cff86ffb556c99213db",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "github"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20757.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20757",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=gitlab",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  196
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6723
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 196,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6723,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20767",
			  "parent": {
				"id": "423:20757"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6723
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6723,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20777",
				  "parent": {
					"id": "423:20767"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  1.0078125
					],
					[
					  0,
					  1,
					  1.8874893188476562
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6724.0078125
					],
					[
					  0,
					  1,
					  41.887489318847656
					]
				  ],
				  "x": 1.0078125,
				  "y": 1.8874893188476562,
				  "width": 22.025835037231445,
				  "height": 20.242509841918945,
				  "absoluteRenderBounds": {
					"x": 6723.0078125,
					"y": 40.887489318847656,
					"width": 23.9921875,
					"height": 22.242507934570312
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16,
							17,
							18,
							19,
							20,
							21
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.14277499914169312,
						  "y": -0.10500118136405945
						},
						"tangentEnd": {
						  "x": 0.0538845919072628,
						  "y": 0.16883838176727295
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.0538845919072628,
						  "y": -0.16883838176727295
						},
						"tangentEnd": {
						  "x": -0.055552154779434204,
						  "y": 0.16829708218574524
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": 2.220446049250313e-16
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.023666206747293472,
						  "y": -0.061193037778139114
						},
						"tangentEnd": {
						  "x": -0.048660505563020706,
						  "y": 0.04400945454835892
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.07923946529626846,
						  "y": -0.07237844914197922
						},
						"tangentEnd": {
						  "x": -0.10731976479291916,
						  "y": -4.440892098500626e-16
						}
					  },
					  {
						"start": 15,
						"end": 16,
						"tangentStart": {
						  "x": 0.10731976479291916,
						  "y": 2.220446049250313e-16
						},
						"tangentEnd": {
						  "x": -0.07923946529626846,
						  "y": -0.07237844914197922
						}
					  },
					  {
						"start": 16,
						"end": 17,
						"tangentStart": {
						  "x": 0.05138281732797623,
						  "y": 0.04966484010219574
						},
						"tangentEnd": {
						  "x": -0.020756326615810394,
						  "y": -0.06838102638721466
						}
					  },
					  {
						"start": 17,
						"end": 18,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 18,
						"end": 19,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 19,
						"end": 20,
						"tangentStart": {
						  "x": 0.050522733479738235,
						  "y": 0.17348626255989075
						},
						"tangentEnd": {
						  "x": 0.06305060535669327,
						  "y": -0.16933591663837433
						}
					  },
					  {
						"start": 20,
						"end": 0,
						"tangentStart": {
						  "x": -0.06305060535669327,
						  "y": 0.16933591663837433
						},
						"tangentEnd": {
						  "x": 0.1516815572977066,
						  "y": -0.09819743782281876
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.642332077026367,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.992331504821777,
						"y": 20.242509841918945,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.3423318862915039,
						"y": 12.502510070800781,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.039766304194927216,
						"y": 12.08120059967041,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.04233180731534958,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.2623318433761597,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.702331781387329,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.8123319149017334,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.10233211517334,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.392332077026367,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.5023322105407715,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.942331790924072,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15.042330741882324,
						"y": 7.782509803771973,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.482332229614258,
						"y": 0.27250993251800537,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.59233283996582,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 17.88233184814453,
						"y": 3.680674433215635e-30,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.172332763671875,
						"y": 0.1125098466873169,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 18.282333374023438,
						"y": 0.29250991344451904,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.722332000732422,
						"y": 7.802509307861328,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.992332458496094,
						"y": 11.562509536743164,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.973037719726562,
						"y": 12.090485572814941,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.642332077026367 12.502510070800781 L 10.992331504821777 20.242509841918945 L 0.3423318862915039 12.502510070800781 C 0.1995568871498108 12.397508889436722 0.09365089610219002 12.250038981437683 0.039766304194927216 12.08120059967041 C -0.014118287712335587 11.912362217903137 -0.013220347464084625 11.73080661892891 0.04233180731534958 11.562509536743164 L 1.2623318433761597 7.782509803771973 L 3.702331781387329 0.27250993251800537 C 3.7259979881346226 0.21131689473986626 3.7636714093387127 0.1565193012356758 3.8123319149017334 0.1125098466873169 C 3.891571380198002 0.04013139754533768 3.9950123503804207 2.22044604925035e-16 4.10233211517334 3.680674433215635e-30 C 4.209651879966259 2.22044604925035e-16 4.313092611730099 0.04013139754533768 4.392332077026367 0.1125098466873169 C 4.443714894354343 0.16217468678951263 4.481575883924961 0.22412888705730438 4.5023322105407715 0.29250991344451904 L 6.942331790924072 7.782509803771973 L 15.042330741882324 7.782509803771973 L 17.482332229614258 0.27250993251800537 C 17.50599843636155 0.21131689473986626 17.5436723344028 0.1565193012356758 17.59233283996582 0.1125098466873169 C 17.67157230526209 0.04013139754533768 17.775012083351612 -4.440892098500589e-16 17.88233184814453 3.680674433215635e-30 C 17.98965161293745 2.22044604925035e-16 18.093093298375607 0.04013139754533768 18.172332763671875 0.1125098466873169 C 18.22371558099985 0.16217468678951263 18.261577047407627 0.22412888705730438 18.282333374023438 0.29250991344451904 L 20.722332000732422 7.802509307861328 L 21.992332458496094 11.562509536743164 C 22.042855191975832 11.735995799303055 22.036088325083256 11.921149656176567 21.973037719726562 12.090485572814941 C 21.90998711436987 12.259821489453316 21.794013634324074 12.404312632977962 21.642332077026367 12.502510070800781 L 21.642332077026367 12.502510070800781 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "836f9306c871eb3f831d9f7165f84432e523518b",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "gitlab"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20758.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20758",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=instagram",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  240
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6767
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 240,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6767,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20768",
			  "parent": {
				"id": "423:20758"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6767
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6767,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20778",
				  "parent": {
					"id": "423:20768"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6769
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 6768,
					"y": 41,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -2.7614238262176514
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 2.7614238262176514
						},
						"tangentEnd": {
						  "x": 2.7614238262176514,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -2.7614238262176514,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 2.7614238262176514
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": -2.7614238262176514
						},
						"tangentEnd": {
						  "x": -2.7614238262176514,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 15,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 5 0 L 15 0 C 17.76142382621765 0 20 2.2385761737823486 20 5 L 20 15 C 20 17.76142382621765 17.76142382621765 20 15 20 L 5 20 C 2.2385761737823486 20 0 17.76142382621765 0 15 L 0 5 C 0 2.2385761737823486 2.2385761737823486 0 5 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20783",
				  "parent": {
					"id": "423:20768"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  8.04296875
					],
					[
					  0,
					  1,
					  7.956729888916016
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6775.04296875
					],
					[
					  0,
					  1,
					  47.956729888916016
					]
				  ],
				  "x": 8.04296875,
				  "y": 7.956729888916016,
				  "width": 8.000008583068848,
				  "height": 8.000008583068848,
				  "absoluteRenderBounds": {
					"x": 6774.04296875,
					"y": 46.956729888916016,
					"width": 10,
					"height": 10.000011444091797
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0.1234116330742836,
						  "y": 0.8322460651397705
						},
						"tangentEnd": {
						  "x": 0.38749924302101135,
						  "y": -0.7467986941337585
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.38749924302101135,
						  "y": 0.7467986941337585
						},
						"tangentEnd": {
						  "x": 0.7515201568603516,
						  "y": -0.37826108932495117
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -0.7515201568603516,
						  "y": 0.37826108932495117
						},
						"tangentEnd": {
						  "x": 0.8306610584259033,
						  "y": 0.13366460800170898
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": -0.8306610584259033,
						  "y": -0.13366460800170898
						},
						"tangentEnd": {
						  "x": 0.5949218273162842,
						  "y": 0.5949218273162842
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.5949218273162842,
						  "y": -0.5949218273162842
						},
						"tangentEnd": {
						  "x": 0.13366460800170898,
						  "y": 0.8306610584259033
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.13366460800170898,
						  "y": -0.8306610584259033
						},
						"tangentEnd": {
						  "x": -0.37826108932495117,
						  "y": 0.7515201568603516
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.37826108932495117,
						  "y": -0.7515201568603516
						},
						"tangentEnd": {
						  "x": -0.7467986941337585,
						  "y": 0.38749924302101135
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.7467986941337585,
						  "y": -0.38749924302101135
						},
						"tangentEnd": {
						  "x": -0.8322460651397705,
						  "y": -0.1234116330742836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.8489227890968323,
						  "y": 0.12588457763195038
						},
						"tangentEnd": {
						  "x": -0.6068429946899414,
						  "y": -0.6068429946899414
						}
					  },
					  {
						"start": 9,
						"end": 0,
						"tangentStart": {
						  "x": 0.6068429946899414,
						  "y": 0.6068429946899414
						},
						"tangentEnd": {
						  "x": -0.12588457763195038,
						  "y": -0.8489227890968323
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.956738471984863,
						"y": 3.4132702350616455,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.550497055053711,
						"y": 5.84228515625,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.798364639282227,
						"y": 7.572943687438965,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.3645246028900146,
						"y": 7.949202537536621,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1715772151947021,
						"y": 6.828431129455566,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.05080585181713104,
						"y": 4.635483741760254,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.4270646870136261,
						"y": 2.2016441822052,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1577231884002686,
						"y": 0.44951170682907104,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.586738586425781,
						"y": 0.0432702973484993,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.828432083129883,
						"y": 1.171576738357544,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.956738471984863 3.4132702350616455 C 8.080150105059147 4.245516300201416 7.937996298074722 5.0954864621162415 7.550497055053711 5.84228515625 C 7.1629978120327 6.5890838503837585 6.549884796142578 7.194682598114014 5.798364639282227 7.572943687438965 C 5.046844482421875 7.951204776763916 4.195185661315918 8.08286714553833 3.3645246028900146 7.949202537536621 C 2.5338635444641113 7.815537929534912 1.7664990425109863 7.423352956771851 1.1715772151947021 6.828431129455566 C 0.576655387878418 6.233509302139282 0.18447045981884003 5.466144800186157 0.05080585181713104 4.635483741760254 C -0.08285875618457794 3.8048226833343506 0.04880359768867493 2.9531643390655518 0.4270646870136261 2.2016441822052 C 0.8053257763385773 1.4501240253448486 1.41092449426651 0.8370109498500824 2.1577231884002686 0.44951170682907104 C 2.904521882534027 0.06201246380805969 3.7544925212860107 -0.0801413357257843 4.586738586425781 0.0432702973484993 C 5.4356613755226135 0.16915487498044968 6.221589088439941 0.5647337436676025 6.828432083129883 1.171576738357544 C 7.435275077819824 1.7784197330474854 7.830853894352913 2.5643474459648132 7.956738471984863 3.4132702350616455 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20788",
				  "parent": {
					"id": "423:20768"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  17.5
					],
					[
					  0,
					  1,
					  6.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6784.5
					],
					[
					  0,
					  1,
					  46.5
					]
				  ],
				  "x": 17.5,
				  "y": 6.5,
				  "width": 0.010000228881835938,
				  "height": 0,
				  "absoluteRenderBounds": {
					"x": 6783.5,
					"y": 45.5,
					"width": 2.009765625,
					"height": 2
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.010000228881835938,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0.010000228881835938 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "aa7e8df8ea208fe983048ea1cf613880869a2858",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "instagram"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20759.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20759",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=linkedin",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  284
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6811
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 284,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6811,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20769",
			  "parent": {
				"id": "423:20759"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6811
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6811,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20779",
				  "parent": {
					"id": "423:20769"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  10
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6821
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "x": 10,
				  "y": 8,
				  "width": 12,
				  "height": 13,
				  "absoluteRenderBounds": {
					"x": 6820,
					"y": 47,
					"width": 14,
					"height": 15
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 1.5912989377975464,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": -1.1252182722091675
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": 1.1252182722091675
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.5912989377975464
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": -0.5304329991340637
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": 0.37507274746894836
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": -0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0.5304329991340637,
						  "y": 0
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.5304329991340637,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.37507274746894836,
						  "y": -0.37507274746894836
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.37507274746894836,
						  "y": 0.37507274746894836
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.5304329991340637
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0,
						  "y": -1.5912989377975464
						},
						"tangentEnd": {
						  "x": -1.1252182722091675,
						  "y": 1.1252182722091675
						}
					  },
					  {
						"start": 13,
						"end": 0,
						"tangentStart": {
						  "x": 1.1252182722091675,
						  "y": -1.1252182722091675
						},
						"tangentEnd": {
						  "x": -1.5912989377975464,
						  "y": 8.881784197001252e-16
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 6,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10.24264144897461,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 12,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 8,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7.414213180541992,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.585786819458008,
						"y": 4.585786819458008,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.757359504699707,
						"y": 1.757359504699707,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 6 0 C 7.591298937797546 0 9.117423176765442 0.6321412324905396 10.24264144897461 1.757359504699707 C 11.367859721183777 2.8825777769088745 12 4.408701062202454 12 6 L 12 13 L 8 13 L 8 6 C 8 5.469567000865936 7.7892859280109406 4.960859566926956 7.414213180541992 4.585786819458008 C 7.039140433073044 4.2107140719890594 6.530432999134064 4 6 4 C 5.469567000865936 4 4.960859566926956 4.2107140719890594 4.585786819458008 4.585786819458008 C 4.2107140719890594 4.960859566926956 4 5.469567000865936 4 6 L 4 13 L 0 13 L 0 6 C 0 4.408701062202454 0.6321412324905396 2.8825777769088745 1.757359504699707 1.757359504699707 C 2.8825777769088745 0.6321412324905396 4.408701062202454 8.881784197001252e-16 6 0 L 6 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20784",
				  "parent": {
					"id": "423:20769"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6813
					],
					[
					  0,
					  1,
					  49
					]
				  ],
				  "x": 2,
				  "y": 9,
				  "width": 4,
				  "height": 12,
				  "absoluteRenderBounds": {
					"x": 6812,
					"y": 48,
					"width": 6,
					"height": 14
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 12,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 4 0 L 4 12 L 0 12 L 0 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20789",
				  "parent": {
					"id": "423:20769"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6813
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 4,
				  "height": 4,
				  "absoluteRenderBounds": {
					"x": 6812,
					"y": 41,
					"width": 6,
					"height": 6
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 1.1045695543289185
						},
						"tangentEnd": {
						  "x": 1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 1.1045695543289185
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": -1.1045695543289185
						},
						"tangentEnd": {
						  "x": -1.1045695543289185,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 0,
						"tangentStart": {
						  "x": 1.1045695543289185,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -1.1045695543289185
						}
					  }
					],
					"vertices": [
					  {
						"x": 4,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 4,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 2,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 4 2 C 4 3.1045695543289185 3.1045695543289185 4 2 4 C 0.8954304456710815 4 0 3.1045695543289185 0 2 C 0 0.8954304456710815 0.8954304456710815 0 2 0 C 3.1045695543289185 0 4 0.8954304456710815 4 2 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "f1591310a4320d7b9e04fcafaeae8b2a29a35f2e",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "linkedin"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20760.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20760",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=codepen",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  328
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6855
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 328,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6855,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20770",
			  "parent": {
				"id": "423:20760"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6855
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6855,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20780",
				  "parent": {
					"id": "423:20770"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6857
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 2,
				  "y": 2,
				  "width": 20,
				  "height": 20,
				  "absoluteRenderBounds": {
					"x": 6856,
					"y": 41,
					"width": 22,
					"height": 22
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 20,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 13.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 10 0 L 20 6.5 L 20 13.5 L 10 20 L 0 13.5 L 0 6.5 L 10 0 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20785",
				  "parent": {
					"id": "423:20770"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  15.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6867
					],
					[
					  0,
					  1,
					  55.5
					]
				  ],
				  "x": 12,
				  "y": 15.5,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 6866,
					"y": 54.5,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 6.5 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20790",
				  "parent": {
					"id": "423:20770"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6857
					],
					[
					  0,
					  1,
					  48.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 6856,
					"y": 47.49991226196289,
					"width": 22,
					"height": 9.00008773803711
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 20,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 20 0 L 10 7 L 0 0"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20792",
				  "parent": {
					"id": "423:20770"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  2
					],
					[
					  0,
					  1,
					  8.5
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6857
					],
					[
					  0,
					  1,
					  48.5
					]
				  ],
				  "x": 2,
				  "y": 8.5,
				  "width": 20,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 6856,
					"y": 47.5,
					"width": 22,
					"height": 9.000083923339844
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 10,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 7 L 10 0 L 20 7"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20794",
				  "parent": {
					"id": "423:20770"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6867
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 0,
				  "height": 6.5,
				  "absoluteRenderBounds": {
					"x": 6866,
					"y": 41,
					"width": 2,
					"height": 8.5
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 6.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONE",
					  "data": "M 0 0 L 0 6.5"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "61c4f040dbf53c9292baa4de7446bd579dc87220",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "codepen"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20761.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20761",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=figma",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  372
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6899
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 372,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6899,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20771",
			  "parent": {
				"id": "423:20761"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6899
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6899,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20781",
				  "parent": {
					"id": "423:20771"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6904
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 5,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 6903,
					"y": 41,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 4.440892098500626e-16
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20786",
				  "parent": {
					"id": "423:20771"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  2
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6911
					],
					[
					  0,
					  1,
					  42
					]
				  ],
				  "x": 12,
				  "y": 2,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 6910,
					"y": 41,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": -6.8489693916262695e-9
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914738893509,
						  "y": -0.4246395528316498
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.1758914738893509,
						  "y": 0.4246395528316498
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914589881897
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914589881897
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 6.8489693916262695e-9
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 10,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 3.886141898679611e-16,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664215564727783,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251262187957764,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606078147888184,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839392185211182,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20791",
				  "parent": {
					"id": "423:20771"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  12
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6911
					],
					[
					  0,
					  1,
					  49
					]
				  ],
				  "x": 12,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 6910,
					"y": 48,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -9.685905411060958e-9,
						  "y": -0.45962655544281006
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 9.685901858347279e-9,
						  "y": -0.45962655544281006
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 9.685905411060958e-9,
						  "y": 0.45962655544281006
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": -0.42463958263397217
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": 0.42463958263397217
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": -0.32500505447387695
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": 0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": -0.1758914738893509
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": 0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.45962655544281006,
						  "y": 0
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": -0.45962655544281006,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.4246395528316498,
						  "y": 0.1758914738893509
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": -0.4246395528316498,
						  "y": -0.1758914738893509
						},
						"tangentEnd": {
						  "x": 0.32500505447387695,
						  "y": 0.32500505447387695
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": -0.32500505447387695,
						  "y": -0.32500505447387695
						},
						"tangentEnd": {
						  "x": 0.1758914589881897,
						  "y": 0.42463958263397217
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": -0.1758914589881897,
						  "y": -0.42463958263397217
						},
						"tangentEnd": {
						  "x": -9.685907187417797e-9,
						  "y": 0.45962655544281006
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 7.77230656136968e-16,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 0.2664213180541992,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 2.1606082916259766,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 6.733577728271484,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.974874496459961,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 4.839391708374023,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.1606082916259766,
						"y": 6.733578681945801,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.2664213180541992,
						"y": 4.839391708374023,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20793",
				  "parent": {
					"id": "423:20771"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  16
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6904
					],
					[
					  0,
					  1,
					  56
					]
				  ],
				  "x": 5,
				  "y": 16,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 6903,
					"y": 55,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0.9282577037811279
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0.9282577037811279,
						  "y": 0
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 7,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.025125503540039,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.9748735427856445,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.974874496459961,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20795",
				  "parent": {
					"id": "423:20771"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  5
					],
					[
					  0,
					  1,
					  9
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6904
					],
					[
					  0,
					  1,
					  49
					]
				  ],
				  "x": 5,
				  "y": 9,
				  "width": 7,
				  "height": 7,
				  "absoluteRenderBounds": {
					"x": 6903,
					"y": 48,
					"width": 9,
					"height": 9
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": -0.9282577037811279
						},
						"tangentEnd": {
						  "x": -0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": -0.9282577037811279,
						  "y": 1.7763568394002505e-15
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.9282577037811279,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0.6563773155212402,
						  "y": 0.6563773155212402
						}
					  },
					  {
						"start": 6,
						"end": 0,
						"tangentStart": {
						  "x": -0.6563773155212402,
						  "y": -0.6563773155212402
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0.9282577037811279
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 3.5,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 1.0251264572143555,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 7,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 3.5,
						"y": 7,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.0251264572143555,
						"y": 5.9748735427856445,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b13ce7b8cc27e11f6f2a8feaba3389a1f683a64a",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "figma"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20762.svg",
		  "makersExportAsSvg": "true",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "423:20762",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Logo=youtube",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  416
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  6943
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 416,
		  "y": 20,
		  "width": 24,
		  "height": 24,
		  "absoluteRenderBounds": {
			"x": 6943,
			"y": 40,
			"width": 24,
			"height": 24
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "423:20772",
			  "parent": {
				"id": "423:20762"
			  },
			  "name": "vectors",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  6943
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 24,
			  "height": 24,
			  "absoluteRenderBounds": {
				"x": 6943,
				"y": 40,
				"width": 24,
				"height": 24
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20782",
				  "parent": {
					"id": "423:20772"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0.9990234375
					],
					[
					  0,
					  1,
					  4
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6943.9990234375
					],
					[
					  0,
					  1,
					  44
					]
				  ],
				  "x": 0.9990234375,
				  "y": 4,
				  "width": 22.001144409179688,
				  "height": 15.459999084472656,
				  "absoluteRenderBounds": {
					"x": 6943,
					"y": 43,
					"width": 24,
					"height": 17.459999084472656
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2,
							3,
							4,
							5,
							6,
							7,
							8,
							9,
							10,
							11,
							12,
							13,
							14,
							15,
							16
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": -0.11878856271505356,
						  "y": -0.47459015250205994
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.4707529842853546,
						  "y": 0.13318465650081635
						}
					  },
					  {
						"start": 2,
						"end": 3,
						"tangentStart": {
						  "x": -1.7200000286102295,
						  "y": -0.41999998688697815
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 3,
						"end": 4,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 1.7200000286102295,
						  "y": -0.46000000834465027
						}
					  },
					  {
						"start": 4,
						"end": 5,
						"tangentStart": {
						  "x": -0.4707529842853546,
						  "y": 0.13318465650081635
						},
						"tangentEnd": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						}
					  },
					  {
						"start": 5,
						"end": 6,
						"tangentStart": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						},
						"tangentEnd": {
						  "x": 0.11878856271505356,
						  "y": -0.47459015250205994
						}
					  },
					  {
						"start": 6,
						"end": 7,
						"tangentStart": {
						  "x": -0.3147854506969452,
						  "y": 1.7455555200576782
						},
						"tangentEnd": {
						  "x": -0.008764072321355343,
						  "y": -1.7736902236938477
						}
					  },
					  {
						"start": 7,
						"end": 8,
						"tangentStart": {
						  "x": -0.011220705695450306,
						  "y": 1.7870450019836426
						},
						"tangentEnd": {
						  "x": -0.31723231077194214,
						  "y": -1.7586982250213623
						}
					  },
					  {
						"start": 8,
						"end": 9,
						"tangentStart": {
						  "x": 0.13095924258232117,
						  "y": 0.45984727144241333
						},
						"tangentEnd": {
						  "x": -0.33983731269836426,
						  "y": -0.33633384108543396
						}
					  },
					  {
						"start": 9,
						"end": 10,
						"tangentStart": {
						  "x": 0.33983731269836426,
						  "y": 0.33633384108543396
						},
						"tangentEnd": {
						  "x": -0.4611796438694,
						  "y": -0.12618708610534668
						}
					  },
					  {
						"start": 10,
						"end": 11,
						"tangentStart": {
						  "x": 1.7200000286102295,
						  "y": 0.46000000834465027
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 11,
						"end": 12,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": -1.7200000286102295,
						  "y": 0.46000000834465027
						}
					  },
					  {
						"start": 12,
						"end": 13,
						"tangentStart": {
						  "x": 0.4707529842853546,
						  "y": -0.13318465650081635
						},
						"tangentEnd": {
						  "x": -0.3406306505203247,
						  "y": 0.35116562247276306
						}
					  },
					  {
						"start": 13,
						"end": 14,
						"tangentStart": {
						  "x": 0.3406306505203247,
						  "y": -0.35116562247276306
						},
						"tangentEnd": {
						  "x": -0.11878856271505356,
						  "y": 0.47459015250205994
						}
					  },
					  {
						"start": 14,
						"end": 15,
						"tangentStart": {
						  "x": 0.3123571574687958,
						  "y": -1.732411503791809
						},
						"tangentEnd": {
						  "x": 0.00632609985768795,
						  "y": 1.7603342533111572
						}
					  },
					  {
						"start": 15,
						"end": 0,
						"tangentStart": {
						  "x": 0.011220705695450306,
						  "y": -1.7870450019836426
						},
						"tangentEnd": {
						  "x": 0.31723231077194214,
						  "y": 1.7586982250213623
						}
					  },
					  {
						"start": 0,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 21.540573120117188,
						"y": 2.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 1.1594061851501465,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 0.4200000762939453,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 0.46000003814697266,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.16191828250885,
						"y": 1.1994061470031738,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 2.4600000381469727,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.0005716445157304406,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0.46057167649269104,
						"y": 13.079999923706055,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 1.1787155866622925,
						"y": 14.294479370117188,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 2.400571823120117,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 11.000571250915527,
						"y": 15.459999084472656,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 19.60057258605957,
						"y": 15,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 20.83922576904297,
						"y": 14.26059341430664,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 21.540573120117188,
						"y": 13,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 22.000572204589844,
						"y": 7.75,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 21.540573120117188 2.4200000762939453 C 21.421784557402134 1.9454099237918854 21.179856419563293 1.5105718076229095 20.83922576904297 1.1594061851501465 C 20.498595118522644 0.8082405626773834 20.071325570344925 0.5531847327947617 19.60057258605957 0.4200000762939453 C 17.88057255744934 8.940696716308594e-8 11.000571250915527 0 11.000571250915527 0 C 11.000571250915527 0 4.120571851730347 2.9802322387695312e-8 2.400571823120117 0.46000003814697266 C 1.9298188388347626 0.593184694647789 1.5025489330291748 0.8482405245304108 1.16191828250885 1.1994061470031738 C 0.8212876319885254 1.550571769475937 0.5793602392077446 1.9854098856449127 0.46057167649269104 2.4600000381469727 C 0.14578622579574585 4.205555558204651 -0.008192427805624902 5.976309776306152 0.0005716445157304406 7.75 C -0.010649061179719865 9.537045001983643 0.1433393657207489 11.321301698684692 0.46057167649269104 13.079999923706055 C 0.5915309190750122 13.539847195148468 0.8388782739639282 13.958145529031754 1.1787155866622925 14.294479370117188 C 1.5185528993606567 14.630813211202621 1.9393921792507172 14.873812913894653 2.400571823120117 15 C 4.120571851730347 15.46000000834465 11.000571250915527 15.459999084472656 11.000571250915527 15.459999084472656 C 11.000571250915527 15.459999084472656 17.88057255744934 15.46000000834465 19.60057258605957 15 C 20.071325570344925 14.866815343499184 20.498595118522644 14.611759036779404 20.83922576904297 14.26059341430664 C 21.179856419563293 13.909427791833878 21.421784557402134 13.47459015250206 21.540573120117188 13 C 21.852930277585983 11.267588496208191 22.00689830444753 9.510334253311157 22.000572204589844 7.75 C 22.011792910285294 5.962954998016357 21.85780543088913 4.178698301315308 21.540573120117188 2.4200000762939453 L 21.540573120117188 2.4200000762939453 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "423:20787",
				  "parent": {
					"id": "423:20772"
				  },
				  "name": "Vector",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.40392157435417175,
						"g": 0.5137255191802979,
						"b": 0.5960784554481506
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "CENTER",
				  "strokeJoin": "ROUND",
				  "dashPattern": [],
				  "strokeCap": "ROUND",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  9.75
					],
					[
					  0,
					  1,
					  8.479999542236328
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  6952.75
					],
					[
					  0,
					  1,
					  48.47999954223633
					]
				  ],
				  "x": 9.75,
				  "y": 8.479999542236328,
				  "width": 5.75,
				  "height": 6.540000915527344,
				  "absoluteRenderBounds": {
					"x": 6951.75,
					"y": 47.47999954223633,
					"width": 7.75,
					"height": 8.540000915527344
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "SCALE",
					"vertical": "SCALE"
				  },
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "vectorNetwork": {
					"regions": [
					  {
						"windingRule": "NONZERO",
						"loops": [
						  [
							0,
							1,
							2
						  ]
						]
					  }
					],
					"segments": [
					  {
						"start": 0,
						"end": 1,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 1,
						"end": 2,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  },
					  {
						"start": 2,
						"end": 0,
						"tangentStart": {
						  "x": 0,
						  "y": 0
						},
						"tangentEnd": {
						  "x": 0,
						  "y": 0
						}
					  }
					],
					"vertices": [
					  {
						"x": 0,
						"y": 6.540000915527344,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 5.75,
						"y": 3.270000457763672,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  },
					  {
						"x": 0,
						"y": 0,
						"strokeCap": "ROUND",
						"strokeJoin": "ROUND",
						"cornerRadius": 0,
						"handleMirroring": "NONE"
					  }
					]
				  },
				  "vectorPaths": [
					{
					  "windingRule": "NONZERO",
					  "data": "M 0 6.540000915527344 L 5.75 3.270000457763672 L 0 0 L 0 6.540000915527344 Z"
					}
				  ],
				  "handleMirroring": "NONE",
				  "reactions": [],
				  "type": "VECTOR",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": true,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "NONE",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": false,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": true,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "NONE",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "388d27589cd11ce92ca63c2ec312c1a108fc773f",
		  "reactions": [],
		  "variantProperties": {
			"Logo": "youtube"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-media-object",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3277",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Horizontal",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8567
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 175.5,
		  "height": 217,
		  "absoluteRenderBounds": {
			"x": 8567,
			"y": 40,
			"width": 175.5,
			"height": 217
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3281",
			  "parent": {
				"id": "443:3277"
			  },
			  "name": "Media",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8567
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 40,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 8567,
				"y": 40,
				"width": 40,
				"height": 40
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20761.svg",
				  "makersExportAsSvg": "true",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3285",
				  "parent": {
					"id": "443:3281"
				  },
				  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  8
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8575
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "x": 8,
				  "y": 8,
				  "width": 24,
				  "height": 24,
				  "absoluteRenderBounds": {
					"x": 8575,
					"y": 48,
					"width": 24,
					"height": 24
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I443:3285;423:20771",
					  "parent": {
						"id": "443:3285"
					  },
					  "name": "vectors",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  8575
						],
						[
						  0,
						  1,
						  48
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 8575,
						"y": 48,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3285;423:20781",
						  "parent": {
							"id": "I443:3285;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8580
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 5,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8579,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 4.440892098500626e-16
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3285;423:20786",
						  "parent": {
							"id": "I443:3285;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8587
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 12,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8586,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": -6.8489693916262695e-9
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 6.8489693916262695e-9
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 10,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664215564727783,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606078147888184,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839392185211182,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3285;423:20791",
						  "parent": {
							"id": "I443:3285;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8587
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 12,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8586,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10,
									11,
									12,
									13,
									14,
									15,
									16
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": -9.685905411060958e-9,
								  "y": -0.45962655544281006
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 9.685901858347279e-9,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": 9.685905411060958e-9,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 10,
								"end": 11,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 11,
								"end": 12,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 12,
								"end": 13,
								"tangentStart": {
								  "x": -0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 13,
								"end": 14,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 14,
								"end": 15,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 15,
								"end": 0,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -9.685907187417797e-9,
								  "y": 0.45962655544281006
								}
							  },
							  {
								"start": 0,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 7.77230656136968e-16,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3285;423:20793",
						  "parent": {
							"id": "I443:3285;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  16
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8580
							],
							[
							  0,
							  1,
							  64
							]
						  ],
						  "x": 5,
						  "y": 16,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8579,
							"y": 63,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0.9282577037811279
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 7,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.025125503540039,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.9748735427856445,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3285;423:20795",
						  "parent": {
							"id": "I443:3285;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8580
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 5,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8579,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 1.7763568394002505e-15
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": false,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "423:20761"
				  },
				  "scaleFactor": 1,
				  "reactions": [],
				  "variantProperties": {
					"Logo": "figma"
				  },
				  "type": "INSTANCE",
				  "makersVariantProps": "8"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.16470588743686676,
					"g": 0.7411764860153198,
					"b": 0.6392157077789307
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 6,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 6,
			  "topRightRadius": 6,
			  "bottomLeftRadius": 6,
			  "bottomRightRadius": 6,
			  "paddingLeft": 8,
			  "paddingRight": 8,
			  "paddingTop": 8,
			  "paddingBottom": 8,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3321",
			  "parent": {
				"id": "443:3277"
			  },
			  "name": "Text",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  56
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8623
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 56,
			  "y": 0,
			  "width": 119.5,
			  "height": 217,
			  "absoluteRenderBounds": {
				"x": 8623,
				"y": 40,
				"width": 119.5,
				"height": 217
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 1,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3325",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3321"
				  },
				  "name": "Publish with Figma",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0,
						"g": 0,
						"b": 0
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8623
					],
					[
					  0,
					  1,
					  40
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 119.5,
				  "height": 72,
				  "absoluteRenderBounds": {
					"x": 8623.587890625,
					"y": 42.70170593261719,
					"width": 83.4228515625,
					"height": 71.48011016845703
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Publish with Figma",
				  "hasMissingFont": false,
				  "fontSize": 24,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 24
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "LEFT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3329",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3321"
				  },
				  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.03947368264198303,
						"g": 0.16421058773994446,
						"b": 0.2605263292789459
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  80
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8623
					],
					[
					  0,
					  1,
					  120
					]
				  ],
				  "x": 0,
				  "y": 80,
				  "width": 119.5,
				  "height": 108,
				  "absoluteRenderBounds": {
					"x": 8623.5625,
					"y": 126.5,
					"width": 107.2548828125,
					"height": 94.7301025390625
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Regular"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "LEFT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3333",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3321"
				  },
				  "name": "Call To Action",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  196
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8623
					],
					[
					  0,
					  1,
					  236
					]
				  ],
				  "x": 0,
				  "y": 196,
				  "width": 119.5,
				  "height": 21,
				  "absoluteRenderBounds": {
					"x": 8623.74609375,
					"y": 241.32598876953125,
					"width": 86.9345703125,
					"height": 10.828125
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Call to action",
				  "hasMissingFont": false,
				  "fontSize": 14,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": false,
				  "textAlignHorizontal": "LEFT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 8,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "FIXED",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "e1db421096409ab3bbaee6d1ce8af7708d834cf7",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Horizontal"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-media-object",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3278",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Vertical",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  235.5
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8782.5
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 235.5,
		  "y": 20,
		  "width": 175.5,
		  "height": 222,
		  "absoluteRenderBounds": {
			"x": 8782.5,
			"y": 40,
			"width": 175.5,
			"height": 222
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3282",
			  "parent": {
				"id": "443:3278"
			  },
			  "name": "Media",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8782.5
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 40,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 8782.5,
				"y": 40,
				"width": 40,
				"height": 40
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20761.svg",
				  "makersExportAsSvg": "true",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3288",
				  "parent": {
					"id": "443:3282"
				  },
				  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  8
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8790.5
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "x": 8,
				  "y": 8,
				  "width": 24,
				  "height": 24,
				  "absoluteRenderBounds": {
					"x": 8790.5,
					"y": 48,
					"width": 24,
					"height": 24
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I443:3288;423:20771",
					  "parent": {
						"id": "443:3288"
					  },
					  "name": "vectors",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  8790.5
						],
						[
						  0,
						  1,
						  48
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 8790.5,
						"y": 48,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3288;423:20781",
						  "parent": {
							"id": "I443:3288;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8795.5
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 5,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8794.5,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 4.440892098500626e-16
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3288;423:20786",
						  "parent": {
							"id": "I443:3288;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8802.5
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 12,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8801.5,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": -6.8489693916262695e-9
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 6.8489693916262695e-9
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 10,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664215564727783,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606078147888184,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839392185211182,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3288;423:20791",
						  "parent": {
							"id": "I443:3288;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8802.5
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 12,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8801.5,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10,
									11,
									12,
									13,
									14,
									15,
									16
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": -9.685905411060958e-9,
								  "y": -0.45962655544281006
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 9.685901858347279e-9,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": 9.685905411060958e-9,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 10,
								"end": 11,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 11,
								"end": 12,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 12,
								"end": 13,
								"tangentStart": {
								  "x": -0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 13,
								"end": 14,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 14,
								"end": 15,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 15,
								"end": 0,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -9.685907187417797e-9,
								  "y": 0.45962655544281006
								}
							  },
							  {
								"start": 0,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 7.77230656136968e-16,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3288;423:20793",
						  "parent": {
							"id": "I443:3288;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  16
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8795.5
							],
							[
							  0,
							  1,
							  64
							]
						  ],
						  "x": 5,
						  "y": 16,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8794.5,
							"y": 63,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0.9282577037811279
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 7,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.025125503540039,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.9748735427856445,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3288;423:20795",
						  "parent": {
							"id": "I443:3288;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  8795.5
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 5,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 8794.5,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 1.7763568394002505e-15
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": false,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "423:20761"
				  },
				  "scaleFactor": 1,
				  "reactions": [],
				  "variantProperties": {
					"Logo": "figma"
				  },
				  "type": "INSTANCE",
				  "makersVariantProps": "8"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.16470588743686676,
					"g": 0.7411764860153198,
					"b": 0.6392157077789307
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 6,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 6,
			  "topRightRadius": 6,
			  "bottomLeftRadius": 6,
			  "bottomRightRadius": 6,
			  "paddingLeft": 8,
			  "paddingRight": 8,
			  "paddingTop": 8,
			  "paddingBottom": 8,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3322",
			  "parent": {
				"id": "443:3278"
			  },
			  "name": "Text",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  56
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8782.5
				],
				[
				  0,
				  1,
				  96
				]
			  ],
			  "x": 0,
			  "y": 56,
			  "width": 175.5,
			  "height": 166,
			  "absoluteRenderBounds": {
				"x": 8782.5,
				"y": 96,
				"width": 175.5,
				"height": 166
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3326",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3322"
				  },
				  "name": "Publish with Figma",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0,
						"g": 0,
						"b": 0
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8782.5
					],
					[
					  0,
					  1,
					  96
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 175.5,
				  "height": 48,
				  "absoluteRenderBounds": {
					"x": 8784.2216796875,
					"y": 98.70170593261719,
					"width": 138.703125,
					"height": 47.48011779785156
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Publish with Figma",
				  "hasMissingFont": false,
				  "fontSize": 24,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 24
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "LEFT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3330",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3322"
				  },
				  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.03947368264198303,
						"g": 0.16421058773994446,
						"b": 0.2605263292789459
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  56
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8782.5
					],
					[
					  0,
					  1,
					  152
					]
				  ],
				  "x": 0,
				  "y": 56,
				  "width": 175.5,
				  "height": 81,
				  "absoluteRenderBounds": {
					"x": 8783.0625,
					"y": 158.5,
					"width": 156.9169921875,
					"height": 67.7301025390625
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Regular"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "LEFT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3334",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3322"
				  },
				  "name": "Call To Action",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  145
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8782.5
					],
					[
					  0,
					  1,
					  241
					]
				  ],
				  "x": 0,
				  "y": 145,
				  "width": 175.5,
				  "height": 21,
				  "absoluteRenderBounds": {
					"x": 8783.24609375,
					"y": 246.32598876953125,
					"width": 86.9345703125,
					"height": 10.828125
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Call to action",
				  "hasMissingFont": false,
				  "fontSize": 14,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": false,
				  "textAlignHorizontal": "LEFT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 8,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "58504651c9117239b676d373b79ef404c30c36e6",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Vertical"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-media-object",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3279",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Vertical Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  451
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  8998
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 451,
		  "y": 20,
		  "width": 175.5,
		  "height": 222,
		  "absoluteRenderBounds": {
			"x": 8998,
			"y": 40,
			"width": 175.5,
			"height": 222
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3283",
			  "parent": {
				"id": "443:3279"
			  },
			  "name": "Media",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  67.75
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9065.75
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 67.75,
			  "y": 0,
			  "width": 40,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 9065.75,
				"y": 40,
				"width": 40,
				"height": 40
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20761.svg",
				  "makersExportAsSvg": "true",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3291",
				  "parent": {
					"id": "443:3283"
				  },
				  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  8
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9073.75
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "x": 8,
				  "y": 8,
				  "width": 24,
				  "height": 24,
				  "absoluteRenderBounds": {
					"x": 9073.75,
					"y": 48,
					"width": 24,
					"height": 24
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I443:3291;423:20771",
					  "parent": {
						"id": "443:3291"
					  },
					  "name": "vectors",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9073.75
						],
						[
						  0,
						  1,
						  48
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 9073.75,
						"y": 48,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3291;423:20781",
						  "parent": {
							"id": "I443:3291;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9078.75
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 5,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9077.75,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 4.440892098500626e-16
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3291;423:20786",
						  "parent": {
							"id": "I443:3291;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9085.75
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 12,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9084.75,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": -6.8489693916262695e-9
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 6.8489693916262695e-9
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 10,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664215564727783,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606078147888184,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839392185211182,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3291;423:20791",
						  "parent": {
							"id": "I443:3291;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9085.75
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 12,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9084.75,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10,
									11,
									12,
									13,
									14,
									15,
									16
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": -9.685905411060958e-9,
								  "y": -0.45962655544281006
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 9.685901858347279e-9,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": 9.685905411060958e-9,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 10,
								"end": 11,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 11,
								"end": 12,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 12,
								"end": 13,
								"tangentStart": {
								  "x": -0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 13,
								"end": 14,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 14,
								"end": 15,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 15,
								"end": 0,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -9.685907187417797e-9,
								  "y": 0.45962655544281006
								}
							  },
							  {
								"start": 0,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 7.77230656136968e-16,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3291;423:20793",
						  "parent": {
							"id": "I443:3291;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  16
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9078.75
							],
							[
							  0,
							  1,
							  64
							]
						  ],
						  "x": 5,
						  "y": 16,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9077.75,
							"y": 63,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0.9282577037811279
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 7,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.025125503540039,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.9748735427856445,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3291;423:20795",
						  "parent": {
							"id": "I443:3291;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9078.75
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 5,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9077.75,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 1.7763568394002505e-15
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": false,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "423:20761"
				  },
				  "scaleFactor": 1,
				  "reactions": [],
				  "variantProperties": {
					"Logo": "figma"
				  },
				  "type": "INSTANCE",
				  "makersVariantProps": "8"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.16470588743686676,
					"g": 0.7411764860153198,
					"b": 0.6392157077789307
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 6,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 6,
			  "topRightRadius": 6,
			  "bottomLeftRadius": 6,
			  "bottomRightRadius": 6,
			  "paddingLeft": 8,
			  "paddingRight": 8,
			  "paddingTop": 8,
			  "paddingBottom": 8,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3323",
			  "parent": {
				"id": "443:3279"
			  },
			  "name": "Text",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  56
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  8998
				],
				[
				  0,
				  1,
				  96
				]
			  ],
			  "x": 0,
			  "y": 56,
			  "width": 175.5,
			  "height": 166,
			  "absoluteRenderBounds": {
				"x": 8998,
				"y": 96,
				"width": 175.5,
				"height": 166
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3327",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3323"
				  },
				  "name": "Publish with Figma",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0,
						"g": 0,
						"b": 0
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8998
					],
					[
					  0,
					  1,
					  96
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 175.5,
				  "height": 48,
				  "absoluteRenderBounds": {
					"x": 9016.4794921875,
					"y": 98.70170593261719,
					"width": 138.703125,
					"height": 47.48011779785156
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Publish with Figma",
				  "hasMissingFont": false,
				  "fontSize": 24,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 24
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3331",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3323"
				  },
				  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.03947368264198303,
						"g": 0.16421058773994446,
						"b": 0.2605263292789459
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  56
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8998
					],
					[
					  0,
					  1,
					  152
					]
				  ],
				  "x": 0,
				  "y": 56,
				  "width": 175.5,
				  "height": 81,
				  "absoluteRenderBounds": {
					"x": 9008.3916015625,
					"y": 158.5,
					"width": 155.89453125,
					"height": 67.7301025390625
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Regular"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3335",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3323"
				  },
				  "name": "Call To Action",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  145
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  8998
					],
					[
					  0,
					  1,
					  241
					]
				  ],
				  "x": 0,
				  "y": 145,
				  "width": 175.5,
				  "height": 21,
				  "absoluteRenderBounds": {
					"x": 9042.2060546875,
					"y": 246.32598876953125,
					"width": 86.9345703125,
					"height": 10.828125
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Call to action",
				  "hasMissingFont": false,
				  "fontSize": 14,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": false,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 8,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "e1a500a4ce9b3228fed698c6cd9423a28f7926d1",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Vertical Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-media-object",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3280",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Vertical Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  666.5
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  9213.5
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 666.5,
		  "y": 20,
		  "width": 175.5,
		  "height": 222,
		  "absoluteRenderBounds": {
			"x": 9213.5,
			"y": 40,
			"width": 175.5,
			"height": 222
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3284",
			  "parent": {
				"id": "443:3280"
			  },
			  "name": "Media",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  135.5
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9349
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 135.5,
			  "y": 0,
			  "width": 40,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 9349,
				"y": 40,
				"width": 40,
				"height": 40
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20761.svg",
				  "makersExportAsSvg": "true",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3294",
				  "parent": {
					"id": "443:3284"
				  },
				  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  8
					],
					[
					  0,
					  1,
					  8
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9357
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "x": 8,
				  "y": 8,
				  "width": 24,
				  "height": 24,
				  "absoluteRenderBounds": {
					"x": 9357,
					"y": 48,
					"width": 24,
					"height": 24
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I443:3294;423:20771",
					  "parent": {
						"id": "443:3294"
					  },
					  "name": "vectors",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9357
						],
						[
						  0,
						  1,
						  48
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 24,
					  "height": 24,
					  "absoluteRenderBounds": {
						"x": 9357,
						"y": 48,
						"width": 24,
						"height": 24
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3294;423:20781",
						  "parent": {
							"id": "I443:3294;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9362
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 5,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9361,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 4.440892098500626e-16
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3294;423:20786",
						  "parent": {
							"id": "I443:3294;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  2
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9369
							],
							[
							  0,
							  1,
							  50
							]
						  ],
						  "x": 12,
						  "y": 2,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9368,
							"y": 49,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": -6.8489693916262695e-9
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914738893509,
								  "y": -0.4246395528316498
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.1758914738893509,
								  "y": 0.4246395528316498
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914589881897
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914589881897
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 6.8489693916262695e-9
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 10,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 3.886141898679611e-16,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664215564727783,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251262187957764,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606078147888184,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839392185211182,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3294;423:20791",
						  "parent": {
							"id": "I443:3294;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  12
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9369
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 12,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9368,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10,
									11,
									12,
									13,
									14,
									15,
									16
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": -9.685905411060958e-9,
								  "y": -0.45962655544281006
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 7,
								"end": 8,
								"tangentStart": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 9.685901858347279e-9,
								  "y": -0.45962655544281006
								}
							  },
							  {
								"start": 8,
								"end": 9,
								"tangentStart": {
								  "x": 9.685905411060958e-9,
								  "y": 0.45962655544281006
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": -0.42463958263397217
								}
							  },
							  {
								"start": 9,
								"end": 10,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": 0.42463958263397217
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": -0.32500505447387695
								}
							  },
							  {
								"start": 10,
								"end": 11,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": 0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": -0.1758914738893509
								}
							  },
							  {
								"start": 11,
								"end": 12,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": 0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.45962655544281006,
								  "y": 0
								}
							  },
							  {
								"start": 12,
								"end": 13,
								"tangentStart": {
								  "x": -0.45962655544281006,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.4246395528316498,
								  "y": 0.1758914738893509
								}
							  },
							  {
								"start": 13,
								"end": 14,
								"tangentStart": {
								  "x": -0.4246395528316498,
								  "y": -0.1758914738893509
								},
								"tangentEnd": {
								  "x": 0.32500505447387695,
								  "y": 0.32500505447387695
								}
							  },
							  {
								"start": 14,
								"end": 15,
								"tangentStart": {
								  "x": -0.32500505447387695,
								  "y": -0.32500505447387695
								},
								"tangentEnd": {
								  "x": 0.1758914589881897,
								  "y": 0.42463958263397217
								}
							  },
							  {
								"start": 15,
								"end": 0,
								"tangentStart": {
								  "x": -0.1758914589881897,
								  "y": -0.42463958263397217
								},
								"tangentEnd": {
								  "x": -9.685907187417797e-9,
								  "y": 0.45962655544281006
								}
							  },
							  {
								"start": 0,
								"end": 0,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  }
							],
							"vertices": [
							  {
								"x": 7.77230656136968e-16,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 0.2664213180541992,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 2.1606082916259766,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 6.733577728271484,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.974874496459961,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 4.839391708374023,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 2.1606082916259766,
								"y": 6.733578681945801,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 0.2664213180541992,
								"y": 4.839391708374023,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3294;423:20793",
						  "parent": {
							"id": "I443:3294;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  16
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9362
							],
							[
							  0,
							  1,
							  64
							]
						  ],
						  "x": 5,
						  "y": 16,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9361,
							"y": 63,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6,
									7
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0.9282577037811279
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0.9282577037811279,
								  "y": 0
								}
							  },
							  {
								"start": 6,
								"end": 7,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 7,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.025125503540039,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 5.9748735427856445,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.974874496459961,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3294;423:20795",
						  "parent": {
							"id": "I443:3294;423:20771"
						  },
						  "name": "Vector",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 1,
								"g": 1,
								"b": 1
							  }
							}
						  ],
						  "strokeStyleId": "",
						  "strokeWeight": 2,
						  "strokeAlign": "CENTER",
						  "strokeJoin": "ROUND",
						  "dashPattern": [],
						  "strokeCap": "ROUND",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  5
							],
							[
							  0,
							  1,
							  9
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9362
							],
							[
							  0,
							  1,
							  57
							]
						  ],
						  "x": 5,
						  "y": 9,
						  "width": 7,
						  "height": 7,
						  "absoluteRenderBounds": {
							"x": 9361,
							"y": 56,
							"width": 9,
							"height": 9
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "SCALE",
							"vertical": "SCALE"
						  },
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "vectorNetwork": {
							"regions": [
							  {
								"windingRule": "NONZERO",
								"loops": [
								  [
									0,
									1,
									2,
									3,
									4,
									5,
									6
								  ]
								]
							  }
							],
							"segments": [
							  {
								"start": 0,
								"end": 1,
								"tangentStart": {
								  "x": 0,
								  "y": -0.9282577037811279
								},
								"tangentEnd": {
								  "x": -0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 1,
								"end": 2,
								"tangentStart": {
								  "x": 0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": -0.9282577037811279,
								  "y": 1.7763568394002505e-15
								}
							  },
							  {
								"start": 2,
								"end": 3,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 3,
								"end": 4,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 4,
								"end": 5,
								"tangentStart": {
								  "x": 0,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0
								}
							  },
							  {
								"start": 5,
								"end": 6,
								"tangentStart": {
								  "x": -0.9282577037811279,
								  "y": 0
								},
								"tangentEnd": {
								  "x": 0.6563773155212402,
								  "y": 0.6563773155212402
								}
							  },
							  {
								"start": 6,
								"end": 0,
								"tangentStart": {
								  "x": -0.6563773155212402,
								  "y": -0.6563773155212402
								},
								"tangentEnd": {
								  "x": 0,
								  "y": 0.9282577037811279
								}
							  }
							],
							"vertices": [
							  {
								"x": 0,
								"y": 3.5,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 1.0251264572143555,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 0,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 7,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 3.5,
								"y": 7,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  },
							  {
								"x": 1.0251264572143555,
								"y": 5.9748735427856445,
								"strokeCap": "ROUND",
								"strokeJoin": "ROUND",
								"cornerRadius": 0,
								"handleMirroring": "NONE"
							  }
							]
						  },
						  "vectorPaths": [
							{
							  "windingRule": "NONZERO",
							  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
							}
						  ],
						  "handleMirroring": "NONE",
						  "reactions": [],
						  "type": "VECTOR",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": true,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "NONE",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": false,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": true,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "NONE",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "423:20761"
				  },
				  "scaleFactor": 1,
				  "reactions": [],
				  "variantProperties": {
					"Logo": "figma"
				  },
				  "type": "INSTANCE",
				  "makersVariantProps": "8"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.16470588743686676,
					"g": 0.7411764860153198,
					"b": 0.6392157077789307
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 6,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 6,
			  "topRightRadius": 6,
			  "bottomLeftRadius": 6,
			  "bottomRightRadius": 6,
			  "paddingLeft": 8,
			  "paddingRight": 8,
			  "paddingTop": 8,
			  "paddingBottom": 8,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3324",
			  "parent": {
				"id": "443:3280"
			  },
			  "name": "Text",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  56
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9213.5
				],
				[
				  0,
				  1,
				  96
				]
			  ],
			  "x": 0,
			  "y": 56,
			  "width": 175.5,
			  "height": 166,
			  "absoluteRenderBounds": {
				"x": 9213.5,
				"y": 96,
				"width": 175.5,
				"height": 166
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3328",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3324"
				  },
				  "name": "Publish with Figma",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0,
						"g": 0,
						"b": 0
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9213.5
					],
					[
					  0,
					  1,
					  96
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 175.5,
				  "height": 48,
				  "absoluteRenderBounds": {
					"x": 9248.7373046875,
					"y": 98.70170593261719,
					"width": 138.74609375,
					"height": 47.48011779785156
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Publish with Figma",
				  "hasMissingFont": false,
				  "fontSize": 24,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 24
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "RIGHT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3332",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3324"
				  },
				  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.03947368264198303,
						"g": 0.16421058773994446,
						"b": 0.2605263292789459
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  56
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9213.5
					],
					[
					  0,
					  1,
					  152
					]
				  ],
				  "x": 0,
				  "y": 56,
				  "width": 175.5,
				  "height": 81,
				  "absoluteRenderBounds": {
					"x": 9232.6982421875,
					"y": 158.5,
					"width": 155.89453125,
					"height": 67.7301025390625
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Regular"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "RIGHT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3336",
				  "listSpacing": 0,
				  "parent": {
					"id": "443:3324"
				  },
				  "name": "Call To Action",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  145
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9213.5
					],
					[
					  0,
					  1,
					  241
					]
				  ],
				  "x": 0,
				  "y": 145,
				  "width": 175.5,
				  "height": 21,
				  "absoluteRenderBounds": {
					"x": 9301.166015625,
					"y": 246.32598876953125,
					"width": 86.9345703125,
					"height": 10.828125
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Call to action",
				  "hasMissingFont": false,
				  "fontSize": 14,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PERCENT",
					"value": 150
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": false,
				  "textAlignHorizontal": "RIGHT",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MAX",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 8,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "e9d3849a64d709f1ec8ebfc8727ee2e5cc02810e",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Vertical Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_MEDIA_OBJECT",
	  "styles": {
		"itemSpacing": 40,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "FIXED",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2801",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3161
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3161,
			"y": 40,
			"width": 473.333251953125,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2804",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3162.7216796875,
				"y": 44.70170593261719,
				"width": 113.266845703125,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2807",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3161.9375,
				"y": 104.09091186523438,
				"width": 438.3623046875,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2810",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2801"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3161
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3161.818115234375,
				"y": 297,
				"width": 446.34375,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "fbe06706aa8b1211c459b8605991cc2dbb07c9ea",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2802",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  513.333251953125
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  3654.333251953125
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 513.333251953125,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 3654.333251953125,
			"y": 40,
			"width": 473.333251953125,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2805",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 3834.455810546875,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2808",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 3673.463623046875,
				"y": 104.09091186523438,
				"width": 436.265869140625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2811",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2802"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  3654.333251953125
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 3668.432373046875,
				"y": 297,
				"width": 445.752685546875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "df7a4ca08db360885c9a998d4a7bd338e5a422f2",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "394:2803",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  1006.66650390625
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  4147.66650390625
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 1006.66650390625,
		  "y": 20,
		  "width": 473.3332824707031,
		  "height": 299,
		  "absoluteRenderBounds": {
			"x": 4147.66650390625,
			"y": 40,
			"width": 473.33349609375,
			"height": 299
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 1,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2806",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 473.3332824707031,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 4506.18994140625,
				"y": 44.70170593261719,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2809",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  53
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  93
				]
			  ],
			  "x": 0,
			  "y": 53,
			  "width": 473.3332824707031,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 4182.89306640625,
				"y": 104.09091186523438,
				"width": 436.43212890625,
				"height": 161.272705078125
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "394:2812",
			  "listSpacing": 0,
			  "parent": {
				"id": "394:2803"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  251
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  4147.66650390625
				],
				[
				  0,
				  1,
				  291
				]
			  ],
			  "x": 0,
			  "y": 251,
			  "width": 473.3332824707031,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 4174.45556640625,
				"y": 297,
				"width": 445.7529296875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "a8b46f08a0353605aa40b2aa95b6a1282389d2b8",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_TEXT_CONTENT",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "FIXED",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-features, makers-features--two-columns",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3338",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Two Columns",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  9469
			],
			[
			  0,
			  1,
			  40
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 1440,
		  "height": 605,
		  "absoluteRenderBounds": {
			"x": 9469,
			"y": 40,
			"width": 1440,
			"height": 605
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3343",
			  "parent": {
				"id": "443:3338"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  320
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9789
				],
				[
				  0,
				  1,
				  40
				]
			  ],
			  "x": 320,
			  "y": 0,
			  "width": 800,
			  "height": 337,
			  "absoluteRenderBounds": {
				"x": 9789,
				"y": 40,
				"width": 800,
				"height": 337
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3348",
				  "parent": {
					"id": "443:3343"
				  },
				  "name": "Text Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9789
					],
					[
					  0,
					  1,
					  88
					]
				  ],
				  "x": 0,
				  "y": 48,
				  "width": 800,
				  "height": 241,
				  "absoluteRenderBounds": {
					"x": 9789,
					"y": 88,
					"width": 800,
					"height": 241
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3353",
					  "parent": {
						"id": "443:3348"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9789
						],
						[
						  0,
						  1,
						  88
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 800,
					  "height": 241,
					  "absoluteRenderBounds": {
						"x": 9789,
						"y": 88,
						"width": 800,
						"height": 241
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3353;394:2805",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3353"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.16470588743686676,
								"g": 0.7411764860153198,
								"b": 0.6392157077789307
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9789
							],
							[
							  0,
							  1,
							  88
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 800,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 10120.0927734375,
							"y": 93.30681610107422,
							"width": 137.8125,
							"height": 17.95738983154297
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "UPPER",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3353;394:2808",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3353"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9789
							],
							[
							  0,
							  1,
							  141
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 800,
						  "height": 116,
						  "absoluteRenderBounds": {
							"x": 9829.3388671875,
							"y": 150.09658813476562,
							"width": 719.556640625,
							"height": 105.26705932617188
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to make and publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3353;394:2811",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3353"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  163.33335876464844
							],
							[
							  0,
							  1,
							  193
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9952.3330078125
							],
							[
							  0,
							  1,
							  281
							]
						  ],
						  "x": 163.33335876464844,
						  "y": 193,
						  "width": 473.3332824707031,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 9966.4326171875,
							"y": 287,
							"width": 445.751953125,
							"height": 39.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2802"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 48,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3407",
			  "parent": {
				"id": "443:3338"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  20
				],
				[
				  0,
				  1,
				  337
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9489
				],
				[
				  0,
				  1,
				  377
				]
			  ],
			  "x": 20,
			  "y": 337,
			  "width": 1400,
			  "height": 268,
			  "absoluteRenderBounds": {
				"x": 9489,
				"y": 377,
				"width": 1400,
				"height": 268
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "columns",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3411",
				  "parent": {
					"id": "443:3407"
				  },
				  "name": "Features Grid",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  150
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9639
					],
					[
					  0,
					  1,
					  377
					]
				  ],
				  "x": 150,
				  "y": 0,
				  "width": 1100,
				  "height": 204,
				  "absoluteRenderBounds": {
					"x": 9639,
					"y": 377,
					"width": 1100,
					"height": 204
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3415",
					  "parent": {
						"id": "443:3411"
					  },
					  "name": "Media Objects",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9639
						],
						[
						  0,
						  1,
						  377
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 500,
					  "height": 204,
					  "absoluteRenderBounds": {
						"x": 9639,
						"y": 377,
						"width": 500,
						"height": 204
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3422",
						  "parent": {
							"id": "443:3415"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9639
							],
							[
							  0,
							  1,
							  377
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 500,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 9639,
							"y": 377,
							"width": 500,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3422;443:3281",
							  "parent": {
								"id": "443:3422"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  377
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 9639,
								"y": 377,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3306;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3422;443:3285",
								  "parent": {
									"id": "I443:3422;443:3281"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9647
									],
									[
									  0,
									  1,
									  385
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9647,
									"y": 385,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3422;443:3285;423:20771",
									  "parent": {
										"id": "I443:3422;443:3285"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9647
										],
										[
										  0,
										  1,
										  385
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 9647,
										"y": 385,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3422;443:3285;423:20781",
										  "parent": {
											"id": "I443:3422;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9652
											],
											[
											  0,
											  1,
											  387
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9651,
											"y": 386,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3422;443:3285;423:20786",
										  "parent": {
											"id": "I443:3422;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9659
											],
											[
											  0,
											  1,
											  387
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9658,
											"y": 386,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3422;443:3285;423:20791",
										  "parent": {
											"id": "I443:3422;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9659
											],
											[
											  0,
											  1,
											  394
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9658,
											"y": 393,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3422;443:3285;423:20793",
										  "parent": {
											"id": "I443:3422;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9652
											],
											[
											  0,
											  1,
											  401
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9651,
											"y": 400,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3422;443:3285;423:20795",
										  "parent": {
											"id": "I443:3422;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9652
											],
											[
											  0,
											  1,
											  394
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9651,
											"y": 393,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3422;443:3321",
							  "parent": {
								"id": "443:3422"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  56
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9695
								],
								[
								  0,
								  1,
								  377
								]
							  ],
							  "x": 56,
							  "y": 0,
							  "width": 444,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 9695,
								"y": 377,
								"width": 444,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3422;443:3325",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3422;443:3321"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9695
									],
									[
									  0,
									  1,
									  377
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 444,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9695.9541015625,
									"y": 379.7016906738281,
									"width": 426.4306640625,
									"height": 23.2073974609375
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "The easiest way to publish your sites",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3422;443:3329",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3422;443:3321"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9695
									],
									[
									  0,
									  1,
									  409
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 444,
								  "height": 54,
								  "absoluteRenderBounds": {
									"x": 9696.380859375,
									"y": 415.5,
									"width": 427.806640625,
									"height": 44.18182373046875
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3277"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Horizontal"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3528",
						  "parent": {
							"id": "443:3415"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  118
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9639
							],
							[
							  0,
							  1,
							  495
							]
						  ],
						  "x": 0,
						  "y": 118,
						  "width": 500,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 9639,
							"y": 495,
							"width": 500,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3528;443:3281",
							  "parent": {
								"id": "443:3528"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  495
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 9639,
								"y": 495,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3373;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3528;443:3285",
								  "parent": {
									"id": "I443:3528;443:3281"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9647
									],
									[
									  0,
									  1,
									  503
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9647,
									"y": 503,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3528;443:3285;423:20771",
									  "parent": {
										"id": "I443:3528;443:3285"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9647
										],
										[
										  0,
										  1,
										  503
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 9647,
										"y": 503,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3528;443:3285;423:20781",
										  "parent": {
											"id": "I443:3528;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9652
											],
											[
											  0,
											  1,
											  505
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9651,
											"y": 504,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3528;443:3285;423:20786",
										  "parent": {
											"id": "I443:3528;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9659
											],
											[
											  0,
											  1,
											  505
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9658,
											"y": 504,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3528;443:3285;423:20791",
										  "parent": {
											"id": "I443:3528;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9659
											],
											[
											  0,
											  1,
											  512
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9658,
											"y": 511,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3528;443:3285;423:20793",
										  "parent": {
											"id": "I443:3528;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9652
											],
											[
											  0,
											  1,
											  519
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9651,
											"y": 518,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3528;443:3285;423:20795",
										  "parent": {
											"id": "I443:3528;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9652
											],
											[
											  0,
											  1,
											  512
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9651,
											"y": 511,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3528;443:3321",
							  "parent": {
								"id": "443:3528"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  56
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9695
								],
								[
								  0,
								  1,
								  495
								]
							  ],
							  "x": 56,
							  "y": 0,
							  "width": 444,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 9695,
								"y": 495,
								"width": 444,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3528;443:3325",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3528;443:3321"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9695
									],
									[
									  0,
									  1,
									  495
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 444,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9695.9541015625,
									"y": 497.7016906738281,
									"width": 426.4306640625,
									"height": 23.207427978515625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "The easiest way to publish your sites",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3528;443:3329",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3528;443:3321"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9695
									],
									[
									  0,
									  1,
									  527
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 444,
								  "height": 54,
								  "absoluteRenderBounds": {
									"x": 9696.380859375,
									"y": 533.5,
									"width": 427.806640625,
									"height": 44.18182373046875
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3277"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Horizontal"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3632",
					  "parent": {
						"id": "443:3411"
					  },
					  "name": "Media Objects",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  600
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  10239
						],
						[
						  0,
						  1,
						  377
						]
					  ],
					  "x": 600,
					  "y": 0,
					  "width": 500,
					  "height": 204,
					  "absoluteRenderBounds": {
						"x": 10239,
						"y": 377,
						"width": 500,
						"height": 204
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3633",
						  "parent": {
							"id": "443:3632"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10239
							],
							[
							  0,
							  1,
							  377
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 500,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 10239,
							"y": 377,
							"width": 500,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3633;443:3281",
							  "parent": {
								"id": "443:3633"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10239
								],
								[
								  0,
								  1,
								  377
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 10239,
								"y": 377,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3439;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3633;443:3285",
								  "parent": {
									"id": "I443:3633;443:3281"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10247
									],
									[
									  0,
									  1,
									  385
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10247,
									"y": 385,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3633;443:3285;423:20771",
									  "parent": {
										"id": "I443:3633;443:3285"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10247
										],
										[
										  0,
										  1,
										  385
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 10247,
										"y": 385,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3633;443:3285;423:20781",
										  "parent": {
											"id": "I443:3633;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10252
											],
											[
											  0,
											  1,
											  387
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10251,
											"y": 386,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3633;443:3285;423:20786",
										  "parent": {
											"id": "I443:3633;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10259
											],
											[
											  0,
											  1,
											  387
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10258,
											"y": 386,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3633;443:3285;423:20791",
										  "parent": {
											"id": "I443:3633;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10259
											],
											[
											  0,
											  1,
											  394
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10258,
											"y": 393,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3633;443:3285;423:20793",
										  "parent": {
											"id": "I443:3633;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10252
											],
											[
											  0,
											  1,
											  401
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10251,
											"y": 400,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3633;443:3285;423:20795",
										  "parent": {
											"id": "I443:3633;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10252
											],
											[
											  0,
											  1,
											  394
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10251,
											"y": 393,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3633;443:3321",
							  "parent": {
								"id": "443:3633"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  56
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10295
								],
								[
								  0,
								  1,
								  377
								]
							  ],
							  "x": 56,
							  "y": 0,
							  "width": 444,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 10295,
								"y": 377,
								"width": 444,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3633;443:3325",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3633;443:3321"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10295
									],
									[
									  0,
									  1,
									  377
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 444,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10295.9541015625,
									"y": 379.7016906738281,
									"width": 426.4306640625,
									"height": 23.2073974609375
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "The easiest way to publish your sites",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3633;443:3329",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3633;443:3321"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10295
									],
									[
									  0,
									  1,
									  409
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 444,
								  "height": 54,
								  "absoluteRenderBounds": {
									"x": 10296.380859375,
									"y": 415.5,
									"width": 427.806640625,
									"height": 44.18182373046875
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3277"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Horizontal"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3738",
						  "parent": {
							"id": "443:3632"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  118
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10239
							],
							[
							  0,
							  1,
							  495
							]
						  ],
						  "x": 0,
						  "y": 118,
						  "width": 500,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 10239,
							"y": 495,
							"width": 500,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3738;443:3281",
							  "parent": {
								"id": "443:3738"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10239
								],
								[
								  0,
								  1,
								  495
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 10239,
								"y": 495,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3506;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3738;443:3285",
								  "parent": {
									"id": "I443:3738;443:3281"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10247
									],
									[
									  0,
									  1,
									  503
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10247,
									"y": 503,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3738;443:3285;423:20771",
									  "parent": {
										"id": "I443:3738;443:3285"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10247
										],
										[
										  0,
										  1,
										  503
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 10247,
										"y": 503,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3738;443:3285;423:20781",
										  "parent": {
											"id": "I443:3738;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10252
											],
											[
											  0,
											  1,
											  505
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10251,
											"y": 504,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3738;443:3285;423:20786",
										  "parent": {
											"id": "I443:3738;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10259
											],
											[
											  0,
											  1,
											  505
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10258,
											"y": 504,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3738;443:3285;423:20791",
										  "parent": {
											"id": "I443:3738;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10259
											],
											[
											  0,
											  1,
											  512
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10258,
											"y": 511,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3738;443:3285;423:20793",
										  "parent": {
											"id": "I443:3738;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10252
											],
											[
											  0,
											  1,
											  519
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10251,
											"y": 518,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3738;443:3285;423:20795",
										  "parent": {
											"id": "I443:3738;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10252
											],
											[
											  0,
											  1,
											  512
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10251,
											"y": 511,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3738;443:3321",
							  "parent": {
								"id": "443:3738"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  56
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10295
								],
								[
								  0,
								  1,
								  495
								]
							  ],
							  "x": 56,
							  "y": 0,
							  "width": 444,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 10295,
								"y": 495,
								"width": 444,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3738;443:3325",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3738;443:3321"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10295
									],
									[
									  0,
									  1,
									  495
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 444,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10295.9541015625,
									"y": 497.7016906738281,
									"width": 426.4306640625,
									"height": 23.207427978515625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "The easiest way to publish your sites",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3738;443:3329",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3738;443:3321"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10295
									],
									[
									  0,
									  1,
									  527
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 444,
								  "height": 54,
								  "absoluteRenderBounds": {
									"x": 10296.380859375,
									"y": 533.5,
									"width": 427.806640625,
									"height": 44.18182373046875
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3277"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Horizontal"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "AUTO",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "SPACE_BETWEEN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "a1d46fc2c05365085d0eb1dd386f53a0212ea09b",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Two Columns"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-features, makers-features--three-columns",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3339",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Three Columns",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  645
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  9469
			],
			[
			  0,
			  1,
			  665
			]
		  ],
		  "x": 20,
		  "y": 645,
		  "width": 1440,
		  "height": 813,
		  "absoluteRenderBounds": {
			"x": 9469,
			"y": 665,
			"width": 1440,
			"height": 813
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3344",
			  "parent": {
				"id": "443:3339"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  320
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9789
				],
				[
				  0,
				  1,
				  665
				]
			  ],
			  "x": 320,
			  "y": 0,
			  "width": 800,
			  "height": 337,
			  "absoluteRenderBounds": {
				"x": 9789,
				"y": 665,
				"width": 800,
				"height": 337
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3349",
				  "parent": {
					"id": "443:3344"
				  },
				  "name": "Text Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9789
					],
					[
					  0,
					  1,
					  713
					]
				  ],
				  "x": 0,
				  "y": 48,
				  "width": 800,
				  "height": 241,
				  "absoluteRenderBounds": {
					"x": 9789,
					"y": 713,
					"width": 800,
					"height": 241
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3357",
					  "parent": {
						"id": "443:3349"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9789
						],
						[
						  0,
						  1,
						  713
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 800,
					  "height": 241,
					  "absoluteRenderBounds": {
						"x": 9789,
						"y": 713,
						"width": 800,
						"height": 241
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3357;394:2805",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3357"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.16470588743686676,
								"g": 0.7411764860153198,
								"b": 0.6392157077789307
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9789
							],
							[
							  0,
							  1,
							  713
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 800,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 10120.0927734375,
							"y": 718.3068237304688,
							"width": 137.8125,
							"height": 17.9573974609375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "UPPER",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3357;394:2808",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3357"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9789
							],
							[
							  0,
							  1,
							  766
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 800,
						  "height": 116,
						  "absoluteRenderBounds": {
							"x": 9829.3388671875,
							"y": 775.0966186523438,
							"width": 719.556640625,
							"height": 105.26702880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to make and publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3357;394:2811",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3357"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  163.33335876464844
							],
							[
							  0,
							  1,
							  193
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9952.3330078125
							],
							[
							  0,
							  1,
							  906
							]
						  ],
						  "x": 163.33335876464844,
						  "y": 193,
						  "width": 473.3332824707031,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 9966.4326171875,
							"y": 912,
							"width": 445.751953125,
							"height": 39.45452880859375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2802"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 48,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3408",
			  "parent": {
				"id": "443:3339"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  337
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9469
				],
				[
				  0,
				  1,
				  1002
				]
			  ],
			  "x": 0,
			  "y": 337,
			  "width": 1440,
			  "height": 476,
			  "absoluteRenderBounds": {
				"x": 9469,
				"y": 1002,
				"width": 1440,
				"height": 476
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "div",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3412",
				  "parent": {
					"id": "443:3408"
				  },
				  "name": "Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  170
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9639
					],
					[
					  0,
					  1,
					  1002
					]
				  ],
				  "x": 170,
				  "y": 0,
				  "width": 1100,
				  "height": 412,
				  "absoluteRenderBounds": {
					"x": 9639,
					"y": 1002,
					"width": 1100,
					"height": 412
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "columns",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3416",
					  "parent": {
						"id": "443:3412"
					  },
					  "name": "Features Grid",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9639
						],
						[
						  0,
						  1,
						  1002
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 1100,
					  "height": 412,
					  "absoluteRenderBounds": {
						"x": 9639,
						"y": 1002,
						"width": 1100,
						"height": 412
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3435",
						  "parent": {
							"id": "443:3416"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9639
							],
							[
							  0,
							  1,
							  1002
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 412,
						  "absoluteRenderBounds": {
							"x": 9639,
							"y": 1002,
							"width": 340,
							"height": 412
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3437",
							  "parent": {
								"id": "443:3435"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  1002
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 190,
							  "absoluteRenderBounds": {
								"x": 9639,
								"y": 1002,
								"width": 340,
								"height": 190
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3439",
								  "parent": {
									"id": "443:3437"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9663
									],
									[
									  0,
									  1,
									  1026
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 142,
								  "absoluteRenderBounds": {
									"x": 9663,
									"y": 1026,
									"width": 292,
									"height": 142
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3439;443:3283",
									  "parent": {
										"id": "443:3439"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  126
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9789
										],
										[
										  0,
										  1,
										  1026
										]
									  ],
									  "x": 126,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 9789,
										"y": 1026,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3323;431-3222.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3439;443:3291",
										  "parent": {
											"id": "I443:3439;443:3283"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9797
											],
											[
											  0,
											  1,
											  1034
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9797,
											"y": 1034,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3439;443:3291;423:20771",
											  "parent": {
												"id": "I443:3439;443:3291"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  9797
												],
												[
												  0,
												  1,
												  1034
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 9797,
												"y": 1034,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3439;443:3291;423:20781",
												  "parent": {
													"id": "I443:3439;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9802
													],
													[
													  0,
													  1,
													  1036
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9801,
													"y": 1035,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3439;443:3291;423:20786",
												  "parent": {
													"id": "I443:3439;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9809
													],
													[
													  0,
													  1,
													  1036
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9808,
													"y": 1035,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3439;443:3291;423:20791",
												  "parent": {
													"id": "I443:3439;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9809
													],
													[
													  0,
													  1,
													  1043
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9808,
													"y": 1042,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3439;443:3291;423:20793",
												  "parent": {
													"id": "I443:3439;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9802
													],
													[
													  0,
													  1,
													  1050
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9801,
													"y": 1049,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3439;443:3291;423:20795",
												  "parent": {
													"id": "I443:3439;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9802
													],
													[
													  0,
													  1,
													  1043
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9801,
													"y": 1042,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3439;443:3323",
									  "parent": {
										"id": "443:3439"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9663
										],
										[
										  0,
										  1,
										  1082
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 86,
									  "absoluteRenderBounds": {
										"x": 9663,
										"y": 1082,
										"width": 292,
										"height": 86
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3439;443:3327",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3439;443:3323"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  1082
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9701.4326171875,
											"y": 1084.70166015625,
											"width": 215.33984375,
											"height": 23.4801025390625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Publish with Figma",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3439;443:3331",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3439;443:3323"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  1114
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 9692.5478515625,
											"y": 1120.5,
											"width": 233.0751953125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3279"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical Center"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "2"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.9458333253860474,
									"g": 0.9458333253860474,
									"b": 0.9458333253860474
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3541",
							  "parent": {
								"id": "443:3435"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  222
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  1224
								]
							  ],
							  "x": 0,
							  "y": 222,
							  "width": 340,
							  "height": 190,
							  "absoluteRenderBounds": {
								"x": 9639,
								"y": 1224,
								"width": 340,
								"height": 190
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3542",
								  "parent": {
									"id": "443:3541"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9663
									],
									[
									  0,
									  1,
									  1248
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 142,
								  "absoluteRenderBounds": {
									"x": 9663,
									"y": 1248,
									"width": 292,
									"height": 142
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3542;443:3283",
									  "parent": {
										"id": "443:3542"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  126
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9789
										],
										[
										  0,
										  1,
										  1248
										]
									  ],
									  "x": 126,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 9789,
										"y": 1248,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3387;431-3222.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3542;443:3291",
										  "parent": {
											"id": "I443:3542;443:3283"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9797
											],
											[
											  0,
											  1,
											  1256
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9797,
											"y": 1256,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3542;443:3291;423:20771",
											  "parent": {
												"id": "I443:3542;443:3291"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  9797
												],
												[
												  0,
												  1,
												  1256
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 9797,
												"y": 1256,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3542;443:3291;423:20781",
												  "parent": {
													"id": "I443:3542;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9802
													],
													[
													  0,
													  1,
													  1258
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9801,
													"y": 1257,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3542;443:3291;423:20786",
												  "parent": {
													"id": "I443:3542;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9809
													],
													[
													  0,
													  1,
													  1258
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9808,
													"y": 1257,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3542;443:3291;423:20791",
												  "parent": {
													"id": "I443:3542;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9809
													],
													[
													  0,
													  1,
													  1265
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9808,
													"y": 1264,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3542;443:3291;423:20793",
												  "parent": {
													"id": "I443:3542;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9802
													],
													[
													  0,
													  1,
													  1272
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9801,
													"y": 1271,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3542;443:3291;423:20795",
												  "parent": {
													"id": "I443:3542;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9802
													],
													[
													  0,
													  1,
													  1265
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9801,
													"y": 1264,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3542;443:3323",
									  "parent": {
										"id": "443:3542"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9663
										],
										[
										  0,
										  1,
										  1304
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 86,
									  "absoluteRenderBounds": {
										"x": 9663,
										"y": 1304,
										"width": 292,
										"height": 86
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3542;443:3327",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3542;443:3323"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  1304
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9701.4326171875,
											"y": 1306.70166015625,
											"width": 215.33984375,
											"height": 23.4801025390625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Publish with Figma",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3542;443:3331",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3542;443:3323"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  1336
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 9692.5478515625,
											"y": 1342.5,
											"width": 233.0751953125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3279"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical Center"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "2"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.9458333253860474,
									"g": 0.9458333253860474,
									"b": 0.9458333253860474
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3659",
						  "parent": {
							"id": "443:3416"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  380
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10019
							],
							[
							  0,
							  1,
							  1002
							]
						  ],
						  "x": 380,
						  "y": 0,
						  "width": 340,
						  "height": 412,
						  "absoluteRenderBounds": {
							"x": 10019,
							"y": 1002,
							"width": 340,
							"height": 412
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3660",
							  "parent": {
								"id": "443:3659"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  1002
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 190,
							  "absoluteRenderBounds": {
								"x": 10019,
								"y": 1002,
								"width": 340,
								"height": 190
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3661",
								  "parent": {
									"id": "443:3660"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10043
									],
									[
									  0,
									  1,
									  1026
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 142,
								  "absoluteRenderBounds": {
									"x": 10043,
									"y": 1026,
									"width": 292,
									"height": 142
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3661;443:3283",
									  "parent": {
										"id": "443:3661"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  126
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10169
										],
										[
										  0,
										  1,
										  1026
										]
									  ],
									  "x": 126,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10169,
										"y": 1026,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3456;431-3222.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3661;443:3291",
										  "parent": {
											"id": "I443:3661;443:3283"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10177
											],
											[
											  0,
											  1,
											  1034
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10177,
											"y": 1034,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3661;443:3291;423:20771",
											  "parent": {
												"id": "I443:3661;443:3291"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10177
												],
												[
												  0,
												  1,
												  1034
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10177,
												"y": 1034,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3661;443:3291;423:20781",
												  "parent": {
													"id": "I443:3661;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10182
													],
													[
													  0,
													  1,
													  1036
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10181,
													"y": 1035,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3661;443:3291;423:20786",
												  "parent": {
													"id": "I443:3661;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10189
													],
													[
													  0,
													  1,
													  1036
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10188,
													"y": 1035,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3661;443:3291;423:20791",
												  "parent": {
													"id": "I443:3661;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10189
													],
													[
													  0,
													  1,
													  1043
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10188,
													"y": 1042,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3661;443:3291;423:20793",
												  "parent": {
													"id": "I443:3661;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10182
													],
													[
													  0,
													  1,
													  1050
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10181,
													"y": 1049,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3661;443:3291;423:20795",
												  "parent": {
													"id": "I443:3661;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10182
													],
													[
													  0,
													  1,
													  1043
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10181,
													"y": 1042,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3661;443:3323",
									  "parent": {
										"id": "443:3661"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10043
										],
										[
										  0,
										  1,
										  1082
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 86,
									  "absoluteRenderBounds": {
										"x": 10043,
										"y": 1082,
										"width": 292,
										"height": 86
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3661;443:3327",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3661;443:3323"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  1082
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10081.4326171875,
											"y": 1084.70166015625,
											"width": 215.33984375,
											"height": 23.4801025390625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Publish with Figma",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3661;443:3331",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3661;443:3323"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  1114
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10072.5478515625,
											"y": 1120.5,
											"width": 233.0751953125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3279"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical Center"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "2"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.9458333253860474,
									"g": 0.9458333253860474,
									"b": 0.9458333253860474
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3753",
							  "parent": {
								"id": "443:3659"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  222
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  1224
								]
							  ],
							  "x": 0,
							  "y": 222,
							  "width": 340,
							  "height": 190,
							  "absoluteRenderBounds": {
								"x": 10019,
								"y": 1224,
								"width": 340,
								"height": 190
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3754",
								  "parent": {
									"id": "443:3753"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10043
									],
									[
									  0,
									  1,
									  1248
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 142,
								  "absoluteRenderBounds": {
									"x": 10043,
									"y": 1248,
									"width": 292,
									"height": 142
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3754;443:3283",
									  "parent": {
										"id": "443:3754"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  126
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10169
										],
										[
										  0,
										  1,
										  1248
										]
									  ],
									  "x": 126,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10169,
										"y": 1248,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3546;431-3222.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3754;443:3291",
										  "parent": {
											"id": "I443:3754;443:3283"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10177
											],
											[
											  0,
											  1,
											  1256
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10177,
											"y": 1256,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3754;443:3291;423:20771",
											  "parent": {
												"id": "I443:3754;443:3291"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10177
												],
												[
												  0,
												  1,
												  1256
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10177,
												"y": 1256,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3754;443:3291;423:20781",
												  "parent": {
													"id": "I443:3754;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10182
													],
													[
													  0,
													  1,
													  1258
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10181,
													"y": 1257,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3754;443:3291;423:20786",
												  "parent": {
													"id": "I443:3754;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10189
													],
													[
													  0,
													  1,
													  1258
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10188,
													"y": 1257,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3754;443:3291;423:20791",
												  "parent": {
													"id": "I443:3754;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10189
													],
													[
													  0,
													  1,
													  1265
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10188,
													"y": 1264,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3754;443:3291;423:20793",
												  "parent": {
													"id": "I443:3754;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10182
													],
													[
													  0,
													  1,
													  1272
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10181,
													"y": 1271,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3754;443:3291;423:20795",
												  "parent": {
													"id": "I443:3754;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10182
													],
													[
													  0,
													  1,
													  1265
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10181,
													"y": 1264,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3754;443:3323",
									  "parent": {
										"id": "443:3754"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10043
										],
										[
										  0,
										  1,
										  1304
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 86,
									  "absoluteRenderBounds": {
										"x": 10043,
										"y": 1304,
										"width": 292,
										"height": 86
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3754;443:3327",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3754;443:3323"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  1304
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10081.4326171875,
											"y": 1306.70166015625,
											"width": 215.33984375,
											"height": 23.4801025390625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Publish with Figma",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3754;443:3331",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3754;443:3323"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  1336
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10072.5478515625,
											"y": 1342.5,
											"width": 233.0751953125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3279"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical Center"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "2"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.9458333253860474,
									"g": 0.9458333253860474,
									"b": 0.9458333253860474
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3805",
						  "parent": {
							"id": "443:3416"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  760
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10399
							],
							[
							  0,
							  1,
							  1002
							]
						  ],
						  "x": 760,
						  "y": 0,
						  "width": 340,
						  "height": 412,
						  "absoluteRenderBounds": {
							"x": 10399,
							"y": 1002,
							"width": 340,
							"height": 412
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3806",
							  "parent": {
								"id": "443:3805"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  1002
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 190,
							  "absoluteRenderBounds": {
								"x": 10399,
								"y": 1002,
								"width": 340,
								"height": 190
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3807",
								  "parent": {
									"id": "443:3806"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10423
									],
									[
									  0,
									  1,
									  1026
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 142,
								  "absoluteRenderBounds": {
									"x": 10423,
									"y": 1026,
									"width": 292,
									"height": 142
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3807;443:3283",
									  "parent": {
										"id": "443:3807"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  126
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10549
										],
										[
										  0,
										  1,
										  1026
										]
									  ],
									  "x": 126,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10549,
										"y": 1026,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3600;431-3222.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3807;443:3291",
										  "parent": {
											"id": "I443:3807;443:3283"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10557
											],
											[
											  0,
											  1,
											  1034
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10557,
											"y": 1034,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3807;443:3291;423:20771",
											  "parent": {
												"id": "I443:3807;443:3291"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10557
												],
												[
												  0,
												  1,
												  1034
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10557,
												"y": 1034,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3807;443:3291;423:20781",
												  "parent": {
													"id": "I443:3807;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10562
													],
													[
													  0,
													  1,
													  1036
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10561,
													"y": 1035,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3807;443:3291;423:20786",
												  "parent": {
													"id": "I443:3807;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10569
													],
													[
													  0,
													  1,
													  1036
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10568,
													"y": 1035,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3807;443:3291;423:20791",
												  "parent": {
													"id": "I443:3807;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10569
													],
													[
													  0,
													  1,
													  1043
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10568,
													"y": 1042,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3807;443:3291;423:20793",
												  "parent": {
													"id": "I443:3807;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10562
													],
													[
													  0,
													  1,
													  1050
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10561,
													"y": 1049,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3807;443:3291;423:20795",
												  "parent": {
													"id": "I443:3807;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10562
													],
													[
													  0,
													  1,
													  1043
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10561,
													"y": 1042,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3807;443:3323",
									  "parent": {
										"id": "443:3807"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10423
										],
										[
										  0,
										  1,
										  1082
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 86,
									  "absoluteRenderBounds": {
										"x": 10423,
										"y": 1082,
										"width": 292,
										"height": 86
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3807;443:3327",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3807;443:3323"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  1082
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10461.4326171875,
											"y": 1084.70166015625,
											"width": 215.33984375,
											"height": 23.4801025390625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Publish with Figma",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3807;443:3331",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3807;443:3323"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  1114
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10452.5478515625,
											"y": 1120.5,
											"width": 233.0751953125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3279"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical Center"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "2"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.9458333253860474,
									"g": 0.9458333253860474,
									"b": 0.9458333253860474
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3859",
							  "parent": {
								"id": "443:3805"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  222
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  1224
								]
							  ],
							  "x": 0,
							  "y": 222,
							  "width": 340,
							  "height": 190,
							  "absoluteRenderBounds": {
								"x": 10399,
								"y": 1224,
								"width": 340,
								"height": 190
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3860",
								  "parent": {
									"id": "443:3859"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10423
									],
									[
									  0,
									  1,
									  1248
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 142,
								  "absoluteRenderBounds": {
									"x": 10423,
									"y": 1248,
									"width": 292,
									"height": 142
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3860;443:3283",
									  "parent": {
										"id": "443:3860"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  126
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10549
										],
										[
										  0,
										  1,
										  1248
										]
									  ],
									  "x": 126,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10549,
										"y": 1248,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3652;431-3222.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3860;443:3291",
										  "parent": {
											"id": "I443:3860;443:3283"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10557
											],
											[
											  0,
											  1,
											  1256
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10557,
											"y": 1256,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3860;443:3291;423:20771",
											  "parent": {
												"id": "I443:3860;443:3291"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10557
												],
												[
												  0,
												  1,
												  1256
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10557,
												"y": 1256,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3860;443:3291;423:20781",
												  "parent": {
													"id": "I443:3860;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10562
													],
													[
													  0,
													  1,
													  1258
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10561,
													"y": 1257,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3860;443:3291;423:20786",
												  "parent": {
													"id": "I443:3860;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10569
													],
													[
													  0,
													  1,
													  1258
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10568,
													"y": 1257,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3860;443:3291;423:20791",
												  "parent": {
													"id": "I443:3860;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10569
													],
													[
													  0,
													  1,
													  1265
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10568,
													"y": 1264,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3860;443:3291;423:20793",
												  "parent": {
													"id": "I443:3860;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10562
													],
													[
													  0,
													  1,
													  1272
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10561,
													"y": 1271,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3860;443:3291;423:20795",
												  "parent": {
													"id": "I443:3860;443:3291;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10562
													],
													[
													  0,
													  1,
													  1265
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10561,
													"y": 1264,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3860;443:3323",
									  "parent": {
										"id": "443:3860"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10423
										],
										[
										  0,
										  1,
										  1304
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 86,
									  "absoluteRenderBounds": {
										"x": 10423,
										"y": 1304,
										"width": 292,
										"height": 86
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3860;443:3327",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3860;443:3323"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  1304
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10461.4326171875,
											"y": 1306.70166015625,
											"width": 215.33984375,
											"height": 23.4801025390625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Publish with Figma",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3860;443:3331",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3860;443:3323"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  1336
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10452.5478515625,
											"y": 1342.5,
											"width": 233.0751953125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "CENTER",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "CENTER",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3279"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical Center"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "2"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.9458333253860474,
									"g": 0.9458333253860474,
									"b": 0.9458333253860474
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "SPACE_BETWEEN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "7cf91172f64e903c251193493b5858fb400cbc62",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Three Columns"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-features, makers-features--three-columns",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3340",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Overlapping Background",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  1478
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  9469
			],
			[
			  0,
			  1,
			  1498
			]
		  ],
		  "x": 20,
		  "y": 1478,
		  "width": 1440,
		  "height": 813,
		  "absoluteRenderBounds": {
			"x": 9469,
			"y": 1498,
			"width": 1440,
			"height": 813
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3345",
			  "parent": {
				"id": "443:3340"
			  },
			  "name": "Intro",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  20
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9489
				],
				[
				  0,
				  1,
				  1498
				]
			  ],
			  "x": 20,
			  "y": 0,
			  "width": 1400,
			  "height": 279,
			  "absoluteRenderBounds": {
				"x": 9489,
				"y": 1498,
				"width": 1400,
				"height": 279
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "div",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3350",
				  "parent": {
					"id": "443:3345"
				  },
				  "name": "Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  150
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9639
					],
					[
					  0,
					  1,
					  1498
					]
				  ],
				  "x": 150,
				  "y": 0,
				  "width": 1100,
				  "height": 279,
				  "absoluteRenderBounds": {
					"x": 9639,
					"y": 1498,
					"width": 1100,
					"height": 279
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3361",
					  "parent": {
						"id": "443:3350"
					  },
					  "name": "Text Container",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  48
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9639
						],
						[
						  0,
						  1,
						  1546
						]
					  ],
					  "x": 0,
					  "y": 48,
					  "width": 1100,
					  "height": 183,
					  "absoluteRenderBounds": {
						"x": 9639,
						"y": 1546,
						"width": 1100,
						"height": 183
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3376",
						  "parent": {
							"id": "443:3361"
						  },
						  "name": "MAKERS_TEXT_CONTENT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9639
							],
							[
							  0,
							  1,
							  1546
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 1100,
						  "height": 183,
						  "absoluteRenderBounds": {
							"x": 9639,
							"y": 1546,
							"width": 1100,
							"height": 183
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3376;394:2804",
							  "listSpacing": 0,
							  "parent": {
								"id": "443:3376"
							  },
							  "name": "Education",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  1546
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 1100,
							  "height": 29,
							  "absoluteRenderBounds": {
								"x": 9640.7216796875,
								"y": 1550.70166015625,
								"width": 210.20703125,
								"height": 23.2073974609375
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Follow our journey",
							  "hasMissingFont": false,
							  "fontSize": 24,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Semi Bold"
							  },
							  "autoRename": true,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3376;394:2807",
							  "listSpacing": 0,
							  "parent": {
								"id": "443:3376"
							  },
							  "name": "Learn how to make and publish sites with Figma",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  53
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  1599
								]
							  ],
							  "x": 0,
							  "y": 53,
							  "width": 473.3332824707031,
							  "height": 58,
							  "absoluteRenderBounds": {
								"x": 9640.9091796875,
								"y": 1608.0965576171875,
								"width": 293.57421875,
								"height": 37.414794921875
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Social Media",
							  "hasMissingFont": false,
							  "fontSize": 48,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "AUTO"
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Bold"
							  },
							  "autoRename": false,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3376;394:2810",
							  "listSpacing": 0,
							  "parent": {
								"id": "443:3376"
							  },
							  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "OUTSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  135
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  1681
								]
							  ],
							  "x": 0,
							  "y": 135,
							  "width": 473.3332824707031,
							  "height": 48,
							  "absoluteRenderBounds": {
								"x": 9640.2275390625,
								"y": 1687,
								"width": 446.0380859375,
								"height": 39.272705078125
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "exportSettings": [],
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "characters": "Follow our jouney on all our social media accounts. Become part of our community and contribute with your feedback.",
							  "hasMissingFont": false,
							  "fontSize": 16,
							  "paragraphIndent": 0,
							  "paragraphSpacing": 0,
							  "textCase": "ORIGINAL",
							  "textDecoration": "NONE",
							  "letterSpacing": {
								"unit": "PERCENT",
								"value": 0
							  },
							  "lineHeight": {
								"unit": "PERCENT",
								"value": 150
							  },
							  "fontName": {
								"family": "Inter",
								"style": "Regular"
							  },
							  "autoRename": false,
							  "textAlignHorizontal": "LEFT",
							  "textAlignVertical": "TOP",
							  "textAutoResize": "HEIGHT",
							  "textStyleId": "",
							  "reactions": [],
							  "type": "TEXT",
							  "children": []
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 24,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "394:2801"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Align": "Left"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 10,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 48,
				  "paddingBottom": 48,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3410",
			  "parent": {
				"id": "443:3340"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  20
				],
				[
				  0,
				  1,
				  279
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9489
				],
				[
				  0,
				  1,
				  1777
				]
			  ],
			  "x": 20,
			  "y": 279,
			  "width": 1400,
			  "height": 534,
			  "absoluteRenderBounds": {
				"x": 9489,
				"y": 1765,
				"width": 1400,
				"height": 546
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "div",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3414",
				  "parent": {
					"id": "443:3410"
				  },
				  "name": "Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  150
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9639
					],
					[
					  0,
					  1,
					  1777
					]
				  ],
				  "x": 150,
				  "y": 0,
				  "width": 1100,
				  "height": 470,
				  "absoluteRenderBounds": {
					"x": 9623,
					"y": 1765,
					"width": 1132,
					"height": 502
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "columns",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3421",
					  "parent": {
						"id": "443:3414"
					  },
					  "name": "Features Grid",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9639
						],
						[
						  0,
						  1,
						  1777
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 1100,
					  "height": 470,
					  "absoluteRenderBounds": {
						"x": 9623,
						"y": 1765,
						"width": 1132,
						"height": 502
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3436",
						  "parent": {
							"id": "443:3421"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9639
							],
							[
							  0,
							  1,
							  1777
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 470,
						  "absoluteRenderBounds": {
							"x": 9623,
							"y": 1765,
							"width": 372,
							"height": 502
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3438",
							  "parent": {
								"id": "443:3436"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  1777
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 219,
							  "absoluteRenderBounds": {
								"x": 9623,
								"y": 1765,
								"width": 372,
								"height": 251
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3464",
								  "parent": {
									"id": "443:3438"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9663
									],
									[
									  0,
									  1,
									  1801
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 171,
								  "absoluteRenderBounds": {
									"x": 9663,
									"y": 1801,
									"width": 292,
									"height": 171
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3464;443:3282",
									  "parent": {
										"id": "443:3464"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9663
										],
										[
										  0,
										  1,
										  1801
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 9663,
										"y": 1801,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3348;431-3219.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3464;443:3288",
										  "parent": {
											"id": "I443:3464;443:3282"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9671
											],
											[
											  0,
											  1,
											  1809
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9671,
											"y": 1809,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3464;443:3288;423:20771",
											  "parent": {
												"id": "I443:3464;443:3288"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  9671
												],
												[
												  0,
												  1,
												  1809
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 9671,
												"y": 1809,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3464;443:3288;423:20781",
												  "parent": {
													"id": "I443:3464;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9676
													],
													[
													  0,
													  1,
													  1811
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9675,
													"y": 1810,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3464;443:3288;423:20786",
												  "parent": {
													"id": "I443:3464;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9683
													],
													[
													  0,
													  1,
													  1811
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9682,
													"y": 1810,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3464;443:3288;423:20791",
												  "parent": {
													"id": "I443:3464;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9683
													],
													[
													  0,
													  1,
													  1818
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9682,
													"y": 1817,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3464;443:3288;423:20793",
												  "parent": {
													"id": "I443:3464;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9676
													],
													[
													  0,
													  1,
													  1825
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9675,
													"y": 1824,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3464;443:3288;423:20795",
												  "parent": {
													"id": "I443:3464;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9676
													],
													[
													  0,
													  1,
													  1818
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9675,
													"y": 1817,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3464;443:3322",
									  "parent": {
										"id": "443:3464"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9663
										],
										[
										  0,
										  1,
										  1857
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 115,
									  "absoluteRenderBounds": {
										"x": 9663,
										"y": 1857,
										"width": 292,
										"height": 115
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3464;443:3326",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3464;443:3322"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  1857
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9664.7216796875,
											"y": 1859.70166015625,
											"width": 67.40234375,
											"height": 23.4801025390625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Figma",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3464;443:3330",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3464;443:3322"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  1889
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 9663.9208984375,
											"y": 1895.5,
											"width": 233.7392578125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3464;443:3334",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3464;443:3322"
										  },
										  "name": "Call To Action",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.008333325386047363,
												"g": 0.28684401512145996,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  94
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  1951
											]
										  ],
										  "x": 0,
										  "y": 94,
										  "width": 292,
										  "height": 21,
										  "absoluteRenderBounds": {
											"x": 9664.00390625,
											"y": 1956.8182373046875,
											"width": 38.78515625,
											"height": 10.3359375
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Install",
										  "hasMissingFont": false,
										  "fontSize": 14,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3278"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "1"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3580",
							  "parent": {
								"id": "443:3436"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  251
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9639
								],
								[
								  0,
								  1,
								  2028
								]
							  ],
							  "x": 0,
							  "y": 251,
							  "width": 340,
							  "height": 219,
							  "absoluteRenderBounds": {
								"x": 9623,
								"y": 2016,
								"width": 372,
								"height": 251
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3581",
								  "parent": {
									"id": "443:3580"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9663
									],
									[
									  0,
									  1,
									  2052
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 171,
								  "absoluteRenderBounds": {
									"x": 9663,
									"y": 2052,
									"width": 292,
									"height": 171
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3581;443:3282",
									  "parent": {
										"id": "443:3581"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9663
										],
										[
										  0,
										  1,
										  2052
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 9663,
										"y": 2052,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20759.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3581;443:3288",
										  "parent": {
											"id": "I443:3581;443:3282"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9671
											],
											[
											  0,
											  1,
											  2060
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9671,
											"y": 2060,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3581;443:3288;423:20771",
											  "parent": {
												"id": "I443:3581;443:3288"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  9671
												],
												[
												  0,
												  1,
												  2060
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 9671,
												"y": 2060,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3581;443:3288;423:20781",
												  "parent": {
													"id": "I443:3581;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9676
													],
													[
													  0,
													  1,
													  2062
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9675,
													"y": 2061,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3581;443:3288;423:20786",
												  "parent": {
													"id": "I443:3581;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9683
													],
													[
													  0,
													  1,
													  2062
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9682,
													"y": 2061,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3581;443:3288;423:20791",
												  "parent": {
													"id": "I443:3581;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9683
													],
													[
													  0,
													  1,
													  2069
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9682,
													"y": 2068,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3581;443:3288;423:20793",
												  "parent": {
													"id": "I443:3581;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9676
													],
													[
													  0,
													  1,
													  2076
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9675,
													"y": 2075,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3581;443:3288;423:20795",
												  "parent": {
													"id": "I443:3581;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  9676
													],
													[
													  0,
													  1,
													  2069
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 9675,
													"y": 2068,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3581;443:3322",
									  "parent": {
										"id": "443:3581"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9663
										],
										[
										  0,
										  1,
										  2108
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 115,
									  "absoluteRenderBounds": {
										"x": 9663,
										"y": 2108,
										"width": 292,
										"height": 115
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3581;443:3326",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3581;443:3322"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  2108
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 9664.7216796875,
											"y": 2110.70166015625,
											"width": 94.8916015625,
											"height": 18.553955078125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "LinkedIn",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3581;443:3330",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3581;443:3322"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  2140
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 9663.9208984375,
											"y": 2146.5,
											"width": 233.7392578125,
											"height": 44.181884765625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3581;443:3334",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3581;443:3322"
										  },
										  "name": "Call To Action",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.008333325386047363,
												"g": 0.28684401512145996,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  94
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9663
											],
											[
											  0,
											  1,
											  2202
											]
										  ],
										  "x": 0,
										  "y": 94,
										  "width": 292,
										  "height": 21,
										  "absoluteRenderBounds": {
											"x": 9663.74609375,
											"y": 2207.678955078125,
											"width": 56.494140625,
											"height": 10.47021484375
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Connect",
										  "hasMissingFont": false,
										  "fontSize": 14,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3278"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "1"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3686",
						  "parent": {
							"id": "443:3421"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  380
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10019
							],
							[
							  0,
							  1,
							  1777
							]
						  ],
						  "x": 380,
						  "y": 0,
						  "width": 340,
						  "height": 470,
						  "absoluteRenderBounds": {
							"x": 10003,
							"y": 1765,
							"width": 372,
							"height": 502
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3687",
							  "parent": {
								"id": "443:3686"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  1777
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 219,
							  "absoluteRenderBounds": {
								"x": 10003,
								"y": 1765,
								"width": 372,
								"height": 251
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3688",
								  "parent": {
									"id": "443:3687"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10043
									],
									[
									  0,
									  1,
									  1801
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 171,
								  "absoluteRenderBounds": {
									"x": 10043,
									"y": 1801,
									"width": 292,
									"height": 171
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3688;443:3282",
									  "parent": {
										"id": "443:3688"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10043
										],
										[
										  0,
										  1,
										  1801
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10043,
										"y": 1801,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20762.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3688;443:3288",
										  "parent": {
											"id": "I443:3688;443:3282"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10051
											],
											[
											  0,
											  1,
											  1809
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10051,
											"y": 1809,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3688;443:3288;423:20771",
											  "parent": {
												"id": "I443:3688;443:3288"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10051
												],
												[
												  0,
												  1,
												  1809
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10051,
												"y": 1809,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3688;443:3288;423:20781",
												  "parent": {
													"id": "I443:3688;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10056
													],
													[
													  0,
													  1,
													  1811
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10055,
													"y": 1810,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3688;443:3288;423:20786",
												  "parent": {
													"id": "I443:3688;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10063
													],
													[
													  0,
													  1,
													  1811
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10062,
													"y": 1810,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3688;443:3288;423:20791",
												  "parent": {
													"id": "I443:3688;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10063
													],
													[
													  0,
													  1,
													  1818
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10062,
													"y": 1817,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3688;443:3288;423:20793",
												  "parent": {
													"id": "I443:3688;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10056
													],
													[
													  0,
													  1,
													  1825
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10055,
													"y": 1824,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3688;443:3288;423:20795",
												  "parent": {
													"id": "I443:3688;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10056
													],
													[
													  0,
													  1,
													  1818
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10055,
													"y": 1817,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3688;443:3322",
									  "parent": {
										"id": "443:3688"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10043
										],
										[
										  0,
										  1,
										  1857
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 115,
									  "absoluteRenderBounds": {
										"x": 10043,
										"y": 1857,
										"width": 292,
										"height": 115
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3688;443:3326",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3688;443:3322"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  1857
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10043.5283203125,
											"y": 1860.54541015625,
											"width": 98.900390625,
											"height": 17.7103271484375
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "YouTube",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3688;443:3330",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3688;443:3322"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  1889
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10043.9208984375,
											"y": 1895.5,
											"width": 233.7392578125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3688;443:3334",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3688;443:3322"
										  },
										  "name": "Call To Action",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.008333325386047363,
												"g": 0.28684401512145996,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  94
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  1951
											]
										  ],
										  "x": 0,
										  "y": 94,
										  "width": 292,
										  "height": 21,
										  "absoluteRenderBounds": {
											"x": 10043.63671875,
											"y": 1956.3260498046875,
											"width": 67.275390625,
											"height": 10.828125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Subscribe",
										  "hasMissingFont": false,
										  "fontSize": 14,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3278"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "1"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3779",
							  "parent": {
								"id": "443:3686"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  251
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  2028
								]
							  ],
							  "x": 0,
							  "y": 251,
							  "width": 340,
							  "height": 219,
							  "absoluteRenderBounds": {
								"x": 10003,
								"y": 2016,
								"width": 372,
								"height": 251
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3780",
								  "parent": {
									"id": "443:3779"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10043
									],
									[
									  0,
									  1,
									  2052
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 171,
								  "absoluteRenderBounds": {
									"x": 10043,
									"y": 2052,
									"width": 292,
									"height": 171
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3780;443:3282",
									  "parent": {
										"id": "443:3780"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10043
										],
										[
										  0,
										  1,
										  2052
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10043,
										"y": 2052,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20758.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3780;443:3288",
										  "parent": {
											"id": "I443:3780;443:3282"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10051
											],
											[
											  0,
											  1,
											  2060
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10051,
											"y": 2060,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3780;443:3288;423:20771",
											  "parent": {
												"id": "I443:3780;443:3288"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10051
												],
												[
												  0,
												  1,
												  2060
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10051,
												"y": 2060,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3780;443:3288;423:20781",
												  "parent": {
													"id": "I443:3780;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10056
													],
													[
													  0,
													  1,
													  2062
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10055,
													"y": 2061,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3780;443:3288;423:20786",
												  "parent": {
													"id": "I443:3780;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10063
													],
													[
													  0,
													  1,
													  2062
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10062,
													"y": 2061,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3780;443:3288;423:20791",
												  "parent": {
													"id": "I443:3780;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10063
													],
													[
													  0,
													  1,
													  2069
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10062,
													"y": 2068,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3780;443:3288;423:20793",
												  "parent": {
													"id": "I443:3780;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10056
													],
													[
													  0,
													  1,
													  2076
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10055,
													"y": 2075,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3780;443:3288;423:20795",
												  "parent": {
													"id": "I443:3780;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10056
													],
													[
													  0,
													  1,
													  2069
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10055,
													"y": 2068,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3780;443:3322",
									  "parent": {
										"id": "443:3780"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10043
										],
										[
										  0,
										  1,
										  2108
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 115,
									  "absoluteRenderBounds": {
										"x": 10043,
										"y": 2108,
										"width": 292,
										"height": 115
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3780;443:3326",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3780;443:3322"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  2108
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10044.7216796875,
											"y": 2111.54541015625,
											"width": 113.5927734375,
											"height": 22.636474609375
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Instagram",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3780;443:3330",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3780;443:3322"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  2140
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10043.9208984375,
											"y": 2146.5,
											"width": 233.7392578125,
											"height": 44.181884765625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3780;443:3334",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3780;443:3322"
										  },
										  "name": "Call To Action",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.008333325386047363,
												"g": 0.28684401512145996,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  94
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10043
											],
											[
											  0,
											  1,
											  2202
											]
										  ],
										  "x": 0,
										  "y": 94,
										  "width": 292,
										  "height": 21,
										  "absoluteRenderBounds": {
											"x": 10044.00390625,
											"y": 2207.818115234375,
											"width": 42.154296875,
											"height": 10.3310546875
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Follow",
										  "hasMissingFont": false,
										  "fontSize": 14,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3278"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "1"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3832",
						  "parent": {
							"id": "443:3421"
						  },
						  "name": "Media Objects",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  760
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10399
							],
							[
							  0,
							  1,
							  1777
							]
						  ],
						  "x": 760,
						  "y": 0,
						  "width": 340,
						  "height": 470,
						  "absoluteRenderBounds": {
							"x": 10383,
							"y": 1765,
							"width": 372,
							"height": 502
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3833",
							  "parent": {
								"id": "443:3832"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  1777
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 340,
							  "height": 219,
							  "absoluteRenderBounds": {
								"x": 10383,
								"y": 1765,
								"width": 372,
								"height": 251
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3834",
								  "parent": {
									"id": "443:3833"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10423
									],
									[
									  0,
									  1,
									  1801
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 171,
								  "absoluteRenderBounds": {
									"x": 10423,
									"y": 1801,
									"width": 292,
									"height": 171
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3834;443:3282",
									  "parent": {
										"id": "443:3834"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10423
										],
										[
										  0,
										  1,
										  1801
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10423,
										"y": 1801,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20754.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3834;443:3288",
										  "parent": {
											"id": "I443:3834;443:3282"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10431
											],
											[
											  0,
											  1,
											  1809
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10431,
											"y": 1809,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3834;443:3288;423:20771",
											  "parent": {
												"id": "I443:3834;443:3288"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10431
												],
												[
												  0,
												  1,
												  1809
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10431,
												"y": 1809,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3834;443:3288;423:20781",
												  "parent": {
													"id": "I443:3834;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10436
													],
													[
													  0,
													  1,
													  1811
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10435,
													"y": 1810,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3834;443:3288;423:20786",
												  "parent": {
													"id": "I443:3834;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10443
													],
													[
													  0,
													  1,
													  1811
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10442,
													"y": 1810,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3834;443:3288;423:20791",
												  "parent": {
													"id": "I443:3834;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10443
													],
													[
													  0,
													  1,
													  1818
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10442,
													"y": 1817,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3834;443:3288;423:20793",
												  "parent": {
													"id": "I443:3834;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10436
													],
													[
													  0,
													  1,
													  1825
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10435,
													"y": 1824,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3834;443:3288;423:20795",
												  "parent": {
													"id": "I443:3834;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10436
													],
													[
													  0,
													  1,
													  1818
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10435,
													"y": 1817,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3834;443:3322",
									  "parent": {
										"id": "443:3834"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10423
										],
										[
										  0,
										  1,
										  1857
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 115,
									  "absoluteRenderBounds": {
										"x": 10423,
										"y": 1857,
										"width": 292,
										"height": 115
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3834;443:3326",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3834;443:3322"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  1857
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10423.9541015625,
											"y": 1859.70166015625,
											"width": 81.1044921875,
											"height": 18.5540771484375
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Twitter",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3834;443:3330",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3834;443:3322"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  1889
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10423.9208984375,
											"y": 1895.5,
											"width": 233.7392578125,
											"height": 44.1817626953125
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3834;443:3334",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3834;443:3322"
										  },
										  "name": "Call To Action",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.008333325386047363,
												"g": 0.28684401512145996,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  94
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  1951
											]
										  ],
										  "x": 0,
										  "y": 94,
										  "width": 292,
										  "height": 21,
										  "absoluteRenderBounds": {
											"x": 10424.00390625,
											"y": 1956.8182373046875,
											"width": 42.154296875,
											"height": 10.3309326171875
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Follow",
										  "hasMissingFont": false,
										  "fontSize": 14,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3278"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "1"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "443:3885",
							  "parent": {
								"id": "443:3832"
							  },
							  "name": "Media Container",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [
								{
								  "type": "DROP_SHADOW",
								  "color": {
									"r": 0,
									"g": 0,
									"b": 0,
									"a": 0.10000000149011612
								  },
								  "offset": {
									"x": 0,
									"y": 4
								  },
								  "radius": 16,
								  "spread": 0,
								  "visible": true,
								  "blendMode": "NORMAL",
								  "showShadowBehindNode": false
								}
							  ],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  251
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  2028
								]
							  ],
							  "x": 0,
							  "y": 251,
							  "width": 340,
							  "height": 219,
							  "absoluteRenderBounds": {
								"x": 10383,
								"y": 2016,
								"width": 372,
								"height": 251
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "makers-media-object",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "443:3886",
								  "parent": {
									"id": "443:3885"
								  },
								  "name": "MAKERS_MEDIA_OBJECT",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  24
									],
									[
									  0,
									  1,
									  24
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10423
									],
									[
									  0,
									  1,
									  2052
									]
								  ],
								  "x": 24,
								  "y": 24,
								  "width": 292,
								  "height": 171,
								  "absoluteRenderBounds": {
									"x": 10423,
									"y": 2052,
									"width": 292,
									"height": 171
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3886;443:3282",
									  "parent": {
										"id": "443:3886"
									  },
									  "name": "Media",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10423
										],
										[
										  0,
										  1,
										  2052
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 40,
									  "height": 40,
									  "absoluteRenderBounds": {
										"x": 10423,
										"y": 2052,
										"width": 40,
										"height": 40
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-423-20756.svg",
										  "makersExportAsSvg": "true",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3886;443:3288",
										  "parent": {
											"id": "I443:3886;443:3282"
										  },
										  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": true,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "relativeTransform": [
											[
											  1,
											  0,
											  8
											],
											[
											  0,
											  1,
											  8
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10431
											],
											[
											  0,
											  1,
											  2060
											]
										  ],
										  "x": 8,
										  "y": 8,
										  "width": 24,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10431,
											"y": 2060,
											"width": 24,
											"height": 24
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "children": [
											{
											  "makersHtmlTag": "",
											  "makersClasses": "",
											  "makersComponent": "",
											  "makersBackgroundImage": "",
											  "makersExportAsSvg": "",
											  "makersPageUrl": "",
											  "makersPageTitle": "",
											  "makersPageImage": "",
											  "makersPageDescription": "",
											  "id": "I443:3886;443:3288;423:20771",
											  "parent": {
												"id": "I443:3886;443:3288"
											  },
											  "name": "vectors",
											  "removed": false,
											  "widgetEvents": [],
											  "visible": true,
											  "locked": false,
											  "opacity": 1,
											  "blendMode": "PASS_THROUGH",
											  "isMask": false,
											  "effects": [],
											  "effectStyleId": "",
											  "relativeTransform": [
												[
												  1,
												  0,
												  0
												],
												[
												  0,
												  1,
												  0
												]
											  ],
											  "absoluteTransform": [
												[
												  1,
												  0,
												  10431
												],
												[
												  0,
												  1,
												  2060
												]
											  ],
											  "x": 0,
											  "y": 0,
											  "width": 24,
											  "height": 24,
											  "absoluteRenderBounds": {
												"x": 10431,
												"y": 2060,
												"width": 24,
												"height": 24
											  },
											  "rotation": 0,
											  "layoutAlign": "INHERIT",
											  "constrainProportions": false,
											  "layoutGrow": 0,
											  "children": [
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3886;443:3288;423:20781",
												  "parent": {
													"id": "I443:3886;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10436
													],
													[
													  0,
													  1,
													  2062
													]
												  ],
												  "x": 5,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10435,
													"y": 2061,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 4.440892098500626e-16
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3886;443:3288;423:20786",
												  "parent": {
													"id": "I443:3886;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  2
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10443
													],
													[
													  0,
													  1,
													  2062
													]
												  ],
												  "x": 12,
												  "y": 2,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10442,
													"y": 2061,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": -6.8489693916262695e-9
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914738893509,
														  "y": -0.4246395528316498
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.1758914738893509,
														  "y": 0.4246395528316498
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914589881897
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914589881897
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 6.8489693916262695e-9
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 10,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 3.886141898679611e-16,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664215564727783,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251262187957764,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606078147888184,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839392185211182,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3886;443:3288;423:20791",
												  "parent": {
													"id": "I443:3886;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  12
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10443
													],
													[
													  0,
													  1,
													  2069
													]
												  ],
												  "x": 12,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10442,
													"y": 2068,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7,
															8,
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": -9.685905411060958e-9,
														  "y": -0.45962655544281006
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 7,
														"end": 8,
														"tangentStart": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 9.685901858347279e-9,
														  "y": -0.45962655544281006
														}
													  },
													  {
														"start": 8,
														"end": 9,
														"tangentStart": {
														  "x": 9.685905411060958e-9,
														  "y": 0.45962655544281006
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": -0.42463958263397217
														}
													  },
													  {
														"start": 9,
														"end": 10,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": 0.42463958263397217
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": -0.32500505447387695
														}
													  },
													  {
														"start": 10,
														"end": 11,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": 0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": -0.1758914738893509
														}
													  },
													  {
														"start": 11,
														"end": 12,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": 0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.45962655544281006,
														  "y": 0
														}
													  },
													  {
														"start": 12,
														"end": 13,
														"tangentStart": {
														  "x": -0.45962655544281006,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.4246395528316498,
														  "y": 0.1758914738893509
														}
													  },
													  {
														"start": 13,
														"end": 14,
														"tangentStart": {
														  "x": -0.4246395528316498,
														  "y": -0.1758914738893509
														},
														"tangentEnd": {
														  "x": 0.32500505447387695,
														  "y": 0.32500505447387695
														}
													  },
													  {
														"start": 14,
														"end": 15,
														"tangentStart": {
														  "x": -0.32500505447387695,
														  "y": -0.32500505447387695
														},
														"tangentEnd": {
														  "x": 0.1758914589881897,
														  "y": 0.42463958263397217
														}
													  },
													  {
														"start": 15,
														"end": 0,
														"tangentStart": {
														  "x": -0.1758914589881897,
														  "y": -0.42463958263397217
														},
														"tangentEnd": {
														  "x": -9.685907187417797e-9,
														  "y": 0.45962655544281006
														}
													  },
													  {
														"start": 0,
														"end": 0,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  }
													],
													"vertices": [
													  {
														"x": 7.77230656136968e-16,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 0.2664213180541992,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 2.1606082916259766,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 6.733577728271484,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.974874496459961,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 4.839391708374023,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 2.1606082916259766,
														"y": 6.733578681945801,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 0.2664213180541992,
														"y": 4.839391708374023,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3886;443:3288;423:20793",
												  "parent": {
													"id": "I443:3886;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  16
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10436
													],
													[
													  0,
													  1,
													  2076
													]
												  ],
												  "x": 5,
												  "y": 16,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10435,
													"y": 2075,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6,
															7
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0.9282577037811279
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0.9282577037811279,
														  "y": 0
														}
													  },
													  {
														"start": 6,
														"end": 7,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 7,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.025125503540039,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 5.9748735427856445,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.974874496459961,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												},
												{
												  "makersHtmlTag": "",
												  "makersClasses": "",
												  "makersComponent": "",
												  "makersBackgroundImage": "",
												  "makersExportAsSvg": "",
												  "makersPageUrl": "",
												  "makersPageTitle": "",
												  "makersPageImage": "",
												  "makersPageDescription": "",
												  "id": "I443:3886;443:3288;423:20795",
												  "parent": {
													"id": "I443:3886;443:3288;423:20771"
												  },
												  "name": "Vector",
												  "removed": false,
												  "widgetEvents": [],
												  "visible": true,
												  "locked": false,
												  "opacity": 1,
												  "blendMode": "PASS_THROUGH",
												  "isMask": false,
												  "effects": [],
												  "effectStyleId": "",
												  "fills": [],
												  "fillStyleId": "",
												  "strokes": [
													{
													  "type": "SOLID",
													  "visible": true,
													  "opacity": 1,
													  "blendMode": "NORMAL",
													  "color": {
														"r": 1,
														"g": 1,
														"b": 1
													  }
													}
												  ],
												  "strokeStyleId": "",
												  "strokeWeight": 2,
												  "strokeAlign": "CENTER",
												  "strokeJoin": "ROUND",
												  "dashPattern": [],
												  "strokeCap": "ROUND",
												  "strokeMiterLimit": 4,
												  "relativeTransform": [
													[
													  1,
													  0,
													  5
													],
													[
													  0,
													  1,
													  9
													]
												  ],
												  "absoluteTransform": [
													[
													  1,
													  0,
													  10436
													],
													[
													  0,
													  1,
													  2069
													]
												  ],
												  "x": 5,
												  "y": 9,
												  "width": 7,
												  "height": 7,
												  "absoluteRenderBounds": {
													"x": 10435,
													"y": 2068,
													"width": 9,
													"height": 9
												  },
												  "rotation": 0,
												  "layoutAlign": "INHERIT",
												  "constrainProportions": false,
												  "layoutGrow": 0,
												  "exportSettings": [],
												  "constraints": {
													"horizontal": "SCALE",
													"vertical": "SCALE"
												  },
												  "cornerRadius": 0,
												  "cornerSmoothing": 0,
												  "vectorNetwork": {
													"regions": [
													  {
														"windingRule": "NONZERO",
														"loops": [
														  [
															0,
															1,
															2,
															3,
															4,
															5,
															6
														  ]
														]
													  }
													],
													"segments": [
													  {
														"start": 0,
														"end": 1,
														"tangentStart": {
														  "x": 0,
														  "y": -0.9282577037811279
														},
														"tangentEnd": {
														  "x": -0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 1,
														"end": 2,
														"tangentStart": {
														  "x": 0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": -0.9282577037811279,
														  "y": 1.7763568394002505e-15
														}
													  },
													  {
														"start": 2,
														"end": 3,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 3,
														"end": 4,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 4,
														"end": 5,
														"tangentStart": {
														  "x": 0,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0
														}
													  },
													  {
														"start": 5,
														"end": 6,
														"tangentStart": {
														  "x": -0.9282577037811279,
														  "y": 0
														},
														"tangentEnd": {
														  "x": 0.6563773155212402,
														  "y": 0.6563773155212402
														}
													  },
													  {
														"start": 6,
														"end": 0,
														"tangentStart": {
														  "x": -0.6563773155212402,
														  "y": -0.6563773155212402
														},
														"tangentEnd": {
														  "x": 0,
														  "y": 0.9282577037811279
														}
													  }
													],
													"vertices": [
													  {
														"x": 0,
														"y": 3.5,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 1.0251264572143555,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 0,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 7,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 3.5,
														"y": 7,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  },
													  {
														"x": 1.0251264572143555,
														"y": 5.9748735427856445,
														"strokeCap": "ROUND",
														"strokeJoin": "ROUND",
														"cornerRadius": 0,
														"handleMirroring": "NONE"
													  }
													]
												  },
												  "vectorPaths": [
													{
													  "windingRule": "NONZERO",
													  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
													}
												  ],
												  "handleMirroring": "NONE",
												  "reactions": [],
												  "type": "VECTOR",
												  "children": []
												}
											  ],
											  "exportSettings": [],
											  "fills": [],
											  "fillStyleId": "",
											  "strokes": [],
											  "strokeStyleId": "",
											  "strokeWeight": 1,
											  "strokeAlign": "INSIDE",
											  "strokeJoin": "MITER",
											  "dashPattern": [],
											  "strokeCap": "NONE",
											  "strokeMiterLimit": 4,
											  "cornerRadius": 0,
											  "cornerSmoothing": 0,
											  "topLeftRadius": 0,
											  "topRightRadius": 0,
											  "bottomLeftRadius": 0,
											  "bottomRightRadius": 0,
											  "paddingLeft": 0,
											  "paddingRight": 0,
											  "paddingTop": 0,
											  "paddingBottom": 0,
											  "primaryAxisAlignItems": "MIN",
											  "counterAxisAlignItems": "MIN",
											  "primaryAxisSizingMode": "AUTO",
											  "layoutGrids": [],
											  "gridStyleId": "",
											  "backgroundStyleId": "",
											  "clipsContent": true,
											  "guides": [],
											  "expanded": false,
											  "constraints": {
												"horizontal": "MIN",
												"vertical": "MIN"
											  },
											  "layoutMode": "NONE",
											  "counterAxisSizingMode": "FIXED",
											  "itemSpacing": 0,
											  "overflowDirection": "NONE",
											  "numberOfFixedChildren": 0,
											  "overlayPositionType": "CENTER",
											  "overlayBackground": {
												"type": "NONE"
											  },
											  "overlayBackgroundInteraction": "NONE",
											  "reactions": [],
											  "type": "FRAME"
											}
										  ],
										  "exportSettings": [],
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": false,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "INSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "topLeftRadius": 0,
										  "topRightRadius": 0,
										  "bottomLeftRadius": 0,
										  "bottomRightRadius": 0,
										  "paddingLeft": 0,
										  "paddingRight": 0,
										  "paddingTop": 0,
										  "paddingBottom": 0,
										  "primaryAxisAlignItems": "MIN",
										  "counterAxisAlignItems": "MIN",
										  "primaryAxisSizingMode": "AUTO",
										  "layoutGrids": [],
										  "gridStyleId": "",
										  "backgroundStyleId": "",
										  "clipsContent": true,
										  "guides": [],
										  "expanded": false,
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "layoutMode": "NONE",
										  "counterAxisSizingMode": "FIXED",
										  "itemSpacing": 0,
										  "overflowDirection": "NONE",
										  "numberOfFixedChildren": 0,
										  "overlayPositionType": "CENTER",
										  "overlayBackground": {
											"type": "NONE"
										  },
										  "overlayBackgroundInteraction": "NONE",
										  "mainComponent": {
											"id": "423:20761"
										  },
										  "scaleFactor": 1,
										  "reactions": [],
										  "variantProperties": {
											"Logo": "figma"
										  },
										  "type": "INSTANCE",
										  "makersVariantProps": "8"
										}
									  ],
									  "exportSettings": [],
									  "fills": [
										{
										  "type": "SOLID",
										  "visible": true,
										  "opacity": 1,
										  "blendMode": "NORMAL",
										  "color": {
											"r": 0.16470588743686676,
											"g": 0.7411764860153198,
											"b": 0.6392157077789307
										  }
										}
									  ],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 6,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 6,
									  "topRightRadius": 6,
									  "bottomLeftRadius": 6,
									  "bottomRightRadius": 6,
									  "paddingLeft": 8,
									  "paddingRight": 8,
									  "paddingTop": 8,
									  "paddingBottom": 8,
									  "primaryAxisAlignItems": "CENTER",
									  "counterAxisAlignItems": "CENTER",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "HORIZONTAL",
									  "counterAxisSizingMode": "AUTO",
									  "itemSpacing": 10,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									},
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3886;443:3322",
									  "parent": {
										"id": "443:3886"
									  },
									  "name": "Text",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  56
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10423
										],
										[
										  0,
										  1,
										  2108
										]
									  ],
									  "x": 0,
									  "y": 56,
									  "width": 292,
									  "height": 115,
									  "absoluteRenderBounds": {
										"x": 10423,
										"y": 2108,
										"width": 292,
										"height": 115
									  },
									  "rotation": 0,
									  "layoutAlign": "STRETCH",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3886;443:3326",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3886;443:3322"
										  },
										  "name": "Publish with Figma",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0,
												"g": 0,
												"b": 0
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  0
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  2108
											]
										  ],
										  "x": 0,
										  "y": 0,
										  "width": 292,
										  "height": 24,
										  "absoluteRenderBounds": {
											"x": 10424.2783203125,
											"y": 2110.70166015625,
											"width": 79.85546875,
											"height": 18.54541015625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "GitHub",
										  "hasMissingFont": false,
										  "fontSize": 24,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PIXELS",
											"value": 24
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3886;443:3330",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3886;443:3322"
										  },
										  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.03947368264198303,
												"g": 0.16421058773994446,
												"b": 0.2605263292789459
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  32
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  2140
											]
										  ],
										  "x": 0,
										  "y": 32,
										  "width": 292,
										  "height": 54,
										  "absoluteRenderBounds": {
											"x": 10423.9208984375,
											"y": 2146.5,
											"width": 233.7392578125,
											"height": 44.181884765625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
										  "hasMissingFont": false,
										  "fontSize": 18,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Regular"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3886;443:3334",
										  "listSpacing": 0,
										  "parent": {
											"id": "I443:3886;443:3322"
										  },
										  "name": "Call To Action",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 0.008333325386047363,
												"g": 0.28684401512145996,
												"b": 1
											  }
											}
										  ],
										  "fillStyleId": "",
										  "strokes": [],
										  "strokeStyleId": "",
										  "strokeWeight": 1,
										  "strokeAlign": "OUTSIDE",
										  "strokeJoin": "MITER",
										  "dashPattern": [],
										  "strokeCap": "NONE",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  0
											],
											[
											  0,
											  1,
											  94
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10423
											],
											[
											  0,
											  1,
											  2202
											]
										  ],
										  "x": 0,
										  "y": 94,
										  "width": 292,
										  "height": 21,
										  "absoluteRenderBounds": {
											"x": 10423.63671875,
											"y": 2207.678955078125,
											"width": 27.0927734375,
											"height": 10.47509765625
										  },
										  "rotation": 0,
										  "layoutAlign": "STRETCH",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "MIN",
											"vertical": "MIN"
										  },
										  "characters": "Star",
										  "hasMissingFont": false,
										  "fontSize": 14,
										  "paragraphIndent": 0,
										  "paragraphSpacing": 0,
										  "textCase": "ORIGINAL",
										  "textDecoration": "NONE",
										  "letterSpacing": {
											"unit": "PERCENT",
											"value": 0
										  },
										  "lineHeight": {
											"unit": "PERCENT",
											"value": 150
										  },
										  "fontName": {
											"family": "Inter",
											"style": "Semi Bold"
										  },
										  "autoRename": false,
										  "textAlignHorizontal": "LEFT",
										  "textAlignVertical": "TOP",
										  "textAutoResize": "HEIGHT",
										  "textStyleId": "",
										  "reactions": [],
										  "type": "TEXT",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": false,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "VERTICAL",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 8,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": false,
								  "guides": [],
								  "expanded": true,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "VERTICAL",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 16,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "443:3278"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Layout": "Vertical"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "1"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 1,
									"g": 1,
									"b": 1
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 12,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 12,
							  "topRightRadius": 12,
							  "bottomLeftRadius": 12,
							  "bottomRightRadius": 12,
							  "paddingLeft": 24,
							  "paddingRight": 24,
							  "paddingTop": 24,
							  "paddingBottom": 24,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": true,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 32,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "reactions": [],
						  "type": "FRAME"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "SPACE_BETWEEN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "HORIZONTAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 16,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "GRADIENT_LINEAR",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "gradientStops": [
				{
				  "color": {
					"r": 0.07450980693101883,
					"g": 0.32549020648002625,
					"b": 0.2823529541492462,
					"a": 1
				  },
				  "position": 0
				},
				{
				  "color": {
					"r": 0.07450980693101883,
					"g": 0.32549020648002625,
					"b": 0.2823529541492462,
					"a": 1
				  },
				  "position": 0.45611268281936646
				},
				{
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1,
					"a": 1
				  },
				  "position": 0.45628267526626587
				},
				{
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1,
					"a": 1
				  },
				  "position": 1
				}
			  ],
			  "gradientTransform": [
				[
				  6.123234262925839e-17,
				  1,
				  0
				],
				[
				  -1,
				  6.123234262925839e-17,
				  1
				]
			  ]
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "7e769ce53eff99f09b60f6e28efda3c88f30ab03",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Overlapping Background"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-features, makers-features--split",
		  "makersComponent": "hero",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3341",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Split",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  2311
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  9469
			],
			[
			  0,
			  1,
			  2331
			]
		  ],
		  "x": 20,
		  "y": 2311,
		  "width": 1440,
		  "height": 1050,
		  "absoluteRenderBounds": {
			"x": 9469,
			"y": 2331,
			"width": 1440,
			"height": 1050
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3346",
			  "parent": {
				"id": "443:3341"
			  },
			  "name": "Container",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  320
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9789
				],
				[
				  0,
				  1,
				  2331
				]
			  ],
			  "x": 320,
			  "y": 0,
			  "width": 800,
			  "height": 337,
			  "absoluteRenderBounds": {
				"x": 9789,
				"y": 2331,
				"width": 800,
				"height": 337
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3351",
				  "parent": {
					"id": "443:3346"
				  },
				  "name": "Text Container",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  48
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9789
					],
					[
					  0,
					  1,
					  2379
					]
				  ],
				  "x": 0,
				  "y": 48,
				  "width": 800,
				  "height": 241,
				  "absoluteRenderBounds": {
					"x": 9789,
					"y": 2379,
					"width": 800,
					"height": 241
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3362",
					  "parent": {
						"id": "443:3351"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9789
						],
						[
						  0,
						  1,
						  2379
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 800,
					  "height": 241,
					  "absoluteRenderBounds": {
						"x": 9789,
						"y": 2379,
						"width": 800,
						"height": 241
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3362;394:2805",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3362"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.16470588743686676,
								"g": 0.7411764860153198,
								"b": 0.6392157077789307
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9789
							],
							[
							  0,
							  1,
							  2379
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 800,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 10120.0927734375,
							"y": 2384.306884765625,
							"width": 137.8125,
							"height": 17.957275390625
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "UPPER",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3362;394:2808",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3362"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9789
							],
							[
							  0,
							  1,
							  2432
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 800,
						  "height": 116,
						  "absoluteRenderBounds": {
							"x": 9829.3388671875,
							"y": 2441.0966796875,
							"width": 719.556640625,
							"height": 105.266845703125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to make and publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3362;394:2811",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3362"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  163.33335876464844
							],
							[
							  0,
							  1,
							  193
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9952.3330078125
							],
							[
							  0,
							  1,
							  2572
							]
						  ],
						  "x": 163.33335876464844,
						  "y": 193,
						  "width": 473.3332824707031,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 9966.4326171875,
							"y": 2578,
							"width": 445.751953125,
							"height": 39.45458984375
						  },
						  "rotation": 0,
						  "layoutAlign": "INHERIT",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "CENTER",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2802"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Center"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "1"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 10,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 48,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			},
			{
			  "makersHtmlTag": "div",
			  "makersClasses": "columns",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3409",
			  "parent": {
				"id": "443:3341"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  120
				],
				[
				  0,
				  1,
				  337
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9589
				],
				[
				  0,
				  1,
				  2668
				]
			  ],
			  "x": 120,
			  "y": 337,
			  "width": 1200,
			  "height": 713,
			  "absoluteRenderBounds": {
				"x": 9589,
				"y": 2648,
				"width": 1224,
				"height": 733
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3413",
				  "parent": {
					"id": "443:3409"
				  },
				  "name": "Text Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9589
					],
					[
					  0,
					  1,
					  2668
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 676,
				  "height": 665,
				  "absoluteRenderBounds": {
					"x": 9589,
					"y": 2668,
					"width": 676,
					"height": 665
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3417",
					  "parent": {
						"id": "443:3413"
					  },
					  "name": "MAKERS_TEXT_CONTENT",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  10
						],
						[
						  0,
						  1,
						  10
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9599
						],
						[
						  0,
						  1,
						  2678
						]
					  ],
					  "x": 10,
					  "y": 10,
					  "width": 473.3332824707031,
					  "height": 299,
					  "absoluteRenderBounds": {
						"x": 9599,
						"y": 2678,
						"width": 473.3330078125,
						"height": 299
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3417;394:2804",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3417"
						  },
						  "name": "Education",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9599
							],
							[
							  0,
							  1,
							  2678
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 473.3332824707031,
						  "height": 29,
						  "absoluteRenderBounds": {
							"x": 9600.7216796875,
							"y": 2682.70166015625,
							"width": 113.2666015625,
							"height": 18.5625
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Education",
						  "hasMissingFont": false,
						  "fontSize": 24,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Semi Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3417;394:2807",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3417"
						  },
						  "name": "Learn how to make and publish sites with Figma",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  53
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9599
							],
							[
							  0,
							  1,
							  2731
							]
						  ],
						  "x": 0,
						  "y": 53,
						  "width": 473.3332824707031,
						  "height": 174,
						  "absoluteRenderBounds": {
							"x": 9601.8974609375,
							"y": 2742.0908203125,
							"width": 407.458984375,
							"height": 161.272705078125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Learn how to publish sites with Figma",
						  "hasMissingFont": false,
						  "fontSize": 48,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "I443:3417;394:2810",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3417"
						  },
						  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0.03947368264198303,
								"g": 0.16421058773994446,
								"b": 0.2605263292789459
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  251
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9599
							],
							[
							  0,
							  1,
							  2929
							]
						  ],
						  "x": 0,
						  "y": 251,
						  "width": 473.3332824707031,
						  "height": 48,
						  "absoluteRenderBounds": {
							"x": 9599.818359375,
							"y": 2935,
							"width": 446.34375,
							"height": 39.45458984375
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
						  "hasMissingFont": false,
						  "fontSize": 16,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "PERCENT",
							"value": 150
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Regular"
						  },
						  "autoRename": false,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": true,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 24,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "mainComponent": {
						"id": "394:2801"
					  },
					  "scaleFactor": 1,
					  "reactions": [],
					  "variantProperties": {
						"Align": "Left"
					  },
					  "type": "INSTANCE",
					  "makersVariantProps": "0"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3477",
					  "parent": {
						"id": "443:3413"
					  },
					  "name": "Media Objects",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  10
						],
						[
						  0,
						  1,
						  333
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9599
						],
						[
						  0,
						  1,
						  3001
						]
					  ],
					  "x": 10,
					  "y": 333,
					  "width": 600,
					  "height": 322,
					  "absoluteRenderBounds": {
						"x": 9599,
						"y": 3001,
						"width": 600,
						"height": 322
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3490",
						  "parent": {
							"id": "443:3477"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9599
							],
							[
							  0,
							  1,
							  3001
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 600,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 9599,
							"y": 3001,
							"width": 600,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3490;443:3281",
							  "parent": {
								"id": "443:3490"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9599
								],
								[
								  0,
								  1,
								  3001
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 9599,
								"y": 3001,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3439;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3490;443:3285",
								  "parent": {
									"id": "I443:3490;443:3281"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9607
									],
									[
									  0,
									  1,
									  3009
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9607,
									"y": 3009,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3490;443:3285;423:20771",
									  "parent": {
										"id": "I443:3490;443:3285"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9607
										],
										[
										  0,
										  1,
										  3009
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 9607,
										"y": 3009,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3490;443:3285;423:20781",
										  "parent": {
											"id": "I443:3490;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3011
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3010,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3490;443:3285;423:20786",
										  "parent": {
											"id": "I443:3490;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9619
											],
											[
											  0,
											  1,
											  3011
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9618,
											"y": 3010,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3490;443:3285;423:20791",
										  "parent": {
											"id": "I443:3490;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9619
											],
											[
											  0,
											  1,
											  3018
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9618,
											"y": 3017,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3490;443:3285;423:20793",
										  "parent": {
											"id": "I443:3490;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3025
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3024,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3490;443:3285;423:20795",
										  "parent": {
											"id": "I443:3490;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3018
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3017,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3490;443:3321",
							  "parent": {
								"id": "443:3490"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  56
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9655
								],
								[
								  0,
								  1,
								  3001
								]
							  ],
							  "x": 56,
							  "y": 0,
							  "width": 544,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 9655,
								"y": 3001,
								"width": 544,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3490;443:3325",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3490;443:3321"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9655
									],
									[
									  0,
									  1,
									  3001
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 544,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9655.9541015625,
									"y": 3003.70166015625,
									"width": 426.4306640625,
									"height": 23.20751953125
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "The easiest way to publish your sites",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3490;443:3329",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3490;443:3321"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9655
									],
									[
									  0,
									  1,
									  3033
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 544,
								  "height": 54,
								  "absoluteRenderBounds": {
									"x": 9655.9462890625,
									"y": 3039.5,
									"width": 506.259765625,
									"height": 44.181884765625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": true,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3277"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Horizontal"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3555",
						  "parent": {
							"id": "443:3477"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  118
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9599
							],
							[
							  0,
							  1,
							  3119
							]
						  ],
						  "x": 0,
						  "y": 118,
						  "width": 600,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 9599,
							"y": 3119,
							"width": 600,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3555;443:3281",
							  "parent": {
								"id": "443:3555"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9599
								],
								[
								  0,
								  1,
								  3119
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 9599,
								"y": 3119,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3506;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3555;443:3285",
								  "parent": {
									"id": "I443:3555;443:3281"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9607
									],
									[
									  0,
									  1,
									  3127
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9607,
									"y": 3127,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3555;443:3285;423:20771",
									  "parent": {
										"id": "I443:3555;443:3285"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9607
										],
										[
										  0,
										  1,
										  3127
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 9607,
										"y": 3127,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3555;443:3285;423:20781",
										  "parent": {
											"id": "I443:3555;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3129
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3128,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3555;443:3285;423:20786",
										  "parent": {
											"id": "I443:3555;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9619
											],
											[
											  0,
											  1,
											  3129
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9618,
											"y": 3128,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3555;443:3285;423:20791",
										  "parent": {
											"id": "I443:3555;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9619
											],
											[
											  0,
											  1,
											  3136
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9618,
											"y": 3135,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3555;443:3285;423:20793",
										  "parent": {
											"id": "I443:3555;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3143
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3142,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3555;443:3285;423:20795",
										  "parent": {
											"id": "I443:3555;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3136
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3135,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3555;443:3321",
							  "parent": {
								"id": "443:3555"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  56
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9655
								],
								[
								  0,
								  1,
								  3119
								]
							  ],
							  "x": 56,
							  "y": 0,
							  "width": 544,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 9655,
								"y": 3119,
								"width": 544,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3555;443:3325",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3555;443:3321"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9655
									],
									[
									  0,
									  1,
									  3119
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 544,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9655.9541015625,
									"y": 3121.70166015625,
									"width": 426.4306640625,
									"height": 23.20751953125
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "The easiest way to publish your sites",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3555;443:3329",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3555;443:3321"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9655
									],
									[
									  0,
									  1,
									  3151
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 544,
								  "height": 54,
								  "absoluteRenderBounds": {
									"x": 9655.9462890625,
									"y": 3157.5,
									"width": 506.259765625,
									"height": 44.181884765625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3277"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Horizontal"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3646",
						  "parent": {
							"id": "443:3477"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  236
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9599
							],
							[
							  0,
							  1,
							  3237
							]
						  ],
						  "x": 0,
						  "y": 236,
						  "width": 600,
						  "height": 86,
						  "absoluteRenderBounds": {
							"x": 9599,
							"y": 3237,
							"width": 600,
							"height": 86
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3646;443:3281",
							  "parent": {
								"id": "443:3646"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9599
								],
								[
								  0,
								  1,
								  3237
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 9599,
								"y": 3237,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3506;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3646;443:3285",
								  "parent": {
									"id": "I443:3646;443:3281"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9607
									],
									[
									  0,
									  1,
									  3245
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9607,
									"y": 3245,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3646;443:3285;423:20771",
									  "parent": {
										"id": "I443:3646;443:3285"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  9607
										],
										[
										  0,
										  1,
										  3245
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 9607,
										"y": 3245,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3646;443:3285;423:20781",
										  "parent": {
											"id": "I443:3646;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3247
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3246,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3646;443:3285;423:20786",
										  "parent": {
											"id": "I443:3646;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9619
											],
											[
											  0,
											  1,
											  3247
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9618,
											"y": 3246,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3646;443:3285;423:20791",
										  "parent": {
											"id": "I443:3646;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9619
											],
											[
											  0,
											  1,
											  3254
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9618,
											"y": 3253,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3646;443:3285;423:20793",
										  "parent": {
											"id": "I443:3646;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3261
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3260,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3646;443:3285;423:20795",
										  "parent": {
											"id": "I443:3646;443:3285;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  9612
											],
											[
											  0,
											  1,
											  3254
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 9611,
											"y": 3253,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3646;443:3321",
							  "parent": {
								"id": "443:3646"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  56
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  9655
								],
								[
								  0,
								  1,
								  3237
								]
							  ],
							  "x": 56,
							  "y": 0,
							  "width": 544,
							  "height": 86,
							  "absoluteRenderBounds": {
								"x": 9655,
								"y": 3237,
								"width": 544,
								"height": 86
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 1,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3646;443:3325",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3646;443:3321"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9655
									],
									[
									  0,
									  1,
									  3237
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 544,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 9655.9541015625,
									"y": 3239.70166015625,
									"width": 426.4306640625,
									"height": 23.20751953125
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "The easiest way to publish your sites",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3646;443:3329",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3646;443:3321"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  9655
									],
									[
									  0,
									  1,
									  3269
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 544,
								  "height": 54,
								  "absoluteRenderBounds": {
									"x": 9655.9462890625,
									"y": 3275.5,
									"width": 506.259765625,
									"height": 44.181884765625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "FIXED",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "HORIZONTAL",
						  "counterAxisSizingMode": "AUTO",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3277"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Horizontal"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "0"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 10,
				  "paddingRight": 10,
				  "paddingTop": 10,
				  "paddingBottom": 10,
				  "primaryAxisAlignItems": "MIN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "VERTICAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 24,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3751",
				  "parent": {
					"id": "443:3409"
				  },
				  "name": "Image Side",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [
					{
					  "type": "DROP_SHADOW",
					  "color": {
						"r": 0,
						"g": 0.5083333253860474,
						"b": 0.473102331161499,
						"a": 0.10000000149011612
					  },
					  "offset": {
						"x": 0,
						"y": 4
					  },
					  "radius": 24,
					  "spread": 0,
					  "visible": true,
					  "blendMode": "NORMAL",
					  "showShadowBehindNode": false
					}
				  ],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  700
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  10289
					],
					[
					  0,
					  1,
					  2668
					]
				  ],
				  "x": 700,
				  "y": 0,
				  "width": 500,
				  "height": 665,
				  "absoluteRenderBounds": {
					"x": 10265,
					"y": 2648,
					"width": 548,
					"height": 713
				  },
				  "rotation": 0,
				  "layoutAlign": "STRETCH",
				  "constrainProportions": false,
				  "layoutGrow": 1,
				  "children": [
					{
					  "makersHtmlTag": "nav",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/fa73f9778ca6a035b67d5e72960492231a74bf88.png",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3752",
					  "parent": {
						"id": "443:3751"
					  },
					  "name": "Image",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  10289
						],
						[
						  0,
						  1,
						  2668
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 500,
					  "height": 665,
					  "absoluteRenderBounds": {
						"x": 10289,
						"y": 2668,
						"width": 500,
						"height": 665
					  },
					  "rotation": 0,
					  "layoutAlign": "STRETCH",
					  "constrainProportions": false,
					  "layoutGrow": 1,
					  "children": [],
					  "exportSettings": [],
					  "fills": [
						{
						  "type": "IMAGE",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "scaleMode": "FILL",
						  "imageTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "scalingFactor": 0.5,
						  "rotation": 0,
						  "filters": {
							"exposure": 0,
							"contrast": 0,
							"saturation": 0,
							"temperature": 0,
							"tint": 0,
							"highlights": 0,
							"shadows": 0
						  },
						  "imageHash": "${image1}"
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 14,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 14,
					  "topRightRadius": 14,
					  "bottomLeftRadius": 14,
					  "bottomRightRadius": 14,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "CENTER",
					  "counterAxisAlignItems": "CENTER",
					  "primaryAxisSizingMode": "FIXED",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 0,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 14,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 14,
				  "topRightRadius": 14,
				  "bottomLeftRadius": 14,
				  "bottomRightRadius": 14,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": true,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "FIXED",
				  "itemSpacing": 0,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 48,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "FIXED",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": true,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 24,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "ec99024f9a913da87825a3eb8ea51e37f495075a",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Split"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "makers-features, makers-features--two-columns",
		  "makersComponent": "features",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "443:3342",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Layout=Side Text",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  3381
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  9469
			],
			[
			  0,
			  1,
			  3401
			]
		  ],
		  "x": 20,
		  "y": 3381,
		  "width": 1440,
		  "height": 498,
		  "absoluteRenderBounds": {
			"x": 9469,
			"y": 3401,
			"width": 1440,
			"height": 498
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "443:3347",
			  "parent": {
				"id": "443:3342"
			  },
			  "name": "Content",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  20
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  9489
				],
				[
				  0,
				  1,
				  3401
				]
			  ],
			  "x": 20,
			  "y": 0,
			  "width": 1400,
			  "height": 498,
			  "absoluteRenderBounds": {
				"x": 9489,
				"y": 3401,
				"width": 1400,
				"height": 498
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "columns",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "443:3352",
				  "parent": {
					"id": "443:3347"
				  },
				  "name": "Features Grid",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  150
					],
					[
					  0,
					  1,
					  64
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  9639
					],
					[
					  0,
					  1,
					  3465
					]
				  ],
				  "x": 150,
				  "y": 64,
				  "width": 1100,
				  "height": 370,
				  "absoluteRenderBounds": {
					"x": 9639,
					"y": 3465,
					"width": 1100,
					"height": 370
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3366",
					  "parent": {
						"id": "443:3352"
					  },
					  "name": "Media Objects",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  0
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  9639
						],
						[
						  0,
						  1,
						  3465
						]
					  ],
					  "x": 0,
					  "y": 0,
					  "width": 340,
					  "height": 78,
					  "absoluteRenderBounds": {
						"x": 9639,
						"y": 3465,
						"width": 340,
						"height": 78
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3380",
						  "listSpacing": 0,
						  "parent": {
							"id": "443:3366"
						  },
						  "name": "From Figma to Site with one click.",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "fills": [
							{
							  "type": "SOLID",
							  "visible": true,
							  "opacity": 1,
							  "blendMode": "NORMAL",
							  "color": {
								"r": 0,
								"g": 0,
								"b": 0
							  }
							}
						  ],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "OUTSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  9639
							],
							[
							  0,
							  1,
							  3465
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 78,
						  "absoluteRenderBounds": {
							"x": 9639.625,
							"y": 3471.397705078125,
							"width": 288.2724609375,
							"height": 63.98876953125
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "exportSettings": [],
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "characters": "From Figma to Site with one click.",
						  "hasMissingFont": false,
						  "fontSize": 32,
						  "paragraphIndent": 0,
						  "paragraphSpacing": 0,
						  "textCase": "ORIGINAL",
						  "textDecoration": "NONE",
						  "letterSpacing": {
							"unit": "PERCENT",
							"value": 0
						  },
						  "lineHeight": {
							"unit": "AUTO"
						  },
						  "fontName": {
							"family": "Inter",
							"style": "Bold"
						  },
						  "autoRename": true,
						  "textAlignHorizontal": "LEFT",
						  "textAlignVertical": "TOP",
						  "textAutoResize": "HEIGHT",
						  "textStyleId": "",
						  "reactions": [],
						  "type": "TEXT",
						  "children": []
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3381",
					  "parent": {
						"id": "443:3352"
					  },
					  "name": "Media Objects",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  380
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  10019
						],
						[
						  0,
						  1,
						  3465
						]
					  ],
					  "x": 380,
					  "y": 0,
					  "width": 340,
					  "height": 370,
					  "absoluteRenderBounds": {
						"x": 10019,
						"y": 3465,
						"width": 340,
						"height": 370
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3382",
						  "parent": {
							"id": "443:3381"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10019
							],
							[
							  0,
							  1,
							  3465
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 169,
						  "absoluteRenderBounds": {
							"x": 10019,
							"y": 3465,
							"width": 340,
							"height": 169
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3382;443:3282",
							  "parent": {
								"id": "443:3382"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  3465
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 10019,
								"y": 3465,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3439;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3382;443:3288",
								  "parent": {
									"id": "I443:3382;443:3282"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10027
									],
									[
									  0,
									  1,
									  3473
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10027,
									"y": 3473,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3382;443:3288;423:20771",
									  "parent": {
										"id": "I443:3382;443:3288"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10027
										],
										[
										  0,
										  1,
										  3473
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 10027,
										"y": 3473,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3382;443:3288;423:20781",
										  "parent": {
											"id": "I443:3382;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10032
											],
											[
											  0,
											  1,
											  3475
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10031,
											"y": 3474,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3382;443:3288;423:20786",
										  "parent": {
											"id": "I443:3382;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10039
											],
											[
											  0,
											  1,
											  3475
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10038,
											"y": 3474,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3382;443:3288;423:20791",
										  "parent": {
											"id": "I443:3382;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10039
											],
											[
											  0,
											  1,
											  3482
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10038,
											"y": 3481,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3382;443:3288;423:20793",
										  "parent": {
											"id": "I443:3382;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10032
											],
											[
											  0,
											  1,
											  3489
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10031,
											"y": 3488,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3382;443:3288;423:20795",
										  "parent": {
											"id": "I443:3382;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10032
											],
											[
											  0,
											  1,
											  3482
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10031,
											"y": 3481,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3382;443:3322",
							  "parent": {
								"id": "443:3382"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  3521
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 113,
							  "absoluteRenderBounds": {
								"x": 10019,
								"y": 3521,
								"width": 340,
								"height": 113
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3382;443:3326",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3382;443:3322"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10019
									],
									[
									  0,
									  1,
									  3521
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 340,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10020.7216796875,
									"y": 3523.70166015625,
									"width": 267.39453125,
									"height": 23.20751953125
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "It’s the best experience",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3382;443:3330",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3382;443:3322"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10019
									],
									[
									  0,
									  1,
									  3553
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 340,
								  "height": 81,
								  "absoluteRenderBounds": {
									"x": 10019.5625,
									"y": 3559.5,
									"width": 292.18359375,
									"height": 71.181884765625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3278"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Vertical"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "1"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3503",
						  "parent": {
							"id": "443:3381"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  201
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10019
							],
							[
							  0,
							  1,
							  3666
							]
						  ],
						  "x": 0,
						  "y": 201,
						  "width": 340,
						  "height": 169,
						  "absoluteRenderBounds": {
							"x": 10019,
							"y": 3666,
							"width": 340,
							"height": 169
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3503;443:3282",
							  "parent": {
								"id": "443:3503"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  3666
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 10019,
								"y": 3666,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3506;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3503;443:3288",
								  "parent": {
									"id": "I443:3503;443:3282"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10027
									],
									[
									  0,
									  1,
									  3674
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10027,
									"y": 3674,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3503;443:3288;423:20771",
									  "parent": {
										"id": "I443:3503;443:3288"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10027
										],
										[
										  0,
										  1,
										  3674
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 10027,
										"y": 3674,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3503;443:3288;423:20781",
										  "parent": {
											"id": "I443:3503;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10032
											],
											[
											  0,
											  1,
											  3676
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10031,
											"y": 3675,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3503;443:3288;423:20786",
										  "parent": {
											"id": "I443:3503;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10039
											],
											[
											  0,
											  1,
											  3676
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10038,
											"y": 3675,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3503;443:3288;423:20791",
										  "parent": {
											"id": "I443:3503;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10039
											],
											[
											  0,
											  1,
											  3683
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10038,
											"y": 3682,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3503;443:3288;423:20793",
										  "parent": {
											"id": "I443:3503;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10032
											],
											[
											  0,
											  1,
											  3690
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10031,
											"y": 3689,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3503;443:3288;423:20795",
										  "parent": {
											"id": "I443:3503;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10032
											],
											[
											  0,
											  1,
											  3683
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10031,
											"y": 3682,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3503;443:3322",
							  "parent": {
								"id": "443:3503"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10019
								],
								[
								  0,
								  1,
								  3722
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 113,
							  "absoluteRenderBounds": {
								"x": 10019,
								"y": 3722,
								"width": 340,
								"height": 113
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3503;443:3326",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3503;443:3322"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10019
									],
									[
									  0,
									  1,
									  3722
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 340,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10020.7216796875,
									"y": 3724.70166015625,
									"width": 267.39453125,
									"height": 23.20751953125
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "It’s the best experience",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3503;443:3330",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3503;443:3322"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10019
									],
									[
									  0,
									  1,
									  3754
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 340,
								  "height": 81,
								  "absoluteRenderBounds": {
									"x": 10019.5625,
									"y": 3760.5,
									"width": 292.18359375,
									"height": 71.181884765625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3278"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Vertical"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "1"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					},
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "443:3606",
					  "parent": {
						"id": "443:3352"
					  },
					  "name": "Media Objects",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "relativeTransform": [
						[
						  1,
						  0,
						  760
						],
						[
						  0,
						  1,
						  0
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  10399
						],
						[
						  0,
						  1,
						  3465
						]
					  ],
					  "x": 760,
					  "y": 0,
					  "width": 340,
					  "height": 370,
					  "absoluteRenderBounds": {
						"x": 10399,
						"y": 3465,
						"width": 340,
						"height": 370
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "children": [
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3607",
						  "parent": {
							"id": "443:3606"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  0
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10399
							],
							[
							  0,
							  1,
							  3465
							]
						  ],
						  "x": 0,
						  "y": 0,
						  "width": 340,
						  "height": 169,
						  "absoluteRenderBounds": {
							"x": 10399,
							"y": 3465,
							"width": 340,
							"height": 169
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3607;443:3282",
							  "parent": {
								"id": "443:3607"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  3465
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 10399,
								"y": 3465,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3439;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3607;443:3288",
								  "parent": {
									"id": "I443:3607;443:3282"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10407
									],
									[
									  0,
									  1,
									  3473
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10407,
									"y": 3473,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3607;443:3288;423:20771",
									  "parent": {
										"id": "I443:3607;443:3288"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10407
										],
										[
										  0,
										  1,
										  3473
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 10407,
										"y": 3473,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3607;443:3288;423:20781",
										  "parent": {
											"id": "I443:3607;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10412
											],
											[
											  0,
											  1,
											  3475
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10411,
											"y": 3474,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3607;443:3288;423:20786",
										  "parent": {
											"id": "I443:3607;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10419
											],
											[
											  0,
											  1,
											  3475
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10418,
											"y": 3474,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3607;443:3288;423:20791",
										  "parent": {
											"id": "I443:3607;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10419
											],
											[
											  0,
											  1,
											  3482
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10418,
											"y": 3481,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3607;443:3288;423:20793",
										  "parent": {
											"id": "I443:3607;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10412
											],
											[
											  0,
											  1,
											  3489
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10411,
											"y": 3488,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3607;443:3288;423:20795",
										  "parent": {
											"id": "I443:3607;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10412
											],
											[
											  0,
											  1,
											  3482
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10411,
											"y": 3481,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3607;443:3322",
							  "parent": {
								"id": "443:3607"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  3521
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 113,
							  "absoluteRenderBounds": {
								"x": 10399,
								"y": 3521,
								"width": 340,
								"height": 113
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3607;443:3326",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3607;443:3322"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10399
									],
									[
									  0,
									  1,
									  3521
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 340,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10400.7216796875,
									"y": 3523.70166015625,
									"width": 267.39453125,
									"height": 23.20751953125
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "It’s the best experience",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3607;443:3330",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3607;443:3322"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10399
									],
									[
									  0,
									  1,
									  3553
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 340,
								  "height": 81,
								  "absoluteRenderBounds": {
									"x": 10399.5625,
									"y": 3559.5,
									"width": 292.18359375,
									"height": 71.181884765625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3278"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Vertical"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "1"
						},
						{
						  "makersHtmlTag": "",
						  "makersClasses": "makers-media-object",
						  "makersComponent": "",
						  "makersBackgroundImage": "",
						  "makersExportAsSvg": "",
						  "makersPageUrl": "",
						  "makersPageTitle": "",
						  "makersPageImage": "",
						  "makersPageDescription": "",
						  "id": "443:3701",
						  "parent": {
							"id": "443:3606"
						  },
						  "name": "MAKERS_MEDIA_OBJECT",
						  "removed": false,
						  "widgetEvents": [],
						  "visible": true,
						  "locked": false,
						  "opacity": 1,
						  "blendMode": "PASS_THROUGH",
						  "isMask": false,
						  "effects": [],
						  "effectStyleId": "",
						  "relativeTransform": [
							[
							  1,
							  0,
							  0
							],
							[
							  0,
							  1,
							  201
							]
						  ],
						  "absoluteTransform": [
							[
							  1,
							  0,
							  10399
							],
							[
							  0,
							  1,
							  3666
							]
						  ],
						  "x": 0,
						  "y": 201,
						  "width": 340,
						  "height": 169,
						  "absoluteRenderBounds": {
							"x": 10399,
							"y": 3666,
							"width": 340,
							"height": 169
						  },
						  "rotation": 0,
						  "layoutAlign": "STRETCH",
						  "constrainProportions": false,
						  "layoutGrow": 0,
						  "children": [
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3701;443:3282",
							  "parent": {
								"id": "443:3701"
							  },
							  "name": "Media",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  0
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  3666
								]
							  ],
							  "x": 0,
							  "y": 0,
							  "width": 40,
							  "height": 40,
							  "absoluteRenderBounds": {
								"x": 10399,
								"y": 3666,
								"width": 40,
								"height": 40
							  },
							  "rotation": 0,
							  "layoutAlign": "INHERIT",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/LfQFIhQ89qYJWPOtt6Onw/images/LfQFIhQ89qYJWPOtt6Onw-I431-3506;431-3216.svg",
								  "makersExportAsSvg": "true",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3701;443:3288",
								  "parent": {
									"id": "I443:3701;443:3282"
								  },
								  "name": "MAKERS_SOCIAL_MEDIA_ICONS",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "relativeTransform": [
									[
									  1,
									  0,
									  8
									],
									[
									  0,
									  1,
									  8
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10407
									],
									[
									  0,
									  1,
									  3674
									]
								  ],
								  "x": 8,
								  "y": 8,
								  "width": 24,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10407,
									"y": 3674,
									"width": 24,
									"height": 24
								  },
								  "rotation": 0,
								  "layoutAlign": "INHERIT",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "children": [
									{
									  "makersHtmlTag": "",
									  "makersClasses": "",
									  "makersComponent": "",
									  "makersBackgroundImage": "",
									  "makersExportAsSvg": "",
									  "makersPageUrl": "",
									  "makersPageTitle": "",
									  "makersPageImage": "",
									  "makersPageDescription": "",
									  "id": "I443:3701;443:3288;423:20771",
									  "parent": {
										"id": "I443:3701;443:3288"
									  },
									  "name": "vectors",
									  "removed": false,
									  "widgetEvents": [],
									  "visible": true,
									  "locked": false,
									  "opacity": 1,
									  "blendMode": "PASS_THROUGH",
									  "isMask": false,
									  "effects": [],
									  "effectStyleId": "",
									  "relativeTransform": [
										[
										  1,
										  0,
										  0
										],
										[
										  0,
										  1,
										  0
										]
									  ],
									  "absoluteTransform": [
										[
										  1,
										  0,
										  10407
										],
										[
										  0,
										  1,
										  3674
										]
									  ],
									  "x": 0,
									  "y": 0,
									  "width": 24,
									  "height": 24,
									  "absoluteRenderBounds": {
										"x": 10407,
										"y": 3674,
										"width": 24,
										"height": 24
									  },
									  "rotation": 0,
									  "layoutAlign": "INHERIT",
									  "constrainProportions": false,
									  "layoutGrow": 0,
									  "children": [
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3701;443:3288;423:20781",
										  "parent": {
											"id": "I443:3701;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10412
											],
											[
											  0,
											  1,
											  3676
											]
										  ],
										  "x": 5,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10411,
											"y": 3675,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 4.440892098500626e-16
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815035343170166 1.0251264572143555 1.0251262187957764 C 1.6815037727355957 0.36874890327453613 2.571742296218872 4.440892098500626e-16 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3701;443:3288;423:20786",
										  "parent": {
											"id": "I443:3701;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  2
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10419
											],
											[
											  0,
											  1,
											  3676
											]
										  ],
										  "x": 12,
										  "y": 2,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10418,
											"y": 3675,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": -6.8489693916262695e-9
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914738893509,
												  "y": -0.4246395528316498
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.1758914738893509,
												  "y": 0.4246395528316498
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914589881897
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914589881897
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 6.8489693916262695e-9
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 10,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 3.886141898679611e-16,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664215564727783,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251262187957764,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606078147888184,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839392185211182,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.886141898679611e-16 L 3.5 3.886141898679611e-16 C 3.95962655544281 -6.84896900301208e-9 4.414752155542374 0.09053009748458862 4.839391708374023 0.2664215564727783 C 5.264031261205673 0.442313015460968 5.649869441986084 0.7001211643218994 5.974874496459961 1.0251262187957764 C 6.299879550933838 1.3501312732696533 6.5576862543821335 1.7359682619571686 6.733577728271484 2.1606078147888184 C 6.909469202160835 2.585247367620468 7 3.04037344455719 7 3.5 C 7 3.95962655544281 6.909469202160835 4.414752632379532 6.733577728271484 4.839392185211182 C 6.5576862543821335 5.264031738042831 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.557687222957611 4.839391708374023 6.733578681945801 C 4.414752155542374 6.9094701409339905 3.95962655544281 7.000000006848969 3.5 7 L 0 7 L 0 3.886141898679611e-16 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3701;443:3288;423:20791",
										  "parent": {
											"id": "I443:3701;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  12
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10419
											],
											[
											  0,
											  1,
											  3683
											]
										  ],
										  "x": 12,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10418,
											"y": 3682,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": -9.685905411060958e-9,
												  "y": -0.45962655544281006
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 7,
												"end": 8,
												"tangentStart": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 9.685901858347279e-9,
												  "y": -0.45962655544281006
												}
											  },
											  {
												"start": 8,
												"end": 9,
												"tangentStart": {
												  "x": 9.685905411060958e-9,
												  "y": 0.45962655544281006
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": -0.42463958263397217
												}
											  },
											  {
												"start": 9,
												"end": 10,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": 0.42463958263397217
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": -0.32500505447387695
												}
											  },
											  {
												"start": 10,
												"end": 11,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": 0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": -0.1758914738893509
												}
											  },
											  {
												"start": 11,
												"end": 12,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": 0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.45962655544281006,
												  "y": 0
												}
											  },
											  {
												"start": 12,
												"end": 13,
												"tangentStart": {
												  "x": -0.45962655544281006,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.4246395528316498,
												  "y": 0.1758914738893509
												}
											  },
											  {
												"start": 13,
												"end": 14,
												"tangentStart": {
												  "x": -0.4246395528316498,
												  "y": -0.1758914738893509
												},
												"tangentEnd": {
												  "x": 0.32500505447387695,
												  "y": 0.32500505447387695
												}
											  },
											  {
												"start": 14,
												"end": 15,
												"tangentStart": {
												  "x": -0.32500505447387695,
												  "y": -0.32500505447387695
												},
												"tangentEnd": {
												  "x": 0.1758914589881897,
												  "y": 0.42463958263397217
												}
											  },
											  {
												"start": 15,
												"end": 0,
												"tangentStart": {
												  "x": -0.1758914589881897,
												  "y": -0.42463958263397217
												},
												"tangentEnd": {
												  "x": -9.685907187417797e-9,
												  "y": 0.45962655544281006
												}
											  },
											  {
												"start": 0,
												"end": 0,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  }
											],
											"vertices": [
											  {
												"x": 7.77230656136968e-16,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 0.2664213180541992,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 2.1606082916259766,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 6.733577728271484,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.974874496459961,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 4.839391708374023,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 2.1606082916259766,
												"y": 6.733578681945801,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 0.2664213180541992,
												"y": 4.839391708374023,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 7.77230656136968e-16 3.5 C -9.685904633830302e-9 3.04037344455719 0.09052985906600952 2.5852478742599487 0.2664213180541992 2.1606082916259766 C 0.4423127770423889 1.7359687089920044 0.7001214027404785 1.3501315116882324 1.0251264572143555 1.0251264572143555 C 1.3501315116882324 0.7001214027404785 1.7359687387943268 0.4423127919435501 2.1606082916259766 0.2664213180541992 C 2.5852478444576263 0.09052984416484833 3.04037344455719 0 3.5 0 C 3.95962655544281 0 4.414752155542374 0.09052984416484833 4.839391708374023 0.2664213180541992 C 5.264031261205673 0.4423127919435501 5.649869441986084 0.7001214027404785 5.974874496459961 1.0251264572143555 C 6.299879550933838 1.3501315116882324 6.557686269283295 1.7359687089920044 6.733577728271484 2.1606082916259766 C 6.909469187259674 2.5852478742599487 7.000000009685902 3.04037344455719 7 3.5 C 7.000000009685905 3.95962655544281 6.909469187259674 4.414752125740051 6.733577728271484 4.839391708374023 C 6.557686269283295 5.264031291007996 6.299879550933838 5.649868488311768 5.974874496459961 5.9748735427856445 C 5.649869441986084 6.2998785972595215 5.264031261205673 6.55768720805645 4.839391708374023 6.733578681945801 C 4.414752155542374 6.909470155835152 3.95962655544281 7 3.5 7 C 3.04037344455719 7 2.5852478444576263 6.909470155835152 2.1606082916259766 6.733578681945801 C 1.7359687387943268 6.55768720805645 1.3501315116882324 6.2998785972595215 1.0251264572143555 5.9748735427856445 C 0.7001214027404785 5.649868488311768 0.4423127770423889 5.264031291007996 0.2664213180541992 4.839391708374023 C 0.09052985906600952 4.414752125740051 -9.685906410187141e-9 3.95962655544281 7.77230656136968e-16 3.5 L 7.77230656136968e-16 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3701;443:3288;423:20793",
										  "parent": {
											"id": "I443:3701;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  16
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10412
											],
											[
											  0,
											  1,
											  3690
											]
										  ],
										  "x": 5,
										  "y": 16,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10411,
											"y": 3689,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6,
													7
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0.9282577037811279
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0.9282577037811279,
												  "y": 0
												}
											  },
											  {
												"start": 6,
												"end": 7,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 7,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.025125503540039,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 5.9748735427856445,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.974874496459961,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815028190612793 1.0251264572143555 1.025125503540039 C 1.6815037727355957 0.36874818801879883 2.571742296218872 0 3.5 0 L 7 0 L 7 3.5 C 7 4.428257703781128 6.631250858306885 5.318497180938721 5.9748735427856445 5.974874496459961 C 5.318496227264404 6.631251811981201 4.428257703781128 7 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631251811981201 1.0251264572143555 5.974874496459961 C 0.36874914169311523 5.318497180938721 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										},
										{
										  "makersHtmlTag": "",
										  "makersClasses": "",
										  "makersComponent": "",
										  "makersBackgroundImage": "",
										  "makersExportAsSvg": "",
										  "makersPageUrl": "",
										  "makersPageTitle": "",
										  "makersPageImage": "",
										  "makersPageDescription": "",
										  "id": "I443:3701;443:3288;423:20795",
										  "parent": {
											"id": "I443:3701;443:3288;423:20771"
										  },
										  "name": "Vector",
										  "removed": false,
										  "widgetEvents": [],
										  "visible": true,
										  "locked": false,
										  "opacity": 1,
										  "blendMode": "PASS_THROUGH",
										  "isMask": false,
										  "effects": [],
										  "effectStyleId": "",
										  "fills": [],
										  "fillStyleId": "",
										  "strokes": [
											{
											  "type": "SOLID",
											  "visible": true,
											  "opacity": 1,
											  "blendMode": "NORMAL",
											  "color": {
												"r": 1,
												"g": 1,
												"b": 1
											  }
											}
										  ],
										  "strokeStyleId": "",
										  "strokeWeight": 2,
										  "strokeAlign": "CENTER",
										  "strokeJoin": "ROUND",
										  "dashPattern": [],
										  "strokeCap": "ROUND",
										  "strokeMiterLimit": 4,
										  "relativeTransform": [
											[
											  1,
											  0,
											  5
											],
											[
											  0,
											  1,
											  9
											]
										  ],
										  "absoluteTransform": [
											[
											  1,
											  0,
											  10412
											],
											[
											  0,
											  1,
											  3683
											]
										  ],
										  "x": 5,
										  "y": 9,
										  "width": 7,
										  "height": 7,
										  "absoluteRenderBounds": {
											"x": 10411,
											"y": 3682,
											"width": 9,
											"height": 9
										  },
										  "rotation": 0,
										  "layoutAlign": "INHERIT",
										  "constrainProportions": false,
										  "layoutGrow": 0,
										  "exportSettings": [],
										  "constraints": {
											"horizontal": "SCALE",
											"vertical": "SCALE"
										  },
										  "cornerRadius": 0,
										  "cornerSmoothing": 0,
										  "vectorNetwork": {
											"regions": [
											  {
												"windingRule": "NONZERO",
												"loops": [
												  [
													0,
													1,
													2,
													3,
													4,
													5,
													6
												  ]
												]
											  }
											],
											"segments": [
											  {
												"start": 0,
												"end": 1,
												"tangentStart": {
												  "x": 0,
												  "y": -0.9282577037811279
												},
												"tangentEnd": {
												  "x": -0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 1,
												"end": 2,
												"tangentStart": {
												  "x": 0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": -0.9282577037811279,
												  "y": 1.7763568394002505e-15
												}
											  },
											  {
												"start": 2,
												"end": 3,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 3,
												"end": 4,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 4,
												"end": 5,
												"tangentStart": {
												  "x": 0,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0
												}
											  },
											  {
												"start": 5,
												"end": 6,
												"tangentStart": {
												  "x": -0.9282577037811279,
												  "y": 0
												},
												"tangentEnd": {
												  "x": 0.6563773155212402,
												  "y": 0.6563773155212402
												}
											  },
											  {
												"start": 6,
												"end": 0,
												"tangentStart": {
												  "x": -0.6563773155212402,
												  "y": -0.6563773155212402
												},
												"tangentEnd": {
												  "x": 0,
												  "y": 0.9282577037811279
												}
											  }
											],
											"vertices": [
											  {
												"x": 0,
												"y": 3.5,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 1.0251264572143555,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 0,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 7,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 3.5,
												"y": 7,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  },
											  {
												"x": 1.0251264572143555,
												"y": 5.9748735427856445,
												"strokeCap": "ROUND",
												"strokeJoin": "ROUND",
												"cornerRadius": 0,
												"handleMirroring": "NONE"
											  }
											]
										  },
										  "vectorPaths": [
											{
											  "windingRule": "NONZERO",
											  "data": "M 0 3.5 C 0 2.571742296218872 0.36874914169311523 1.6815037727355957 1.0251264572143555 1.0251264572143555 C 1.6815037727355957 0.36874914169311523 2.571742296218872 1.7763568394002505e-15 3.5 0 L 7 0 L 7 7 L 3.5 7 C 2.571742296218872 7 1.6815037727355957 6.631250858306885 1.0251264572143555 5.9748735427856445 C 0.36874914169311523 5.318496227264404 0 4.428257703781128 0 3.5 Z"
											}
										  ],
										  "handleMirroring": "NONE",
										  "reactions": [],
										  "type": "VECTOR",
										  "children": []
										}
									  ],
									  "exportSettings": [],
									  "fills": [],
									  "fillStyleId": "",
									  "strokes": [],
									  "strokeStyleId": "",
									  "strokeWeight": 1,
									  "strokeAlign": "INSIDE",
									  "strokeJoin": "MITER",
									  "dashPattern": [],
									  "strokeCap": "NONE",
									  "strokeMiterLimit": 4,
									  "cornerRadius": 0,
									  "cornerSmoothing": 0,
									  "topLeftRadius": 0,
									  "topRightRadius": 0,
									  "bottomLeftRadius": 0,
									  "bottomRightRadius": 0,
									  "paddingLeft": 0,
									  "paddingRight": 0,
									  "paddingTop": 0,
									  "paddingBottom": 0,
									  "primaryAxisAlignItems": "MIN",
									  "counterAxisAlignItems": "MIN",
									  "primaryAxisSizingMode": "AUTO",
									  "layoutGrids": [],
									  "gridStyleId": "",
									  "backgroundStyleId": "",
									  "clipsContent": true,
									  "guides": [],
									  "expanded": false,
									  "constraints": {
										"horizontal": "MIN",
										"vertical": "MIN"
									  },
									  "layoutMode": "NONE",
									  "counterAxisSizingMode": "FIXED",
									  "itemSpacing": 0,
									  "overflowDirection": "NONE",
									  "numberOfFixedChildren": 0,
									  "overlayPositionType": "CENTER",
									  "overlayBackground": {
										"type": "NONE"
									  },
									  "overlayBackgroundInteraction": "NONE",
									  "reactions": [],
									  "type": "FRAME"
									}
								  ],
								  "exportSettings": [],
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": false,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 1,
										"g": 1,
										"b": 1
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "INSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "cornerRadius": 0,
								  "cornerSmoothing": 0,
								  "topLeftRadius": 0,
								  "topRightRadius": 0,
								  "bottomLeftRadius": 0,
								  "bottomRightRadius": 0,
								  "paddingLeft": 0,
								  "paddingRight": 0,
								  "paddingTop": 0,
								  "paddingBottom": 0,
								  "primaryAxisAlignItems": "MIN",
								  "counterAxisAlignItems": "MIN",
								  "primaryAxisSizingMode": "AUTO",
								  "layoutGrids": [],
								  "gridStyleId": "",
								  "backgroundStyleId": "",
								  "clipsContent": true,
								  "guides": [],
								  "expanded": false,
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "layoutMode": "NONE",
								  "counterAxisSizingMode": "FIXED",
								  "itemSpacing": 0,
								  "overflowDirection": "NONE",
								  "numberOfFixedChildren": 0,
								  "overlayPositionType": "CENTER",
								  "overlayBackground": {
									"type": "NONE"
								  },
								  "overlayBackgroundInteraction": "NONE",
								  "mainComponent": {
									"id": "423:20761"
								  },
								  "scaleFactor": 1,
								  "reactions": [],
								  "variantProperties": {
									"Logo": "figma"
								  },
								  "type": "INSTANCE",
								  "makersVariantProps": "8"
								}
							  ],
							  "exportSettings": [],
							  "fills": [
								{
								  "type": "SOLID",
								  "visible": true,
								  "opacity": 1,
								  "blendMode": "NORMAL",
								  "color": {
									"r": 0.16470588743686676,
									"g": 0.7411764860153198,
									"b": 0.6392157077789307
								  }
								}
							  ],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 6,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 6,
							  "topRightRadius": 6,
							  "bottomLeftRadius": 6,
							  "bottomRightRadius": 6,
							  "paddingLeft": 8,
							  "paddingRight": 8,
							  "paddingTop": 8,
							  "paddingBottom": 8,
							  "primaryAxisAlignItems": "CENTER",
							  "counterAxisAlignItems": "CENTER",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "HORIZONTAL",
							  "counterAxisSizingMode": "AUTO",
							  "itemSpacing": 10,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							},
							{
							  "makersHtmlTag": "",
							  "makersClasses": "",
							  "makersComponent": "",
							  "makersBackgroundImage": "",
							  "makersExportAsSvg": "",
							  "makersPageUrl": "",
							  "makersPageTitle": "",
							  "makersPageImage": "",
							  "makersPageDescription": "",
							  "id": "I443:3701;443:3322",
							  "parent": {
								"id": "443:3701"
							  },
							  "name": "Text",
							  "removed": false,
							  "widgetEvents": [],
							  "visible": true,
							  "locked": false,
							  "opacity": 1,
							  "blendMode": "PASS_THROUGH",
							  "isMask": false,
							  "effects": [],
							  "effectStyleId": "",
							  "relativeTransform": [
								[
								  1,
								  0,
								  0
								],
								[
								  0,
								  1,
								  56
								]
							  ],
							  "absoluteTransform": [
								[
								  1,
								  0,
								  10399
								],
								[
								  0,
								  1,
								  3722
								]
							  ],
							  "x": 0,
							  "y": 56,
							  "width": 340,
							  "height": 113,
							  "absoluteRenderBounds": {
								"x": 10399,
								"y": 3722,
								"width": 340,
								"height": 113
							  },
							  "rotation": 0,
							  "layoutAlign": "STRETCH",
							  "constrainProportions": false,
							  "layoutGrow": 0,
							  "children": [
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3701;443:3326",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3701;443:3322"
								  },
								  "name": "Publish with Figma",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0,
										"g": 0,
										"b": 0
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  0
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10399
									],
									[
									  0,
									  1,
									  3722
									]
								  ],
								  "x": 0,
								  "y": 0,
								  "width": 340,
								  "height": 24,
								  "absoluteRenderBounds": {
									"x": 10400.7216796875,
									"y": 3724.70166015625,
									"width": 267.39453125,
									"height": 23.20751953125
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "It’s the best experience",
								  "hasMissingFont": false,
								  "fontSize": 24,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PIXELS",
									"value": 24
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Semi Bold"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								},
								{
								  "makersHtmlTag": "",
								  "makersClasses": "",
								  "makersComponent": "",
								  "makersBackgroundImage": "",
								  "makersExportAsSvg": "",
								  "makersPageUrl": "",
								  "makersPageTitle": "",
								  "makersPageImage": "",
								  "makersPageDescription": "",
								  "id": "I443:3701;443:3330",
								  "listSpacing": 0,
								  "parent": {
									"id": "I443:3701;443:3322"
								  },
								  "name": "Lorem ipsum dolor sit amet, aeque tation vis ei.",
								  "removed": false,
								  "widgetEvents": [],
								  "visible": true,
								  "locked": false,
								  "opacity": 1,
								  "blendMode": "PASS_THROUGH",
								  "isMask": false,
								  "effects": [],
								  "effectStyleId": "",
								  "fills": [
									{
									  "type": "SOLID",
									  "visible": true,
									  "opacity": 1,
									  "blendMode": "NORMAL",
									  "color": {
										"r": 0.03947368264198303,
										"g": 0.16421058773994446,
										"b": 0.2605263292789459
									  }
									}
								  ],
								  "fillStyleId": "",
								  "strokes": [],
								  "strokeStyleId": "",
								  "strokeWeight": 1,
								  "strokeAlign": "OUTSIDE",
								  "strokeJoin": "MITER",
								  "dashPattern": [],
								  "strokeCap": "NONE",
								  "strokeMiterLimit": 4,
								  "relativeTransform": [
									[
									  1,
									  0,
									  0
									],
									[
									  0,
									  1,
									  32
									]
								  ],
								  "absoluteTransform": [
									[
									  1,
									  0,
									  10399
									],
									[
									  0,
									  1,
									  3754
									]
								  ],
								  "x": 0,
								  "y": 32,
								  "width": 340,
								  "height": 81,
								  "absoluteRenderBounds": {
									"x": 10399.5625,
									"y": 3760.5,
									"width": 292.18359375,
									"height": 71.181884765625
								  },
								  "rotation": 0,
								  "layoutAlign": "STRETCH",
								  "constrainProportions": false,
								  "layoutGrow": 0,
								  "exportSettings": [],
								  "constraints": {
									"horizontal": "MIN",
									"vertical": "MIN"
								  },
								  "characters": "Lorem ipsum dolor sit amet, aeque tation vis ei. Ne his simul sensibus percipitur.",
								  "hasMissingFont": false,
								  "fontSize": 18,
								  "paragraphIndent": 0,
								  "paragraphSpacing": 0,
								  "textCase": "ORIGINAL",
								  "textDecoration": "NONE",
								  "letterSpacing": {
									"unit": "PERCENT",
									"value": 0
								  },
								  "lineHeight": {
									"unit": "PERCENT",
									"value": 150
								  },
								  "fontName": {
									"family": "Inter",
									"style": "Regular"
								  },
								  "autoRename": false,
								  "textAlignHorizontal": "LEFT",
								  "textAlignVertical": "TOP",
								  "textAutoResize": "HEIGHT",
								  "textStyleId": "",
								  "reactions": [],
								  "type": "TEXT",
								  "children": []
								}
							  ],
							  "exportSettings": [],
							  "fills": [],
							  "fillStyleId": "",
							  "strokes": [],
							  "strokeStyleId": "",
							  "strokeWeight": 1,
							  "strokeAlign": "INSIDE",
							  "strokeJoin": "MITER",
							  "dashPattern": [],
							  "strokeCap": "NONE",
							  "strokeMiterLimit": 4,
							  "cornerRadius": 0,
							  "cornerSmoothing": 0,
							  "topLeftRadius": 0,
							  "topRightRadius": 0,
							  "bottomLeftRadius": 0,
							  "bottomRightRadius": 0,
							  "paddingLeft": 0,
							  "paddingRight": 0,
							  "paddingTop": 0,
							  "paddingBottom": 0,
							  "primaryAxisAlignItems": "MIN",
							  "counterAxisAlignItems": "MIN",
							  "primaryAxisSizingMode": "AUTO",
							  "layoutGrids": [],
							  "gridStyleId": "",
							  "backgroundStyleId": "",
							  "clipsContent": false,
							  "guides": [],
							  "expanded": false,
							  "constraints": {
								"horizontal": "MIN",
								"vertical": "MIN"
							  },
							  "layoutMode": "VERTICAL",
							  "counterAxisSizingMode": "FIXED",
							  "itemSpacing": 8,
							  "overflowDirection": "NONE",
							  "numberOfFixedChildren": 0,
							  "overlayPositionType": "CENTER",
							  "overlayBackground": {
								"type": "NONE"
							  },
							  "overlayBackgroundInteraction": "NONE",
							  "reactions": [],
							  "type": "FRAME"
							}
						  ],
						  "exportSettings": [],
						  "fills": [],
						  "fillStyleId": "",
						  "strokes": [],
						  "strokeStyleId": "",
						  "strokeWeight": 1,
						  "strokeAlign": "INSIDE",
						  "strokeJoin": "MITER",
						  "dashPattern": [],
						  "strokeCap": "NONE",
						  "strokeMiterLimit": 4,
						  "cornerRadius": 0,
						  "cornerSmoothing": 0,
						  "topLeftRadius": 0,
						  "topRightRadius": 0,
						  "bottomLeftRadius": 0,
						  "bottomRightRadius": 0,
						  "paddingLeft": 0,
						  "paddingRight": 0,
						  "paddingTop": 0,
						  "paddingBottom": 0,
						  "primaryAxisAlignItems": "MIN",
						  "counterAxisAlignItems": "MIN",
						  "primaryAxisSizingMode": "AUTO",
						  "layoutGrids": [],
						  "gridStyleId": "",
						  "backgroundStyleId": "",
						  "clipsContent": false,
						  "guides": [],
						  "expanded": false,
						  "constraints": {
							"horizontal": "MIN",
							"vertical": "MIN"
						  },
						  "layoutMode": "VERTICAL",
						  "counterAxisSizingMode": "FIXED",
						  "itemSpacing": 16,
						  "overflowDirection": "NONE",
						  "numberOfFixedChildren": 0,
						  "overlayPositionType": "CENTER",
						  "overlayBackground": {
							"type": "NONE"
						  },
						  "overlayBackgroundInteraction": "NONE",
						  "mainComponent": {
							"id": "443:3278"
						  },
						  "scaleFactor": 1,
						  "reactions": [],
						  "variantProperties": {
							"Layout": "Vertical"
						  },
						  "type": "INSTANCE",
						  "makersVariantProps": "1"
						}
					  ],
					  "exportSettings": [],
					  "fills": [],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 1,
					  "strokeAlign": "INSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "cornerRadius": 0,
					  "cornerSmoothing": 0,
					  "topLeftRadius": 0,
					  "topRightRadius": 0,
					  "bottomLeftRadius": 0,
					  "bottomRightRadius": 0,
					  "paddingLeft": 0,
					  "paddingRight": 0,
					  "paddingTop": 0,
					  "paddingBottom": 0,
					  "primaryAxisAlignItems": "MIN",
					  "counterAxisAlignItems": "MIN",
					  "primaryAxisSizingMode": "AUTO",
					  "layoutGrids": [],
					  "gridStyleId": "",
					  "backgroundStyleId": "",
					  "clipsContent": false,
					  "guides": [],
					  "expanded": false,
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "layoutMode": "VERTICAL",
					  "counterAxisSizingMode": "FIXED",
					  "itemSpacing": 32,
					  "overflowDirection": "NONE",
					  "numberOfFixedChildren": 0,
					  "overlayPositionType": "CENTER",
					  "overlayBackground": {
						"type": "NONE"
					  },
					  "overlayBackgroundInteraction": "NONE",
					  "reactions": [],
					  "type": "FRAME"
					}
				  ],
				  "exportSettings": [],
				  "fills": [],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 1,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 0,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 0,
				  "topRightRadius": 0,
				  "bottomLeftRadius": 0,
				  "bottomRightRadius": 0,
				  "paddingLeft": 0,
				  "paddingRight": 0,
				  "paddingTop": 0,
				  "paddingBottom": 0,
				  "primaryAxisAlignItems": "SPACE_BETWEEN",
				  "counterAxisAlignItems": "MIN",
				  "primaryAxisSizingMode": "FIXED",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "reactions": [],
				  "type": "FRAME"
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 64,
			  "paddingBottom": 64,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "VERTICAL",
			  "counterAxisSizingMode": "FIXED",
			  "itemSpacing": 0,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "reactions": [],
			  "type": "FRAME"
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 20,
		  "paddingRight": 20,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 0,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "91efddfd43387fe982c7e35586470322f8f1eaa9",
		  "reactions": [],
		  "variantProperties": {
			"Layout": "Side Text"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_FEATURES",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  }
	}
  ]`;
