<template>
    <div class="makers-update-page-screen">
        <div class="image-preview" :style="{'background-image': `url(${page.makersPageImage})`}">
            <input
                type="file"
                ref="uploadPageImage"
                @change="handleImageSelection()"
                accept="image/png, image/jpeg, image/svg+xml"
            />
            <button class="button button--secondary" @click="openUploadImage()">
                <div class="icon icon--reverse icon--black3"></div>
                Replace Image
            </button>
        </div>
        <form @submit.prevent="handleUpdatePage()">
            <section>
                <div class="field field--title">
                    <div class="input">
                        <input v-model="page.makersPageTitle" @input="handleInput()" type="text" class="input__field" placeholder="Page Title">
                    </div>
                </div>
                <div class="field">
                    <div class="input">
                        <textarea ref="textarea" v-model="page.makersPageDescription" @input="[handleInput(), autoHeight()]" type="text" class="input__field" placeholder="Page Description"></textarea>
                    </div>
                </div>
            </section>
            <section>
                <div class="field field--url">
                    <div v-if="!domain" class="domain text-ellipsis">https://custom.domain.com/</div>
                    <div v-else class="domain text-ellipsis">https://{{domain}}/</div>
                    <div class="input">
                        <input class="url-text" disabled :value="pageUrl"/>
                    </div>
                </div>
            </section>
        </form>
        <div v-if="hasFooter" class="makers-footer">
            <div class="start"></div>
            <div class="end">
                <button class="button button--primary" :disabled="isLoading" @click="handleUpdatePage()" type="submit">
                    <template v-if="!isLoading">Save</template>
                    <div v-else class="icon icon--spinner icon--white"></div>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { validFileType, isHomePage } from '../../helpers/utilities';
    import { dispatch, handleEvent } from "../../figma/ui-message-handler";
    export default {
        props: ['page', 'hasFooter', 'backPage'],
        data() {
            return {
                isLoading: false,
                hasErrors: false,

                selectedFile: null,
                pageUrl: ''
            }
        },
        mounted() {
            this.autoHeight();
            this.handleInput();
            handleEvent("pageUpdated", () => this.isLoading = false);
        },
        computed: {
            ...mapState(['fileKey', 'site', 'homePage', 'pages']),
            domain() {
                if (this.site.site == null) {return null;}
                if ( !('domain' in this.site.site) ) {return null;}
                if (this.site.site.domain === null) {return null;}
                return this.site.site.domain.name;
            }
        },
        watch: {
            page(newVal) {
                this.pageUrl = (isHomePage(this.homePage, newVal)) ? '' : newVal.makersPageTitle.toLowerCase().replaceAll(" ", "-");
            }
        },
        methods: {
			...mapMutations(['setCurrentPage', 'setHomePage']),

            handleInput() {
				if (this.pages.length === 1) {
					// If there's only one page this should be the home page:
					this.pageUrl = '';
					this.setHomePage(this.page);
				} else {
					this.pageUrl = (isHomePage(this.homePage, this.page)) ? '' : this.sanitizePageTitle(this.page.makersPageTitle);
				}
                if (this.hasErrors) this.hasErrors = false;
            },
            handleUpdatePage() {
                this.isLoading = true;
                (this.selectedFile !== null) ? this.saveImageInCloud() : this.updatePage(this.page.makersPageImage);
            },
			sanitizePageTitle(string) {
				const noSpaces = string.toLowerCase().replaceAll(" ", "-");
				const sanitized = noSpaces.replace(/[^\w\s]/gi, '');
				return sanitized
			},

            saveImageInCloud() {
                const URL = 'site/upload/image';
                const data = new FormData();
                data.append('fileKey', this.fileKey);
                data.append('image', this.selectedFile);
                axios.post(URL, data, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json'}})
                    .then( (response) => this.updatePage(response.data.imageUrl) )
                    .catch( () => { this.isLoading = false; } );
            },
            updatePage(imageUrl) {
                dispatch('updatePage', {
                    id: this.page.id,
                    url: this.pageUrl,
                    imageUrl: imageUrl,
                    title: this.page.makersPageTitle.replace(/(\r\n|\n|\r)/gm, ""), // replace is tho remove new lines
                    description: this.page.makersPageDescription.replace(/(\r\n|\n|\r)/gm, ""),
                });
				// Go back to previous page
				this.setCurrentPage({name: this.backPage || 'home'});
            },
            handleImageSelection() {
                const input = this.$refs.uploadPageImage;
                this.selectedFile = input.files[0];
                const isImage = validFileType(this.selectedFile, ['image/png', 'image/jpeg', 'image/svg+xml'])
                if (isImage) {
                    const imageUrl = URL.createObjectURL(this.selectedFile);
                    this.page.makersPageImage = imageUrl; // For UI purposes
                } else {
                    dispatch("notify", "❌ Not a valid image format. Please use png, jpeg or svg 🙌")
                }
            },
            openUploadImage() {
                this.$refs.uploadPageImage.click();
            },
            autoHeight() {
                const textarea = this.$refs.textarea;
                textarea.style.height = "5px";
                textarea.style.height = (textarea.scrollHeight)+"px";
            }
        }
    }
</script>
