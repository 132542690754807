<template>
    <div class="makers-header-options-menu">
        <b-dropdown aria-role="list">
            <template #trigger>
                <button class="button button--tertiary">
                    <div class="icon icon--ellipses"></div>
                </button>
            </template>
            <b-dropdown-item
                :value="menu"
                aria-role="listitem"
                :class="setClasses(menu)"
                @click="handleClick(menu)"
                v-for="(menu, index) in optionsMenu" :key="index"
            >
                <div v-if="! menu.isPro" class="type type--xsmall">{{menu.text}}</div>
				<template v-else>
					<div class="type type--xsmall" v-text="(siteStatus === 'live') ? 'Update site' : 'Custom domain'"></div>
					<span v-if="menu.isPro" class="makers-pro-badge">PRO</span>
				</template>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>
<script>
    import { dispatch } from '../../figma/ui-message-handler';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    export default {
        data() {
            return {
                optionsMenu: [
                    { id: 'admin', text: 'Admin' },
                    { id: 'login', text: 'Log in' },
                    { id: 'signup', text: 'Create account' },
					// { id: 'publish', text: '', isPro: true},
                    { id: 'sites', text: 'Sites' },
                    { id: 'plans', text: 'Plans' },
                    { id: 'account', text: 'Account' },
                    { id: 'support', text: 'Support' },
                    // { id: 'community', text: 'Community' },
                    { id: 'logout', text: 'Log out' },
                ]
            }
        },
        computed: {
			...mapState(['isPublished', 'site', 'siteStatus']),
            ...mapGetters(['isAuthenticated', 'isAdmin']),
        },
        methods: {
            ...mapActions(['authenticateUser', 'signOut']),
            ...mapMutations(['setCurrentPage']),

            setClasses(menu) {
                return {
					'is-pro': menu.isPro,
                    hide:   menu.id === 'login' && this.isAuthenticated     ||
                            menu.id === 'signup' && this.isAuthenticated    ||
                            menu.id === 'logout' && !this.isAuthenticated   ||
                            menu.id === 'sites' && !this.isAuthenticated    ||
                            menu.id === 'publish' && !this.isAuthenticated    ||
                            menu.id === 'account' && !this.isAuthenticated  ||
                            menu.id === 'admin' && !this.isAdmin
                }
            },

            handleClick(menu) {
                switch (menu.id) {
                    case 'logout': this.handleLogOut(); break;
					case 'publish': this.handlePublish(); break;
                    default: this.goToPage(menu); break;
                }
            },
            handleLogOut() {
                this.setCurrentPage( {name: 'home', params: {} } );
                this.signOut()
                    .then(() => dispatch("notify", "✅ You are logged out."));
            },
			handlePublish() {
                const newPage = (this.isPublished) ? { name: 'update-site'} : { name: 'publish' };
                this.setCurrentPage(newPage);
            },

            goToPage(page) {
                const newPage = { name: page.id, params: {} };
                this.setCurrentPage(newPage);
            }
        }

    }
</script>
