<template>
    <div class="makers-publish-content makers-is-publishing">
        <div class="intro">
            <h1>🎉</h1>
            <h1>We are publishing your site!</h1>
            <p>
                Your site will be live shortly.
                Please don’t close the Makers Plugin as we deploy your site after the DNS has propagated.
                If you run into anything, please reach us throught
                <a href="mailto:support@makers.so?subject=Help about Publishing on Makers" target="_blank">email</a> or the
                <span @click="setCurrentPage({name: 'support'})">support page</span>.
            </p>
			<p>Please wait a few moments until we start building your Site.</p>
        </div>
        <section>
            <div class="buttons-wrapper">
                <div class="start"></div>
                <div class="end">
                    <button class="button button--primary" :disabled="!isBuilding" @click="setCurrentPage({name: 'sites'})">Done</button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex'
    export default {
        methods: {
            ...mapMutations(['setCurrentPage']),
        },
		computed: {
			...mapState(['siteStatus']),

			isBuilding() {
				return (this.siteStatus === 'building');
			}
		},
    }
</script>
