<template>
    <div class="makers-update-site-page makers-publish-page">
        <makers-component-header header-name="Update" back-page="home"/>
        <main v-if="pages.length > 0">
			<div class="makers-content-wrapper">
				<div class="intro">
					<h1>Update Site.</h1>
					<h2>Check if everything is how you want it. When you’re ready click “Update Site”.</h2>
				</div>
				<section class="domain-section">
					<div class="section-title">Domain</div>
					<div class="domain-wrapper">
						<a :href="`https://${site.site.domain.name}`" target="_blank">
							<div class="text-ellipsis" v-text="`https://${site.site.domain.name}`"></div>
							<div class="icon">
								<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 9.16667v4.00003c0 .3536-.1405.6927-.3905.9428-.2501.25-.5892.3905-.9428.3905H3.33333c-.35362 0-.69276-.1405-.94281-.3905C2.14048 13.8594 2 13.5203 2 13.1667V5.83333c0-.35362.14048-.69276.39052-.94281.25005-.25004.58919-.39052.94281-.39052h4M10 2.5h4v4M6.66675 9.83333L14.0001 2.5" stroke="#4A4A4A" stroke-width="1.32493" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</div>
						</a>
					</div>
				</section>
				<makers-site-pages></makers-site-pages>
				<makers-publish-favicon-analytics :show-title="false"></makers-publish-favicon-analytics>
			</div>
        </main>
        <main v-else class="no-pages-found">
			<div class="makers-content-wrapper">
				<div class="intro">
					<h1>No Pages Found.</h1>
					<h2>You need to create at least one page to publish your site.</h2>
				</div>
				<section>
					<button class="button button--primary" @click="setCurrentPage({name: 'home'})">
						Create a page
					</button>
				</section>
			</div>
        </main>
        <div class="makers-footer">
            <div class="start"></div>
            <div class="end">
                <button class="button button--secondary" :disabled="isLoading" @click="handlePreview()">
                    <template v-if="!isLoading">
                        <div class="icon icon--small"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8.66667v4.00003c0 .3536-.1405.6927-.3905.9428-.2501.25-.5892.3905-.9428.3905H3.33333c-.35362 0-.69276-.1405-.94281-.3905C2.14048 13.3594 2 13.0203 2 12.6667V5.33333c0-.35362.14048-.69276.39052-.94281C2.64057 4.14048 2.97971 4 3.33333 4h4M10 2h4v4M6.66675 9.33333L14.0001 2" stroke="#4A4A4A" stroke-width="1.32493" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
                        Preview
                    </template>
                    <div v-else class="icon icon--spinner icon--black3"></div>
                </button>
                <button class="button button--primary" :disabled="isLoading" @click="handlePublishSite()">Update site</button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { dispatch } from '../figma/ui-message-handler';
    export default {
		props: ['controller'],
        computed: {
            ...mapState(['pages', 'site', 'isLoading']),
        },
        methods: {
            ...mapMutations(['setIsLoading', 'setIsPreviewWorkflow', 'setCurrentPage']),

            handlePreview() {
                this.setIsLoading(true);
				this.setIsPreviewWorkflow(true);
				this.controller.getAllNodesSaveInDatabaseAndOpenBrowser();
            },
            handlePublishSite() {
				this.setIsLoading(true);
				this.setIsPreviewWorkflow(false);
				this.controller.getAllNodesSaveInDatabaseAndOpenBrowser();
                setTimeout(() => {
                    dispatch("notify", "✅ We are Updating your site");
                    this.setCurrentPage({name: 'sites'});
                }, 3000);
            },
        }
    }
</script>
