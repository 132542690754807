<template>
    <div class="makers-site-status-flash-card" :class="{show: show, hide: hide}">
        <div v-if="siteStatus !== 'live'" class="circles">
            <div class="pulsing-circle pulsing" :style="getStatusOvalColor(siteStatus, true)"></div>
            <div class="pulsing-circle" :style="getStatusOvalColor(siteStatus)"></div>
        </div>
        <div v-else class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17l-5-5" stroke="#5DCBAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="info">
            <template v-if="siteStatus !== 'live'" :style="getStatusColor(site.status)">{{ siteStatus | capitalize }} your site</template>
            <template v-else>
               <div>Your site is Live 🎉</div> 
               <button class="button button--secondary" @click="setHide()">Visit</button>
            </template>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { setStatusColor } from "../helpers/utilities";
    export default {
        props: ['siteStatus', 'siteDomain'],
        data() {
            return {
                show: false,
                hide: false,
            }
        },
        mounted() {
            setTimeout(() => this.show = true, 100);
        },
        computed: {
            ...mapState(['site']),
        },
        methods: {
            ...mapMutations(['setShowSiteStatusFlashCard']),
            setHide() {
                this.show = false;
                this.hide = true;
                setTimeout(() => this.setShowSiteStatusFlashCard(false), 300);
                window.open(`https://${this.site.site.domain.name}`, '_blank');
            },

            getStatusOvalColor(status, hasTransparency = false) {
                return (hasTransparency) ? {background: setStatusColor(status).transparent} : {background: setStatusColor(status).color};
            },
            getStatusColor(status) {
                return {color: setStatusColor(status).text};
            },
        },
        filters: {
            capitalize: s => s.charAt(0).toUpperCase() + s.slice(1)
        }
    }
</script>