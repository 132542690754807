<template>
    <div class="makers-publish-content">
        <div class="intro">
            <h1>Fonts</h1>
            <h2>Your site's fonts.</h2>
        </div>
		<section class="fonts-section">
			<div class="fonts">
				<div
					:key="index"
					class="font"
					v-for="(font, index) in fonts"
					:class="{'is-disabled': !font.isGoogleFont}"
				>
					<div class="start">{{font.family}}, {{font.style}}</div>
					<div class="end">
						<template v-if="!font.isGoogleFont">
							<div class="icon">
								<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.4493 3.94895 1.97932 18.089c-.17463.3024-.26703.6452-.26801.9945-.00098.3492.0895.6926.26244.996.17293.3034.4223.5562.72328.7333.30098.1771.64309.2723.99229.2762H20.6293c.3492-.0039.6913-.0991.9923-.2762.301-.1771.5504-.4299.7233-.7333.1729-.3034.2634-.6468.2624-.996-.0009-.3493-.0933-.6921-.268-.9945l-8.47-14.14005c-.1782-.29389-.4293-.53688-.7288-.70552-.2995-.16863-.6374-.25722-.9812-.25722-.3437 0-.6816.08859-.9812.25722-.2995.16864-.5505.41163-.7288.70552v0Z" fill="#FFE600"/><path d="M12.1593 9.08899V13.089M12.1593 17.089h.01"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.159302 .0889893)" d="M0 0h24v24H0z"/></clipPath></defs></svg>
							</div>
						</template>
						<div v-else class="icon icon--check icon--green"></div>
					</div>
				</div>
			</div>
			<div v-if="hasCustomFonts" class="info">
				<div class="icon">
					<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.4493 3.94895 1.97932 18.089c-.17463.3024-.26703.6452-.26801.9945-.00098.3492.0895.6926.26244.996.17293.3034.4223.5562.72328.7333.30098.1771.64309.2723.99229.2762H20.6293c.3492-.0039.6913-.0991.9923-.2762.301-.1771.5504-.4299.7233-.7333.1729-.3034.2634-.6468.2624-.996-.0009-.3493-.0933-.6921-.268-.9945l-8.47-14.14005c-.1782-.29389-.4293-.53688-.7288-.70552-.2995-.16863-.6374-.25722-.9812-.25722-.3437 0-.6816.08859-.9812.25722-.2995.16864-.5505.41163-.7288.70552v0Z" fill="#FFE600"/><path d="M12.1593 9.08899V13.089M12.1593 17.089h.01"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.159302 .0889893)" d="M0 0h24v24H0z"/></clipPath></defs></svg>
				</div>
				<div>These fonts will not load in your site. For now, we support Google Fonts exclusively.<br><br>
					We will add custom fonts support really soon though 😎</div>
			</div>
		</section>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        computed: {
            ...mapState(['fonts']),

			hasCustomFonts() {
				return this.fonts.some(f => !f.isGoogleFont);
			}
        },
    }
</script>
