export const textContent = () => {
	return textContentJson;
}
const textContentJson = `
[
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1070",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  -163
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": -163,
			"y": -271,
			"width": 115,
			"height": 52
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1071",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1070"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  -135
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": -133.34454345703125,
				"y": -251.09091186523438,
				"width": 55.823326110839844,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "43cef506927f8ea24ffc7376948db23c7d8e4b04",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "118:1072",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1072",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  155
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  -28
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 155,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": -44,
			"y": -283,
			"width": 147,
			"height": 84
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1073",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1072"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 1.6554509401321411,
				"y": -251.09091186523438,
				"width": 55.82332992553711,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "a19acbaa13a915b2c56ae380a8b12e72be3b55ca",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1074",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  290
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  107
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 290,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": 107,
			"y": -271,
			"width": 115,
			"height": 52
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1075",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1074"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  135
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 136.65545654296875,
				"y": -251.09091186523438,
				"width": 55.82331848144531,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "2a87726e36a0f85074ccd628b59e640025b6926c",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "118:1076",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1076",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  425
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  242
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 425,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": 226,
			"y": -283,
			"width": 147,
			"height": 84
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1077",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1076"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  270
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 271.65545654296875,
				"y": -251.09091186523438,
				"width": 55.823333740234375,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "3da62086e199c2b42c634e87c585ea61765f1291",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BUTTON",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  },
	  "defaultInstanceIndex": 0
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1070",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  -163
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": -163,
			"y": -271,
			"width": 115,
			"height": 52
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1071",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1070"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  -135
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": -133.34454345703125,
				"y": -251.09091186523438,
				"width": 55.823326110839844,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "43cef506927f8ea24ffc7376948db23c7d8e4b04",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "118:1072",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1072",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Primary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  155
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  -28
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 155,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": -44,
			"y": -283,
			"width": 147,
			"height": 84
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1073",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1072"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 1.6554509401321411,
				"y": -251.09091186523438,
				"width": 55.82332992553711,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0,
				"g": 0.4117647111415863,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "a19acbaa13a915b2c56ae380a8b12e72be3b55ca",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Primary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1074",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Default, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  290
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  107
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 290,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": 107,
			"y": -271,
			"width": 115,
			"height": 52
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1075",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1074"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  135
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 136.65545654296875,
				"y": -251.09091186523438,
				"width": 55.82331848144531,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "2a87726e36a0f85074ccd628b59e640025b6926c",
		  "reactions": [
			{
			  "action": {
				"type": "NODE",
				"destinationId": "118:1076",
				"navigation": "CHANGE_TO",
				"transition": {
				  "type": "SMART_ANIMATE",
				  "easing": {
					"type": "EASE_IN_AND_OUT"
				  },
				  "duration": 0.30000001192092896
				},
				"preserveScrollPosition": false
			  },
			  "trigger": {
				"type": "ON_HOVER"
			  }
			}
		  ],
		  "variantProperties": {
			"State": "Default",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "118:1076",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "State=Hover, Type=Secondary",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [
			{
			  "type": "DROP_SHADOW",
			  "color": {
				"r": 0,
				"g": 0,
				"b": 0,
				"a": 0.10000000149011612
			  },
			  "offset": {
				"x": 0,
				"y": 4
			  },
			  "radius": 16,
			  "spread": 0,
			  "visible": true,
			  "blendMode": "NORMAL",
			  "showShadowBehindNode": false
			}
		  ],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  425
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  242
			],
			[
			  0,
			  1,
			  -271
			]
		  ],
		  "x": 425,
		  "y": 20,
		  "width": 115,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": 226,
			"y": -283,
			"width": 147,
			"height": 84
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "118:1077",
			  "listSpacing": 0,
			  "parent": {
				"id": "118:1076"
			  },
			  "name": "Button",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  28
				],
				[
				  0,
				  1,
				  6
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  270
				],
				[
				  0,
				  1,
				  -265
				]
			  ],
			  "x": 28,
			  "y": 6,
			  "width": 59,
			  "height": 40,
			  "absoluteRenderBounds": {
				"x": 271.65545654296875,
				"y": -251.09091186523438,
				"width": 55.823333740234375,
				"height": 13.282669067382812
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Button",
			  "hasMissingFont": false,
			  "fontSize": 18,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PIXELS",
				"value": 40
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "WIDTH_AND_HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			}
		  ],
		  "exportSettings": [],
		  "fills": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 1,
				"g": 1,
				"b": 1
			  }
			}
		  ],
		  "fillStyleId": "",
		  "strokes": [
			{
			  "type": "SOLID",
			  "visible": true,
			  "opacity": 1,
			  "blendMode": "NORMAL",
			  "color": {
				"r": 0.008333325386047363,
				"g": 0.28684401512145996,
				"b": 1
			  }
			}
		  ],
		  "strokeStyleId": "",
		  "strokeWeight": 2,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 10,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 10,
		  "topRightRadius": 10,
		  "bottomLeftRadius": 10,
		  "bottomRightRadius": 10,
		  "paddingLeft": 28,
		  "paddingRight": 28,
		  "paddingTop": 6,
		  "paddingBottom": 6,
		  "primaryAxisAlignItems": "CENTER",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": false,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 16,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "3da62086e199c2b42c634e87c585ea61765f1291",
		  "reactions": [],
		  "variantProperties": {
			"State": "Hover",
			"Type": "Secondary"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BUTTON",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  },
	  "defaultInstanceIndex": 0
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "281:1450",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Direction=Horizontal",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  764
			],
			[
			  0,
			  1,
			  -291
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 240,
		  "height": 52,
		  "absoluteRenderBounds": {
			"x": 764,
			"y": -291,
			"width": 240,
			"height": 52
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1444",
			  "parent": {
				"id": "281:1450"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  764
				],
				[
				  0,
				  1,
				  -291
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 115,
			  "height": 52,
			  "absoluteRenderBounds": {
				"x": 764,
				"y": -291,
				"width": 115,
				"height": 52
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1444;118:1071",
				  "listSpacing": 0,
				  "parent": {
					"id": "281:1444"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  6
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  792
					],
					[
					  0,
					  1,
					  -285
					]
				  ],
				  "x": 28,
				  "y": 6,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 793.6554565429688,
					"y": -271.0909118652344,
					"width": 55.82330322265625,
					"height": 13.282684326171875
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0.4117647111415863,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 6,
			  "paddingBottom": 6,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "118:1070"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "118:1072",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Primary"
			  },
			  "type": "INSTANCE"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1447",
			  "parent": {
				"id": "281:1450"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  125
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  889
				],
				[
				  0,
				  1,
				  -291
				]
			  ],
			  "x": 125,
			  "y": 0,
			  "width": 115,
			  "height": 52,
			  "absoluteRenderBounds": {
				"x": 889,
				"y": -291,
				"width": 115,
				"height": 52
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1447;118:1075",
				  "listSpacing": 0,
				  "parent": {
					"id": "281:1447"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  6
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  917
					],
					[
					  0,
					  1,
					  -285
					]
				  ],
				  "x": 28,
				  "y": 6,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 918.6554565429688,
					"y": -271.0909118652344,
					"width": 55.82330322265625,
					"height": 13.282684326171875
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 6,
			  "paddingBottom": 6,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "118:1074"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "118:1076",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Secondary"
			  },
			  "type": "INSTANCE"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "HORIZONTAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 10,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "819514bd7773e0710fb33a578b4232bc3c499e9b",
		  "reactions": [],
		  "variantProperties": {
			"Direction": "Horizontal"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "283:1481",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Direction=Vertical",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  92
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  764
			],
			[
			  0,
			  1,
			  -219
			]
		  ],
		  "x": 20,
		  "y": 92,
		  "width": 115,
		  "height": 114,
		  "absoluteRenderBounds": {
			"x": 764,
			"y": -219,
			"width": 115,
			"height": 114
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "283:1482",
			  "parent": {
				"id": "283:1481"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  0
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  764
				],
				[
				  0,
				  1,
				  -219
				]
			  ],
			  "x": 0,
			  "y": 0,
			  "width": 115,
			  "height": 52,
			  "absoluteRenderBounds": {
				"x": 764,
				"y": -219,
				"width": 115,
				"height": 52
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I283:1482;118:1071",
				  "listSpacing": 0,
				  "parent": {
					"id": "283:1482"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  6
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  792
					],
					[
					  0,
					  1,
					  -213
					]
				  ],
				  "x": 28,
				  "y": 6,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 793.6554565429688,
					"y": -199.09091186523438,
					"width": 55.82330322265625,
					"height": 13.282669067382812
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0.4117647111415863,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 6,
			  "paddingBottom": 6,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "118:1070"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "118:1072",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Primary"
			  },
			  "type": "INSTANCE"
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "283:1483",
			  "parent": {
				"id": "283:1481"
			  },
			  "name": "MAKERS_BUTTON",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  62
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  764
				],
				[
				  0,
				  1,
				  -157
				]
			  ],
			  "x": 0,
			  "y": 62,
			  "width": 115,
			  "height": 52,
			  "absoluteRenderBounds": {
				"x": 764,
				"y": -157,
				"width": 115,
				"height": 52
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I283:1483;118:1075",
				  "listSpacing": 0,
				  "parent": {
					"id": "283:1483"
				  },
				  "name": "Button",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "OUTSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "relativeTransform": [
					[
					  1,
					  0,
					  28
					],
					[
					  0,
					  1,
					  6
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  792
					],
					[
					  0,
					  1,
					  -151
					]
				  ],
				  "x": 28,
				  "y": 6,
				  "width": 59,
				  "height": 40,
				  "absoluteRenderBounds": {
					"x": 793.6554565429688,
					"y": -137.09091186523438,
					"width": 55.82330322265625,
					"height": 13.282669067382812
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "exportSettings": [],
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "characters": "Button",
				  "hasMissingFont": false,
				  "fontSize": 18,
				  "paragraphIndent": 0,
				  "paragraphSpacing": 0,
				  "textCase": "ORIGINAL",
				  "textDecoration": "NONE",
				  "letterSpacing": {
					"unit": "PERCENT",
					"value": 0
				  },
				  "lineHeight": {
					"unit": "PIXELS",
					"value": 40
				  },
				  "fontName": {
					"family": "Inter",
					"style": "Semi Bold"
				  },
				  "autoRename": true,
				  "textAlignHorizontal": "CENTER",
				  "textAlignVertical": "TOP",
				  "textAutoResize": "WIDTH_AND_HEIGHT",
				  "textStyleId": "",
				  "reactions": [],
				  "type": "TEXT",
				  "children": []
				}
			  ],
			  "exportSettings": [],
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 1,
					"g": 1,
					"b": 1
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.008333325386047363,
					"g": 0.28684401512145996,
					"b": 1
				  }
				}
			  ],
			  "strokeStyleId": "",
			  "strokeWeight": 2,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 10,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 10,
			  "topRightRadius": 10,
			  "bottomLeftRadius": 10,
			  "bottomRightRadius": 10,
			  "paddingLeft": 28,
			  "paddingRight": 28,
			  "paddingTop": 6,
			  "paddingBottom": 6,
			  "primaryAxisAlignItems": "CENTER",
			  "counterAxisAlignItems": "CENTER",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 16,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "118:1074"
			  },
			  "scaleFactor": 1,
			  "reactions": [
				{
				  "action": {
					"type": "NODE",
					"destinationId": "118:1076",
					"navigation": "CHANGE_TO",
					"transition": {
					  "type": "SMART_ANIMATE",
					  "easing": {
						"type": "EASE_IN_AND_OUT"
					  },
					  "duration": 0.30000001192092896
					},
					"preserveScrollPosition": false
				  },
				  "trigger": {
					"type": "ON_HOVER"
				  }
				}
			  ],
			  "variantProperties": {
				"State": "Default",
				"Type": "Secondary"
			  },
			  "type": "INSTANCE"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 0,
		  "paddingBottom": 0,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "AUTO",
		  "itemSpacing": 10,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b0945fc98738ad7410517ceeb9cc051053180074",
		  "reactions": [],
		  "variantProperties": {
			"Direction": "Vertical"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_BUTTONS_GROUP",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "VERTICAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  },
	  "defaultInstanceIndex": 0
	},
	{
	  "components": [
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "281:1460",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Left",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  20
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1411
			],
			[
			  0,
			  1,
			  -740
			]
		  ],
		  "x": 20,
		  "y": 20,
		  "width": 500,
		  "height": 471,
		  "absoluteRenderBounds": {
			"x": 1411,
			"y": -740,
			"width": 500,
			"height": 471
		  },
		  "rotation": 0,
		  "layoutAlign": "STRETCH",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1451",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1460"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  48
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1411
				],
				[
				  0,
				  1,
				  -692
				]
			  ],
			  "x": 0,
			  "y": 48,
			  "width": 500,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 1412.7215576171875,
				"y": -687.2982788085938,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1452",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1460"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  101
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1411
				],
				[
				  0,
				  1,
				  -639
				]
			  ],
			  "x": 0,
			  "y": 101,
			  "width": 500,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 1411.9375,
				"y": -627.9091186523438,
				"width": 438.3621826171875,
				"height": 161.27273559570312
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1453",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1460"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  299
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1411
				],
				[
				  0,
				  1,
				  -441
				]
			  ],
			  "x": 0,
			  "y": 299,
			  "width": 500,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 1411.8182373046875,
				"y": -435,
				"width": 446.34375,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "LEFT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1455",
			  "parent": {
				"id": "281:1460"
			  },
			  "name": "MAKERS_BUTTONS_GROUP",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  371
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1411
				],
				[
				  0,
				  1,
				  -369
				]
			  ],
			  "x": 0,
			  "y": 371,
			  "width": 240,
			  "height": 52,
			  "absoluteRenderBounds": {
				"x": 1411,
				"y": -369,
				"width": 240,
				"height": 52
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1455;281:1444",
				  "parent": {
					"id": "281:1455"
				  },
				  "name": "MAKERS_BUTTON",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1411
					],
					[
					  0,
					  1,
					  -369
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 115,
				  "height": 52,
				  "absoluteRenderBounds": {
					"x": 1411,
					"y": -369,
					"width": 115,
					"height": 52
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I281:1455;281:1444;118:1071",
					  "listSpacing": 0,
					  "parent": {
						"id": "I281:1455;281:1444"
					  },
					  "name": "Button",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  28
						],
						[
						  0,
						  1,
						  6
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  1439
						],
						[
						  0,
						  1,
						  -363
						]
					  ],
					  "x": 28,
					  "y": 6,
					  "width": 59,
					  "height": 40,
					  "absoluteRenderBounds": {
						"x": 1440.6553955078125,
						"y": -349.0909118652344,
						"width": 55.8233642578125,
						"height": 13.282684326171875
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Button",
					  "hasMissingFont": false,
					  "fontSize": 18,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PIXELS",
						"value": 40
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0,
						"g": 0.4117647111415863,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 10,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 10,
				  "topRightRadius": 10,
				  "bottomLeftRadius": 10,
				  "bottomRightRadius": 10,
				  "paddingLeft": 28,
				  "paddingRight": 28,
				  "paddingTop": 6,
				  "paddingBottom": 6,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "118:1070"
				  },
				  "scaleFactor": 1,
				  "reactions": [
					{
					  "action": {
						"type": "NODE",
						"destinationId": "118:1072",
						"navigation": "CHANGE_TO",
						"transition": {
						  "type": "SMART_ANIMATE",
						  "easing": {
							"type": "EASE_IN_AND_OUT"
						  },
						  "duration": 0.30000001192092896
						},
						"preserveScrollPosition": false
					  },
					  "trigger": {
						"type": "ON_HOVER"
					  }
					}
				  ],
				  "variantProperties": {
					"State": "Default",
					"Type": "Primary"
				  },
				  "type": "INSTANCE"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1455;281:1447",
				  "parent": {
					"id": "281:1455"
				  },
				  "name": "MAKERS_BUTTON",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  125
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  1536
					],
					[
					  0,
					  1,
					  -369
					]
				  ],
				  "x": 125,
				  "y": 0,
				  "width": 115,
				  "height": 52,
				  "absoluteRenderBounds": {
					"x": 1536,
					"y": -369,
					"width": 115,
					"height": 52
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I281:1455;281:1447;118:1075",
					  "listSpacing": 0,
					  "parent": {
						"id": "I281:1455;281:1447"
					  },
					  "name": "Button",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.008333325386047363,
							"g": 0.28684401512145996,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  28
						],
						[
						  0,
						  1,
						  6
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  1564
						],
						[
						  0,
						  1,
						  -363
						]
					  ],
					  "x": 28,
					  "y": 6,
					  "width": 59,
					  "height": 40,
					  "absoluteRenderBounds": {
						"x": 1565.6553955078125,
						"y": -349.0909118652344,
						"width": 55.8233642578125,
						"height": 13.282684326171875
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Button",
					  "hasMissingFont": false,
					  "fontSize": 18,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PIXELS",
						"value": 40
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 10,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 10,
				  "topRightRadius": 10,
				  "bottomLeftRadius": 10,
				  "bottomRightRadius": 10,
				  "paddingLeft": 28,
				  "paddingRight": 28,
				  "paddingTop": 6,
				  "paddingBottom": 6,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "118:1074"
				  },
				  "scaleFactor": 1,
				  "reactions": [
					{
					  "action": {
						"type": "NODE",
						"destinationId": "118:1076",
						"navigation": "CHANGE_TO",
						"transition": {
						  "type": "SMART_ANIMATE",
						  "easing": {
							"type": "EASE_IN_AND_OUT"
						  },
						  "duration": 0.30000001192092896
						},
						"preserveScrollPosition": false
					  },
					  "trigger": {
						"type": "ON_HOVER"
					  }
					}
				  ],
				  "variantProperties": {
					"State": "Default",
					"Type": "Secondary"
				  },
				  "type": "INSTANCE"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "281:1450"
			  },
			  "scaleFactor": 1,
			  "reactions": [],
			  "variantProperties": {
				"Direction": "Horizontal"
			  },
			  "type": "INSTANCE"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 48,
		  "paddingBottom": 48,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MIN",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "902f0b0536dc1c72d7df8fa0a4a72fd578eed74d",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Left"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "281:1462",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Center",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  540
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  1931
			],
			[
			  0,
			  1,
			  -740
			]
		  ],
		  "x": 540,
		  "y": 20,
		  "width": 500,
		  "height": 471,
		  "absoluteRenderBounds": {
			"x": 1931,
			"y": -740,
			"width": 500,
			"height": 471
		  },
		  "rotation": 0,
		  "layoutAlign": "INHERIT",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1463",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1462"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  48
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1931
				],
				[
				  0,
				  1,
				  -692
				]
			  ],
			  "x": 0,
			  "y": 48,
			  "width": 500,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 2124.4560546875,
				"y": -687.2982788085938,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1464",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1462"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  101
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1931
				],
				[
				  0,
				  1,
				  -639
				]
			  ],
			  "x": 0,
			  "y": 101,
			  "width": 500,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 1963.4637451171875,
				"y": -627.9091186523438,
				"width": 436.2657470703125,
				"height": 161.27273559570312
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1465",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1462"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  299
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  1931
				],
				[
				  0,
				  1,
				  -441
				]
			  ],
			  "x": 0,
			  "y": 299,
			  "width": 500,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 1958.4324951171875,
				"y": -435,
				"width": 445.7528076171875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "CENTER",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1466",
			  "parent": {
				"id": "281:1462"
			  },
			  "name": "MAKERS_BUTTONS_GROUP",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  130
				],
				[
				  0,
				  1,
				  371
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  2061
				],
				[
				  0,
				  1,
				  -369
				]
			  ],
			  "x": 130,
			  "y": 371,
			  "width": 240,
			  "height": 52,
			  "absoluteRenderBounds": {
				"x": 2061,
				"y": -369,
				"width": 240,
				"height": 52
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1466;281:1444",
				  "parent": {
					"id": "281:1466"
				  },
				  "name": "MAKERS_BUTTON",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2061
					],
					[
					  0,
					  1,
					  -369
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 115,
				  "height": 52,
				  "absoluteRenderBounds": {
					"x": 2061,
					"y": -369,
					"width": 115,
					"height": 52
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I281:1466;281:1444;118:1071",
					  "listSpacing": 0,
					  "parent": {
						"id": "I281:1466;281:1444"
					  },
					  "name": "Button",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  28
						],
						[
						  0,
						  1,
						  6
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2089
						],
						[
						  0,
						  1,
						  -363
						]
					  ],
					  "x": 28,
					  "y": 6,
					  "width": 59,
					  "height": 40,
					  "absoluteRenderBounds": {
						"x": 2090.655517578125,
						"y": -349.0909118652344,
						"width": 55.8232421875,
						"height": 13.282684326171875
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Button",
					  "hasMissingFont": false,
					  "fontSize": 18,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PIXELS",
						"value": 40
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0,
						"g": 0.4117647111415863,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 10,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 10,
				  "topRightRadius": 10,
				  "bottomLeftRadius": 10,
				  "bottomRightRadius": 10,
				  "paddingLeft": 28,
				  "paddingRight": 28,
				  "paddingTop": 6,
				  "paddingBottom": 6,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "118:1070"
				  },
				  "scaleFactor": 1,
				  "reactions": [
					{
					  "action": {
						"type": "NODE",
						"destinationId": "118:1072",
						"navigation": "CHANGE_TO",
						"transition": {
						  "type": "SMART_ANIMATE",
						  "easing": {
							"type": "EASE_IN_AND_OUT"
						  },
						  "duration": 0.30000001192092896
						},
						"preserveScrollPosition": false
					  },
					  "trigger": {
						"type": "ON_HOVER"
					  }
					}
				  ],
				  "variantProperties": {
					"State": "Default",
					"Type": "Primary"
				  },
				  "type": "INSTANCE"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1466;281:1447",
				  "parent": {
					"id": "281:1466"
				  },
				  "name": "MAKERS_BUTTON",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  125
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2186
					],
					[
					  0,
					  1,
					  -369
					]
				  ],
				  "x": 125,
				  "y": 0,
				  "width": 115,
				  "height": 52,
				  "absoluteRenderBounds": {
					"x": 2186,
					"y": -369,
					"width": 115,
					"height": 52
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I281:1466;281:1447;118:1075",
					  "listSpacing": 0,
					  "parent": {
						"id": "I281:1466;281:1447"
					  },
					  "name": "Button",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.008333325386047363,
							"g": 0.28684401512145996,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  28
						],
						[
						  0,
						  1,
						  6
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2214
						],
						[
						  0,
						  1,
						  -363
						]
					  ],
					  "x": 28,
					  "y": 6,
					  "width": 59,
					  "height": 40,
					  "absoluteRenderBounds": {
						"x": 2215.655517578125,
						"y": -349.0909118652344,
						"width": 55.8232421875,
						"height": 13.282684326171875
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Button",
					  "hasMissingFont": false,
					  "fontSize": 18,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PIXELS",
						"value": 40
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 10,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 10,
				  "topRightRadius": 10,
				  "bottomLeftRadius": 10,
				  "bottomRightRadius": 10,
				  "paddingLeft": 28,
				  "paddingRight": 28,
				  "paddingTop": 6,
				  "paddingBottom": 6,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "118:1074"
				  },
				  "scaleFactor": 1,
				  "reactions": [
					{
					  "action": {
						"type": "NODE",
						"destinationId": "118:1076",
						"navigation": "CHANGE_TO",
						"transition": {
						  "type": "SMART_ANIMATE",
						  "easing": {
							"type": "EASE_IN_AND_OUT"
						  },
						  "duration": 0.30000001192092896
						},
						"preserveScrollPosition": false
					  },
					  "trigger": {
						"type": "ON_HOVER"
					  }
					}
				  ],
				  "variantProperties": {
					"State": "Default",
					"Type": "Secondary"
				  },
				  "type": "INSTANCE"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "281:1450"
			  },
			  "scaleFactor": 1,
			  "reactions": [],
			  "variantProperties": {
				"Direction": "Horizontal"
			  },
			  "type": "INSTANCE"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 48,
		  "paddingBottom": 48,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "CENTER",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "d4b90ff0c7cf9f27247fdc60c749005d48883404",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Center"
		  },
		  "type": "COMPONENT"
		},
		{
		  "makersHtmlTag": "",
		  "makersClasses": "",
		  "makersComponent": "",
		  "makersBackgroundImage": "",
		  "makersExportAsSvg": "",
		  "makersPageUrl": "",
		  "makersPageTitle": "",
		  "makersPageImage": "",
		  "makersPageDescription": "",
		  "id": "281:1471",
		  "parent": {
			"id": "255:1272"
		  },
		  "name": "Align=Right",
		  "removed": false,
		  "widgetEvents": [],
		  "visible": true,
		  "locked": false,
		  "opacity": 1,
		  "blendMode": "PASS_THROUGH",
		  "isMask": false,
		  "effects": [],
		  "effectStyleId": "",
		  "relativeTransform": [
			[
			  1,
			  0,
			  1060
			],
			[
			  0,
			  1,
			  20
			]
		  ],
		  "absoluteTransform": [
			[
			  1,
			  0,
			  2451
			],
			[
			  0,
			  1,
			  -740
			]
		  ],
		  "x": 1060,
		  "y": 20,
		  "width": 500,
		  "height": 471,
		  "absoluteRenderBounds": {
			"x": 2451,
			"y": -740,
			"width": 500,
			"height": 471
		  },
		  "rotation": 0,
		  "layoutAlign": "STRETCH",
		  "constrainProportions": false,
		  "layoutGrow": 0,
		  "children": [
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1472",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1471"
			  },
			  "name": "Education",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  48
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  2451
				],
				[
				  0,
				  1,
				  -692
				]
			  ],
			  "x": 0,
			  "y": 48,
			  "width": 500,
			  "height": 29,
			  "absoluteRenderBounds": {
				"x": 2836.1904296875,
				"y": -687.2982788085938,
				"width": 113.26708984375,
				"height": 18.5625
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Education",
			  "hasMissingFont": false,
			  "fontSize": 24,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Semi Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1473",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1471"
			  },
			  "name": "Learn how to make and publish sites with Figma",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0,
					"g": 0,
					"b": 0
				  }
				}
			  ],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  101
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  2451
				],
				[
				  0,
				  1,
				  -639
				]
			  ],
			  "x": 0,
			  "y": 101,
			  "width": 500,
			  "height": 174,
			  "absoluteRenderBounds": {
				"x": 2512.8935546875,
				"y": -627.9091186523438,
				"width": 436.431640625,
				"height": 161.27273559570312
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Learn how to make and publish sites with Figma",
			  "hasMissingFont": false,
			  "fontSize": 48,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "AUTO"
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Bold"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1474",
			  "listSpacing": 0,
			  "parent": {
				"id": "281:1471"
			  },
			  "name": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "fills": [
				{
				  "type": "SOLID",
				  "visible": true,
				  "opacity": 1,
				  "blendMode": "NORMAL",
				  "color": {
					"r": 0.03947368264198303,
					"g": 0.16421058773994446,
					"b": 0.2605263292789459
				  }
				}
			  ],
			  "fillStyleId": "S:d30a1097733fd4530af3538ca8d16c0dffd1cd37,",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "OUTSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "relativeTransform": [
				[
				  1,
				  0,
				  0
				],
				[
				  0,
				  1,
				  299
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  2451
				],
				[
				  0,
				  1,
				  -441
				]
			  ],
			  "x": 0,
			  "y": 299,
			  "width": 500,
			  "height": 48,
			  "absoluteRenderBounds": {
				"x": 2504.4560546875,
				"y": -435,
				"width": 445.752685546875,
				"height": 39.45452880859375
			  },
			  "rotation": 0,
			  "layoutAlign": "STRETCH",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "exportSettings": [],
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "characters": "Makers is a Figma Plugin to help you build and publish sites directly in Figma. No code required.",
			  "hasMissingFont": false,
			  "fontSize": 16,
			  "paragraphIndent": 0,
			  "paragraphSpacing": 0,
			  "textCase": "ORIGINAL",
			  "textDecoration": "NONE",
			  "letterSpacing": {
				"unit": "PERCENT",
				"value": 0
			  },
			  "lineHeight": {
				"unit": "PERCENT",
				"value": 150
			  },
			  "fontName": {
				"family": "Inter",
				"style": "Regular"
			  },
			  "autoRename": true,
			  "textAlignHorizontal": "RIGHT",
			  "textAlignVertical": "TOP",
			  "textAutoResize": "HEIGHT",
			  "textStyleId": "",
			  "reactions": [],
			  "type": "TEXT",
			  "children": []
			},
			{
			  "makersHtmlTag": "",
			  "makersClasses": "",
			  "makersComponent": "",
			  "makersBackgroundImage": "",
			  "makersExportAsSvg": "",
			  "makersPageUrl": "",
			  "makersPageTitle": "",
			  "makersPageImage": "",
			  "makersPageDescription": "",
			  "id": "281:1475",
			  "parent": {
				"id": "281:1471"
			  },
			  "name": "MAKERS_BUTTONS_GROUP",
			  "removed": false,
			  "widgetEvents": [],
			  "visible": true,
			  "locked": false,
			  "opacity": 1,
			  "blendMode": "PASS_THROUGH",
			  "isMask": false,
			  "effects": [],
			  "effectStyleId": "",
			  "relativeTransform": [
				[
				  1,
				  0,
				  260
				],
				[
				  0,
				  1,
				  371
				]
			  ],
			  "absoluteTransform": [
				[
				  1,
				  0,
				  2711
				],
				[
				  0,
				  1,
				  -369
				]
			  ],
			  "x": 260,
			  "y": 371,
			  "width": 240,
			  "height": 52,
			  "absoluteRenderBounds": {
				"x": 2711,
				"y": -369,
				"width": 240,
				"height": 52
			  },
			  "rotation": 0,
			  "layoutAlign": "INHERIT",
			  "constrainProportions": false,
			  "layoutGrow": 0,
			  "children": [
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1475;281:1444",
				  "parent": {
					"id": "281:1475"
				  },
				  "name": "MAKERS_BUTTON",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  0
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2711
					],
					[
					  0,
					  1,
					  -369
					]
				  ],
				  "x": 0,
				  "y": 0,
				  "width": 115,
				  "height": 52,
				  "absoluteRenderBounds": {
					"x": 2711,
					"y": -369,
					"width": 115,
					"height": 52
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I281:1475;281:1444;118:1071",
					  "listSpacing": 0,
					  "parent": {
						"id": "I281:1475;281:1444"
					  },
					  "name": "Button",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 1,
							"g": 1,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  28
						],
						[
						  0,
						  1,
						  6
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2739
						],
						[
						  0,
						  1,
						  -363
						]
					  ],
					  "x": 28,
					  "y": 6,
					  "width": 59,
					  "height": 40,
					  "absoluteRenderBounds": {
						"x": 2740.655517578125,
						"y": -349.0909118652344,
						"width": 55.8232421875,
						"height": 13.282684326171875
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Button",
					  "hasMissingFont": false,
					  "fontSize": 18,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PIXELS",
						"value": 40
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0,
						"g": 0.4117647111415863,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 10,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 10,
				  "topRightRadius": 10,
				  "bottomLeftRadius": 10,
				  "bottomRightRadius": 10,
				  "paddingLeft": 28,
				  "paddingRight": 28,
				  "paddingTop": 6,
				  "paddingBottom": 6,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "118:1070"
				  },
				  "scaleFactor": 1,
				  "reactions": [
					{
					  "action": {
						"type": "NODE",
						"destinationId": "118:1072",
						"navigation": "CHANGE_TO",
						"transition": {
						  "type": "SMART_ANIMATE",
						  "easing": {
							"type": "EASE_IN_AND_OUT"
						  },
						  "duration": 0.30000001192092896
						},
						"preserveScrollPosition": false
					  },
					  "trigger": {
						"type": "ON_HOVER"
					  }
					}
				  ],
				  "variantProperties": {
					"State": "Default",
					"Type": "Primary"
				  },
				  "type": "INSTANCE"
				},
				{
				  "makersHtmlTag": "",
				  "makersClasses": "",
				  "makersComponent": "",
				  "makersBackgroundImage": "",
				  "makersExportAsSvg": "",
				  "makersPageUrl": "",
				  "makersPageTitle": "",
				  "makersPageImage": "",
				  "makersPageDescription": "",
				  "id": "I281:1475;281:1447",
				  "parent": {
					"id": "281:1475"
				  },
				  "name": "MAKERS_BUTTON",
				  "removed": false,
				  "widgetEvents": [],
				  "visible": true,
				  "locked": false,
				  "opacity": 1,
				  "blendMode": "PASS_THROUGH",
				  "isMask": false,
				  "effects": [],
				  "effectStyleId": "",
				  "relativeTransform": [
					[
					  1,
					  0,
					  125
					],
					[
					  0,
					  1,
					  0
					]
				  ],
				  "absoluteTransform": [
					[
					  1,
					  0,
					  2836
					],
					[
					  0,
					  1,
					  -369
					]
				  ],
				  "x": 125,
				  "y": 0,
				  "width": 115,
				  "height": 52,
				  "absoluteRenderBounds": {
					"x": 2836,
					"y": -369,
					"width": 115,
					"height": 52
				  },
				  "rotation": 0,
				  "layoutAlign": "INHERIT",
				  "constrainProportions": false,
				  "layoutGrow": 0,
				  "children": [
					{
					  "makersHtmlTag": "",
					  "makersClasses": "",
					  "makersComponent": "",
					  "makersBackgroundImage": "",
					  "makersExportAsSvg": "",
					  "makersPageUrl": "",
					  "makersPageTitle": "",
					  "makersPageImage": "",
					  "makersPageDescription": "",
					  "id": "I281:1475;281:1447;118:1075",
					  "listSpacing": 0,
					  "parent": {
						"id": "I281:1475;281:1447"
					  },
					  "name": "Button",
					  "removed": false,
					  "widgetEvents": [],
					  "visible": true,
					  "locked": false,
					  "opacity": 1,
					  "blendMode": "PASS_THROUGH",
					  "isMask": false,
					  "effects": [],
					  "effectStyleId": "",
					  "fills": [
						{
						  "type": "SOLID",
						  "visible": true,
						  "opacity": 1,
						  "blendMode": "NORMAL",
						  "color": {
							"r": 0.008333325386047363,
							"g": 0.28684401512145996,
							"b": 1
						  }
						}
					  ],
					  "fillStyleId": "",
					  "strokes": [],
					  "strokeStyleId": "",
					  "strokeWeight": 2,
					  "strokeAlign": "OUTSIDE",
					  "strokeJoin": "MITER",
					  "dashPattern": [],
					  "strokeCap": "NONE",
					  "strokeMiterLimit": 4,
					  "relativeTransform": [
						[
						  1,
						  0,
						  28
						],
						[
						  0,
						  1,
						  6
						]
					  ],
					  "absoluteTransform": [
						[
						  1,
						  0,
						  2864
						],
						[
						  0,
						  1,
						  -363
						]
					  ],
					  "x": 28,
					  "y": 6,
					  "width": 59,
					  "height": 40,
					  "absoluteRenderBounds": {
						"x": 2865.655517578125,
						"y": -349.0909118652344,
						"width": 55.8232421875,
						"height": 13.282684326171875
					  },
					  "rotation": 0,
					  "layoutAlign": "INHERIT",
					  "constrainProportions": false,
					  "layoutGrow": 0,
					  "exportSettings": [],
					  "constraints": {
						"horizontal": "MIN",
						"vertical": "MIN"
					  },
					  "characters": "Button",
					  "hasMissingFont": false,
					  "fontSize": 18,
					  "paragraphIndent": 0,
					  "paragraphSpacing": 0,
					  "textCase": "ORIGINAL",
					  "textDecoration": "NONE",
					  "letterSpacing": {
						"unit": "PERCENT",
						"value": 0
					  },
					  "lineHeight": {
						"unit": "PIXELS",
						"value": 40
					  },
					  "fontName": {
						"family": "Inter",
						"style": "Semi Bold"
					  },
					  "autoRename": true,
					  "textAlignHorizontal": "CENTER",
					  "textAlignVertical": "TOP",
					  "textAutoResize": "WIDTH_AND_HEIGHT",
					  "textStyleId": "",
					  "reactions": [],
					  "type": "TEXT",
					  "children": []
					}
				  ],
				  "exportSettings": [],
				  "fills": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 1,
						"g": 1,
						"b": 1
					  }
					}
				  ],
				  "fillStyleId": "",
				  "strokes": [
					{
					  "type": "SOLID",
					  "visible": true,
					  "opacity": 1,
					  "blendMode": "NORMAL",
					  "color": {
						"r": 0.008333325386047363,
						"g": 0.28684401512145996,
						"b": 1
					  }
					}
				  ],
				  "strokeStyleId": "",
				  "strokeWeight": 2,
				  "strokeAlign": "INSIDE",
				  "strokeJoin": "MITER",
				  "dashPattern": [],
				  "strokeCap": "NONE",
				  "strokeMiterLimit": 4,
				  "cornerRadius": 10,
				  "cornerSmoothing": 0,
				  "topLeftRadius": 10,
				  "topRightRadius": 10,
				  "bottomLeftRadius": 10,
				  "bottomRightRadius": 10,
				  "paddingLeft": 28,
				  "paddingRight": 28,
				  "paddingTop": 6,
				  "paddingBottom": 6,
				  "primaryAxisAlignItems": "CENTER",
				  "counterAxisAlignItems": "CENTER",
				  "primaryAxisSizingMode": "AUTO",
				  "layoutGrids": [],
				  "gridStyleId": "",
				  "backgroundStyleId": "",
				  "clipsContent": false,
				  "guides": [],
				  "expanded": false,
				  "constraints": {
					"horizontal": "MIN",
					"vertical": "MIN"
				  },
				  "layoutMode": "HORIZONTAL",
				  "counterAxisSizingMode": "AUTO",
				  "itemSpacing": 16,
				  "overflowDirection": "NONE",
				  "numberOfFixedChildren": 0,
				  "overlayPositionType": "CENTER",
				  "overlayBackground": {
					"type": "NONE"
				  },
				  "overlayBackgroundInteraction": "NONE",
				  "mainComponent": {
					"id": "118:1074"
				  },
				  "scaleFactor": 1,
				  "reactions": [
					{
					  "action": {
						"type": "NODE",
						"destinationId": "118:1076",
						"navigation": "CHANGE_TO",
						"transition": {
						  "type": "SMART_ANIMATE",
						  "easing": {
							"type": "EASE_IN_AND_OUT"
						  },
						  "duration": 0.30000001192092896
						},
						"preserveScrollPosition": false
					  },
					  "trigger": {
						"type": "ON_HOVER"
					  }
					}
				  ],
				  "variantProperties": {
					"State": "Default",
					"Type": "Secondary"
				  },
				  "type": "INSTANCE"
				}
			  ],
			  "exportSettings": [],
			  "fills": [],
			  "fillStyleId": "",
			  "strokes": [],
			  "strokeStyleId": "",
			  "strokeWeight": 1,
			  "strokeAlign": "INSIDE",
			  "strokeJoin": "MITER",
			  "dashPattern": [],
			  "strokeCap": "NONE",
			  "strokeMiterLimit": 4,
			  "cornerRadius": 0,
			  "cornerSmoothing": 0,
			  "topLeftRadius": 0,
			  "topRightRadius": 0,
			  "bottomLeftRadius": 0,
			  "bottomRightRadius": 0,
			  "paddingLeft": 0,
			  "paddingRight": 0,
			  "paddingTop": 0,
			  "paddingBottom": 0,
			  "primaryAxisAlignItems": "MIN",
			  "counterAxisAlignItems": "MIN",
			  "primaryAxisSizingMode": "AUTO",
			  "layoutGrids": [],
			  "gridStyleId": "",
			  "backgroundStyleId": "",
			  "clipsContent": false,
			  "guides": [],
			  "expanded": false,
			  "constraints": {
				"horizontal": "MIN",
				"vertical": "MIN"
			  },
			  "layoutMode": "HORIZONTAL",
			  "counterAxisSizingMode": "AUTO",
			  "itemSpacing": 10,
			  "overflowDirection": "NONE",
			  "numberOfFixedChildren": 0,
			  "overlayPositionType": "CENTER",
			  "overlayBackground": {
				"type": "NONE"
			  },
			  "overlayBackgroundInteraction": "NONE",
			  "mainComponent": {
				"id": "281:1450"
			  },
			  "scaleFactor": 1,
			  "reactions": [],
			  "variantProperties": {
				"Direction": "Horizontal"
			  },
			  "type": "INSTANCE"
			}
		  ],
		  "exportSettings": [],
		  "fills": [],
		  "fillStyleId": "",
		  "strokes": [],
		  "strokeStyleId": "",
		  "strokeWeight": 1,
		  "strokeAlign": "INSIDE",
		  "strokeJoin": "MITER",
		  "dashPattern": [],
		  "strokeCap": "NONE",
		  "strokeMiterLimit": 4,
		  "cornerRadius": 0,
		  "cornerSmoothing": 0,
		  "topLeftRadius": 0,
		  "topRightRadius": 0,
		  "bottomLeftRadius": 0,
		  "bottomRightRadius": 0,
		  "paddingLeft": 0,
		  "paddingRight": 0,
		  "paddingTop": 48,
		  "paddingBottom": 48,
		  "primaryAxisAlignItems": "MIN",
		  "counterAxisAlignItems": "MAX",
		  "primaryAxisSizingMode": "AUTO",
		  "layoutGrids": [],
		  "gridStyleId": "",
		  "backgroundStyleId": "",
		  "clipsContent": false,
		  "guides": [],
		  "expanded": true,
		  "constraints": {
			"horizontal": "MIN",
			"vertical": "MIN"
		  },
		  "layoutMode": "VERTICAL",
		  "counterAxisSizingMode": "FIXED",
		  "itemSpacing": 24,
		  "overflowDirection": "NONE",
		  "numberOfFixedChildren": 0,
		  "overlayPositionType": "CENTER",
		  "overlayBackground": {
			"type": "NONE"
		  },
		  "overlayBackgroundInteraction": "NONE",
		  "description": "",
		  "documentationLinks": [],
		  "remote": false,
		  "key": "b655736c5a05495a5f2781907964410b28f44eb8",
		  "reactions": [],
		  "variantProperties": {
			"Align": "Right"
		  },
		  "type": "COMPONENT"
		}
	  ],
	  "name": "MAKERS_TEXT_CONTENT",
	  "styles": {
		"itemSpacing": 20,
		"layoutMode": "HORIZONTAL",
		"primaryAxisSizingMode": "AUTO",
		"paddingTop": 20,
		"paddingRight": 20,
		"paddingBottom": 20,
		"paddingLeft": 20,
		"counterAxisSizingMode": "AUTO",
		"primaryAxisAlignItems": "MIN",
		"counterAxisAlignItems": "MIN"
	  },
	  "defaultInstanceIndex": 0
	}
  ]
`;
