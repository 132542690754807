export const columns = () => {
	return columnsJson;
}
const columnsJson = `[
	{
		"makersText": "",
		"makersAttrs": "",
		"makersHtmlTag": "",
		"makersClasses": "",
		"makersComponent": "",
		"makersVariantProps": "",
		"makersBackgroundImage": "",
		"makersExportAsSvg": "",
		"makersInteractions": "",
		"makersPageUrl": "",
		"makersPageTitle": "",
		"makersPageImage": "",
		"makersPageDescription": "",
		"id": "17:778",
		"parent": {
		  "id": "0:1"
		},
		"name": "COLUMNS SECTION",
		"removed": false,
		"widgetEvents": [],
		"visible": true,
		"locked": false,
		"opacity": 1,
		"blendMode": "PASS_THROUGH",
		"isMask": false,
		"effects": [],
		"effectStyleId": "",
		"relativeTransform": [
		  [
			1,
			0,
			40
		  ],
		  [
			0,
			1,
			-796
		  ]
		],
		"absoluteTransform": [
		  [
			1,
			0,
			40
		  ],
		  [
			0,
			1,
			-796
		  ]
		],
		"x": 40,
		"y": -796,
		"width": 1440,
		"height": 488,
		"absoluteRenderBounds": {
		  "x": 40,
		  "y": -796,
		  "width": 1440,
		  "height": 488
		},
		"rotation": 0,
		"layoutAlign": "INHERIT",
		"constrainProportions": false,
		"layoutGrow": 0,
		"children": [
		  {
			"makersText": "",
			"makersAttrs": "",
			"makersHtmlTag": "div",
			"makersClasses": "columns",
			"makersComponent": "",
			"makersVariantProps": "",
			"makersBackgroundImage": "",
			"makersExportAsSvg": "",
			"makersInteractions": "",
			"makersPageUrl": "",
			"makersPageTitle": "",
			"makersPageImage": "",
			"makersPageDescription": "",
			"id": "17:626",
			"parent": {
			  "id": "17:778"
			},
			"name": "COLUMNS",
			"removed": false,
			"widgetEvents": [],
			"visible": true,
			"locked": false,
			"opacity": 1,
			"blendMode": "PASS_THROUGH",
			"isMask": false,
			"effects": [],
			"effectStyleId": "",
			"relativeTransform": [
			  [
				1,
				0,
				220
			  ],
			  [
				0,
				1,
				0
			  ]
			],
			"absoluteTransform": [
			  [
				1,
				0,
				260
			  ],
			  [
				0,
				1,
				-796
			  ]
			],
			"x": 220,
			"y": 0,
			"width": 1000,
			"height": 488,
			"absoluteRenderBounds": {
			  "x": 260,
			  "y": -796,
			  "width": 1000,
			  "height": 488
			},
			"rotation": 0,
			"layoutAlign": "INHERIT",
			"constrainProportions": false,
			"layoutGrow": 0,
			"children": [
			  {
				"makersText": "",
				"makersAttrs": "",
				"makersHtmlTag": "",
				"makersClasses": "",
				"makersComponent": "",
				"makersVariantProps": "",
				"makersBackgroundImage": "",
				"makersExportAsSvg": "",
				"makersInteractions": "",
				"makersPageUrl": "",
				"makersPageTitle": "",
				"makersPageImage": "",
				"makersPageDescription": "",
				"id": "17:627",
				"parent": {
				  "id": "17:626"
				},
				"name": "Column | ADD YOUR CONTENT HERE",
				"removed": false,
				"widgetEvents": [],
				"visible": true,
				"locked": false,
				"opacity": 1,
				"blendMode": "PASS_THROUGH",
				"isMask": false,
				"effects": [],
				"effectStyleId": "",
				"relativeTransform": [
				  [
					1,
					0,
					0
				  ],
				  [
					0,
					1,
					0
				  ]
				],
				"absoluteTransform": [
				  [
					1,
					0,
					260
				  ],
				  [
					0,
					1,
					-796
				  ]
				],
				"x": 0,
				"y": 0,
				"width": 484,
				"height": 432,
				"absoluteRenderBounds": {
				  "x": 260,
				  "y": -796,
				  "width": 484,
				  "height": 432
				},
				"rotation": 0,
				"layoutAlign": "INHERIT",
				"constrainProportions": false,
				"layoutGrow": 1,
				"children": [],
				"exportSettings": [],
				"fills": [],
				"fillStyleId": "",
				"strokes": [],
				"strokeStyleId": "",
				"strokeWeight": 1,
				"strokeAlign": "INSIDE",
				"strokeJoin": "MITER",
				"dashPattern": [],
				"strokeCap": "NONE",
				"strokeMiterLimit": 4,
				"cornerRadius": 0,
				"cornerSmoothing": 0,
				"topLeftRadius": 0,
				"topRightRadius": 0,
				"bottomLeftRadius": 0,
				"bottomRightRadius": 0,
				"paddingLeft": 0,
				"paddingRight": 0,
				"paddingTop": 24,
				"paddingBottom": 0,
				"primaryAxisAlignItems": "MIN",
				"counterAxisAlignItems": "MIN",
				"primaryAxisSizingMode": "AUTO",
				"layoutGrids": [],
				"gridStyleId": "",
				"backgroundStyleId": "",
				"clipsContent": false,
				"guides": [],
				"expanded": true,
				"constraints": {
				  "horizontal": "MIN",
				  "vertical": "MIN"
				},
				"layoutMode": "VERTICAL",
				"counterAxisSizingMode": "FIXED",
				"itemSpacing": 16,
				"overflowDirection": "NONE",
				"numberOfFixedChildren": 0,
				"overlayPositionType": "CENTER",
				"overlayBackground": {
				  "type": "NONE"
				},
				"overlayBackgroundInteraction": "NONE",
				"reactions": [],
				"type": "FRAME"
			  },
			  {
				"makersText": "",
				"makersAttrs": "",
				"makersHtmlTag": "",
				"makersClasses": "",
				"makersComponent": "",
				"makersVariantProps": "",
				"makersBackgroundImage": "",
				"makersExportAsSvg": "",
				"makersInteractions": "",
				"makersPageUrl": "",
				"makersPageTitle": "",
				"makersPageImage": "",
				"makersPageDescription": "",
				"id": "17:629",
				"parent": {
				  "id": "17:626"
				},
				"name": "Column | ADD YOUR CONTENT HERE",
				"removed": false,
				"widgetEvents": [],
				"visible": true,
				"locked": false,
				"opacity": 1,
				"blendMode": "PASS_THROUGH",
				"isMask": false,
				"effects": [],
				"effectStyleId": "",
				"relativeTransform": [
				  [
					1,
					0,
					516
				  ],
				  [
					0,
					1,
					0
				  ]
				],
				"absoluteTransform": [
				  [
					1,
					0,
					776
				  ],
				  [
					0,
					1,
					-796
				  ]
				],
				"x": 516,
				"y": 0,
				"width": 484,
				"height": 432,
				"absoluteRenderBounds": {
				  "x": 776,
				  "y": -796,
				  "width": 484,
				  "height": 432
				},
				"rotation": 0,
				"layoutAlign": "INHERIT",
				"constrainProportions": false,
				"layoutGrow": 1,
				"children": [],
				"exportSettings": [],
				"fills": [],
				"fillStyleId": "",
				"strokes": [],
				"strokeStyleId": "",
				"strokeWeight": 1,
				"strokeAlign": "INSIDE",
				"strokeJoin": "MITER",
				"dashPattern": [],
				"strokeCap": "NONE",
				"strokeMiterLimit": 4,
				"cornerRadius": 0,
				"cornerSmoothing": 0,
				"topLeftRadius": 0,
				"topRightRadius": 0,
				"bottomLeftRadius": 0,
				"bottomRightRadius": 0,
				"paddingLeft": 0,
				"paddingRight": 0,
				"paddingTop": 24,
				"paddingBottom": 0,
				"primaryAxisAlignItems": "MIN",
				"counterAxisAlignItems": "MIN",
				"primaryAxisSizingMode": "AUTO",
				"layoutGrids": [],
				"gridStyleId": "",
				"backgroundStyleId": "",
				"clipsContent": false,
				"guides": [],
				"expanded": true,
				"constraints": {
				  "horizontal": "MIN",
				  "vertical": "MIN"
				},
				"layoutMode": "VERTICAL",
				"counterAxisSizingMode": "FIXED",
				"itemSpacing": 16,
				"overflowDirection": "NONE",
				"numberOfFixedChildren": 0,
				"overlayPositionType": "CENTER",
				"overlayBackground": {
				  "type": "NONE"
				},
				"overlayBackgroundInteraction": "NONE",
				"reactions": [],
				"type": "FRAME"
			  }
			],
			"exportSettings": [],
			"fills": [],
			"fillStyleId": "",
			"strokes": [],
			"strokeStyleId": "",
			"strokeWeight": 1,
			"strokeAlign": "INSIDE",
			"strokeJoin": "MITER",
			"dashPattern": [],
			"strokeCap": "NONE",
			"strokeMiterLimit": 4,
			"cornerRadius": 0,
			"cornerSmoothing": 0,
			"topLeftRadius": 0,
			"topRightRadius": 0,
			"bottomLeftRadius": 0,
			"bottomRightRadius": 0,
			"paddingLeft": 0,
			"paddingRight": 0,
			"paddingTop": 0,
			"paddingBottom": 56,
			"primaryAxisAlignItems": "MIN",
			"counterAxisAlignItems": "MIN",
			"primaryAxisSizingMode": "FIXED",
			"layoutGrids": [],
			"gridStyleId": "",
			"backgroundStyleId": "",
			"clipsContent": false,
			"guides": [],
			"expanded": false,
			"constraints": {
			  "horizontal": "MIN",
			  "vertical": "MIN"
			},
			"layoutMode": "HORIZONTAL",
			"counterAxisSizingMode": "AUTO",
			"itemSpacing": 32,
			"overflowDirection": "NONE",
			"numberOfFixedChildren": 0,
			"overlayPositionType": "CENTER",
			"overlayBackground": {
			  "type": "NONE"
			},
			"overlayBackgroundInteraction": "NONE",
			"reactions": [],
			"type": "FRAME"
		  }
		],
		"exportSettings": [],
		"fills": [],
		"fillStyleId": "",
		"strokes": [],
		"strokeStyleId": "",
		"strokeWeight": 1,
		"strokeAlign": "INSIDE",
		"strokeJoin": "MITER",
		"dashPattern": [],
		"strokeCap": "NONE",
		"strokeMiterLimit": 4,
		"cornerRadius": 0,
		"cornerSmoothing": 0,
		"topLeftRadius": 0,
		"topRightRadius": 0,
		"bottomLeftRadius": 0,
		"bottomRightRadius": 0,
		"paddingLeft": 20,
		"paddingRight": 20,
		"paddingTop": 0,
		"paddingBottom": 0,
		"primaryAxisAlignItems": "CENTER",
		"counterAxisAlignItems": "CENTER",
		"primaryAxisSizingMode": "AUTO",
		"layoutGrids": [],
		"gridStyleId": "",
		"backgroundStyleId": "",
		"clipsContent": false,
		"guides": [],
		"expanded": false,
		"constraints": {
		  "horizontal": "MIN",
		  "vertical": "MIN"
		},
		"layoutMode": "VERTICAL",
		"counterAxisSizingMode": "FIXED",
		"itemSpacing": 10,
		"overflowDirection": "NONE",
		"numberOfFixedChildren": 0,
		"overlayPositionType": "CENTER",
		"overlayBackground": {
		  "type": "NONE"
		},
		"overlayBackgroundInteraction": "NONE",
		"reactions": [],
		"type": "FRAME"
	  }
]`;
