<template>
    <div class="makers-auth-page makers-plans-page">
        <makers-component-header header-name="Plans" back-page="home" />
        <main>
            <div class="content-wrapper">
                <h1>Plans.</h1>
                <h2>As simple as it can be.</h2>
                <h2>$12/month per site.</h2>
                <button
                    class="button button--primary"
                    @click="(isAuthenticated) ? setCurrentPage({name: 'home'}) : setCurrentPage({name: 'signup'})"
                >
                    Create your site
                </button>

                <div class="plan-features">
                    <div class="feature" v-for="(feature, index) in features" :key="index">
                        <div class="type type--small">{{feature.name}}</div>
                        <div class="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17l-5-5" stroke="#5DCBAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        data() {
            return {
                features: [
                    {name: 'Figma to Site'},
                    {name: 'Custom Domains'},
                    {name: 'Unlimited Pages'},
                    {name: 'Super Fast'},
                    {name: 'Great SEO'},
                    {name: 'Fully Responsive'},
                    {name: 'Automatic SSL (https)'},
                    {name: 'Custom Social Media Images'},
                    {name: 'Custom Favicon'},
                    {name: 'Live Site Previews'},
                ]
            }
        },
        computed: {
            ...mapGetters(['isAuthenticated']),
        },
        methods: {
            ...mapMutations(['setCurrentPage']),
        }
    }
</script>
