<template>
	<div class="set-text-tag">
		<div class="start">
			<div class="icon-wrapper">
				<div class="icon">
					<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.6465 16.3535 17 11.7071 17.7071 11l5.3536 5.3535-5.3536 5.3536L17 21l4.6465-4.6465ZM9.41417 16.3536 14.0607 21l-.7071.7071-5.35363-5.3535L13.3536 11l.7071.7071-4.64653 4.6465Z" fill="#000"/></svg>
				</div>
			</div>
			<div class="type type--xsmall">Assign HTML Tag:</div>
		</div>
		<div class="buttons-group">
			<button
				class="button"
				@click="setTextTag(tag)"
				v-for="(tag, index) in tags" :key="index"
				:class="{ 'button--active': makersHtmlTag === tag.id }"
			>
				{{tag.display}}
			</button>
		</div>
	</div>
</template>

<script>
	import { dispatch } from '../figma/ui-message-handler';
	export default {
		props: ['currentSelection'],
		data() {
			return {
				makersHtmlTag: '',
				tags: [
					{id: 'h1', display: 'H1'},
					{id: 'h2', display: 'H2'},
					{id: 'p', display: 'p'},
					{id: 'div', display: 'div'},
				]
			}
		},
		mounted() {
			this.makersHtmlTag = (this.currentSelection.makersHtmlTag == "" || this.currentSelection.makersHtmlTag == null ) ? 'div' : this.currentSelection.makersHtmlTag;
		},
		watch: {
			currentSelection(newVal) {
				this.makersHtmlTag = newVal.makersHtmlTag;
			}
		},
		methods: {
			setTextTag(tag) {
				this.makersHtmlTag = tag.id
				const payload = { id: this.currentSelection.id, key: 'makersHtmlTag', value: this.makersHtmlTag };
                dispatch('updateNode', payload);
			},
		}
	}
</script>
