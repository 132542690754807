import api from '../../api/user_api';

const state = {
    token: null,
    user: null,
    isSubscribed: null,
}

const getters = {
    isAuthenticated(state) {
        return (state.token && state.user) ? true : false;
    },

    isAdmin(state) {
        return (state.token && state.user && state.user.email === 'john@makers.so') ? true : false;
    },

    user(state) {
        return state.user;
    },

    isSubscribed(state) {
        return (state.isSubscribed);
    }
}

const actions = {
    // Before Auth
    async isEmailAvailable(_, data) {
        return await api.isEmailAvailable(data);
    },
    
    sendRecoverPasswordLink(_, data) {
        return api.sendRecoverPasswordLink(data);
    },

    async setNewPassword(_, data) {
        return await api.setNewPassword(data);
    },

    // CRUD
    async createUser({dispatch}, data) {
        return await api.createUser(data)
            .then((response) => dispatch('authenticateUser', response.data));
    },

    async updateUser({commit}, data) {
        return await api.updateUser(data)
            .then((response) => commit('updateUser', response.data.result));
    },

    // Other
    async sendSupportMessage(_, data) {
        return await api.sendSupportMessage(data);
    },
    
    // Subscription
    async getIsSubscribed({commit}, data) {
        const response = await api.isSubscribed(data);
        commit('setIsSubscribed', response.data);
    },
    async updateSubscriptionQuantity(_, data) {
        return await api.updateSubscriptionQuantity(data);
    },

    // Authentication
    async authenticateUser({dispatch}, data) {
        return await api.authenticateUser(data)
            .then((response) => dispatch('attemptAuthentication', response.data.token));
    },

    async attemptAuthentication({commit, state}, token) {
        if (token) commit('setToken', token);
        if (!state.token) return;

        return await api.attemptAuthentication()
            .then((response) => commit('setUser', response.data))
            .catch(() => {
                commit('setToken', null);
                commit('setUser', null);
            })
    },

    async signOut({commit}) {
        return await api.signOut()
            .then(() => {
                commit('setToken', null);
                commit('setUser', null);
            });
    }
}

const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    setUser(state, user) {
        if (user === null) {state.user = null; return;}
        state.user = user;
    },
    updateUser(state, user) {
        state.user = user;
    },
    setIsSubscribed(state, isSubscribed) {
        state.isSubscribed = isSubscribed;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}