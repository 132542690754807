import api from '../../api/site_api';

const state = {
    sites: [],
    site: null,
}

const getters = {
    getSiteByFigmaId(id) {
        return this.site.id === id || null;
    }
}

const actions = {

    // CREATE
    async createSite({commit}, data) {
        return await api.createSite(data)
            .then((response) => {
                commit('setSite', response.data);
                return response.data;
            });
    },

    // READ
    async getSites({commit}, id) {
        return await api.getSites(id)
            .then((response) => {
                commit('setSites', response.data);
                return response.data;
            });
    },
    async getSiteByFigmaId({commit}, id) {
        return await api.getSiteByFigmaId(id)
            .then((response) => {
                commit('setSite', response.data);
                return response.data;
            }).catch(() => null); // Return null if no site was found
    },

    // UPDATE
    async updateSite({commit}, data) {
        return await api.updateSite(data)
            .then((response) => commit('setSite', response.data));
    },
    async publishSite({commit}, data) {
        commit('setSiteStatus', 'building');
        const response = await api.publishSite(data);
		// console.log("response, ", response);
    },
    async storeImagesInOurBucket(_, data) {
        return await api.storeImages(data);
    },
    async updateSiteHomePage(_, data) {
        const response = await api.updateSiteHomePage(data);
        return response.data;
    },

    // DELETE
    async deleteSite({commit}, id) {
        const response = await api.deleteSite(id);
        if (response) commit('setSite', null);
    },
    async deleteSiteAll(_, id) {
        return await api.deleteSiteAll(id)
            .then((response) => response)
            .catch((error) => console.log(error));
    }

}

const mutations = {
    setSite(state, value) {
		state.site = value;
    },
    setSiteStatus(state, value) {
        state.site.status = value;
    },
    setSites(state, value) {
        state.sites = value;
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
