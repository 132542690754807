<script>
	import { mapState, mapActions, mapMutations } from 'vuex';
	import { getImageUrlFromBucket } from '../../helpers/utilities';
	import { dispatch, handleEvent } from '../../figma/ui-message-handler';
	export default {
		render() {},
		mounted() {
			handleEvent("storeImage", (data) => this.storeImageUrlFromBytes(data));
			handleEvent("shouldStoreImage", (image) => this.shouldStoreImage(image));
            handleEvent("getArrayBufferFromImage", (imageUrl) => this.handleArrayBufferFromImage(imageUrl));


			// handleEvent("saveImagesInOurBucket", (json) => this.saveImagesInOurBucket(json));
		},
		computed: {
			...mapState(['newOrUpdatedImages', 'fileKey']),
		},
		methods: {
			...mapActions(['storeImagesInOurBucket']),
            ...mapMutations(['pushToNewOrUpdatedImages']),

            async handleArrayBufferFromImage(imageUrl) {
                const request = new XMLHttpRequest();
                request.open("GET", imageUrl);
                request.responseType = "arraybuffer";
                request.setRequestHeader('Content-Type', 'application/xml');

                request.onload = async () => {
                    const arrayBufferView = await new Uint8Array(request.response);
                    dispatch("setArrayBufferFromImage", arrayBufferView );
                }
                request.send();
            },
			shouldStoreImage(data) {
				// Check what type of image we are storing:
				// Is it an SVG
				// Or a PNG
				const isOfTypeSvg = (data.node.makersExportAsSvg === "true");
				const imageHash = (!isOfTypeSvg) ? data.image.imageHash : `${this.fileKey}-${data.node.id}`.replaceAll(":", "-");
				if ( this.newOrUpdatedImages.includes(imageHash) === false ) {
					this.pushToNewOrUpdatedImages(imageHash);
				} else {
					const imageUrl = getImageUrlFromBucket(this.fileKey, imageHash);
					// const extension = (!isOfTypeSvg) ? '.png' : '.svg';
					const extension = (!isOfTypeSvg) ? '' : '.svg';
					this.updateNodeWithBackgroundImage(data.node.id, imageUrl + extension);
					return;
				}
				dispatch("getImageUrlFromBytes", {node: data.node, image: data.image, imageHash});
			},
			async storeImageUrlFromBytes(data) {
				const body = new FormData();
				const blob = new Blob([new Uint8Array(data.bytes).buffer]);
				body.append('image', blob);
				body.append('image_hash', data.imageHash);
				body.append('figma_id', this.fileKey);
				const imageUrl = await this.storeImagesInOurBucket(body);
                this.updateNodeWithBackgroundImage(data.node.id, imageUrl.data);
				dispatch("imageSavedSuccessfully", data.node);
			},

			updateNodeWithBackgroundImage(nodeId, imageUrl) {
                const updatedNode = { id: nodeId, key: 'makersBackgroundImage', value: imageUrl };
                dispatch('updateNode', updatedNode);
            },
		}
	}
</script>
