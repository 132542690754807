<template>
	<div class="set-image-alt">
		<div class="start">
			<div class="icon-wrapper">
				<div class="icon icon--image"></div>
			</div>
		</div>
		<div class="field">
			<div class="input">
				<input v-model="input" type="text" class="input__field  input__with_border" placeholder="Add alt: a text description of the image" />
			</div>
		</div>
	</div>
</template>

<script>
	import { dispatch } from '../figma/ui-message-handler';
	export default {
		props: ['currentSelection'],
		data() {
			return {
				alt: '',
				attrs: '',
				timeout: null,
			}
		},
		mounted() {
			this.setAlt();
		},
		computed: {
			input: {
				get() {
					return this.alt
				},
				set(val) {
					if (this.timeout) clearTimeout(this.timeout)
					this.timeout = setTimeout(() => {
						this.alt = val;
						if (this.attrs === '') {
							this.attrs = {alt: this.alt}
						} else {
							this.attrs["alt"] = this.alt;
						}
						const payload = { id: this.currentSelection.id, key: 'makersAttrs', value: JSON.stringify(this.attrs) };
						dispatch("updateNode", payload);
					}, 500)
				}
			}
		},
		watch: {
			currentSelection(newValue) {
				this.setAlt();
			}
		},
		methods: {
			setAlt() {
				if (this.currentSelection.makersAttrs == '' && this.currentSelection.makerAttrs == null) {
					this.alt = '';
					return;
				}
				this.attrs = JSON.parse(this.currentSelection.makersAttrs);
				if (! this.attrs.hasOwnProperty('alt')) {
					this.alt = '';
					return;
				}
				this.alt = this.attrs.alt;
			},
		}
	}
</script>
