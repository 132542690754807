<script>
	import { faq } from '../../assets/components/faq';
	import { main } from '../../assets/components/main';
	import { stats } from '../../assets/components/stats';
	import { footer } from '../../assets/components/footer';
	import { columns } from '../../assets/components/columns';
	import { features } from '../../assets/components/features';
	import { textContent } from '../../assets/components/text-content';
	import { callToAction } from '../../assets/components/call-to-action';
	import { navigationBar } from '../../assets/components/navigation-bar';
	import { dispatch, handleEvent } from '../../figma/ui-message-handler';
	export default {
		render() {},
		mounted() {
			handleEvent("getComponentJson", (id) => this.getComponentJson(id));
		},
		methods: {
			getComponentJson(id) {
				switch (id) {
					case "faq": dispatch('setFaqJson', faq());
					case "main": dispatch('setMainJson', main());
					case "stats": dispatch('setStatsJson', stats());
					case "footer": dispatch('setFooterJson', footer());
					case "columns": dispatch('setColumnsJson', columns());
					case "features": dispatch('setFeaturesJson', features());
					case "text-content": dispatch('setTextContentJson', textContent());
					case "call-to-action": dispatch('setCallToActionJson', callToAction());
					case "navigation-bar": dispatch('setNavigationBarJson', navigationBar());
				}
			}
		}
	}
</script>
