<template>
	<div class="makers-publish-page">
		<makers-component-header header-name="Publish" back-page="home"/>
		<main v-if="pages.length > 0">
			<div class="steps">
				<makers-site-pages v-if="currentStep === 1" :is-publish-workflow="true"></makers-site-pages>
				<makers-publish-custom-domain v-else-if="currentStep === 2" :has-domain-error-from-server="hasDomainErrorFromServer"></makers-publish-custom-domain>
				<makers-publish-favicon-analytics v-else-if="currentStep === 3" :show-title="true"></makers-publish-favicon-analytics>
				<makers-publish-fonts v-else-if="currentStep === 4" :show-title="true"></makers-publish-fonts>
				<makers-publish-is-ready v-else-if="currentStep === 5" :controller="controller"></makers-publish-is-ready>
				<makers-publish-site-is-publishing v-else-if="currentStep === 6"></makers-publish-site-is-publishing>
			</div>

			<makers-publish-workflow-footer v-if="currentStep !== 6"></makers-publish-workflow-footer>
		</main>
		<main v-else class="no-pages-found">
			<div class="intro">
				<h1>No Pages Found.</h1>
				<h2>You need to create at least one page to publish your site.</h2>
			</div>
			<section>
				<button class="button button--primary" @click="setCurrentPage({ name: 'home' })">
					Create a page
				</button>
			</section>
		</main>
	</div>
</template>
<script>
import { dispatch } from '../figma/ui-message-handler';
import { mapActions, mapMutations, mapState } from "vuex";
import { validateDomainName } from "../helpers/utilities";
export default {
	props: ['controller'],
	data() {
		return {
			isLoading: false,
			currentStep: 1,
			form: {
				domain: null,
				googleAnalyticsTag: null,
			},
			isDomainNameValid: null,
			hasDomainErrorFromServer: false,
		};
	},
	mounted() {
		if (this.site.site != null) {
			if (this.site.site.domain != null) {
				this.form.domain = this.site.site.domain.name;
				this.form.googleAnalyticsTag = this.site.site.domain.analytics_id;
				this.isDomainNameValid = validateDomainName(this.site.site.domain.name);
			}
		}
	},
	computed: {
		...mapState(["pages", "site", "domain"]),
	},
	methods: {
		...mapActions(["createDomain"]),
		...mapMutations(["setCurrentPage"]),

		changeCurrentStep(move) {
			move === "next" ? this.currentStep++ : this.currentStep--;
		},

		handleNewDomain() {
			this.isLoading = true;
			const siteId = this.site.site != null ? this.site.site.id : "temp";
			const body = new FormData();
			body.append("site_id", siteId);
			body.append("name", this.form.domain);
			this.createDomain(body)
				.then(() => {
					this.changeCurrentStep("next");
					this.isLoading = false;
				})
				.catch((e) => {
					this.isLoading = false;
					this.hasDomainErrorFromServer = true;
					dispatch("notify", "❌ This domain is already in use. Please try again 🙌");
				});
		},
	},
};
</script>
