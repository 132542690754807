<template>
	<div class="makers-purchase-confirmation">
		<template v-if="isAuth">
			<makers-template-purchase-if-authenticated
				:template="template"
				button-name="Buy template"
				:is-subscribed="isSubscribed"
				:error-message="paymentErrorMessage"
				:is-payment-loading="isPaymentLoading"
				@updatePaymentLoading="(value) => isPaymentLoading = value"
				@handlePayButtonClick="(data) => handlePayButtonClick(data)"
				@handlePaymentSuccess="(email) => $emit('handlePaymentSuccess', email)"
			/>
		</template>
		<template v-else>
			<div class="field">
				<label style="font-size: 15px; color: #30313D">Email</label>
				<div class="input">
					<input v-model="email" @input="removeErrors()" type="email" class="input__field  input__with_border" placeholder="The email to receive the template" required>
				</div>
			</div>
			<template v-if="isValidEmail">
				<makers-stripe-payments-element
					:template="template"
					button-name="Buy template"
					:is-loading="isPaymentLoading"
					:error-message="paymentErrorMessage"
					:price="template.price" class="mt-small"
					@updatePaymentLoading="(value) => isPaymentLoading = value"
					@handlePayButtonClick="(data) => handlePayButtonClick(data)"
				/>
			</template>
		</template>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	export default {
		props: ['template'],
		data() {
			return {
				email: '',
				isPaymentLoading: false,
				paymentErrorMessage: null,
			}
		},
		mounted() {
			if (this.isAuth) {
				this.email = this.user.user.email;
			}
		},
		computed: {
			...mapState(['user']),

			isAuth() {
				return this.user.user !== null;
			},
			isSubscribed() {
				return (!this.isAuth) ? false : this.user.isSubscribed;
			},
			isValidEmail() {
				return String(this.email)
					.toLowerCase()
					.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					);
			},
		},
		methods: {
			...mapMutations(['setCurrentPage']),

			async handlePayButtonClick(data) {
				const { paymentIntent } = await data.stripe.confirmPayment({
					elements: data.elements,
					redirect: "if_required",
					confirmParams: {
						return_url: `${process.env.VUE_APP_URL}/payment`,
					},
				});


				this.checkStatus(paymentIntent);
				this.isPaymentLoading = false;
			},
			async checkStatus(paymentIntent) {
				if (paymentIntent == null) return;
  				switch (paymentIntent.status) {
					case "succeeded":
						this.$emit("handlePaymentSuccess", this.email);
						break;
					case "processing":
						this.paymentErrorMessage = "Your payment is processing...";
						break;
					case "requires_payment_method":
						this.paymentErrorMessage = "Your payment was not successful, please try again.";
						break;
					default:
						this.paymentErrorMessage = "An unexpected error occured. Please contact us at support@makers.so";
					break;
				}
			},

			removeErrors() {
                if (this.hasErrors) {
                    this.hasErrors = false;
                }
            },
		}
	}
</script>
